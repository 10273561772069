import * as React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { theme } from 'twin.macro';

// components
import XAxisTick from './content/XAxisTick';
import YAxisTick from './content/YAxisTick';
import XAxisLabel from './content/XAxisLabel';
import CustomeSlider from './CustomeSlider';
import ChartLegend from './ChartLegend';
import PensionGraphTooltip from './content/PensionGraphTooltip';

// types
export type Chart = {
  age: number,
  thisYear: number,
  householderAmount: number,
  spouseAmount: number|null,
  totalAmount: number,
  reEmploymentIncome: number,
  publicPensionHouseholder: number,
  publicPensionSpouse: number|null,
  corporatePensionDbHouseholder: number,
  corporatePensionDcHouseholder: number,
  corporatePensionDcSpouse: number|null,
  idecoHouseholder: number,
  idecoSpouse: number|null,
}

type PensionGraphProps = {
  datas: Chart[]|undefined;
};
const PensionGraph: React.FC<PensionGraphProps> = ({ datas }) => {
  // 四捨五入されたグラフ
  const [chartDatas, setChartDatas] = React.useState<Chart[]>();

  // 指定範囲
  const [min, setMin] = React.useState<number>();
  const [max, setMax] = React.useState<number>();
  const [range, setRange] = React.useState([0, 0]);
  const [chartData, setChartData] = React.useState<Chart[]>();

  // ツールチップの位置
  const [tooltipY, setTooltipY] = React.useState(0);

  // ref属性の参照（BarChartの型が不明なのでanyを仕様）
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const chartRef = React.useRef<any>(null);

  React.useEffect(() => {
    // グラフデータを四捨五入する
    const newChartDatas = datas?.map((data) => {
      return {
        age: data.age,
        thisYear: data.thisYear,
        householderAmount: Math.floor(data.householderAmount / 1000) / 10,
        spouseAmount: data.spouseAmount !== null ? Math.floor(data.spouseAmount / 1000) / 10 : null,
        totalAmount: Math.floor(data.totalAmount / 1000) / 10,
        reEmploymentIncome: Math.floor(data.reEmploymentIncome / 1000) / 10,
        publicPensionHouseholder: Math.floor(data.publicPensionHouseholder / 1000) / 10,
        publicPensionSpouse: data.publicPensionSpouse !== null
          ? Math.floor(data.publicPensionSpouse / 1000) / 10 : null,
        corporatePensionDbHouseholder: Math.floor(data.corporatePensionDbHouseholder / 1000) / 10,
        corporatePensionDcHouseholder: Math.floor(data.corporatePensionDcHouseholder / 1000) / 10,
        corporatePensionDcSpouse: data.corporatePensionDcSpouse !== null
          ? Math.floor(data.corporatePensionDcSpouse / 1000) / 10 : null,
        idecoHouseholder: Math.floor(data.idecoHouseholder / 1000) / 10,
        idecoSpouse: data.idecoSpouse !== null ? Math.floor(data.idecoSpouse / 1000) / 10 : null,
      };
    });
    setChartDatas(newChartDatas);

    // 年齢の最小値と最大値を範囲指定にする
    if (datas !== undefined) {
      const ageArray = datas.map((data) => data.age);
      const newMin = Math.min.apply(null, ageArray);
      const newMax = Math.max.apply(null, ageArray);
      setMin(newMin);
      setMax(newMax);
      setRange([
        datas.findIndex((data) => data.age === newMin),
        datas.findIndex((data) => data.age === newMax),
      ]);
    }
  }, [datas]);

  React.useEffect(() => {
    if (chartDatas === undefined) {
      return;
    }

    // 指定範囲されたグラフを生成
    if (range !== undefined) {
      const rangeData = chartDatas.slice(range[0], range[1] + 1);
      setChartData(rangeData);
    }
  }, [chartDatas, range]);

  return (
    <>
      <p className="text-[10px] text-gray-500">
        受取額
        <br />
        (万円)
      </p>
      <div className="relative w-full pt-[184px]">
        <div
          className="absolute inset-0 -mx-3"
          onMouseLeave={() => chartRef.current.handleMouseLeave()}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              maxBarSize={20}
              margin={{
                top: 12, right: 12, left: 12, bottom: 12,
              }}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray="0" vertical={false} />
              <Bar dataKey="spouseAmount" stackId="bar" fill={theme('colors.green')} />
              <Bar dataKey="householderAmount" stackId="bar" fill={theme('colors.primary')} />
              <XAxis
                dataKey="age"
                tickLine={false}
                tick={<XAxisTick />}
                label={<XAxisLabel value="年齢 (歳)" />}
              />
              <YAxis
                tickFormatter={(n: number) => n.toLocaleString()}
                mirror
                axisLine={false}
                tickLine={false}
                tick={<YAxisTick />}
              />
              <XAxis
                xAxisId={0}
                axisLine={false}
                tickLine={false}
                dataKey="value"
                tick={<XAxisTick />}
              />
              <Tooltip
                cursor={false}
                trigger="click"
                content={(
                  <PensionGraphTooltip onComplete={(height) => {
                    // ツールチップの位置を指定する
                    setTooltipY(height);
                  }}
                  />
                )}
                wrapperStyle={{ zIndex: 10, top: (tooltipY + 50) * -1 }}
                allowEscapeViewBox={{ y: true }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      {chartDatas !== undefined && (
        <div className="mx-2">
          <CustomeSlider
            value={range}
            valueLabelFormat={(value: number) => {
              const item = chartDatas[value];
              return item !== undefined ? item.age : null;
            }}
            min={chartDatas.findIndex((data) => data.age === min)}
            max={chartDatas.findIndex((data) => data.age === max)}
            sx={{ mt: 0 }}
            onChange={(event: Event, newValue: number | number[]) => {
              if (typeof newValue === 'object') {
                setRange(newValue);
              }
            }}
            valueLabelDisplay="on"
          />
        </div>
      )}
      <ChartLegend
        options={[
          {
            color: 'bg-primary',
            label: 'あなた',
          },
          {
            color: 'bg-green',
            label: '配偶者',
          },
        ]}
      />
    </>
  );
};
export default PensionGraph;
