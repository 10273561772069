import * as React from 'react';

// services
import validation, { ValidationError } from '../../services/validation';

// components
import FailedMessage from './FailedMessage';

type InputEmailProps = {
  className?: string;
  defaultValue?: string|null;
  disabled?: boolean;
  onChange?: (_: string|null) => void;
  onError?: (_: ValidationError[]) => void;
};

/**
 * メールアドレス入力項目
 * @param props
 * @returns
 */
const InputEmail: React.FC<InputEmailProps> = ({
  className, defaultValue, disabled,
  onChange, onError,
}) => {
  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<ValidationError[]>([]);

  return (
    <>
      <div className="flex items-center">
        <input
          type="text"
          placeholder="(例) mail@example.com"
          className={`form-input w-full disabled:bg-gray-100 ${className}`}
          defaultValue={defaultValue ?? undefined}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            // 値をが正しいか検証する
            const { value } = event.currentTarget;
            const errorMessages: ValidationError[] = [];
            const errorMessage1 = validation(value, { email: true });
            if (errorMessage1 !== null) {
              errorMessages.push({ key: 'email', message: errorMessage1 });
            }
            if (onChange !== undefined) {
              onChange(value !== '' ? value : null);
            }
            setErrors(errorMessages);
            if (onError !== undefined) {
              onError(errorMessages);
            }
          }}
          disabled={disabled}
        />
      </div>
      {
        onError === undefined
        && <FailedMessage errors={errors} />
      }
    </>
  );
};
InputEmail.defaultProps = {
  className: '',
  defaultValue: undefined,
  disabled: false,
  onChange: undefined,
  onError: undefined,
};

export default InputEmail;
