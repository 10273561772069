import * as React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Area,
  ReferenceLine,
} from 'recharts';
import { theme } from 'twin.macro';

// キャッシュフローデータ
import { useAppSelector } from '../../app/hooks';
import { selectCashflowChart } from '../../reducers/cashflowReducer';
import type { CashflowChart } from '../../reducers/cashflowReducer';

// components
import Modal from '../../features/Modal';
import DetailBox from './CashflowDetail';
import XAxisTick from '../../features/recharts/content/XAxisTick';
import YAxisTick from '../../features/recharts/content/YAxisTick';
import CustomeSlider from '../../features/recharts/CustomeSlider';
import XAxisIconLabel from './content/XAxisIconLabel';
import ChartHelp from './ChartHelp';

// エリアチャート色指定用のグラデーションオフセット
const gradientOffset = (data: number[]) => {
  const dataMax = Math.max(...data);
  const dataMin = Math.min(...data);
  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }
  return dataMax / (dataMax - dataMin);
};

const CashflowGraph: React.FC = () => {
  const cashflows = useAppSelector(selectCashflowChart);

  // 四捨五入されたグラフ
  const [chartDatas, setChartDatas] = React.useState<CashflowChart[]>();

  // 指定範囲
  const [min, setMin] = React.useState<number>();
  const [max, setMax] = React.useState<number>();
  const [range, setRange] = React.useState([0, 0]);
  const [chartData, setChartData] = React.useState<CashflowChart[]>([]);

  // エリアチャートの色指定
  const [off, setOff] = React.useState(0);

  // 貯蓄が0になる年齢
  const [deadline, setDeadline] = React.useState<number>();

  // モーダル表示で選択された年齢
  const [activeAge, setActiveAge] = React.useState<number>();

  // メモリ管理
  const [xAxisTicks, setXAxisTicks] = React.useState<number[]>();
  const [yAxisTicks1, setYAxisTicks1] = React.useState<number[]>([]);
  const [yAxisTicks2, setYAxisTicks2] = React.useState<number[]>([]);

  // グラフの見方の表示状況
  const [showHelp, setShowHelp] = React.useState(false);

  React.useEffect(() => {
    // グラフデータを四捨五入する
    const newChartDatas = cashflows.map((c) => {
      c.incomings = Math.round(c.incomings / 10000);
      c.expenses = Math.round(c.expenses / 10000);
      c.savings = Math.round(c.savings / 10000);
      return c;
    });
    setChartDatas(newChartDatas);

    // 年齢の最小値と最大値を範囲指定にする
    const ageArray = cashflows.map((data) => data.age);
    const newMin = Math.min.apply(null, ageArray);
    const newMax = Math.max.apply(null, ageArray);
    setMin(newMin);
    setMax(newMax);
    setRange([
      cashflows.findIndex((data) => data.age === newMin),
      cashflows.findIndex((data) => data.age === newMax),
    ]);

    // エリアチャートが0になる位置を取得
    const deadlineItem = cashflows.find((item) => item.savings < 0);
    setDeadline(deadlineItem?.age);
  }, [cashflows]);

  React.useEffect(() => {
    if (chartDatas === undefined) {
      return;
    }

    // 表示範囲のデータを取得
    const newChartData = chartDatas.slice(range[0], range[1] + 1);
    setChartData(newChartData);

    // エリアチャートの色指定
    const offset = gradientOffset(newChartData.map((c) => c.savings));
    setOff(offset);

    // 5の倍数ごとに横軸のメモリを生成
    const xticks = newChartData
      .map((item) => item.age)
      .filter((age, i) => i === 0 || i === newChartData.length - 1 || age % 5 === 0);
    setXAxisTicks(xticks);

    // 左軸のメモリを生成
    let yaxis1Max = Math.max(
      ...newChartData.map((item) => Math.abs(Math.max(item.expenses, item.incomings))),
    );
    const yaxis1Step = 10 ** (String(Math.round(yaxis1Max)).length - 2);
    yaxis1Max = Math.ceil(yaxis1Max / yaxis1Step) * yaxis1Step;
    setYAxisTicks1([-yaxis1Max, -yaxis1Max / 2, 0, yaxis1Max / 2, yaxis1Max]);

    // 右軸のメモリを生成
    let yaxis2Max = Math.max(...newChartData.map((item) => Math.abs(item.savings)));
    const yaxis2Step = 10 ** (String(Math.round(yaxis2Max)).length - 2);
    yaxis2Max = Math.ceil(yaxis2Max / yaxis2Step) * yaxis2Step;
    setYAxisTicks2([-yaxis2Max, -yaxis2Max / 2, 0, yaxis2Max / 2, yaxis2Max]);
  }, [chartDatas, range]);

  return (
    <>
      <div className="flex items-center justify-between text-gray-500 text-[0.625rem] leading-[1.4]">
        <span className="text-center">
          収支額
          <br />
          （万円）
        </span>
        {deadline !== undefined
        && (
          <span className="text-sm text-caution font-bold">
            {deadline}
            歳で貯蓄がゼロの予想です！
          </span>
        )}
        <span className="text-center">
          貯蓄額
          <br />
          （万円）
        </span>
      </div>
      <div className="relative w-full pt-[330px]">
        <div className="absolute inset-0 -mx-3">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
              margin={{
                top: 10, right: 12, left: 12, bottom: 0,
              }}
              onClick={(event) => {
                if (event !== null) {
                  const activeChartData = cashflows.find((data) => {
                    if (event.activeLabel !== undefined) {
                      return data.age === parseInt(event.activeLabel, 10);
                    }
                    return false;
                  });
                  setActiveAge(activeChartData?.age);
                }
              }}
            >
              <CartesianGrid strokeDasharray="0" vertical={false} />
              <Bar dataKey="incomings" fill={theme('colors.primary')} yAxisId={1} barSize={3} />
              <Bar dataKey="expenses" fill={theme('colors.rose')} yAxisId={1} barSize={3} />
              <Area
                dataKey="savings"
                fill="url(#splitColor)"
                stroke="#7ECEF4"
                fillOpacity={0.5}
                strokeOpacity={0.5}
                yAxisId={2}
              />
              <XAxis
                xAxisId={0}
                axisLine={false}
                tickLine={false}
                dataKey="age"
                ticks={xAxisTicks}
                dy={15}
                minTickGap={0}
                tick={<XAxisTick fill={theme('colors.primary')} fontSize="12px" />}
              />
              <YAxis
                yAxisId={1}
                ticks={yAxisTicks1}
                domain={[yAxisTicks1[0], yAxisTicks1[yAxisTicks1.length - 1]]}
                mirror
                axisLine={false}
                tickLine={false}
                tick={<YAxisTick />}
              />
              <YAxis
                yAxisId={2}
                ticks={yAxisTicks2}
                domain={[yAxisTicks2[0], yAxisTicks2[yAxisTicks2.length - 1]]}
                mirror
                axisLine={false}
                tickLine={false}
                orientation="right"
                tick={<YAxisTick />}
              />
              {deadline !== undefined && (
                <ReferenceLine
                  isFront
                  xAxisId={0}
                  yAxisId={1}
                  segment={[
                    { x: deadline, y: 0 },
                    { x: deadline, y: 0 },
                  ]}
                  label={<XAxisIconLabel />}
                  stroke="transparent"
                />
              )}
              <defs>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="#16c1c5" stopOpacity={1} />
                  <stop offset={off} stopColor="#ff3162" stopOpacity={1} />
                </linearGradient>
              </defs>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="mx-2">
        <CustomeSlider
          value={range}
          valueLabelFormat={(value: number) => {
            const item = cashflows[value];
            return item !== undefined ? item.age : null;
          }}
          min={cashflows.findIndex((data) => data.age === min)}
          max={cashflows.findIndex((data) => data.age === max)}
          sx={{ mt: 0 }}
          onChange={(event: Event, newValue: number | number[]) => {
            if (typeof newValue === 'object') {
              setRange(newValue);
            }
          }}
          valueLabelDisplay="on"
        />
      </div>
      <div className="flex items-center justify-between text-xs text-gray-500 mb-md">
        <div className="flex items-center">
          <span className="w-5 h-0.5 bg-primary mr-3" />
          <span>収入</span>
        </div>
        <div className="flex items-center">
          <span className="w-5 h-0.5 bg-rose mr-3" />
          <span>支出</span>
        </div>
        <div className="flex items-center">
          <span className="w-5 h-2.5 bg-green mr-3" />
          <span>貯蓄</span>
        </div>
        <div className="flex items-center">
          <span>グラフの見方</span>
          <button
            type="button"
            className="ml-3"
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <i className="icon-question text-xl" />
          </button>
        </div>
      </div>
      <Modal show={activeAge !== undefined} onClose={() => setActiveAge(undefined)}>
        <DetailBox datas={cashflows} active={activeAge} />
      </Modal>
      <Modal show={showHelp} onClose={() => setShowHelp(false)}>
        <ChartHelp />
      </Modal>
    </>
  );
};
export default CashflowGraph;
