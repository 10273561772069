import * as React from 'react';
import deepEqual from 'deep-equal';
import moment from 'moment';

// ユーザ情報
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectBirthdate, selectEducationExpenses } from '../../reducers/userReducer';

// 入力データ
import {
  selectFamily,
  setFamily as setDispatchFamily,
  setBirthDate,
  setEducationExpenseMatrix,
} from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// config
import { LOAN_START_AGE_ADDITION } from '../../config/validation';

// components
import InputYearMonth from '../../features/input/InputYearMonth';
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputInteger from '../../features/input/InputInteger';
import InputSelect from '../../features/input/InputSelect';
import Append from '../../features/button/Append';
import HelpButton from '../../features/button/HelpButton';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

type ChildrenBirthdate = {
  id: number,
  value: string,
}
type ExpectedChildren = {
  id: number,
  value: string,
}

const HomeBasicsFamily: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const defaultFamily = useAppSelector(selectFamily);
  const educationExpensesMatrix = useAppSelector(selectEducationExpenses);

  // 入力データ
  const [family, setFamily] = React.useState(defaultFamily);

  // 生年月のセレクト項目のために本日の日付を取得
  const today = moment();

  // こどもの生年月のセレクト項目のために自身の生年月を取得
  const birthdateString = useAppSelector(selectBirthdate);
  const birthdate = moment(birthdateString);

  // こどもを追加したときのデフォルトの年月日
  // こどもの生年月はプルダウンに変更を加えたときに1日に指定する
  const defaultChildBirthdate = moment(birthdateString)
    .add(LOAN_START_AGE_ADDITION, 'years')
    .format('YYYY-MM-DD');

  // 配偶者有無
  const [hasSpouse, setHasSpouse] = React.useState(defaultFamily.spouse.hasSpouse);

  // 配偶者の生年月
  const [spouseBirthdate, setSpouseBirthdate] = React.useState(defaultFamily.spouse.birthMonth);

  // 結婚予定
  const [willHaveSpouse, setWillHaveSpouse] = React.useState(defaultFamily.spouse.willHaveSpouse);

  // 予定時期・想定年齢
  const [
    ageOfMarriagePlanHouseholder,
    setSgeOfMarriagePlanHouseholder,
  ] = React.useState(defaultFamily.spouse.ageOfMarriagePlanHouseholder);
  const [
    ageOfMarriagePlanSpouse,
    setAgeOfMarriagePlanSpouse,
  ] = React.useState(defaultFamily.spouse.ageOfMarriagePlanSpouse);

  // こども有無
  const [hasChildren, setHasChildren] = React.useState(defaultFamily.children.hasChildren);
  const [
    childrenBirthdates, setChildrenBirthdates,
  ] = React.useState<ChildrenBirthdate[]>((defaultFamily.children.birthMonth ?? [])
    .map((value, i) => {
      return {
        id: i,
        value,
      };
    }));
  const [
    childrenCount, setChildrenCount,
  ] = React.useState(defaultFamily.children.birthMonth.length);

  // 将来のこども
  const [
    willHaveChildren, setHasExpectedChildren,
  ] = React.useState(defaultFamily.children.willHaveChildren);
  const [
    expectedChildrens, setExpectedChildrens,
  ] = React.useState<ExpectedChildren[]>((defaultFamily.children.expectedBirthYear ?? [])
    .map((value, i) => {
      return {
        id: i,
        value,
      };
    }));
  const [
    expectedChildrenCount, setExpectedChildrenCount,
  ] = React.useState(defaultFamily.children.expectedBirthYear.length);

  // 予定生まれ年リスト
  const expectedChildrenOptions = (() => {
    const options = Array.from(Array(100).keys()).map((n) => {
      const date = moment().add(n, 'years');
      if (date.year() > today.year()) {
        date.set('month', 3).set('date', 1);
      } else {
        date.set('month', 11).set('date', 31);
      }
      const age = date.diff(moment(birthdateString), 'years');
      return {
        value: date.format('YYYY-MM-DD'),
        label: `${date.year()}年 (あなた${age}歳)`,
      };
    });
    options.unshift({
      value: '',
      label: '選択してください',
    });
    return options;
  })();

  // データ更新
  React.useEffect(() => {
    setFamily({
      spouse: {
        hasSpouse,
        birthMonth: spouseBirthdate,
        willHaveSpouse,
        ageOfMarriagePlanHouseholder,
        ageOfMarriagePlanSpouse,
      },
      children: {
        hasChildren,
        birthMonth: childrenBirthdates.map((data) => data.value),
        willHaveChildren,
        expectedBirthYear: expectedChildrens.map((data) => data.value),
      },
    });
  }, [
    hasSpouse,
    spouseBirthdate,
    willHaveSpouse,
    ageOfMarriagePlanHouseholder,
    ageOfMarriagePlanSpouse,
    hasChildren,
    childrenBirthdates,
    willHaveChildren,
    expectedChildrens,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setFamily(defaultFamily);
      setWillHaveSpouse(defaultFamily.spouse.willHaveSpouse);
      setSgeOfMarriagePlanHouseholder(defaultFamily.spouse.ageOfMarriagePlanHouseholder);
      setAgeOfMarriagePlanSpouse(defaultFamily.spouse.ageOfMarriagePlanSpouse);

      // こどもの要素を更新する
      let newChildrenCount = childrenCount;
      setChildrenBirthdates((defaultFamily.children.birthMonth ?? []).map((value) => {
        newChildrenCount += 1;
        return {
          id: newChildrenCount,
          value,
        };
      }));
      setChildrenCount(newChildrenCount);

      // 将来の子供の予定の配列を更新する
      let newExpectedChildrenCount = expectedChildrenCount;
      setExpectedChildrens((defaultFamily.children.expectedBirthYear ?? []).map((value) => {
        newExpectedChildrenCount += 1;
        return {
          id: newExpectedChildrenCount,
          value,
        };
      }));
      setExpectedChildrenCount(newExpectedChildrenCount);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>家族構成</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">あなたのこと家族のことを教えてください。</p>
        <p className="text-sm mt-2 mb-6">
          これから長い人生を考えていく上で欠かせないのが家族構成とプロフィールです。正確に入力することで、
          これから起こる教育・住宅など様々なイベントが自身や家族が何歳のときのものかを想定することができるようになります。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">あなた</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>生年月</span>
              </div>
              <InputYearMonth
                name="user-birthdate"
                defaultValue={birthdateString}
                disabled
                showAge
              />
            </div>
          </section>
          <section>
            <h3 className="fieldset-title">配偶者</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>配偶者有無</span>
                <HelpButton>
                  生計をともにしている配偶者(パートナー)がいる場合は｢いる｣を選択してください。
                </HelpButton>
              </div>
              <InputRadioGroup
                name="spouse"
                options={[
                  { value: 0, label: 'いない' },
                  { value: 1, label: 'いる' },
                ]}
                defaultValue={hasSpouse ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  const flag = parseInt(value, 10) === 1;
                  setHasSpouse(flag);
                  if (flag) {
                    if (birthdateString !== spouseBirthdate) {
                      // 配偶者た追加されたときに、本人と配偶者の生年月が違った場合に本人と同一にする
                      setSpouseBirthdate(birthdateString);
                    }
                  } else if (birthdateString !== spouseBirthdate) {
                    // 配偶者が削除されたときに、本人と配偶者の生年月が違った場合にNULLにする
                    setSpouseBirthdate(null);
                  }
                }}
              />
            </div>
            {hasSpouse
              ? (
                <div className="fieldset-body">
                  <div className="form-label">
                    <span>生年月</span>
                  </div>
                  <InputYearMonth
                    name="spouse-birthdate"
                    defaultValue={spouseBirthdate}
                    startYear={today.year() - 99}
                    endYear={today.year()}
                    onChange={(date) => {
                      if (date !== null) {
                        // 配偶者の生年月が変更されたときは1日にする
                        const startOfMonth = moment(date);
                        startOfMonth.set('date', 1);
                        setSpouseBirthdate(startOfMonth.format('YYYY-MM-DD'));
                      } else {
                        setSpouseBirthdate(null);
                      }
                    }}
                    showAge
                  />
                </div>
              )
              : (
                <>
                  <div className="fieldset-body">
                    <div className="form-label">
                      <span>結婚の予定</span>
                    </div>
                    <InputRadioGroup
                      name="willHaveSpouse"
                      options={[
                        { value: 0, label: 'なし' },
                        { value: 1, label: 'あり' },
                      ]}
                      defaultValue={willHaveSpouse ? 1 : 0}
                      onChange={(event) => {
                        const { value } = event.currentTarget;
                        setWillHaveSpouse(parseInt(value, 10) === 1);
                      }}
                    />
                  </div>
                  <div className="fieldset-body">
                    <div className="form-label">
                      <span>予定時期・想定年齢</span>
                    </div>
                    <InputInteger
                      name="ageOfMarriagePlanHouseholder"
                      className="w-[120px]"
                      unit="歳の時に"
                      defaultValue={ageOfMarriagePlanHouseholder}
                      disabled={!willHaveSpouse}
                      maxlength={2}
                      onChange={(value) => setSgeOfMarriagePlanHouseholder(value)}
                    />
                    <InputInteger
                      name="ageOfMarriagePlanSpouse"
                      className="w-[120px] mt-3"
                      unit="歳の相手と結婚"
                      defaultValue={ageOfMarriagePlanSpouse}
                      disabled={!willHaveSpouse}
                      maxlength={2}
                      onChange={(value) => setAgeOfMarriagePlanSpouse(value)}
                    />
                  </div>
                </>
              )}
          </section>
          <section>
            <h3 className="fieldset-title">こども</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>こども有無</span>
              </div>
              <InputRadioGroup
                name="children"
                options={[
                  { value: 0, label: 'いない' },
                  { value: 1, label: 'いる' },
                ]}
                defaultValue={hasChildren ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setHasChildren(parseInt(value, 10) === 1);
                }}
              />
            </div>
            {childrenBirthdates.map((childrenBirthdate) => (
              <div className="fieldset-body" key={`children-${childrenBirthdate.id}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="form-label mb-0">
                    <span>生年月</span>
                  </div>
                  <button
                    type="button"
                    className="btn-remove"
                    onClick={() => {
                      const newChildrenBirthdates = childrenBirthdates
                        .filter((data) => data.id !== childrenBirthdate.id);
                      setChildrenBirthdates(newChildrenBirthdates);
                    }}
                  >
                    <span />
                  </button>
                </div>
                <InputYearMonth
                  name={`children-birthdate-${childrenBirthdate.id}`}
                  defaultValue={childrenBirthdate.value}
                  startYear={birthdate.year() + LOAN_START_AGE_ADDITION}
                  endYear={birthdate.year() + LOAN_START_AGE_ADDITION + 99}
                  disabled={!hasChildren}
                  showAge
                  onChange={(date) => {
                    const newChildrenBirthdates = childrenBirthdates.map((data) => {
                      if (data.id === childrenBirthdate.id && date !== null) {
                        return {
                          id: data.id,
                          value: date,
                        };
                      }
                      return data;
                    });
                    setChildrenBirthdates(newChildrenBirthdates);
                  }}
                  dayValue={1}
                />
              </div>
            ))}
            <div className="fieldset-body">
              <Append
                label="子供を追加する"
                onClick={() => {
                  const newChildrenBirthdates = childrenBirthdates.slice();
                  const count = childrenCount + 1;
                  newChildrenBirthdates.push({
                    id: count,
                    value: defaultChildBirthdate,
                  });
                  setChildrenCount(count);
                  setChildrenBirthdates(newChildrenBirthdates);
                }}
                disabled={!hasChildren}
              />
            </div>
          </section>
          <section>
            <h3 className="fieldset-title">将来のこども</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>将来の子供の予定</span>
              </div>
              <InputRadioGroup
                name="willHaveChildren"
                options={[
                  { value: 0, label: 'ない' },
                  { value: 1, label: 'ある' },
                ]}
                defaultValue={willHaveChildren ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setHasExpectedChildren(parseInt(value, 10) === 1);
                }}
              />
            </div>
            {expectedChildrens.map((expectedChildren) => (
              <div className="fieldset-body" key={`children-${expectedChildren.id}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="form-label mb-0">
                    <span>予定生まれ年</span>
                  </div>
                  <button
                    type="button"
                    className="btn-remove"
                    onClick={() => {
                      const newExpectedChildrens = expectedChildrens
                        .filter((data) => data.id !== expectedChildren.id);
                      setExpectedChildrens(newExpectedChildrens);
                    }}
                  >
                    <span />
                  </button>
                </div>
                <InputSelect
                  className="block w-full"
                  options={expectedChildrenOptions}
                  disabled={!willHaveChildren}
                  defaultValue={expectedChildren.value}
                  onChange={(event) => {
                    const { value } = event.currentTarget;
                    const newExpectedChildrens = expectedChildrens.map((data) => {
                      if (data.id === expectedChildren.id) {
                        return {
                          id: data.id,
                          value,
                        };
                      }
                      return data;
                    });
                    setExpectedChildrens(newExpectedChildrens);
                  }}
                />
              </div>
            ))}
            <div className="fieldset-body">
              <Append
                label="出産予定を追加する"
                onClick={() => {
                  const newExpectedChildrens = expectedChildrens.slice();
                  const count = expectedChildrenCount + 1;
                  newExpectedChildrens.push({
                    id: count,
                    value: '',
                  });
                  setExpectedChildrenCount(count);
                  setExpectedChildrens(newExpectedChildrens);
                }}
                disabled={!willHaveChildren}
              />
            </div>
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultFamily, family)}
          contentId="family"
          onClick={() => {
            dispatch(setBirthDate(birthdateString));
            dispatch(setEducationExpenseMatrix(educationExpensesMatrix));
            dispatch(setDispatchFamily(family));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeBasicsFamily;
