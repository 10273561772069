import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../app/hooks';

// ヘッダー情報
import { selectHeaderHeight } from '../../reducers/headerReducer';

// データ
import { selectIsGuest } from '../../reducers/userReducer';
import { pushNextStep } from '../../reducers/stepReducer';

// components
import Diagnosis from './Diagnosis';
import DisabledMessage from './DisabledMessage';
import CashflowGraph from './CashflowGraph';
import NextStep from './NextStep';

const Home: React.FC = () => {
  const { pathname, hash } = useLocation();
  const dispach = useAppDispatch();
  const guest = useAppSelector(selectIsGuest);

  // ref属性の参照
  const ref = React.useRef<HTMLDivElement>(null);

  // スクロール位置のオフセットに合わせるために、ヘッダーの高さを取得
  const height = useAppSelector(selectHeaderHeight);

  // スクロール位置のマージン
  const margin = 10;

  React.useEffect(() => {
    // 画面遷移時にアクティブページを記録する
    dispach(pushNextStep(pathname));
  }, [pathname]);

  React.useEffect(() => {
    if (ref.current !== null && hash === `#${ref.current.id}`) {
      const posy = ref.current.offsetTop - height - margin;
      // URLにハッシュが含まれていたとき、アンカーリンクの位置までスクロールを移動する
      setTimeout(() => {
        window.scrollTo(0, posy);
      }, 10);
    }
  }, [pathname, ref]);

  return (
    <div className="pb-12">
      <div className="pt-5">
        {pathname === '/home/advice' && <Diagnosis />}
        <div className="container">
          {!guest
          && (
            <>
              <NextStep />
              <DisabledMessage />
            </>
          )}
          <div className="text-center my-4">
            <p className="text-base text-primary font-bold">シミュレーション結果</p>
          </div>
          <CashflowGraph />
        </div>
      </div>
      <div id="content" ref={ref}>
        <Outlet />
      </div>
    </div>
  );
};
export default Home;
