import * as React from 'react';
import styled from 'styled-components';

// components
const SwitchIcon = styled.div<{close: boolean}>`
  position: relative;
  width: 1rem;
  height: 1rem;
  & > span {
    position: absolute;
    display: block;
    width: 1rem;
    height: 1px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0. 0.2, 1);
    transition-duration: 150ms;
    top: 50%;
    &:nth-child(1) {
      transform: ${({ close }) => (close ? 'rotate(-180deg)' : 'rotate(0deg)')};
    }
    &:nth-child(2) {
      transform: ${({ close }) => (close ? 'rotate(90deg)' : 'rotate(0deg)')};
    }
  }
`;

type ToggleButtonProps = {
  open: boolean;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { open, color, onClick } = props;
  return (
    <button
      type="button"
      className="block h-4"
      onClick={onClick}
    >
      <SwitchIcon close={!open}>
        <span className={color} />
        <span className={color} />
      </SwitchIcon>
    </button>
  );
};
ToggleButton.defaultProps = {
  color: 'bg-primary',
  onClick: undefined,
};
export default ToggleButton;
