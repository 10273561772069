import React from 'react';
import { theme } from 'twin.macro';
import { TickProps } from '../props';

type XAxisTickProps = {
  dy?: number;
  fill?: string;
  fontSize?: string;
} & TickProps;
const XAxisTick: React.FC<XAxisTickProps> = ({
  x,
  y,
  textAnchor,
  payload,
  dy = 10,
  fill = theme('colors.gray-500'),
  fontSize = '10px',
}) => {
  const value = (payload !== undefined ? payload.value : undefined) || '';
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={dy}
        textAnchor={textAnchor}
        fill={fill}
        style={{ fontFamily: theme('fontFamily.alpha'), fontSize }}
      >
        {value.toLocaleString()}
      </text>
    </g>
  );
};
XAxisTick.defaultProps = {
  dy: undefined,
  fill: undefined,
  fontSize: undefined,
};

export default XAxisTick;
