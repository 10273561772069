import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// components
import Loading from '../../features/Loading';

// 認証情報
import { useAppDispatch } from '../../app/hooks';
import { guestAsync } from '../../reducers/authReducer';

const Guest: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const loginAsync = async (): Promise<void> => {
      await dispatch(guestAsync());
      navigate('/registration/guest', { replace: true });
    };
    loginAsync();
  });
  return (
    <div className="flex justify-center items-center h-40">
      <Loading>
        データを読み込み中です…
      </Loading>
    </div>
  );
};
export default Guest;
