import * as React from 'react';
import moment from 'moment';

// データ
import { useAppSelector } from '../../app/hooks';
import { selectFamily, getAssetStartDate } from '../../reducers/inputReducer';
import { selectAssetsTrustSpouse } from '../../reducers/cashflowReducer';
import { selectRiskTrust } from '../../reducers/riskReducer';

// 入力データの型
import type { AssetFormationDataItem } from '../../reducers/inputReducer';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputYearMonth from '../../features/input/InputYearMonth';
import InputReserveAmount from '../../features/input/InputReserveAmount';
import InputAssetValuation from '../../features/input/InputAssetValuation';
import AssetGraph from '../../features/recharts/AssetGraph';
import RiskGraph from '../../features/recharts/RiskGraph';
import HelpButton from '../../features/button/HelpButton';
import { isLessThanToday } from '../../services/validation';

type FieldsetSpouseProps = {
  prefix?: string;
  data: AssetFormationDataItem;
  onChange?: (_:AssetFormationDataItem) => void;
};
const FieldsetSpouse: React.FC<FieldsetSpouseProps> = ({ prefix, data, onChange }) => {
  // 加入
  const [hasBeJoined, setHasBeJoined] = React.useState(data.hasBeJoined ?? 'JOINED');

  // 加入年月
  const [joinMonth, setJoinMonth] = React.useState(data.joinMonth);
  const [startYear, setStartYear] = React.useState<number>();
  const [endYear, setEndYear] = React.useState<number>();

  // 加入年月の範囲を指定するために、生年月を取得
  const defaultFamily = useAppSelector(selectFamily);
  const birthdate = defaultFamily.spouse.birthMonth;

  // 積立額
  const [
    monthlyContributionAmount, setMonthlyContributionAmount,
  ] = React.useState(data.monthlyContributionAmount);

  // 資産評価額
  const [presentValue, setPresentValue] = React.useState(data.presentValue);

  // 元本積立額/予想資産残高のグラフを表示するために、キャッシュフローを取得
  const assetGraphData = useAppSelector(selectAssetsTrustSpouse);

  // 年利回り
  const [expectedReturn, setExpectedReturn] = React.useState(data.expectedReturn);

  // リスクデータ
  const riskDatas = useAppSelector(selectRiskTrust);
  const [riskValue, setRiskValue] = React.useState(data.riskValue);

  // 加入状況を変更する処理
  const onChangeHasBeJoined = ((value: string) => {
    if (value === 'TOBE_JOINED') {
      // 今から加入の場合は現在にする
      const now = moment();
      const date = moment(joinMonth);
      date.set('years', now.year());
      date.set('months', now.month());
      date.set('date', 1);
      setJoinMonth(date.format('YYYY-MM-DD'));
    }
  });

  // 加入状態に変化があったとき、加入年月の範囲を変更する
  React.useEffect(() => {
    const startDate = getAssetStartDate(hasBeJoined, birthdate);
    setStartYear(startDate.year());
    setEndYear(startDate.year() + 99);
  }, [hasBeJoined]);

  // 値に変化があったらイベントを呼び出す
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        ...data,
        hasBeJoined,
        joinMonth,
        monthlyContributionAmount,
        presentValue,
        riskValue,
        expectedReturn,
      });
    }
  }, [
    hasBeJoined,
    joinMonth,
    monthlyContributionAmount,
    presentValue,
    riskValue,
    expectedReturn,
  ]);

  return (
    <>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入</span>
          <HelpButton>
            積立投信あるいは投資信託をご保有の方は｢加入済み｣を、
            積立投信あるいは投資信託を保有されない方で今後保有をご検討される方(＝加入シミュレーションをされる方)は｢今から加入｣を、
            それ以外の方は｢非加入｣をご選択ください。
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}hasBeJoined`}
          options={[
            { value: 'JOINED', label: '加入済み' },
            { value: 'NOT_JOINED', label: '非加入' },
            { value: 'TOBE_JOINED', label: '今から加入' },
          ]}
          defaultValue={hasBeJoined}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setHasBeJoined(value);
            onChangeHasBeJoined(value);
          }}
        />
        {assetGraphData !== undefined && assetGraphData.length > 0 && (
          <div className="mt-8">
            <AssetGraph
              datas={assetGraphData}
              expectedReturn={expectedReturn}
              help={(
                <>
                  一律｢60歳｣を表示しております。
                </>
              )}
            />
          </div>
        )}
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入年月</span>
          <HelpButton>
            ｢今から加入｣をご選択された方で加入シミュレーションを実施される場合、｢今月｣をご入力ください (＝来月以降の日付はご入力いただけません)。
          </HelpButton>
        </div>
        <InputYearMonth
          name={`${prefix}joinMonth`}
          value={joinMonth}
          dayValue={1}
          startYear={startYear}
          endYear={endYear}
          disabled={hasBeJoined !== 'JOINED'}
          onChange={(date) => setJoinMonth(date)}
          validate={isLessThanToday(parseInt(moment().format('YYYYMMDD'), 10))}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>積立額 (任意)</span>
          <HelpButton>
            積立投信をご保有される方は｢積立額｣を月単位でご入力ください。
          </HelpButton>
        </div>
        <InputReserveAmount
          name={`${prefix}monthlyContributionAmount`}
          defaultValue={monthlyContributionAmount}
          disabled={hasBeJoined === 'NOT_JOINED'}
          onChange={(value) => setMonthlyContributionAmount(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>資産評価額</span>
          <HelpButton>
            ｢加入済み｣の方は現時点での資産評価額を入力してください。
          </HelpButton>
        </div>
        <InputAssetValuation
          name={`${prefix}presentValue`}
          defaultValue={presentValue}
          disabled={hasBeJoined !== 'JOINED'}
          onChange={(value) => setPresentValue(value)}
        />
      </div>
      <div className="fieldset-body">
        <RiskGraph
          datas={riskDatas}
          value={riskValue}
          onChange={((value, expectedReturnValue) => {
            setRiskValue(value);
            setExpectedReturn(expectedReturnValue?.expectedReturn ?? null);
          })}
        />
      </div>
    </>
  );
};
FieldsetSpouse.defaultProps = {
  prefix: '',
  onChange: undefined,
};
export default FieldsetSpouse;
