import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// async
import {
  fetchAsync as fetchAsyncThunk,
  guestAsync as guestAsyncThunk,
  fetchDebug as fetchDebugThunk,
  mockObjects as mockObjectsBase,
} from './async/fetchAsync';
import { storeAsync as storeAsyncThunk } from './async/storeAsync';
import { updateAsync as updateAsyncThunk } from './async/updateAsync';
import { updateAccountAsync as updateAccountAsyncThunk } from './async/updateAccountAsync';
import { deleteAccountAsync as deleteAccountAsyncThunk } from './async/deleteAccountAsync';
import { updateProgressAsync as updateProgressAsyncThunk } from './async/updateProgressAsync';
import { createAccountAsync as createAccountAsyncThunk } from './async/createAccountAsync';

// ローカルモックデータ
export const mockObjects = mockObjectsBase;

// API実装
export const fetchAsync = fetchAsyncThunk;
export const guestAsync = guestAsyncThunk;
export const fetchDebug = fetchDebugThunk;
export const storeAsync = storeAsyncThunk;
export const updateAsync = updateAsyncThunk;
export const updateAccountAsync = updateAccountAsyncThunk;
export const deleteAccountAsync = deleteAccountAsyncThunk;
export const updateProgressAsync = updateProgressAsyncThunk;
export const createAccountAsync = createAccountAsyncThunk;

type State = {
  status: 'idle' | 'loading' | 'failed';
  authCode?: string;
  sessionCookie?: string;
}

const initialState: State = {
  status: 'idle',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    //
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = (action.payload !== null) ? 'idle' : 'failed';
        if (action.payload !== null) {
          state.authCode = action.payload.authCode;
          state.sessionCookie = action.payload.sessionCookie;
        }
      })
      .addCase(fetchAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(guestAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(guestAsync.fulfilled, (state, action) => {
        state.status = (action.payload !== null) ? 'idle' : 'failed';
        if (action.payload !== null) {
          state.authCode = action.payload.authCode;
          state.sessionCookie = action.payload.sessionCookie;
        }
      })
      .addCase(guestAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDebug.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDebug.fulfilled, (state, action) => {
        state.status = (action.payload !== null) ? 'idle' : 'failed';
        if (action.payload !== null) {
          state.authCode = action.payload.code;
          state.sessionCookie = action.payload.sessionCookie;
        }
      })
      .addCase(fetchDebug.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(storeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storeAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
      })
      .addCase(storeAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
      })
      .addCase(updateAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateAccountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAccountAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
      })
      .addCase(updateAccountAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createAccountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAccountAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
      })
      .addCase(createAccountAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteAccountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAccountAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
        if (action.payload) {
          state.authCode = undefined;
          state.sessionCookie = undefined;
        }
      })
      .addCase(deleteAccountAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateProgressAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProgressAsync.fulfilled, (state, action) => {
        state.status = (action.payload) ? 'idle' : 'failed';
      })
      .addCase(updateProgressAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthCode = (state: RootState) => state.auth.authCode;
export const selectSessionId = (state: RootState) => state.auth.sessionCookie;
export const selectAuthStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;
