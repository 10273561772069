import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectHasSpouseOrWill, selectIncome, setIncome as setDispachIncome } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// components
import Append from '../../features/button/Append';
import type { InputSelectOption } from '../../features/input/InputSelect';
import Fieldset from './Fieldset';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

// 職業選択オプション（あなた）
const employmentTypeHouseholderOptions: InputSelectOption[] = [
  { value: 'FULLTIME_EMPLOYEE', label: '正社員' },
  { value: 'TEMPORARY_STAFF', label: '契約・派遣社員' },
];
// 職業選択オプション（配偶者）
const employmentTypeOptions: InputSelectOption[] = [
  ...employmentTypeHouseholderOptions,
  { value: 'PART_TIME_JOB', label: 'パート・アルバイト' },
  { value: 'HOUSEWIFE_HUSBAND', label: '専業主婦・主夫' },
];

const HomeBasicsIncomings: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouseOrWill);
  const defaultIncome = useAppSelector(selectIncome);

  // 入力データ
  const [income, setIncome] = React.useState(defaultIncome);

  // 世帯主情報
  const [householder, setHouseholder] = React.useState(defaultIncome.householder);

  // 配偶者情報
  const [spouse, setSpouse] = React.useState(defaultIncome.spouse);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // データ更新
  React.useEffect(() => {
    setIncome({
      householder,
      spouse,
    });
  }, [
    householder,
    spouse,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setIncome(defaultIncome);
      setHouseholder(defaultIncome.householder);
      setSpouse(defaultIncome.spouse);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>預金・収入</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">世帯の預貯金額や現在の仕事から将来を予測していきます。</p>
        <p className="text-sm mt-2 mb-6">
          貯金・預金額や現在の仕事のことを入力していただくと、その入力情報と統計データに基づき将来の収入などを自動算出していきます。
          育休などの情報も入れていただくと、より詳細なシミュレーションを行うことが可能です。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">あなた</h3>
            <Fieldset
              data={householder}
              employmentTypeOptions={employmentTypeHouseholderOptions}
              onChange={(data) => setHouseholder(data)}
            />
          </section>
          <div>
            <div className="py-8 border-primary border-t">
              <Append
                label={showSpouse ? '詳細を閉じる（配偶者）' : '配偶者を入力する\n (もしくは今後結婚予定の配偶者)'}
                onClick={() => setShowSpouse(!showSpouse)}
                minus={showSpouse}
                disabled={!hasSpouse}
              />
            </div>
          </div>
          {showSpouse && (
            <section>
              <h3 className="fieldset-title">配偶者</h3>
              <Fieldset
                prefix="spouse"
                data={spouse}
                employmentTypeOptions={employmentTypeOptions}
                isSpouse
                onChange={(data) => setSpouse(data)}
              />
            </section>
          ) }
        </div>
        <FixedButton
          disabled={deepEqual(defaultIncome, income)}
          contentId="incomings"
          onClick={() => {
            dispatch(setDispachIncome(income));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeBasicsIncomings;
