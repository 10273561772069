import * as React from 'react';
import FAQ, { Q, A } from './FAQ';

const HelpQuit: React.FC = () => (
  <FAQ>
    <Q>
      <p>人生100年デザイナーを退会したいのですが、どうすればいいでしょうか？</p>
    </Q>
    <A>
      <p>
        ログイン後、画面右上にある「三本線」マークより「ユーザ設定」を選択してください。ユーザ設定画面に遷移しましたら「ユーザ情報を削除する」を押してください。
        削除の最終確認が表示されますので「削除する」ボタンを押してください。
      </p>
    </A>
  </FAQ>
);
export default HelpQuit;
