import * as React from 'react';
import { usePrompt } from '../../app/react-router-dom-v.6.02.prompt.blocker';

// ヘッダー情報
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectHeaderHeight } from '../../reducers/headerReducer';
import { selectNextStep, isGreaterProgress } from '../../reducers/stepReducer';
import { selectHasFailed } from '../../reducers/validateReducer';

// API更新
import { updateRequestData } from '../../reducers/inputReducer';
import { updateAsync, selectAuthStatus } from '../../reducers/authReducer';

// components
import Loading from '../Loading';

import { setErrorMessages, resetErrorMessages } from '../../reducers/errorReducer';

type FixedButtonProps = {
  className?: string;
  contentId: string;
  disabled?: boolean;
  onClick: () => void;
  validation?: () => string | null;
}
const FixedButton: React.FC<FixedButtonProps> = ({
  children, className, contentId = '', disabled, onClick, validation: propsValidate,
}) => {
  const validation = propsValidate;
  const authStatus = useAppSelector(selectAuthStatus);
  const hasFailed = useAppSelector(selectHasFailed);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  // スクロール位置のオフセットに合わせるために、ヘッダーの高さを取得
  const height = useAppSelector(selectHeaderHeight);

  // スクロール位置のマージン
  const margin = 10;

  // 進捗を更新するために現在の状態を取得
  const nextSteps = useAppSelector(selectNextStep);
  const [progress, setProgress] = React.useState(3);

  React.useEffect(() => {
    const nextStep = nextSteps.find((s) => s.contentId === contentId);
    if (isGreaterProgress(nextStep?.progress, 'SAVED')) {
      setProgress(6);
    } else {
      setProgress(3);
    }
  }, [
    nextSteps,
    contentId,
  ]);

  // 画面遷移時に確認アラートを出す
  usePrompt('保存されていない項目は破棄されます。よろしいですか？', !disabled);

  return (
    <>
      {(!disabled && !hasFailed && authStatus !== 'loading')
      && (
        <>
          <div className="mt-6">
            <p className={`text-sm leading-[1.4] text-rose text-center mb-4 font-bold ${className}`}>
              変更内容はグラフに未反映です。
              <br />
              保存して反映してください。
            </p>
          </div>
          <div className="w-full sticky bottom-6 z-10">
            <div className="w-[275px] mx-auto">
              <button
                type="submit"
                className="btn-submit"
                disabled={disabled || hasFailed}
                onClick={async () => {
                  if (validation !== undefined) {
                    // サーバーエラーを消す
                    dispatch(resetErrorMessages());
                    const errorMessages = validation();
                    if (errorMessages !== null) {
                      const error: string[] = [];
                      error.push(errorMessages);
                      dispatch(setErrorMessages(error));
                      return;
                    }
                  }
                  onClick();
                  dispatch(updateRequestData());

                  // スクロールを移動してロード
                  window.scroll({
                    top: 0 - height - margin,
                    behavior: 'smooth',
                  });
                  setLoading(true);
                  await dispatch(updateAsync({
                    contentId,
                    progress,
                  }));
                  setLoading(false);
                }}
              >
                {children}
              </button>
            </div>
          </div>
        </>
      )}
      {loading
      && (
        <div className=" fixed inset-0 z-50">
          <div className="flex justify-center items-center h-full">
            <Loading>
              データを読み込み中です…
            </Loading>
          </div>
        </div>
      )}
    </>
  );
};
FixedButton.defaultProps = {
  className: '',
  disabled: false,
  validation: undefined,
};
export default FixedButton;
