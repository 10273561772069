import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';
import FAQ, { Q, A } from './FAQ';

const List = styled.ul`
  li {
    position: relative;
    padding-left: ${theme`padding.4`};
    margin-top: ${theme`margin.2`};
    &::before {
      content: '・';
      position: absolute;
      left: 0;
      top: 0;
    };
  }
`;

const HelpLogin: React.FC = () => (
  <>
    <FAQ>
      <Q>
        <p>JIS&amp;Tとはなんでしょうか？</p>
      </Q>
      <A>
        <p className="  rele">
          JIS&amp;Tとは日本インベスター・ソリューション・アンド・テクノロジー株式会社の略で、確定拠出年金の記録関連業務を行っている会社です。
          本サｰビスでは、JIS&amp;Tにご登録いただいているあなたの情報を利用いたします。
        </p>
      </A>
    </FAQ>
    <FAQ>
      <Q>
        <p>JIS&amp;Tから送られてくる「口座開設のお知らせ」をなくしてパスワードがわかりません。</p>
      </Q>
      <A>
        <p>JIS&amp;Tが提供する確定拠出年金インターネットサービスのサイトからパスワード再発行手続きを行ってください。</p>
        <p className="mt-8"><a href="https://www.dcplan.co.jp/cont/pub/html/GWEB0070110.html" target="_blank" rel="noopener noreferrer" className="arrow-link text-gray-500">確定拠出年金インターネットサービス</a></p>
      </A>
    </FAQ>
    <FAQ>
      <Q>
        <p>確定拠出年金(DC)加入者ではないと利用できないのでしょうか？</p>
      </Q>
      <A>
        <p>本サービスは、みずほ銀行とご契約をいただいた企業にお勤めの確定拠出年金(DC)加入者様のみがご利用をいただけるサービスです。</p>
        <p>
          確定拠出年金(DC)加入者様ではなくても「体験版（ゲストログイン）」をご利用いただくことが可能です。
          ただし体験版（ゲストログイン）では入力いただいたデータを保存することができません。
        </p>
      </A>
    </FAQ>
    <FAQ>
      <Q>
        <p>確定拠出年金(DC)加入者なのに、ログインができません。「サービスを利用いただけない可能性がある」と出力されます。</p>
      </Q>
      <A>
        <p>本サービスは、みずほ銀行とご契約をいただいた企業にお勤めの確定拠出年金(DC)加入者様のみがご利用をいただけるサービスです。</p>
        <p>当該条件を満たしていてもログインできない場合は以下等可能性が想定されます。</p>
        <List>
          <li>ＤＣ制度に加入後間もないため：本サービスはＤＣ制度加入後所定の期間後からご利用をいただけるサービスです。</li>
          <li>ご退職済の場合</li>
          <li>
            ＤＣ制度加入時に、「日本インベスター・ソリューション・アンド・テクノロジー株式会社が保有する個人情報を、
            確定拠出年金サービス株式会社を含む運営管理機関へ提供すること」に関し非同意としたため。
          </li>
        </List>
      </A>
    </FAQ>
  </>
);
export default HelpLogin;
