import React from 'react';
import { Transition } from '@headlessui/react';

// images
import imgChartHelp1 from './assets/chart-help1.png';
import imgChartHelp2 from './assets/chart-help2.png';

// コンテンツページ
const ChartHelpPage1: React.FC = () => (
  <>
    <h3 className="text-base leading-normal text-center mb-5 font-bold">
      100歳までに
      <br />
      貯蓄がある状態を目指そう
    </h3>
    <figure className="rounded w-full h-[150px] flex items-center justify-center">
      <img src={imgChartHelp1} alt="" width={255} height={150} className="block" />
    </figure>
    <p className="text-sm mt-5">あなたの現状から統計的に貯蓄額ラインを作成しています。人生100年時代を豊に過ごしていく目安として、貯蓄額ラインがマイナスにならないようにお金のことを考えていきましょう。</p>
  </>
);
const ChartHelpPage2: React.FC = () => (
  <>
    <h3 className="text-base leading-normal text-center mb-5 font-bold">
      貯蓄がマイナスになる
      <br />
      タイミングを見極めよう
    </h3>
    <figure className="rounded w-full h-[150px] flex items-center justify-center">
      <img src={imgChartHelp2} alt="" width={255} height={150} className="block" />
    </figure>
    <p className="text-sm mt-5">貯蓄がなくなる年齢をグラフ上でお知らせします。100歳まで貯蓄額がマイナスにならないように生活費や資産形成などを繰り返し調整しながら計画を立てていきましょう。</p>
  </>
);

const ChartHelp: React.FC = () => {
  // コンテンツの横幅
  const [wrapperWidth, setWrapperWidth] = React.useState(1);

  // コンテンツページ
  const datas = [
    {
      page: 0,
      children: <ChartHelpPage1 />,
    },
    {
      page: 1,
      children: <ChartHelpPage2 />,
    },
  ];

  // 現在のページ
  const [currentPage, setCurrentPage] = React.useState(0);
  const [direction, setDirection] = React.useState<'prev'|'next'>();
  const [prevPage, setPrevPage] = React.useState<number>();
  const [nextPage, setNextPage] = React.useState<number>();

  // ref属性の参照
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // コンテンツの横幅取得
    const handleResize = () => {
      if (wrapperRef.current !== null) {
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    // 前のページを取得
    setPrevPage((currentPage > 0) ? currentPage - 1 : undefined);

    // 次のページを取得
    setNextPage((currentPage < datas.length - 1) ? currentPage + 1 : undefined);
  }, [datas, currentPage]);

  const page = (n: number) => {
    // 方向を判定する
    setDirection(n > currentPage ? 'next' : 'prev');

    // ページを更新する
    setCurrentPage(n);
  };

  return (
    <div className="relative py-md">
      {prevPage !== undefined && (
        <button
          type="button"
          className="btn-prev absolute left-0 top-1/2 z-10"
          onClick={() => page(prevPage)}
        >
          <span />
        </button>
      )}
      {nextPage !== undefined && (
        <button
          type="button"
          className="btn-next absolute right-0 top-1/2 z-10"
          onClick={() => page(nextPage)}
        >
          <span />
        </button>
      )}
      <div className="flex items-start overflow-hidden w-full h-[378px] text-primary" ref={wrapperRef}>
        <div className="flex flex-nowrap">
          {datas.map((data) => (
            <Transition
              appear={false}
              unmount={false}
              show={currentPage === data.page}
              enter="transform transition ease-in-out duration-500"
              enterFrom={direction === 'next' ? 'translate-x-96 opacity-0' : '-translate-x-96 opacity-0'}
              enterTo="translate-x-0 opacity-100"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo={direction === 'next' ? '-translate-x-96 opacity-0' : 'translate-x-96 opacity-0'}
              className="w-0 overflow-visible"
              as="div"
              key={data.page}
            >
              <div className="px-8" style={{ width: `${wrapperWidth}px` }}>
                {data.children}
              </div>
            </Transition>
          ))}
        </div>
      </div>
      <nav className="flex items-center justify-center space-x-3">
        {datas.map((data) => (
          <button
            type="button"
            key={data.page}
            onClick={() => page(data.page)}
          >
            <span className={`block w-2 h-2 rounded-full ${currentPage === data.page ? 'bg-primary' : 'bg-gray-200'}`} />
          </button>
        ))}
      </nav>
    </div>
  );
};
ChartHelp.defaultProps = {
  active: undefined,
};
export default ChartHelp;
