import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

// error
import checkApiError from './checkApiError';

// ユーザ情報削除
type DeleteAccount = {
  jisTid?:string|null,
}
export const deleteAccountAsync = createAsyncThunk<boolean, DeleteAccount, { state: RootState }>('auth/deleteAccountAsync', async (params, thunkApi) => {
  const state = thunkApi.getState();
  const domain = process.env.REACT_APP_API_DOMAIN;

  try {
    const { jisTid } = params;
    const response = await axios.delete(`${domain}/userAccount`, {
      data: {
        userAccount: {
          jisTid: jisTid ?? state.user.account.jisTid,
        },
      },
    });

    // JSONデータからエラーかどうか判断する
    checkApiError(response);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
  }
  return false;
});
export default deleteAccountAsync;
