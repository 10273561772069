import * as React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { theme } from 'twin.macro';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// components
import HelpButton from '../button/HelpButton';
import TableInputRow from '../table/TableInputRow';
import XAxisTick from './content/XAxisTick';
import YAxisTick from './content/YAxisTick';
import XAxisLabel from './content/XAxisLabel';
import CustomeSlider from './CustomeSlider';
import ChartLegend from './ChartLegend';

export type AssetGraphChart = {
  age: number;
  acquisitionValue: number; // 元本積立額
  presentValue: number; // 予想資産残高
}

const CustomizedTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (!active) {
    return null;
  }
  if (payload === undefined || payload === null || payload.length === 0) {
    return null;
  }
  const data = payload[0].payload as AssetGraphChart;
  return (
    <div className="bg-white border-2 border-primary rounded text-primary px-6 py-1">
      <div className="flex flex-col items-center">
        <div>
          <span className="font-alpha text-2xl">{data.age}</span>
          <span className="text-sm pl-1">歳</span>
        </div>
        <div>
          <p className="text-sm">元本積立額</p>
        </div>
        <div>
          <span className="font-alpha text-2xl">{costValue(data.acquisitionValue, 6)}</span>
          <span className="text-sm pl-1">万円</span>
        </div>
        <div>
          <p className="text-sm">予想資産残高</p>
        </div>
        <div>
          <span className="font-alpha text-2xl">{costValue(data.presentValue, 6)}</span>
          <span className="text-sm pl-1">万円</span>
        </div>
      </div>
    </div>
  );
};

/**
 * 企業年金関連のグラフ
 */
type AssetGraphProps = {
  datas: AssetGraphChart[]|undefined;
  expectedReturn?: number|null;
  help: React.ReactElement;
};
const AssetGraph: React.FC<AssetGraphProps> = ({ datas, expectedReturn, help }) => {
  // 四捨五入されたグラフ
  const [chartDatas, setChartDatas] = React.useState<AssetGraphChart[]>();

  // 指定範囲
  const [min, setMin] = React.useState<number>();
  const [max, setMax] = React.useState<number>();
  const [range, setRange] = React.useState([0, 0]);
  const [chartData, setChartData] = React.useState(datas);

  // 歳時の運用状況
  const [lastChartData, setLastChartData] = React.useState<AssetGraphChart>();

  // ref属性の参照（LineChartの型が不明なのでanyを仕様）
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const chartRef = React.useRef<any>(null);

  React.useEffect(() => {
    // グラフデータを四捨五入する
    const newChartDatas = datas?.map((data) => {
      return {
        age: data.age,
        acquisitionValue: Math.round(data.acquisitionValue / 10000),
        presentValue: Math.round(data.presentValue / 10000),
      };
    });
    setChartDatas(newChartDatas);

    // 年齢の最小値と最大値を範囲指定にする
    if (datas !== undefined) {
      const ageArray = datas.map((data) => data.age);
      const newMin = Math.min.apply(null, ageArray);
      const newMax = Math.max.apply(null, ageArray);
      setMin(newMin);
      setMax(newMax);
      setRange([
        datas.findIndex((data) => data.age === newMin),
        datas.findIndex((data) => data.age === newMax),
      ]);

      // 歳時の運用状況
      if (newChartDatas !== undefined) {
        setLastChartData(newChartDatas.find((data) => data.age === newMax));
      }
    }
  }, [datas]);

  React.useEffect(() => {
    if (chartDatas === undefined) {
      return;
    }

    // 指定範囲されたグラフを生成
    if (range !== undefined) {
      const rangeData = chartDatas.slice(range[0], range[1] + 1);
      setChartData(rangeData);
    }
  }, [chartDatas, range]);

  return (
    <>
      <div className="bg-gray-100 text-primary rounded p-4 mb-6">
        <div className="flex items-center">
          <p>
            <span className="font-alpha text-2xl">{lastChartData?.age}</span>
            <span className="text-sm ml-1">歳時の運用状況</span>
          </p>
          <HelpButton className="text-xl mt-3 ml-2">
            {help}
          </HelpButton>
        </div>
        <TableInputRow
          label="年利回り"
          value={expectedReturn}
          unit="%"
          unitWidth="w-10"
          division={-2}
          digits={2}
          help={(
            <>
              各リスク(0～9)毎の推奨ポートフォリオに基づく数値です（ご本人の現時点での利回りではありません）。下段の「リスク」にございます横棒バーにて変更が可能です。
            </>
          )}
        />
        <TableInputRow
          label="元本積立額"
          value={lastChartData?.acquisitionValue}
          unit="万円"
          unitWidth="w-10"
          fixed
          maxDigits={4}
          help={(
            <>
              各年齢までに拠出予定の積立合計金額です。
            </>
          )}
        />
        <TableInputRow
          label="予想資産残高"
          value={lastChartData?.presentValue}
          unit="万円"
          unitWidth="w-10"
          fixed
          maxDigits={4}
          help={(
            <>
              下記入力状況から想定される、元本積立額に関する資産運用結果金額です。
            </>
          )}
        />
      </div>
      <p className="text-[10px] text-gray-500">
        金額
        <br />
        (万円)
      </p>
      <div className="relative w-full pt-[184px]">
        <div
          className="absolute inset-0 -mx-3"
          onMouseLeave={() => chartRef.current.handleMouseLeave()}
        >
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
              margin={{
                top: 12, right: 12, left: 12, bottom: 12,
              }}
              barGap={10}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray="0" vertical={false} />
              <Bar dataKey="acquisitionValue" fill={theme('colors.primary')} />
              <Line dataKey="presentValue" strokeWidth={2} dot={false} stroke={theme('colors.green')} />
              <XAxis
                dataKey="age"
                tick={<XAxisTick />}
                label={<XAxisLabel value="年齢 (歳)" />}
              />
              <YAxis
                tickFormatter={(n: number) => n.toLocaleString()}
                mirror
                axisLine={false}
                tickLine={false}
                tick={<YAxisTick />}
              />
              <XAxis
                xAxisId={0}
                axisLine={false}
                tickLine={false}
                dataKey="value"
                tick={<XAxisTick />}
              />
              <Tooltip
                cursor={false}
                trigger="click"
                content={<CustomizedTooltip />}
                wrapperStyle={{ zIndex: 10, top: -200 }}
                allowEscapeViewBox={{ y: true }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      {datas !== undefined && (
        <div className="mx-2">
          <CustomeSlider
            value={range}
            valueLabelFormat={(value: number) => {
              const item = datas[value];
              return item !== undefined ? item.age : null;
            }}
            min={datas.findIndex((data) => data.age === min)}
            max={datas.findIndex((data) => data.age === max)}
            sx={{ mt: 0 }}
            onChange={(event: Event, newValue: number | number[]) => {
              if (typeof newValue === 'object') {
                setRange(newValue);
              }
            }}
            valueLabelDisplay="on"
          />
        </div>
      )}
      <ChartLegend
        options={[
          {
            color: 'bg-primary',
            label: '元本積立額',
            type: 'Line',
          },
          {
            color: 'bg-green',
            label: '予想資産残高',
            type: 'Line',
          },
        ]}
      />
    </>
  );
};
AssetGraph.defaultProps = {
  expectedReturn: undefined,
};
export default AssetGraph;
