// images
import iconFlag from '../assets/icon-flag.svg';
import iconBasic from '../assets/icon-basic.svg';
import iconEducation from '../assets/icon-education.svg';
import iconHouse from '../assets/icon-house.svg';
import iconRetire from '../assets/icon-retire.svg';
import iconAsset from '../assets/icon-asset.svg';
import iconCong from '../assets/icon-cong.svg';
import iconLogout from '../assets/icon-logout.svg';

export type NavItem = {
  name: string,
  to: string,
  guest: boolean,
  title?: boolean,
};
export type NavData = {
  name: string,
  to: string,
  title?: boolean,
  icon?: string,
  items?: NavItem[],
  ignore?: boolean,
  guest: boolean,
};

// ヘッダーメニューの設定
export const headerMenus: NavData[] = [
  {
    name: 'アドバイス',
    to: '/home/advice',
    icon: iconFlag,
    title: false,
    guest: false,
  },
  {
    name: '基本情報',
    to: '/home/basics',
    icon: iconBasic,
    guest: true,
    items: [
      {
        name: '家族構成',
        to: '/home/basics/family',
        guest: true,
      },
      {
        name: '預金・収入',
        to: '/home/basics/incomings',
        guest: true,
      },
      {
        name: '生活費',
        to: '/home/basics/livingCosts',
        guest: true,
      },
      {
        name: '生活費削減',
        to: '/home/basics/livingCostsReduce',
        guest: false,
      },
    ],
  },
  {
    name: '教育費用',
    to: '/home/education',
    icon: iconEducation,
    guest: false,
  },
  {
    name: '住宅費用',
    to: '/home/house',
    icon: iconHouse,
    guest: false,
    items: [
      {
        name: '現在の住宅',
        to: '/home/house/present',
        guest: false,
      },
      {
        name: '住宅購入予定',
        to: '/home/house/plan',
        guest: false,
      },
      {
        name: '繰り上げ返済計画',
        to: '/home/house/prepayment',
        guest: false,
      },
    ],
  },
  {
    name: '退職・老後',
    to: '/home/retire',
    icon: iconRetire,
    guest: false,
    items: [
      {
        name: '退職金・年金',
        to: '/home/retire/pension',
        guest: false,
      },
      {
        name: '老後の生活',
        to: '/home/retire/retirement',
        guest: false,
      },
      {
        name: '老後の再就職',
        to: '/home/retire/reEmployment',
        guest: false,
      },
    ],
  },
  {
    name: '資産形成',
    to: '/home/asset',
    icon: iconAsset,
    guest: true,
    items: [
      {
        name: '貯蓄・金融資産',
        to: '/home/asset/depositFinAssets',
        guest: true,
      },
      {
        name: '企業年金 (DC)',
        to: '/home/asset/dc',
        guest: true,
      },
      {
        name: '個人年金 (iDeCo)',
        to: '/home/asset/ideco',
        guest: false,
      },
      {
        name: 'つみたてNISA(～2023年)',
        to: '/home/asset/nisa',
        guest: false,
      },
      {
        name: '積立投信・投資信託',
        to: '/home/asset/trust',
        guest: false,
      },
      {
        name: '積立定期・その他',
        to: '/home/asset/otherInvestments',
        guest: false,
      },
    ],
  },
  {
    name: 'ユーザ設定',
    to: '/config',
    icon: iconCong,
    ignore: true,
    guest: false,
  },
  {
    name: 'ログアウト',
    to: '/logout',
    icon: iconLogout,
    ignore: true,
    guest: true,
  },
];

// サブメニュー情報
export const subMenus: NavData[] = [
  ...headerMenus,
  {
    name: 'ヘルプ',
    to: '/help',
    guest: true,
    items: [
      {
        name: '全体の概要・使い方',
        to: '/help/overview',
        guest: true,
      },
      {
        name: 'ログイン',
        to: '/help/login',
        guest: true,
      },
      {
        name: 'iDeCoアカウント',
        to: '/help/ideco',
        guest: true,
      },
      {
        name: 'ユーザ登録',
        to: '/help/personalInfo',
        guest: true,
      },
      {
        name: 'メール配信停止',
        to: '/help/mail',
        guest: true,
      },
      {
        name: '退会',
        to: '/help/quit',
        guest: true,
      },
      {
        name: 'お問い合わせ',
        to: '/help/contact',
        guest: true,
      },
    ],
  },
  {
    name: 'ご利用規約',
    to: '/terms',
    guest: true,
  },
];
