import * as React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { theme } from 'twin.macro';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// components
import XAxisTick from '../../features/recharts/content/XAxisTick';
import YAxisTick from '../../features/recharts/content/YAxisTick';
import XAxisLabel from '../../features/recharts/content/XAxisLabel';
import CustomeSlider from '../../features/recharts/CustomeSlider';
import ChartLegend from '../../features/recharts/ChartLegend';

export type Chart = {
  age: number,
  before: number,
  after: number,
}

const CustomizedTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (!active) {
    return null;
  }
  if (payload === undefined || payload === null || payload.length === 0) {
    return null;
  }
  const data = payload[0].payload as Chart;
  return (
    <div className="bg-white border-2 border-primary rounded text-primary px-6 py-1">
      <div className="flex flex-col items-center">
        <div>
          <span className="font-alpha text-2xl">{data.age}</span>
          <span className="text-sm pl-1">歳</span>
        </div>
        <div>
          <p className="text-sm">当初計画</p>
        </div>
        <div>
          <span className="font-alpha text-2xl">{costValue(data.before, 12)}</span>
          <span className="text-sm pl-1">万円</span>
        </div>
        <div>
          <p className="text-sm">繰り上げ後</p>
        </div>
        <div>
          <span className="font-alpha text-2xl">{costValue(data.after, 12)}</span>
          <span className="text-sm pl-1">万円</span>
        </div>
      </div>
    </div>
  );
};

type LoanGraphProps = {
  datas: Chart[]|undefined;
};
const LoanGraph: React.FC<LoanGraphProps> = ({ datas }) => {
  // 四捨五入されたグラフ
  const [chartDatas, setChartDatas] = React.useState<Chart[]>();

  // 指定範囲
  const [min, setMin] = React.useState<number>();
  const [max, setMax] = React.useState<number>();
  const [range, setRange] = React.useState([0, 0]);
  const [chartData, setChartData] = React.useState(datas);

  // ref属性の参照（LineChartの型が不明なのでanyを仕様）
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const chartRef = React.useRef<any>(null);

  React.useEffect(() => {
    // グラフデータを四捨五入する
    const newChartDatas = datas?.map((data) => {
      return {
        age: data.age,
        before: Math.round(data.before / 10000),
        after: Math.round(data.after / 10000),
      };
    });
    setChartDatas(newChartDatas);

    // 年齢の最小値と最大値を範囲指定にする
    if (datas !== undefined) {
      const ageArray = datas.map((data) => data.age);
      const newMin = Math.min.apply(null, ageArray);
      const newMax = Math.max.apply(null, ageArray);
      setMin(newMin);
      setMax(newMax);
      setRange([
        datas.findIndex((data) => data.age === newMin),
        datas.findIndex((data) => data.age === newMax),
      ]);
    }
  }, [datas]);

  React.useEffect(() => {
    if (chartDatas === undefined) {
      return;
    }

    // 指定範囲されたグラフを生成
    if (range !== undefined) {
      const rangeData = chartDatas.slice(range[0], range[1] + 1);
      setChartData(rangeData);
    }
  }, [chartDatas, range]);

  return (
    <>
      <p className="text-[10px] text-gray-500">(万円)</p>
      <div className="relative w-full pt-[184px]">
        <div
          className="absolute inset-0 -mx-3"
          onMouseLeave={() => chartRef.current.handleMouseLeave()}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 12, right: 12, left: 12, bottom: 12,
              }}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray="0" vertical={false} />
              <Line dataKey="before" strokeWidth={2.5} dot={false} stroke={theme('colors.primary')} />
              <Line dataKey="after" strokeWidth={2.5} dot={false} stroke={theme('colors.green')} />
              <XAxis
                dataKey="age"
                tick={<XAxisTick />}
                label={<XAxisLabel value="年齢 (歳)" />}
              />
              <YAxis
                tickFormatter={(n: number) => n.toLocaleString()}
                mirror
                axisLine={false}
                tickLine={false}
                tick={<YAxisTick />}
              />
              <XAxis
                xAxisId={0}
                axisLine={false}
                tickLine={false}
                dataKey="value"
                tick={<XAxisTick />}
              />
              <Tooltip
                cursor={false}
                trigger="click"
                content={<CustomizedTooltip />}
                wrapperStyle={{ zIndex: 10, top: -200 }}
                allowEscapeViewBox={{ y: true }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      {datas !== undefined && (
        <div className="mx-2">
          <CustomeSlider
            value={range}
            valueLabelFormat={(value: number) => {
              const item = datas[value];
              return item !== undefined ? item.age : null;
            }}
            min={datas.findIndex((data) => data.age === min)}
            max={datas.findIndex((data) => data.age === max)}
            sx={{ mt: 0 }}
            onChange={(event: Event, newValue: number | number[]) => {
              if (typeof newValue === 'object') {
                setRange(newValue);
              }
            }}
            valueLabelDisplay="on"
          />
        </div>
      )}
      <ChartLegend
        options={[
          {
            color: 'bg-primary',
            label: '当初計画',
          },
          {
            color: 'bg-green',
            label: '一部繰上げ返済プラン',
          },
        ]}
      />
    </>
  );
};
export default LoanGraph;
