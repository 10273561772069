import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectBirthdate } from '../../reducers/userReducer';
import {
  selectHasSpouse, selectFamily, selectHousingExpenses,
  setHousingExpenses as setDispatchHousingExpenses,
} from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// validation
import { isPeriodOfFixedRateCheck } from '../../services/validation';

// components
import InputDecimal from '../../features/input/InputDecimal';
import InputRadioGroup, { InputRadioOption } from '../../features/input/InputRadioGroup';
import Fieldset from './Fieldset';
import Append from '../../features/button/Append';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const livingStyleOptions: InputRadioOption[] = [
  { value: 'RENTAL_HOUSING', label: '賃貸住宅' },
  { value: 'OWNING_HOUSE', label: '持ち家' },
  { value: 'HOME', label: '実家' },
];

const HomeHousePresent: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouse);

  // 借入年月の選択範囲を決めるための生年月を取得
  const birthdate = useAppSelector(selectBirthdate);
  // 借入年月の選択範囲を決めるための配偶者の生年月を取得
  const defaultFamily = useAppSelector(selectFamily);
  const spouseBirthMonth = defaultFamily.spouse.birthMonth;

  // 入力データ
  const defaultHousingExpenses = useAppSelector(selectHousingExpenses);
  const [housingExpenses, setHousingExpenses] = React.useState(defaultHousingExpenses);

  // 現在の住宅
  const [presentHouse, setPresentHouse] = React.useState(housingExpenses.presentHouse);

  // 家賃
  const [monthlyRent, setMonthlyRent] = React.useState(housingExpenses.monthlyRent);

  // 管理費・修繕費
  const [maintenanceFee, setMaintenanceFee] = React.useState(housingExpenses.maintenanceFee);

  // 居住費
  const [
    monthlyHousingExpense, setMonthlyHousingExpense,
  ] = React.useState(housingExpenses.monthlyHousingExpense);

  // 現在の住宅ローン (あなた)
  const [
    loanOfHouseholder, setLoanOfHouseholder,
  ] = React.useState(housingExpenses.loanOfHouseholder);

  // 現在の住宅ローン (配偶者)
  const [loanOfSpouse, setLoanOfSpouse] = React.useState(housingExpenses.loanOfSpouse);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // データ更新
  React.useEffect(() => {
    setHousingExpenses((data) => {
      return {
        ...data,
        presentHouse,
        monthlyRent,
        maintenanceFee,
        loanOfHouseholder,
        loanOfSpouse,
        monthlyHousingExpense,
      };
    });
  }, [
    presentHouse,
    monthlyRent,
    maintenanceFee,
    loanOfHouseholder,
    loanOfSpouse,
    monthlyHousingExpense,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setHousingExpenses(defaultHousingExpenses);

      // 現在の住宅を切り替えたときローンをリセットする
      setMonthlyRent(defaultHousingExpenses.monthlyRent);
      setMaintenanceFee(defaultHousingExpenses.maintenanceFee);
      setMonthlyHousingExpense(defaultHousingExpenses.monthlyHousingExpense);
      setLoanOfHouseholder(defaultHousingExpenses.loanOfHouseholder);
      setLoanOfSpouse(defaultHousingExpenses.loanOfSpouse);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>現在の住宅</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">現在住んでいる居住形態を教えてください。</p>
        <p className="text-sm mt-2 mb-6">
          賃貸住宅の場合は家賃を、実家の場合は実家に入れている居住費を入力してください。
          持ち家の場合で住宅ローンを組んでいる場合は契約当時の情報をご用意いただけると入力がスムーズです。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">住居形態</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>現在の住宅</span>
              </div>
              <InputRadioGroup
                name="presentHouse"
                options={livingStyleOptions}
                defaultValue={presentHouse}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  if (value !== '') {
                    setPresentHouse(value);
                  } else {
                    setPresentHouse(null);
                  }
                }}
              />
            </div>
            <div className="fieldset-body">
              {
                presentHouse === 'RENTAL_HOUSING' && (
                  <>
                    <div className="form-label">
                      <span>家賃</span>
                    </div>
                    <InputDecimal
                      name="monthlyRent"
                      className="w-[120px]"
                      unit="万円／月"
                      defaultValue={monthlyRent}
                      division={4}
                      size={3}
                      digits={1}
                      onChange={(value) => setMonthlyRent(value)}
                    />
                  </>
                )
              }
              {
                presentHouse === 'OWNING_HOUSE' && (
                  <>
                    <div className="form-label">
                      <span>管理費・修繕費</span>
                    </div>
                    <InputDecimal
                      name="maintenanceFee"
                      className="w-[120px]"
                      unit="万円／月"
                      defaultValue={maintenanceFee}
                      division={4}
                      size={3}
                      digits={1}
                      onChange={(value) => setMaintenanceFee(value)}
                    />
                  </>
                )
              }
              {
                presentHouse === 'HOME' && (
                  <>
                    <div className="form-label">
                      <span>居住費</span>
                    </div>
                    <InputDecimal
                      name="monthlyHousingExpense"
                      className="w-[120px]"
                      unit="万円／月"
                      defaultValue={monthlyHousingExpense}
                      division={4}
                      size={3}
                      digits={1}
                      onChange={(value) => setMonthlyHousingExpense(value)}
                    />
                  </>
                )
              }
            </div>
          </section>
          {
            presentHouse === 'OWNING_HOUSE' && (
              <>
                <section>
                  <h3 className="fieldset-title">現在の住宅ローン (あなた)</h3>
                  <Fieldset
                    prefix="loanOfHouseholder"
                    datas={loanOfHouseholder}
                    birthdate={birthdate}
                    onChange={
                      (data) => setLoanOfHouseholder(data)
                    }
                  />
                </section>
                <div className="py-8 border-primary border-t">
                  <Append
                    label={showSpouse ? '詳細を閉じる（配偶者）' : '現在の住宅ローン (配偶者)'}
                    onClick={() => setShowSpouse(!showSpouse)}
                    minus={showSpouse}
                    disabled={!hasSpouse}
                  />
                </div>
                {showSpouse && (
                  <div className="border-primary border-t">
                    <Fieldset
                      prefix="loanOfSpouse"
                      datas={loanOfSpouse}
                      birthdate={spouseBirthMonth}
                      onChange={
                        (data) => setLoanOfSpouse(data)
                      }
                    />
                  </div>
                )}
              </>
            )
          }
        </div>
        <FixedButton
          disabled={deepEqual(defaultHousingExpenses, housingExpenses)}
          contentId="present"
          onClick={() => {
            dispatch(setDispatchHousingExpenses(housingExpenses));
          }}
          validation={isPeriodOfFixedRateCheck(housingExpenses, null, 'present')}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeHousePresent;
