import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// ユーザ情報
import { useAppSelector, useAppDispatch } from '../../app/hooks';

// 初期データ登録
import { storeAsync, updateAccountAsync } from '../../reducers/authReducer';
import { selectIsGuest, setInitialSettingStatus } from '../../reducers/userReducer';

import { selectMessages } from '../../reducers/errorReducer';
import Alert, { Error } from '../../features/error/Alert';

// images
import { ReactComponent as ImgFamilySingle } from './assets/familySingle.svg';
import { ReactComponent as ImgFamilyPair } from './assets/familyPair.svg';
import { ReactComponent as ImgFamilyFamily } from './assets/familyFamily.svg';
import { ReactComponent as ImgFamilyyChild } from './assets/familyChild.svg';
import { ReactComponent as ImgChileSingle } from './assets/childSingle.svg';
import { ReactComponent as ImgChildPair } from './assets/childPair.svg';
import { ReactComponent as ImgChildMany } from './assets/childMany.svg';
import { ReactComponent as ImgSpouseHouse } from './assets/spouseHouse.svg';
import { ReactComponent as ImgSpouseRegular } from './assets/spouseRegular.svg';
import { ReactComponent as ImgSpouseTemporary } from './assets/spouseTemporary.svg';
import { ReactComponent as ImgSpousePartTime } from './assets/spousePartTime.svg';
import { ReactComponent as ImgHouseHome } from './assets/houseHome.svg';
import { ReactComponent as ImgHouseRent } from './assets/houseRent.svg';
import { ReactComponent as ImgHouseOwn } from './assets/houseOwn.svg';
import { ReactComponent as ImgLifeStyleTravel } from './assets/lifeStyleTravel.svg';
import { ReactComponent as ImgLifeStyleWork } from './assets/lifeStyleWork.svg';
import { ReactComponent as ImgLifeStyleHobby } from './assets/lifeStyleHobby.svg';
import { ReactComponent as ImgLifeStyleHouse } from './assets/lifeStyleHouse.svg';
import { ReactComponent as ImgRetirementSimple } from './assets/retirementSimple.svg';
import { ReactComponent as ImgRetirementKeep } from './assets/retirementKeep.svg';
import { ReactComponent as ImgRetirementLuxury } from './assets/retirementLuxury.svg';

// components
import Loading from '../../features/Loading';
import ServerError from '../../features/error/ServerError';

const StepBar = styled.div`
  transition-duration: 300ms;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

// 初期設定マスターデータ
type DataChoise = {
  img: React.FunctionComponent,
  label: string,
  sublabel?: string,
  value: number,
  code?: 'TRAVEL_LIKE' | 'FULL_WORK' | 'MANY_HOBBIES' | 'RELAX_AT_HOME',
}
type Data = {
  title: string,
  key: 'family' | 'children' | 'spouse' | 'house' | 'lifeStyle' | 'retirement',
  ignore?: number[],
  choises: DataChoise[]
}
const DATAS: Data[] = [
  {
    title: '現在の家族構成について\n教えてください',
    key: 'family',
    choises: [
      {
        img: ImgFamilySingle,
        label: '単身',
        sublabel: '(親世代は対象外)',
        value: 1,
      },
      {
        img: ImgFamilyPair,
        label: '夫婦',
        sublabel: '(あなた＋配偶者)',
        value: 2,
      },
      {
        img: ImgFamilyFamily,
        label: '親子',
        sublabel: '(あなた+配偶者+子)',
        value: 3,
      },
      {
        img: ImgFamilyyChild,
        label: 'ひとり親',
        sublabel: '(あなた＋子)',
        value: 4,
      },
    ],
  },
  {
    title: 'あなたの子供の人数を\n教えてください',
    key: 'children',
    ignore: [1, 2],
    choises: [
      {
        img: ImgChileSingle,
        label: '1人',
        value: 1,
      },
      {
        img: ImgChildPair,
        label: '2人',
        value: 2,
      },
      {
        img: ImgChildMany,
        label: '3人以上',
        value: 3,
      },
    ],
  },
  {
    title: 'あなたの配偶者について\n教えてください',
    key: 'spouse',
    ignore: [1, 4],
    choises: [
      {
        img: ImgSpouseHouse,
        label: '専業主婦',
        sublabel: '(主夫)',
        value: 1,
      },
      {
        img: ImgSpouseRegular,
        label: '共働き',
        sublabel: '(正社員)',
        value: 2,
      },
      {
        img: ImgSpouseTemporary,
        label: '共働き',
        sublabel: '(派遣・契約社員)',
        value: 3,
      },
      {
        img: ImgSpousePartTime,
        label: '共働き',
        sublabel: '(パート・アルバイト)',
        value: 4,
      },
    ],
  },
  {
    title: 'あなたのお住まいについて\n教えてください',
    key: 'house',
    choises: [
      {
        img: ImgHouseHome,
        label: '実家暮らし',
        value: 1,
      },
      {
        img: ImgHouseRent,
        label: '賃貸住宅',
        value: 2,
      },
      {
        img: ImgHouseOwn,
        label: '持ち家',
        value: 3,
      },
    ],
  },
  {
    title: 'あなたに最も当てはまる\nライフスタイルを教えてください',
    key: 'lifeStyle',
    choises: [
      {
        img: ImgLifeStyleTravel,
        label: '旅行好き',
        value: 1,
        code: 'TRAVEL_LIKE',
      },
      {
        img: ImgLifeStyleWork,
        label: '仕事が充実',
        value: 2,
        code: 'FULL_WORK',
      },
      {
        img: ImgLifeStyleHobby,
        label: '趣味が多い',
        value: 3,
        code: 'MANY_HOBBIES',
      },
      {
        img: ImgLifeStyleHouse,
        label: '家でのんびり',
        value: 4,
        code: 'RELAX_AT_HOME',
      },
    ],
  },
  {
    title: 'あなたの理想の老後の暮らし方を\n教えてください',
    key: 'retirement',
    choises: [
      {
        img: ImgRetirementSimple,
        label: '質素な暮らし',
        value: 1,
      },
      {
        img: ImgRetirementKeep,
        label: '現状維持',
        value: 2,
      },
      {
        img: ImgRetirementLuxury,
        label: '贅沢な暮らし',
        value: 3,
      },
    ],
  },
];

// 選択されたデータ
type Choise = {
  key: 'family' | 'children' | 'spouse' | 'house' | 'lifeStyle' | 'retirement',
  value: number,
}

const InitialSetting: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ゲストかどうか
  const guest = useAppSelector(selectIsGuest);

  // エラーメッセージを取得
  const errorMes = useAppSelector(selectMessages);
  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<Error[]>([]);

  React.useEffect(() => {
    const errs: Error[] = [];
    errorMes?.map((message, i) => {
      errs.push({
        key: i.toString(),
        message,
      });
      return errs;
    });
    setErrors(errs);
  }, [errorMes]);

  // ステップ
  const [step, setStep] = React.useState<number>(0);
  const [stepStyle, setStepStyle] = React.useState({
    width: '0%',
  });

  // 現在のステップの選択項目情報
  const [datas, setDatas] = React.useState(DATAS);
  const [data, setData] = React.useState<Data>();

  // 選択されたデータ
  const [choises, setChoises] = React.useState<Choise[]>([]);

  React.useEffect(() => {
    // 最終ステップ
    const stepMax = datas.length;

    // ステップバー更新
    const stepWidth = (step / stepMax) * 100;
    setStepStyle({
      width: `${stepWidth}%`,
    });

    // 最終ステップを超えた場合は、最後の画面を表示
    const stepData = (stepMax > step) ? datas[step] : datas[datas.length - 1];
    // 画面データ更新
    setData(stepData);
  }, [step]);

  // 読み込み中
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="container pt-[50px] pb-[50px]">
      <h1 className="text-2xl text-center text-primary font-bold mb-4">シミュレーション初期設定</h1>
      <div className="bg-white rounded-full overflow-hidden w-[160px] h-[7px] mx-auto mb-8">
        <StepBar className="bg-primary h-full" style={stepStyle} />
      </div>
      {!loading
        ? (data !== undefined) && (
          <>
            <ServerError />
            <Alert errors={errors} className="mb-4">
              アカウント登録エラー
            </Alert>
            <p className="text-lg text-center text-primary whitespace-pre-wrap mb-4 font-bold">{data.title}</p>
            <div className="flex justify-center flex-wrap">
              {data.choises.map((choise) => {
                const choiseValue = choises.find((obj) => obj.key === data.key)?.value;
                let className = 'flex flex-col justify-center items-center rounded w-full h-[124px] drop-shadow';
                if (choiseValue === choise.value) {
                  className += ' bg-primary text-white stroke-red';
                } else {
                  className += ' bg-white text-primary active:bg-primary active:bg-white';
                }
                return (
                  <div className="w-6/12 p-[10px]" key={choise.value}>
                    <button
                      type="button"
                      className={className}
                      onClick={() => {
                        let newDatas = datas;
                        if (data.key === 'family') {
                          // 家族構成選択時にステップ選択項目の中身を変更
                          newDatas = DATAS.filter((obj) => {
                            if (obj.ignore) {
                              return obj.ignore.indexOf(choise.value) === -1;
                            }
                            return true;
                          });
                          setDatas(newDatas);
                        }

                        let next = step + 1;
                        if (next === newDatas.length - 1) {
                          // 最終画面の一つ手間ですでに選択状態になっていたら、一気に最終画面まで進める
                          const lastData = newDatas[newDatas.length - 1];
                          if (choises.find((obj) => obj.key === lastData.key) !== undefined) {
                            next = newDatas.length;
                          }
                        }
                        if (next > newDatas.length) {
                          // 確認画面を出すために、マスターデータの長さより一つ多めのstep数にする
                          next = newDatas.length;
                        }
                        setStep(next);

                        // 選択された情報を更新
                        const newChoises = choises.filter((obj) => obj.key !== data.key);
                        newChoises.push({
                          key: data.key,
                          value: choise.value,
                        });
                        setChoises(newChoises);
                      }}
                    >
                      <div className="mb-[12px]">
                        <choise.img />
                      </div>
                      <span className="text-base font-bold">{choise.label}</span>
                      {choise.sublabel !== undefined && <span className="text-xs -mt-1">{choise.sublabel}</span>}
                    </button>
                  </div>
                );
              })}
            </div>
            {
              (step === datas.length) && (
                <div className="px-sm mt-8">
                  <button
                    type="submit"
                    className="btn-submit"
                    onClick={async () => {
                      setLoading(true);
                      window.scrollTo(0, 0);
                      // データを新規に登録
                      try {
                        const isSuccesed = await dispatch(storeAsync({
                          family: choises.find((obj) => obj.key === 'family')?.value,
                          children: choises.find((obj) => obj.key === 'children')?.value,
                          spouse: choises.find((obj) => obj.key === 'spouse')?.value,
                          house: choises.find((obj) => obj.key === 'house')?.value,
                          lifeStyle: choises.find((obj) => obj.key === 'lifeStyle')?.value,
                          retirement: choises.find((obj) => obj.key === 'retirement')?.value,
                        }));

                        if (!isSuccesed.payload) {
                          setLoading(false);
                          return;
                        }
                      } catch (e) {
                        //
                      }

                      if (!guest) {
                        // アカウント更新
                        try {
                          await dispatch(updateAccountAsync({
                            initialSettingStatus: true,
                          }));
                        } catch (e) {
                          //
                        }
                      } else {
                        dispatch(setInitialSettingStatus(true));
                      }

                      // 画面遷移
                      navigate('/home');
                    }}
                  >
                    初期設定を完了
                  </button>
                </div>
              )
            }
            {
              (step > 0) && (
                <div className="text-center mt-6">
                  <button
                    type="button"
                    className="btn-back"
                    onClick={() => {
                      if (step > datas.length - 1 && data !== undefined) {
                        // 最後の画面から戻った場合2ステップ戻す
                        const next = step - 2;
                        setStep(next);
                      } else {
                        const next = step - 1;
                        setStep(next);
                      }
                    }}
                  >
                    戻る
                  </button>
                </div>
              )
            }
          </>
        )
        : (
          <Loading>
            データを読み込み中です…
          </Loading>
        )}
    </div>
  );
};
export default InitialSetting;
