import * as React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAccount } from '../../reducers/userReducer';

// ユーザ情報更新・削除
import { updateAccountAsync, deleteAccountAsync, updateProgressAsync } from '../../reducers/authReducer';
import { pushNextStep, CONFIG_DATAS } from '../../reducers/stepReducer';
// services
import validation from '../../services/validation';

// components
import InputNickname from '../../features/input/InputNickname';
import InputEmail from '../../features/input/InputEmail';
import InputCheckbox from '../../features/input/InputCheckbox';
import Alert, { Error } from '../../features/error/Alert';
import Modal from '../../features/Modal';
import Loading from '../../features/Loading';
import ServerError from '../../features/error/ServerError';

const ConfigScene: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<Error[]>([]);

  const { pathname } = useLocation();

  React.useEffect(() => {
    // 画面遷移時にアクティブページを記録する
    const async = async (): Promise<void> => {
      const currentConfig = CONFIG_DATAS.find((d) => d.to === '/home/advice');
      const contentId = currentConfig?.contentId;
      if (contentId !== undefined) {
        dispatch(pushNextStep(contentId));
        await dispatch(updateProgressAsync({
          contentId,
        }));
      }
    };
    async();
  }, [pathname]);

  // デフォルト値を指定するためにユーザ情報
  const defaulAccount = useAppSelector(selectAccount);

  // ニックネーム
  const [nickname, setNickname] = React.useState(defaulAccount.nickname);

  // メールアドレス
  const [email, setEmail] = React.useState(defaulAccount.email);

  // みずほ銀行からの情報提供メールを受け取る
  const [
    requestForEmailNewsLetter, setRequestForEmailNewsLetter,
  ] = React.useState(defaulAccount.requestForEmailNewsLetter);

  // ユーザ情報削除確認のモーダル表示
  const [showConfirm, setShowConfirm] = React.useState(false);

  // 削除中
  const [deleting, setDeleting] = React.useState(false);

  return (
    <div className="container py-[50px]">
      <div className="px-sm">
        <h1 className="text-lg text-primary font-bold text-center mb-10">ユーザ登録情報の変更</h1>
        <ServerError />
        <Alert errors={errors} className="mb-4">
          アカウント登録エラー
        </Alert>
        <div className="space-y-8">
          <div>
            <div className="form-label text-gray-500">
              <span>ニックネーム</span>
            </div>
            <InputNickname defaultValue={nickname} onChange={(value) => setNickname(value)} />
          </div>
          <div>
            <div className="form-label text-gray-500">
              <span>メールアドレス</span>
            </div>
            <InputEmail defaultValue={email} onChange={(value) => setEmail(value)} />
            <InputCheckbox
              className="text-gray-500 mt-4"
              defaultChecked={requestForEmailNewsLetter}
              onChange={(checked) => setRequestForEmailNewsLetter(checked)}
            >
              みずほ銀行からの情報提供メールを受け取る
            </InputCheckbox>
          </div>
        </div>
        <div className="space-y-8 mt-10">
          <div className="px-xs">
            <button
              type="submit"
              className="btn-submit"
              onClick={async () => {
                // バリデーションのエラー結果
                const errorMessages:Error[] = [];

                // ニックネームのバリデーション
                const nicknameError = validation(nickname, { name: 'ニックネーム', required: true });
                if (nicknameError !== null) {
                  errorMessages.push({ key: 'name', message: nicknameError });
                }

                // メールアドレスのバリデーション
                const emailError = validation(email, { name: 'メールアドレス', required: true, email: true });
                if (emailError !== null) {
                  errorMessages.push({ key: 'email', message: emailError });
                }
                if (errorMessages.length === 0) {
                  // バリデーションが成功したら値を保存
                  const isSuccesed = await dispatch(updateAccountAsync({
                    nickname,
                    email,
                    requestForEmailNewsLetter,
                  }));
                  if (!isSuccesed.payload) {
                    return;
                  }
                  // eslint-disable-next-line no-alert
                  alert('更新しました');
                }
                setErrors(errorMessages);
              }}
            >
              変更を保存
            </button>
          </div>
          <div className="px-sm">
            <Link to="/home" className="btn-back btn-back--lg">シミュレーション結果に戻る</Link>
          </div>
        </div>
        <div className="border-t border-t-gray-200 mt-[50px] pt-[50px]">
          <div className="text-center">
            <button type="button" className="text-gray-500 underline font-bold" onClick={() => setShowConfirm(true)}>ユーザ情報を削除する</button>
          </div>
          <p className="text-sm text-gray-700 mt-5">※ニックネーム・メールアドレス・シミュレーション入力値がすべて削除されます。再度ご利用いただくには改めてユーザ登録が必要となりますので、ご注意ください。</p>
        </div>
      </div>
      <Modal show={showConfirm} onClose={() => setShowConfirm(false)}>
        <div className="py-7">
          {deleting
            ? (
              <Loading>
                <p>削除中です…</p>
                <p>完了後トップ画面へ遷移します。</p>
              </Loading>
            )
            : (
              <div className="text-center text-base text-primary font-bold">
                <p>
                  入力情報が全てリセットされます。
                  <br />
                  ユーザ情報を削除しても
                  <br />
                  よろしいでしょうか？
                </p>
                <div className="px-10 flex flex-col items-center space-y-5 mt-5">
                  <button
                    type="button"
                    className="btn-submit drop-shadow max-w-[235px]"
                    onClick={async () => {
                      // ユーザ削除処理
                      setDeleting(true);
                      await dispatch(deleteAccountAsync({}));
                      setDeleting(false);
                      navigate('/top');
                    }}
                  >
                    削除
                  </button>
                  <button type="button" className="btn-submit drop-shadow max-w-[235px]" onClick={() => setShowConfirm(false)}>キャンセル</button>
                </div>
              </div>
            )}
        </div>
      </Modal>
    </div>
  );
};
export default ConfigScene;
