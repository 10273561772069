import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// データ
import { useAppSelector } from '../../app/hooks';
import {
  selectNickname,
  selectBankBranchName,
  selectBankClerkPhoneNo,
  selectReminder,
  selectResponsible,
} from '../../reducers/userReducer';

// images
import imgHeroPension from './assets/hero_pension.svg';
import imgHeroRetirement from './assets/hero_retirement.svg';
import imgHeroDiscussion from './assets/hero_discussion.png';

// components
import PensionGraphSection from '../../features/section/PensionGraphSection';
import PensionTableSection from '../../features/section/PensionTableSection';
import RetirementGraphSection from '../../features/section/RetirementGraphSection';
import NextSteps from './NextSteps';
import Recommends from './Recommends';
import FinishBalloons from './FinishBalloons';
import ServerError from '../../features/error/ServerError';

const H2 = styled.h2`
  text-align: center;
  font-weight: 700;
  color: ${theme`colors.primary`};
  font-size: ${theme`fontSize.xl`};
  margin: ${theme`margin.6`} 0;
`;

const HomeAdvice: React.FC = () => {
  // ユーザ名取得
  const nickname = useAppSelector(selectNickname);

  const bankBranchName = useAppSelector(selectBankBranchName);
  const responsible = useAppSelector(selectResponsible);
  const bankClerkPhoneNo = useAppSelector(selectBankClerkPhoneNo);
  const reminder = useAppSelector(selectReminder);
  return (
    <div className="container">
      <ServerError />
      <NextSteps />
      <H2>
        {nickname}
        さんの
        <br />
        老後のイメージ
      </H2>
      <div className="space-y-5">
        <div className="card pb-0">
          <h3>老後の貯蓄額のバランスをみて退職金・年金の受け取り方法を検討しましょう。</h3>
          <figure>
            <img src={imgHeroPension} alt="" width={212} height={170} />
          </figure>
          <div className="px-7 mb-8">
            <Link to="/home/retire/pension" className="btn-link">受取方法を選択する</Link>
          </div>
          <PensionGraphSection />
          <PensionTableSection />
        </div>
        <div className="card pb-0">
          <figure>
            <img src={imgHeroRetirement} alt="" width={207} height={150} />
          </figure>
          <h3>老後も現在の生活水準を維持するための準備をしていきましょう。</h3>
          <div className="px-7 mb-8 mt-5">
            <Link to="/home/retire/retirement" className="btn-link">老後の暮らし方を選択する</Link>
          </div>
          <RetirementGraphSection />
        </div>
      </div>
      { bankBranchName
      && (
        <>
          <H2>
            {`${nickname ? `${nickname}さん` : 'あなた'}の`}
            <br />
            会社の担当者
          </H2>
          <div className="border-[5px] border-gray-50 text-primary p-4">
            <h3 className="text-base font-bold text-center">
              {nickname}
              さん
              <br />
              DCはじめ資産運用について
              <br />
              お悩みはありませんか？
            </h3>
            <figure className="mt-5">
              <img src={imgHeroDiscussion} alt="" width={270} height={139} className="block mx-auto" />
            </figure>
            <div className="card">
              <div className="text-center space-y-2">
                <p className="text-base">
                  {nickname}
                  さん
                  <br />
                  担当のみずほ銀行
                </p>
                <p className="text-base font-bold">
                  {`${bankBranchName} ${responsible}`}
                </p>
                <p className="font-alpha text-3xl leading-none">{bankClerkPhoneNo}</p>
                <p className="text-sm">までお気軽にお問い合わせください。</p>
              </div>
              <p className="text-sm mt-2">{reminder}</p>
            </div>
          </div>
        </>
      )}
      <H2>
        {nickname}
        さんへ
        <br />
        おすすめの商品
      </H2>
      <Recommends />
      <FinishBalloons />
    </div>
  );
};
export default HomeAdvice;
