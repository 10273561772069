import * as React from 'react';
import moment from 'moment';

// グリーンラベルに表示するラベル名を取得
import { getLabelValue } from '../../reducers/estimatedReducer';

type EstimationLabelDateProps = {
  source: string|null|undefined;
  value: string|null|undefined;
};

/**
 * グリーンラベル部品コンポーネント（加入年月）
 * @param props
 * @returns
 */
const EstimationLabelDate: React.FC<EstimationLabelDateProps> = (
  { source, value },
) => {
  const [label, setLabel] = React.useState<string|null>(null);

  React.useEffect(() => {
    setLabel(getLabelValue(source));
  }, [source]);

  if (label === null) {
    return null;
  }

  // グリーンラベルに表示する年月の値を取得
  const getDateValue = (n:string|null = null) => {
    if (n !== null) {
      const date = moment(n);
      return date.format('「YYYY年M月」');
    }
    return '-';
  };

  return (
    <p className="green-label">
      {label}
      { getDateValue(value) }
    </p>
  );
};
export default EstimationLabelDate;
