import * as React from 'react';
import { Link } from 'react-router-dom';
import deepEqual from 'deep-equal';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectLivingExpenses, setLivingExpenses as setDispatchLivingExpenses } from '../../reducers/inputReducer';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';
import { selectMonthlyHousingExpenses } from '../../reducers/cashflowReducer';

// images
import imgHero from './assets/hero.svg';

// components
import InputInteger from '../../features/input/InputInteger';
import InputRadioGroup from '../../features/input/InputRadioGroup';
import Amount from '../../features/block/Amount';
import Remarks from '../../features/block/Remarks';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeBasicsLivingCostsReduce: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);

  // 改善モード前は生活費削減部分は非活性になる
  const isAdviced = useAppSelector(selectIsAdviceOpened);

  // デフォルト値を指定するための情報
  const defaultLivingExpenses = useAppSelector(selectLivingExpenses);
  const [livingExpenses, setLivingExpenses] = React.useState(defaultLivingExpenses);

  // 今月の削減率
  const [
    savingExpensesRate, setSavingExpensesRate,
  ] = React.useState(defaultLivingExpenses.savingExpensesRate);

  // 削減可能な生活費(教育費は含まない)
  // それぞれの値を万単位で四捨五入して足す
  // ・住宅費月額
  // ・生活費月額
  // ・自動車購入費月額
  // ・旅行規制年額÷12
  // ・その他年額÷12
  const monthlyHousingExpenses = useAppSelector(selectMonthlyHousingExpenses);
  const reducableCost = Math.round((monthlyHousingExpenses ?? 0)
    + (defaultLivingExpenses.monthlyLivingExpenses ?? 0)
    + (defaultLivingExpenses.carMaintenanceExpensesMonthly ?? 0)
    + ((defaultLivingExpenses.annualTravelExpenses ?? 0) / 12)
    + ((defaultLivingExpenses.annualOtherExpenses ?? 0) / 12));

  // 削減の費用を取得
  const getCostValue = (n: number) => {
    const cost = Math.round(reducableCost / 10000) * 10000;
    return costValue(Math.round(cost * (n / 100)), 6);
  };

  // 毎月の削減率
  const savingExpensesRateOptions = [1, 2, 3, -1]
    .map((n) => {
      if (n === -1) {
        return {
          value: -1,
          label: 'カスタマイズ',
        };
      }
      return {
        value: n,
        label: `${n}%${'　'}${getCostValue(n)} 円/月 の削減`,
      };
    });

  // 選択中の毎月の削減率
  const [selectSavingExpensesRate, setSelectSavingExpensesRate] = React.useState<number>();

  // カスタマイズの値
  const [
    customeSavingExpensesRate, setCustomeSavingExpensesRate,
  ] = React.useState<number|null>(null);

  const getCustomeSavingExpensesRate = (() => {
    if (selectSavingExpensesRate === undefined) {
      const rate = defaultLivingExpenses.savingExpensesRate;
      const hasOption = savingExpensesRateOptions
        .findIndex((option) => option.value === rate) !== -1;
      if (!hasOption) {
        return rate ?? null;
      }
      return null;
    }
    return customeSavingExpensesRate;
  });

  React.useEffect(() => {
    // 選択項目の中に値がある場合は、カスタム値をなしにして選択値に指定
    const rate = defaultLivingExpenses.savingExpensesRate;
    const hasOption = savingExpensesRateOptions
      .findIndex((option) => option.value === rate) !== -1;
    if (hasOption) {
      setSelectSavingExpensesRate(rate ?? undefined);
    } else {
      setSelectSavingExpensesRate(-1);
      setCustomeSavingExpensesRate(rate ?? null);
    }
  }, [defaultLivingExpenses]);

  // データ更新
  React.useEffect(() => {
    setLivingExpenses((data) => {
      return {
        ...data,
        savingExpensesRate,
      };
    });
  }, [
    savingExpensesRate,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setLivingExpenses(defaultLivingExpenses);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>生活費削減</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">毎月小さな削減でも長い目で見ると大きく変わってきます。</p>
        <p className="text-sm mt-2 mb-6">
          人生100年まだまだ長い道のりです。そして長い期間毎月の支出を少し抑えるだけでも影響する資産へのインパクトは計り知れません。
          例えば生活費を毎月5,000円削減するだけでも、それを50年続けたら300万も違ってきます。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">生活費削減</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>削減可能な生活費</span>
              </div>
              <Amount value={isAdviced ? reducableCost : null} division={4} empty="???" />
              <Remarks className="mt-6">
                <p>税金・保険料などは削減対象外となりますので、生活費の画面から 詳細をご確認・ご入力ください</p>
                <Link to="/home/basics/livingCosts" className="arrow-link mt-1">生活費</Link>
              </Remarks>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>毎月の削減率</span>
              </div>
              {selectSavingExpensesRate !== undefined && (
                <InputRadioGroup
                  name="selectSavingExpensesRate"
                  options={savingExpensesRateOptions}
                  defaultValue={selectSavingExpensesRate}
                  disabled={!isAdviced}
                  onChange={(event) => {
                    const { value } = event.currentTarget;
                    const n = parseInt(value, 10);
                    setSelectSavingExpensesRate(n);
                    if (n !== -1) {
                      setSavingExpensesRate(n);
                    } else {
                      setSavingExpensesRate(customeSavingExpensesRate);
                    }
                  }}
                />
              )}
              <div className="mt-4">
                <InputInteger
                  name="customeSavingExpensesRate"
                  className="w-[80px]"
                  unit="%"
                  defaultValue={getCustomeSavingExpensesRate()}
                  disabled={!(isAdviced && selectSavingExpensesRate === -1)}
                  maxlength={2}
                  onChange={(value) => {
                    setCustomeSavingExpensesRate(value);
                    setSavingExpensesRate(value);
                  }}
                />
                <p>
                  {`${(selectSavingExpensesRate !== null) ? getCostValue(customeSavingExpensesRate || 0) : '???'} 円／月の削減`}
                </p>
              </div>
            </div>
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultLivingExpenses, livingExpenses)}
          contentId="livingCostsReduce"
          onClick={() => {
            dispatch(setDispatchLivingExpenses(livingExpenses));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeBasicsLivingCostsReduce;
