import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

// error
import checkApiError from './checkApiError';

// set
import { setErrorMessages } from '../errorReducer';

// 全入力項目の確認が完了しているかどうか
import { selectIsAdviceOpened } from '../stepReducer';

// 更新APIからリクエストされたデータの型
type UpdateProgressResponseData = {
  insertedCount: number,
}

// 画面遷移時にアクティブページを記録
type Update = {
  contentId: string,
}
export const updateProgressAsync = createAsyncThunk<boolean, Update, { state: RootState }>('auth/updateProgressAsync', async (params, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState();
  const domain = process.env.REACT_APP_API_DOMAIN;
  if (domain === '') {
    // ローカルテストのときは、APIのアクセスをスキップ
    return true;
  }

  // 全入力項目の確認が完了していれば、progressの値を4にして渡す
  const isAdviced = selectIsAdviceOpened(state);

  try {
    // データ更新
    const { contentId } = params;
    const response = await axios.post(`${domain}/accountProgress`, {
      actions: [
        {
          jisTid: state.user.account.jisTid,
          contentId,
          progress: isAdviced ? 4 : 1,
        },
      ],
    }, {
      headers: {
        accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'jis-tid': state.user.account.jisTid,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'cf-api-token': state.user.token,
      },
    });

    // JSONデータからエラーかどうか判断する
    const errorMessages = checkApiError(response);
    if (errorMessages) {
      dispatch(setErrorMessages(errorMessages));
      return false;
    }

    // APIからリクエストされたデータを読み込み
    const data = response.data as UpdateProgressResponseData;
    if (data !== null) {
      return true;
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
  }
  return false;
});
export default updateProgressAsync;
