import * as React from 'react';
import moment from 'moment';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectAssetFormationOthers, setAssetFormationOthers as setDispatchAssetFormationOthers } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// components
import Append from '../../features/button/Append';
import Fieldset from './Fieldset';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeAssetOtherInvestments: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const defaultAssetFormationOthers = useAppSelector(selectAssetFormationOthers);

  // 入力データ
  const [
    assetFormationOthers, setAssetFormationOthers,
  ] = React.useState(defaultAssetFormationOthers);

  // その他の運用状況
  const [otherItems, setOtherItems] = React.useState(
    defaultAssetFormationOthers.map((otherInvestment, i) => {
      return {
        id: i,
        data: otherInvestment,
      };
    }),
  );
  const [otherCount, setOtherCount] = React.useState(otherItems?.length);

  // データ更新
  React.useEffect(() => {
    setAssetFormationOthers(otherItems.map((otherItem) => otherItem.data));
  }, [
    otherItems,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setAssetFormationOthers(defaultAssetFormationOthers);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>積立定期・その他</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">積立定期を忘れてませんか？その他の資産もすべて入力していきましょう。</p>
        <p className="text-sm mt-2 mb-6">
          シミュレーションで忘れがちになるのが積立型の商品。
          定期預金や資産運用型の保険など将来戻ってくるお金で資産形成に役立てることができるものを運用している場合はこちらに入力していきましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          {otherItems.map((otherItem, index) => (
            <section key={otherItem.id}>
              <h3 className="fieldset-title">
                {index === 0
                  ? <>その他の運用状況</>
                  : (
                    <>
                      その他の運用状況（
                      {index + 1}
                      ）
                    </>
                  )}
              </h3>
              <Fieldset
                prefix={`otherItem${otherItem.id}`}
                data={otherItem.data}
                onChange={(datas) => {
                  setOtherItems((old) => old
                    .map((item, i) => ((i === index) ? { id: item.id, data: datas } : item)));
                }}
              />
              {index !== 0
              && (
                <div className="px-xs pb-8">
                  <button
                    type="button"
                    className="btn-back btn-back--lg"
                    onClick={() => {
                      const newOtherItems = otherItems.filter((data) => data.id !== otherItem.id);
                      setOtherItems(newOtherItems);
                    }}
                  >
                    その他の運用状況（
                    {index + 1}
                    ）を削除
                  </button>
                </div>
              )}
            </section>
          ))}
          <div>
            <div className="py-8 border-primary border-t">
              <Append
                label="その他の運用を追加する"
                onClick={() => {
                  const newOtherItems = otherItems.slice();
                  const count = otherCount + 1;
                  const today = moment();
                  today.set('month', 0);
                  today.set('date', 1);
                  newOtherItems.push({
                    id: count,
                    data: {
                      presentValue: null,
                      monthlyContributionAmount: null,
                      startMonth: today.format('YYYY-MM-DD'),
                      endMonth: today.format('YYYY-MM-DD'),
                      totalReturn: null,
                      hasBeJoined: 'NOT_JOINED',
                      acquisitionValue: null,
                    },
                  });
                  setOtherCount(count);
                  setOtherItems(newOtherItems);
                }}
              />
            </div>
          </div>
        </div>
        <FixedButton
          disabled={deepEqual(defaultAssetFormationOthers, assetFormationOthers)}
          contentId="otherInvestments"
          onClick={() => {
            dispatch(setDispatchAssetFormationOthers(assetFormationOthers));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeAssetOtherInvestments;
