import * as React from 'react';
import {
  TooltipProps,
  ResponsiveContainer,
  PieChart, Pie, Cell, Label,
} from 'recharts';
import { theme } from 'twin.macro';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../../services/costValue';

// types
import type { Chart } from '../PensionGraph';

// ツールチップ内の凡例（メインサイズ）
type PensionGraphTooltipLegendProps = {
  className: string,
  value: number|null,
}
const PensionGraphTooltipLegend: React.FC<PensionGraphTooltipLegendProps> = (
  { className, value, children },
) => (
  <div>
    <div className="flex items-center justify-center">
      <span className={`inline-block w-3 h-3 rounded-full mr-2 ${className}`} />
      <span className="text-sm text-primary">{children}</span>
    </div>
    <div className="text-center">
      <span className="font-alpha text-xl break-all">{value !== null ? costValue(value, 4) : '-'}</span>
      <span className="text-sm ml-1">万円/月</span>
    </div>
  </div>
);

type PensionChart = {
  key: string,
  label: string,
  value: number|null,
  color: string,
  className: string,
}

// ツールチップ内の凡例（小サイズ）
type PensionGraphTooltipLegendSmallProps = {
  className: string,
  value: number|null,
}
const PensionGraphTooltipLegendSmall: React.FC<PensionGraphTooltipLegendSmallProps> = (
  {
    className, value, children,
  },
) => (
  <div className="flex items-center justify-between leading-none px-3">
    <div className="flex-shrink-0">
      <span className={`inline-block w-3 h-3 rounded-full mr-1.5 ${className}`} />
      <span className="text-xs text-primary leading-none">{children}</span>
    </div>
    <div className="text-right">
      <span className="font-alpha text-base leading-none break-all">{value !== null ? costValue(value, 4) : '-'}</span>
      <span className="text-xs leading-none ml-1">万円/月</span>
    </div>
  </div>
);

interface PensionGraphTooltipProps extends TooltipProps<number, string> {
  onComplete: (_:number) => void,
}
const PensionGraphTooltip: React.FC<PensionGraphTooltipProps> = (
  { active, payload, onComplete },
) => {
  if (active && payload && payload.length) {
    const chartData = payload[0].payload as Chart;

    // 公的年金の円グラフデータ
    const [pensionChartData, setPensionChartData] = React.useState<PensionChart[]>();

    // 凡例データ
    const [pensionLegendData, setPensionLegendData] = React.useState<PensionChart[]>();

    // ref属性の参照
    const ref = React.useRef<HTMLDivElement>(null);

    // 公的年金の円グラフデータ生成
    React.useEffect(() => {
      if (chartData !== undefined) {
        const newPensionLegentData: PensionChart[] = [];
        if (chartData.reEmploymentIncome > 0) {
          newPensionLegentData.push({
            key: 'reEmploymentIncome',
            label: '再就職',
            value: chartData.reEmploymentIncome,
            color: theme('colors.primary'),
            className: 'bg-primary',
          });
        }
        if (chartData.publicPensionHouseholder) {
          newPensionLegentData.push({
            key: 'publicPensionHouseholder',
            label: '公的年金',
            value: chartData.publicPensionHouseholder,
            color: theme('colors.primary-700'),
            className: 'bg-primary-700',
          });
        }
        if (chartData.corporatePensionDbHouseholder) {
          newPensionLegentData.push({
            key: 'corporatePensionDbHouseholder',
            label: '企業年金（DB）',
            value: chartData.corporatePensionDbHouseholder,
            color: theme('colors.primary-300'),
            className: 'bg-primary-300',
          });
        }
        if (chartData.corporatePensionDcHouseholder) {
          newPensionLegentData.push({
            key: 'corporatePensionDcHouseholder',
            label: '企業年金（DC）',
            value: chartData.corporatePensionDcHouseholder,
            color: theme('colors.primary-200'),
            className: 'bg-primary-200',
          });
        }
        if (chartData.idecoHouseholder) {
          newPensionLegentData.push({
            key: 'idecoHouseholder',
            label: '個人年金（iDeCo）',
            value: chartData.idecoHouseholder,
            color: theme('colors.primary-100'),
            className: 'bg-primary-100',
          });
        }
        if (chartData.publicPensionSpouse) {
          newPensionLegentData.push({
            key: 'publicPensionSpouse',
            label: '公的年金',
            value: chartData.publicPensionSpouse,
            color: theme('colors.green-400'),
            className: 'bg-green-400',
          });
        }
        if (chartData.corporatePensionDcSpouse) {
          newPensionLegentData.push({
            key: 'corporatePensionDcSpouse',
            label: '企業年金（DC）',
            value: chartData.corporatePensionDcSpouse,
            color: theme('colors.green-300'),
            className: 'bg-green-300',
          });
        }
        if (chartData.idecoSpouse) {
          newPensionLegentData.push({
            key: 'idecoSpouse',
            label: '個人年金（iDeCo）',
            value: chartData.idecoSpouse,
            color: theme('colors.green-200'),
            className: 'bg-green-200',
          });
        }
        setPensionChartData(newPensionLegentData);
        setPensionLegendData(newPensionLegentData);
      }
    }, [chartData]);

    // 要素の高さを取得
    React.useEffect(() => {
      const height = ref.current?.offsetHeight;
      if (height !== undefined) {
        onComplete(height);
      }
    }, [ref.current?.offsetHeight]);

    return (
      <div className="bg-white border-2 border-primary rounded text-primary w-[265px] px-2 py-4" ref={ref}>
        <h3 className="text-center text-sm mb-1">{`${chartData.thisYear}年の収入`}</h3>
        <div className="flex justify-around space-x-2">
          <PensionGraphTooltipLegend className="bg-primary" value={chartData.householderAmount}>
            あなた
          </PensionGraphTooltipLegend>
          <PensionGraphTooltipLegend className="bg-green" value={chartData.spouseAmount}>
            配偶者
          </PensionGraphTooltipLegend>
        </div>
        {pensionChartData !== undefined && (
          <div className="relative w-full pt-[140px] mt-2">
            <div className="absolute inset-0">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={140} height={140}>
                  <Pie
                    data={pensionChartData}
                    isAnimationActive={false}
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={70}
                    startAngle={90}
                    endAngle={360 + 90}
                    paddingAngle={1}
                    dataKey="value"
                    nameKey="name"
                  >
                    {pensionChartData.map((data) => (
                      <Cell
                        key={`cell-${data.key}`}
                        fill={data.color}
                      />
                    ))}
                    <Label position="center" style={{ textAnchor: 'end' }} dx={11} className="font-alpha text-2xl text-primary mr-1">{chartData.age}</Label>
                    <Label position="center" style={{ textAnchor: 'start' }} dx={15} dy={3} className="text-sm text-primary">歳</Label>
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        <div className="text-center my-2">
          <span className="font-alpha text-xl leading-none break-all">{costValue(chartData.totalAmount, 12)}</span>
          <span className="text-sm leading-none ml-1">万円/月</span>
        </div>
        <div className="space-y-1.5">
          {pensionLegendData?.map((data) => (
            <PensionGraphTooltipLegendSmall
              className={data.className}
              value={data.value}
              key={data.key}
            >
              {data.label}
            </PensionGraphTooltipLegendSmall>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
export default PensionGraphTooltip;
