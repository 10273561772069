import Slider from '@mui/material/Slider';
import { styled as muiStyled } from '@mui/material/styles';
import { theme } from 'twin.macro';

const CustomeSlider = muiStyled(Slider)(() => {
  return {
    color: theme('colors.primary'),
    height: 10,
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: theme('colors.primary'),
      '&::before': {
        boxShadow: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: 0,
      backgroundColor: 'unset',
      color: theme('colors.primary'),
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-track': {
      border: 0,
      opacity: 0.3,
    },
    '& .MuiSlider-rail': {
      color: theme('colors.gray-200'),
    },
  };
});
export default CustomeSlider;
