import * as React from 'react';

export type InputSelectOption = {
  label: string,
  value: string,
}

type InputSelectProps = {
  className?: string;
  options: InputSelectOption[];
  defaultValue?: string|null;
  onChange?: (_: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
};

/**
 * セレクト項目
 * @param props
 * @returns
 */
const InputSelect: React.FC<InputSelectProps> = ({
  className, options, defaultValue, onChange, disabled,
}) => {
  const [value, setValue] = React.useState<string|null|undefined>(null);

  // ref属性の参照
  const ref = React.useRef<HTMLSelectElement>(null);

  React.useEffect(() => {
    // デフォルトの値を指定
    if (defaultValue !== undefined && defaultValue !== null) {
      setValue(defaultValue);
    } else {
      setValue(undefined);
    }
  }, [defaultValue]);

  return (
    <div>
      {value !== null && (
        <div className={`form-select-wrap ${disabled && 'disabled'} ${className}`}>
          <select name="select" id="select" className="form-select" defaultValue={value} ref={ref} onChange={onChange} disabled={disabled}>
            {
              options.map((option) => (
                <option value={option.value} key={`select-${option.value}`}>{option.label}</option>
              ))
            }
          </select>
        </div>
      ) }
    </div>
  );
};
InputSelect.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  onChange: undefined,
  disabled: false,
};

export default InputSelect;
