import * as React from 'react';

// components
import InputInteger from './InputInteger';

// 推定値の型
import type { EstimatedValueDataItem } from '../../reducers/estimatedReducer';

/**
 * 積立金入力項目
 * @param props
 * @returns
 */
type InputReserveAmountProps = {
  name: string;
  defaultValue?: number|null;
  disabled?: boolean;
  required?: boolean;
  max?: number;
  estimatedItem?: EstimatedValueDataItem|undefined,
  onChange?: (_:number|null) => void;
};
const InputReserveAmount: React.FC<InputReserveAmountProps> = (
  {
    name, defaultValue, disabled, required, max, estimatedItem, onChange,
  },
) => {
  let minValue: number|undefined;
  let maxValue = max;
  if (estimatedItem !== undefined) {
    // 推計値を受け取ったときに推計値から最小値/最大値を指定する
    if (estimatedItem.floorValue !== null) {
      minValue = estimatedItem.floorValue;
    }
    if (estimatedItem.capValue !== null) {
      maxValue = estimatedItem.capValue;
    }
  }
  return (
    <InputInteger
      name={name}
      className="w-[120px]"
      unit="円／月"
      required={required}
      min={minValue}
      max={maxValue}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
InputReserveAmount.defaultProps = {
  defaultValue: undefined,
  disabled: false,
  required: false,
  max: undefined,
  estimatedItem: undefined,
  onChange: undefined,
};
export default InputReserveAmount;
