/**
 * 価格の値を取得（限界桁数は+をつける）
 * @param n (値)
 * @param digits (桁数)
 * @returns
 */
const costValue = (n:number, digits?:number) => {
  if (digits !== undefined) {
    const max = (10 ** digits) - 1;
    if (Math.abs(n) > max) {
      const sign = n > 0 ? 1 : -1;
      return `${(max * sign).toLocaleString()}+`;
    }
  }
  return n.toLocaleString();
};
export default costValue;
