import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

// データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { updateProgressAsync } from '../../reducers/authReducer';
import { selectStepData, pushNextStep, CONFIG_DATAS } from '../../reducers/stepReducer';

// images
import iconFlag from './assets/icon-flag.svg';

const NextStep: React.FC = () => {
  const { pathname } = useLocation();
  const dispach = useAppDispatch();
  const stepData = useAppSelector(selectStepData);

  React.useEffect(() => {
    // 画面遷移時にアクティブページを記録する
    const async = async (): Promise<void> => {
      const currentConfig = CONFIG_DATAS.find((d) => d.to === pathname);
      const contentId = currentConfig?.contentId;
      if (contentId !== undefined) {
        dispach(pushNextStep(contentId));
        await dispach(updateProgressAsync({
          contentId,
        }));
      }
    };
    async();
  }, [pathname]);
  return (
    <Link to={stepData.to} className="border-2 border-primary rounded px-5 py-3.5 flex items-center">
      <img src={iconFlag} alt="" width={16} height={18} className="mr-3.5" />
      <p className="text-sm leading-[1.4] text-primary font-bold">{stepData.message}</p>
    </Link>
  );
};
export default NextStep;
