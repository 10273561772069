import * as React from 'react';
import FAQ, { Q, A } from './FAQ';

const HelpIdeco: React.FC = () => (
  <FAQ>
    <Q>
      <p>個人型確定拠出年金（iDeCo）のアカウントでもログインできるのでしょうか？</p>
    </Q>
    <A>
      <p>
        本サービスは、みずほ銀行とご契約をいただいた企業にお勤めの確定拠出年金(DC)加入者様のみがご利用をいただけるサービスであり、
        個人型確定拠出年金（iDeCo）のアカウントでのログインはいただけません。
        iDeCoの口座情報を反映される場合は、恐れ入りますが手入力していただく必要がございます。
      </p>
    </A>
  </FAQ>
);
export default HelpIdeco;
