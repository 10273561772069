import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { selectHasDc } from './userReducer';

type RiskToleranceA = {
  foreignReit?: number|null,
  domesticStock?: number|null,
  foreignStock?: number|null,
  riskTolerance?: number|null,
  foreignBond?: number|null,
  foreignBondHedge?: number|null,
  assetPattern:number|null,
  foreignReitHedge?: number|null,
  principalSecuringType:number|null,
  expectedReturn:number|null,
  domesticBond?: number|null,
  sigma?: number|null,
  foreignStockHedge?: number|null,
  domesticReit?: number|null,
};

type RiskToleranceB = {
  developedReit?: number|null,
  domesticStock?: number|null,
  foreignStock?: number|null,
  riskTolerance?: number|null,
  foreignBondHedge?: number|null,
  foreignBond?: number|null,
  developedReitHedge?: number|null,
  expectedReturn?: number|null,
  domesticBond?: number|null,
  sigma?: number|null,
  foreignStockHedge?: number|null,
  domesticReit?: number|null,
};

export type RiskTolerance = {
  // DC or iDeCo
  a?: RiskToleranceA[],
  // NISA or 信託
  b?: RiskToleranceB[],
}

const initialState: RiskTolerance = {
};

export const riskSlice = createSlice({
  name: 'risk',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRiskTolerance: (state, action: PayloadAction<RiskTolerance>) => {
      state.a = action.payload.a;
      state.b = action.payload.b;
    },
  },
});

export const {
  setRiskTolerance,
} = riskSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRiskA = (state: RootState) => state.risk.a;

export type RiskData = {
  name: string,
  value: number,
  color: string,
  legend: string,
}
export type Risk = {
  riskTolerance: number,
  expectedReturn: number,
  sigma: number,
  datas: RiskData[],
}

// 企業年金（DC）または企業年金（iDeco）
// 本人（DC）は risk.assetPattern または 10
// 配偶者（DC）は assetPattern = 10
// 本人（iDeco）は assetPattern = 32
// 配偶者（iDeco）は assetPattern = 32
const getRistA = (state: RootState, assetPattern: number):Risk[]|undefined => {
  const datas = state.risk.a;
  return datas?.filter((risk) => risk.assetPattern === assetPattern)
    .map((risk) => {
      return {
        riskTolerance: risk.riskTolerance ?? 0,
        expectedReturn: risk.expectedReturn ?? 0,
        sigma: risk.sigma ?? 0,
        datas: [
          {
            name: '国内債券',
            value: risk.domesticBond ?? 0,
            color: '#0E3382',
            legend: 'bg-[#0E3382]',
          },
          {
            name: '海外債券',
            value: (risk.foreignBond ?? 0) + (risk.foreignBondHedge ?? 0),
            color: '#7695C3',
            legend: 'bg-[#7695C3]',
          },
          {
            name: '国内株式',
            value: risk.domesticStock ?? 0,
            color: '#556B4A',
            legend: 'bg-[#556B4A]',
          },
          {
            name: '海外株式',
            value: (risk.foreignStock ?? 0) + (risk.foreignStockHedge ?? 0),
            color: '#C4D595',
            legend: 'bg-[#C4D595]',
          },
          {
            name: '国内REIT',
            value: risk.domesticReit ?? 0,
            color: '#61386C',
            legend: 'bg-[#61386C]',
          },
          {
            name: '海外REIT',
            value: (risk.foreignReit ?? 0) + (risk.foreignReitHedge ?? 0),
            color: '#8A1212',
            legend: 'bg-[#8A1212]',
          },
          {
            name: '元本確保型',
            value: risk.principalSecuringType ?? 0,
            color: '#000066',
            legend: 'bg-[#000066]',
          },
        ],
      };
    });
};

// 企業年金（DC）
export const selectRiskDc = (state: RootState) => {
  const isDcLogin = selectHasDc(state);
  // DCが有効であればassetPatternを指定
  const assetPattern = isDcLogin
    ? state.user.bkData?.assetPattern ?? 10
    : 10;
  return getRistA(state, assetPattern);
};
export const selectRiskDcSpouse = (state: RootState) => getRistA(state, 10);

// 企業年金（iDeco）
export const selectRiskIdeco = (state: RootState) => getRistA(state, 32);
export const selectRiskIdecoSpouse = (state: RootState) => getRistA(state, 32);

// つみたてNISA または 積立投信・投資信託
export const getRistB = (state: RootState):Risk[]|undefined => {
  const datas = state.risk.b;
  return datas?.map((risk) => {
    return {
      riskTolerance: risk.riskTolerance ?? 0,
      expectedReturn: risk.expectedReturn ?? 0,
      sigma: risk.sigma ?? 0,
      datas: [
        {
          name: '国内債券',
          value: risk.domesticBond ?? 0,
          color: '#0E3382',
          legend: 'bg-[#0E3382]',
        },
        {
          name: 'ヘッジ外債',
          value: (risk.foreignBondHedge ?? 0),
          color: '#2866A3',
          legend: 'bg-[#2866A3]',
        },
        {
          name: '海外債券',
          value: risk.foreignBond ?? 0,
          color: '#7695C3',
          legend: 'bg-[#7695C3]',
        },
        {
          name: '国内株式',
          value: risk.domesticStock ?? 0,
          color: '#556B4A',
          legend: 'bg-[#556B4A]',
        },
        {
          name: '海外株式',
          value: (risk.foreignStock ?? 0) + (risk.foreignStockHedge ?? 0),
          color: '#C4D595',
          legend: 'bg-[#C4D595]',
        },
        {
          name: '国内REIT',
          value: risk.domesticReit ?? 0,
          color: '#61386C',
          legend: 'bg-[#61386C]',
        },
        {
          name: '海外REIT',
          value: (risk.developedReit ?? 0) + (risk.developedReitHedge ?? 0),
          color: '#A2B276',
          legend: 'bg-[#A2B276]',
        },
      ],
    };
  });
};

// つみたてNISA
export const selectRiskNisa = (state: RootState) => getRistB(state);

// 積立投信・投資信託
export const selectRiskTrust = (state: RootState) => getRistB(state);

export default riskSlice.reducer;
