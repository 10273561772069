import * as React from 'react';
import moment from 'moment';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectEducationExpenses as selectEducationExpensesMatrix } from '../../reducers/userReducer';
import {
  selectFamily,
  selectEducationExpenses,
  setEducationExpenses as setDispatchEducationExpenses,
  setEducationExpenseMatrix,
} from '../../reducers/inputReducer';
import type { EducationPolicy } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// components
import InputRadioGroup, { InputRadioOption } from '../../features/input/InputRadioGroup';
import HelpButton from '../../features/button/HelpButton';
import SumTable from '../../features/table/SumTable';
import Fieldset, { SchoolStatus } from './Fieldset';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

/**
 * 数値を漢数字表記に変換
 * @param {number} n 半角数字
 * @returns {string} 漢数字表記
 */
const numbersToKanji = (n: number): string => {
  if (n === undefined || n === null) {
    return '';
  }
  if (n === 0) {
    return '零';
  }

  const kanjiNums = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const kanjiNames = ['十', '百', '千', '万', '億', '兆', '京', '垓'].reverse();
  const exponents = [1, 2, 3, 4, 8, 12, 16, 20].reverse();

  let value = '';
  let num = n;
  exponents.forEach((exponent, i) => {
    const bias = 10 ** exponent;
    if (num >= bias) {
      const top = Math.floor(num / bias);
      if (top >= 10) {
        value += numbersToKanji(top);
      } else if (top === 1 && exponent <= 3) {
        // ※先頭の数字が1、かつ指数が3 (千の位) 以下の場合のみ『一』をつけない
      } else {
        value += kanjiNums[top];
      }
      value += kanjiNames[i];
      num -= top * bias;
    }
  });
  if (num >= 0) {
    value += kanjiNums[num];
  }
  return value;
};

type SchoolObject = {
  label: string,
  status: SchoolStatus
}
type SchoolConfig = {
  range: number[],
  label: string,
  status: SchoolStatus
}

/**
 * 生年月日から学年データを取得
 * @param {moment.Moment} date 生年月日
 * @returns {SchoolObject} 学年データ
 */
const getSchoolObject = (date: moment.Moment): SchoolObject => {
  // 満年齢
  const birthDate = date.clone();
  if (birthDate.month() + 1 < 4) {
    birthDate.subtract(1, 'year');
  }
  const aprilDate = birthDate.set('month', 3).set('date', 2);
  const age = moment().diff(aprilDate, 'years');

  // 学年を取得
  const schoolConfig: SchoolConfig[] = [
    {
      range: [0, 6],
      label: '未就学',
      status: 'PRE_SCHOOL',
    },
    {
      range: [7, 12],
      label: '小学',
      status: 'PRIMARY_SCHOOL',
    },
    {
      range: [13, 15],
      label: '中学',
      status: 'JUNIOR_HIGH_SCHOOL',
    },
    {
      range: [16, 18],
      label: '高校',
      status: 'HIGH_SCHOOL',
    },
    {
      range: [19, 22],
      label: '大学',
      status: 'UNIVERSITY',
    },
  ];
  const school = age > 0
    ? schoolConfig.find((config) => age >= config.range[0] && age <= config.range[1])
    : schoolConfig[0];

  // 学年データを取得
  if (school !== undefined) {
    if (school.status !== 'PRE_SCHOOL') {
      const grade = numbersToKanji(age - school.range[0] + 1);
      return {
        label: `${school.label}${grade}年生`,
        status: school.status,
      };
    }
    return {
      label: school.label,
      status: school.status,
    };
  }
  return {
    label: '教育期間が終わっています。',
    status: 'NONE',
  };
};

const HomeEducation: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const educationExpensesMatrix = useAppSelector(selectEducationExpensesMatrix);

  // 教育費の出費イメージ入力データ
  const defaultEducationExpenses = useAppSelector(selectEducationExpenses);
  const [educationExpenses, setEducationExpenses] = React.useState(defaultEducationExpenses);

  // 教育費用入力データ配列
  const [childrens, setChildrens] = React.useState(defaultEducationExpenses.children);

  // こどものデータ
  const defaultFamily = useAppSelector(selectFamily);
  const { birthMonth, expectedBirthYear } = defaultFamily.children;
  const defaultChildrens = [...(birthMonth ?? []), ...(expectedBirthYear ?? [])]
    .map((birthdate, i) => {
      const date = moment(birthdate);
      const school = getSchoolObject(date);
      const space = (school.status === 'NONE') ? '\n' : ' ';

      // 教育費用データ
      const educationExpensesChildrens = defaultEducationExpenses.children ?? [];

      // 満年齢
      const birthDate = date.clone();
      if (birthDate.month() + 1 < 4) {
        birthDate.subtract(1, 'year');
      }
      const aprilDate = birthDate.set('month', 3).set('date', 2);
      const age = moment().diff(aprilDate, 'years');

      return {
        id: i,
        title: `第${numbersToKanji(i + 1)}子${space}(${school.label})`,
        status: school.status,
        datas: educationExpensesChildrens[i],
        age,
      };
    });

  // 毎月の削減率
  const [
    educationPolicy,
    setEducationPolicy,
  ] = React.useState(educationExpenses.educationPolicy);
  const educationPolicyOptions:InputRadioOption[] = [
    { value: 'ALL_PUBLIC', label: 'あまりかからない', help: ' (すべて国公立)' },
    { value: 'PRIVATE_FROM_UNIVERSITY', label: '少しかかる', help: ' (大学から私立)' },
    { value: 'PRIVATE_FROM_HIGH_SCHOOL', label: '結構かかる', help: ' (高校から私立)' },
    { value: 'VERY_EXPENSIVE', label: '非常にかかる', help: ' (小・中学生から私立)' },
    {
      value: 'CUSTOMIZATION',
      label: 'カスタマイズ',
      help: '(習い事や予備校など詳細入力をする場合)',
    },
  ];

  // 合計配列
  const [totals, setTotals] = React.useState((childrens ?? []).map(() => 0));

  // データ更新
  React.useEffect(() => {
    setEducationExpenses({
      educationPolicy,
      children: childrens,
    });
  }, [
    educationPolicy,
    childrens,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setEducationExpenses(defaultEducationExpenses);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>教育費用</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">小学校から大学までの教育費をイメージできますか？</p>
        <p className="text-sm mt-2 mb-6">
          それぞれ小中高大とかかる費用がイメージつかなくてもご安心ください。
          統計データより授業料や習い事さらには仕送りなど平均値で算出しますのでより現実的な教育費でシミュレーションを行うことが可能です。
        </p>
        <ServerError />
        <div className="fieldset relative border-b-0">
          <section>
            <h3 className="fieldset-title">教育費の出費</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>教育費の出費イメージ</span>
                <HelpButton>
                  ・各費用は統計データをもとに平均値を計算しています。
                  <br />
                  ・授業料については教材、制服代、給食費、部活動などの費用を計上しています。
                </HelpButton>
              </div>
              <InputRadioGroup
                name="educationPolicy"
                options={educationPolicyOptions}
                defaultValue={educationPolicy}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setEducationPolicy(value as EducationPolicy);
                }}
              />
            </div>
          </section>
          {defaultChildrens.map((children, index) => (
            <section key={children.id}>
              <h3 className="fieldset-title whitespace-pre-wrap">{children.title}</h3>
              <Fieldset
                prefix={`children${index}`}
                status={children.status}
                educationPolicy={educationPolicy}
                datas={children.datas}
                age={children.age}
                onChange={(datas) => {
                  setChildrens((oldChildrens) => (oldChildrens ?? [])
                    .map((item, i) => ((i === index) ? datas : item)));
                }}
                onTotal={(value) => {
                  // 合計配列を更新
                  setTotals((oldTotals) => oldTotals.map((n, i) => (i === index ? value : n)));
                }}
              />
            </section>
          ))}
          {defaultChildrens.length > 0 && (
            <section className="mt-2">
              <h3 className="fieldset-title">世帯合計</h3>
              <div className="fieldset-body">
                <SumTable label="合計" value={totals.reduce((sum, n) => sum + n, 0)} unit="万円" division={4} />
              </div>
            </section>
          )}
        </div>
        <FixedButton
          disabled={deepEqual(defaultEducationExpenses, educationExpenses)}
          contentId="education"
          onClick={() => {
            dispatch(setEducationExpenseMatrix(educationExpensesMatrix));
            dispatch(setDispatchEducationExpenses(educationExpenses));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeEducation;
