import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

// error
import checkApiError from './checkApiError';

// set
import { setBasic } from '../userReducer';
import {
  setLoginData, setBirthDate, setBasic as setBasicInput, setEstimated as setEstimatedInput,
} from '../inputReducer';
import { setCashflow } from '../cashflowReducer';
import { setEstimated } from '../estimatedReducer';
import { setErrorMessages, resetErrorMessages } from '../errorReducer';

// type
import type { BasicJist, EducationExpensesMatrix } from '../userReducer';
import type {
  Family, Income, Expenses, AfterRetirement, AssetFormation,
} from '../inputReducer';
import type { Cashflow } from '../cashflowReducer';
import type { EstimatedValueData } from '../estimatedReducer';
import type { RiskTolerance } from '../riskReducer';

// 新規登録APIからリクエストされたデータの型
type StoreResponseData = {
  basics?: {
    loginDate?: string|null,
    account: {
      jisTid?: string|null,
      isGuest?: boolean|null,
      userRegistrationStatus?: boolean|null,
      initialSettingStatus?: boolean|null,
      nickname?: string|null,
      email?: string|null,
      requestForEmailNewsLetter?: boolean|null,
      annualIncome?: number|null,
      lifeStyle?: string|null,
      birthDay?: string|null,
      age?: number|null,
      retirementAge?: number|null,
    },
    bkData?: {
      assetPattern?: number|null,
      companyFlg?: number|null,
      dcFutureLatchFuncFlg?:boolean|null,
      recommendedSfFlg?: boolean|null,
      recommendedPwedFlg?:boolean|null,
      recommendedNisaFlg?: boolean|null,
      recommendedIdecoFlg?: boolean|null,
      dbModelAmountSettingAmount?: number|null,
      bankBranchName?: string|null,
      responsible?: string|null,
      bankClerkPhoneNo?: string|null,
      reminder?: string|null,
    },
    tbData?: {
      subscriberNo?: string|null,
      requiredPaymentAmount?: number|null,
      lumpSumRetireAmount?: number|null,
      definedBenefitRetireAmount?: number|null,
    },
    jist?: BasicJist,
    riskTolerance?: RiskTolerance,
    educationExpenses?: EducationExpensesMatrix[],
    token: string|null,
  },
  apiInput?: {
    family?: Family,
    income?: Income,
    expenses?: Expenses,
    afterRetirement?: AfterRetirement,
    assetFormation?: AssetFormation,
  },
  cashflowList?: Cashflow[],
  estimationValues?: {
    householder?: EstimatedValueData,
    spouse?: EstimatedValueData,
  },
}

// データを新規に登録（シミュレーション初期設定）
type Store = {
  family: number|undefined,
  children: number|undefined,
  spouse: number|undefined,
  house: number|undefined,
  lifeStyle: number|undefined,
  retirement: number|undefined,
}
export const storeAsync = createAsyncThunk<boolean, Store, { state: RootState }>('auth/storeAsync', async (initialValue, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState();
  const domain = process.env.REACT_APP_API_DOMAIN;

  // サーバーエラーを消す
  dispatch(resetErrorMessages());

  try {
    // データ更新
    const response = await axios.post(`${domain}/cashflowWithNextstep`, {
      basics: {
        loginDate: state.user.loginDate,
        account: {
          jisTid: state.user.account.jisTid,
          isGuest: state.user.account.isGuest,
          initialSettingStatus: state.user.account.initialSettingStatus,
          nickname: state.user.account.nickname,
          email: state.user.account.email,
          requestForEmailNewsLetter: state.user.account.requestForEmailNewsLetter,
          annualIncome: state.user.account.annualIncome,
          lifeStyle: state.user.account.lifeStyle,
          birthDay: state.user.account.birthDay,
          age: state.user.account.age,
          retirementAge: state.user.account.retirementAge,
        },
      },
      family: state.input.family,
      income: state.input.income,
      expenses: state.input.expenses,
      afterRetirement: state.input.afterRetirement,
      assetFormation: state.input.assetFormation,
      jist: state.user.jist,
      initialValue: {
        children: initialValue.children ?? null,
        family: initialValue.family ?? null,
        house: initialValue.house ?? null,
        lifeStyle: initialValue.lifeStyle ?? null,
        retirement: initialValue.retirement ?? null,
        spouse: initialValue.spouse ?? null,
      },
      isInitialCall: true,
    }, {
      headers: {
        accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'jis-tid': state.user.account.jisTid,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'cf-api-token': state.user.token,
      },
    });
    // JSONデータからエラーかどうか判断する
    const errorMessages = checkApiError(response);
    if (errorMessages) {
      dispatch(setErrorMessages(errorMessages));
      return false;
    }

    // APIからリクエストされたデータを読み込み
    const data = response.data as StoreResponseData;
    if (data !== null) {
      if (data.basics !== undefined) {
        dispatch(setBasic(data.basics));
      }
      if (data.apiInput !== undefined) {
        dispatch(setBirthDate(state.user.account.birthDay));
        dispatch(setBasicInput({
          birthDate: state.user.account.birthDay ?? null,
          companyFlg: state.user.bkData?.companyFlg ?? null,
          opeAmt: state.user.jist?.opeAmt ?? null,
        }));
        dispatch(setEstimatedInput(data.estimationValues));
        dispatch(setLoginData(data.apiInput));
      }
      if (data.cashflowList !== undefined) {
        dispatch(setCashflow(data.cashflowList));
      }
      if (data.estimationValues !== undefined) {
        dispatch(setEstimated(data.estimationValues));
      }
    }
  } catch (error) {
    dispatch(setErrorMessages(['何らかのエラーが発生しました']));
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
    return false;
  }
  return true;
});
export default storeAsync;
