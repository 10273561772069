import * as React from 'react';
import moment from 'moment';

// データ
import { useAppSelector } from '../../app/hooks';
import {
  selectHasDc, selectCompanyFlg, selectBirthdate,
} from '../../reducers/userReducer';
import { getAssetStartDate } from '../../reducers/inputReducer';
import { selectEstimated } from '../../reducers/estimatedReducer';
import { selectAssetsDc } from '../../reducers/cashflowReducer';
import { selectRiskDc } from '../../reducers/riskReducer';

// 入力データの型
import type { AssetFormationDcItem } from '../../reducers/inputReducer';

// components
import InputRadioGroup, { InputRadioOption } from '../../features/input/InputRadioGroup';
import InputYearMonth from '../../features/input/InputYearMonth';
import InputReserveAmount from '../../features/input/InputReserveAmount';
import InputAssetValuation from '../../features/input/InputAssetValuation';
import AssetGraph from '../../features/recharts/AssetGraph';
import RiskGraph from '../../features/recharts/RiskGraph';
import HelpButton from '../../features/button/HelpButton';
import EstimationLabelDate from '../../features/label/EstimationLabelDate';
import EstimationLabelAmount from '../../features/label/EstimationLabelAmount';
// services
import { isLessThanToday } from '../../services/validation';

type FieldsetProps = {
  prefix?: string;
  data: AssetFormationDcItem;
  onChange?: (_:AssetFormationDcItem) => void;
};
const Fieldset: React.FC<FieldsetProps> = ({ prefix, data, onChange }) => {
  // 企業フラグを取得
  const companyFlg = useAppSelector(selectCompanyFlg);

  // 推定値
  const estimated = useAppSelector(selectEstimated).householder.corporatePensionDc ?? {};

  // DCが有効かどうか
  const isDcLogin = useAppSelector(selectHasDc);

  // 加入
  const [hasBeJoined, setHasBeJoined] = React.useState(data.hasBeJoined);

  // 加入年月
  const [joinMonth, setJoinMonth] = React.useState(data.joinMonth);
  const [startYear, setStartYear] = React.useState<number>();
  const [endYear, setEndYear] = React.useState<number>();

  // 加入年月の範囲を指定するために、生年月を取得
  const birthdate = useAppSelector(selectBirthdate);

  // 積立額
  const [
    monthlyContributionAmount, setMonthlyContributionAmount,
  ] = React.useState(data.monthlyContributionAmount);

  // 積立額（本人負担）
  const [
    monthlyContributionAmountOfEmployee, setMonthlyContributionAmountOfEmployee,
  ] = React.useState(data.monthlyContributionAmountOfEmployee);

  // 資産評価額
  const [presentValue, setPresentValue] = React.useState(data.presentValue);

  // 加入オプション
  const hasBeJoinedOptions: InputRadioOption[] = [
    { value: 'JOINED', label: '加入済み' },
    ...(() => {
      if (!isDcLogin) {
        return [
          { value: 'NOT_JOINED', label: '非加入' },
          { value: 'TOBE_JOINED', label: '今から加入' },
        ];
      }
      return [];
    })(),
  ];

  // 運用状況（元本積立額/予想資産残高）のグラフデータ
  const assetGraphData = useAppSelector(selectAssetsDc);

  // 年利回り
  const [expectedReturn, setExpectedReturn] = React.useState(data.expectedReturn);

  // リスクデータ
  const riskDatas = useAppSelector(selectRiskDc);
  const [riskValue, setRiskValue] = React.useState(data.riskValue);

  const onChangeHasBeJoined = ((value: string) => {
    if (value === 'TOBE_JOINED') {
      // 今から加入の場合は現在にする
      const now = moment();
      const date = moment(joinMonth);
      date.set('years', now.year());
      date.set('months', now.month());
      date.set('date', 1);
      setJoinMonth(date.format('YYYY-MM-DD'));
    }
  });

  // 加入状態に変化があったとき、加入年月の範囲を変更する
  React.useEffect(() => {
    const startDate = getAssetStartDate(hasBeJoined, birthdate);
    setStartYear(startDate.year());
    setEndYear(startDate.year() + 99);
  }, [hasBeJoined]);

  // 値に変化があったらイベントを呼び出す
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        ...data,
        hasBeJoined,
        joinMonth,
        monthlyContributionAmount,
        monthlyContributionAmountOfEmployee,
        presentValue,
        riskValue,
        expectedReturn,
      });
    }
  }, [
    hasBeJoined,
    joinMonth,
    monthlyContributionAmount,
    monthlyContributionAmountOfEmployee,
    presentValue,
    riskValue,
    expectedReturn,
  ]);

  return (
    <>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入</span>
          <HelpButton>
            企業型確定拠出年金に加入済の方は｢加入済み｣を、
            今後加入をご検討される方 (＝加入シミュレーションをされる方)は｢今から加入｣を、
            それ以外の方は｢非加入｣をご選択ください。
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}hasBeJoined`}
          options={hasBeJoinedOptions}
          defaultValue={hasBeJoined}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setHasBeJoined(value);
            onChangeHasBeJoined(value);
          }}
        />
        {assetGraphData !== undefined && assetGraphData.length > 0 && (
          <div className="mt-8">
            <AssetGraph
              datas={assetGraphData}
              expectedReturn={expectedReturn}
              help={(
                <>
                  お勤めの企業からあなたの退職年齢データを受領している場合は当該年齢を、受領していない場合は｢60歳｣を表示しております。
                </>
              )}
            />
          </div>
        )}
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入年月</span>
          <HelpButton>
            ｢今から加入｣をご選択された方で加入シミュレーションを実施される場合、｢今月｣をご入力ください (＝来月以降の日付はご入力いただけません)。
          </HelpButton>
        </div>
        <InputYearMonth
          name={`${prefix}joinMonth`}
          value={joinMonth}
          startYear={startYear}
          endYear={endYear}
          disabled={estimated.joinMonth?.fixed || hasBeJoined !== 'JOINED'}
          onChange={(date) => setJoinMonth(date)}
          validate={isLessThanToday(parseInt(moment().format('YYYYMMDD'), 10))}
        />
        <EstimationLabelDate
          source={estimated.joinMonth?.source}
          value={estimated.joinMonth?.value}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>積立額</span>
          <HelpButton>
            ｢積立額｣は、お勤め先が定める上限値の範囲でご変更いただけます。
            制度上の上限金額は55,000円/月ですが、お勤め先により異なる場合がございます。
          </HelpButton>
        </div>
        <InputReserveAmount
          name={`${prefix}monthlyContributionAmount`}
          defaultValue={monthlyContributionAmount}
          disabled={estimated.monthlyContributionAmount?.fixed || hasBeJoined === 'NOT_JOINED'}
          required
          estimatedItem={estimated.monthlyContributionAmount}
          onChange={(value) => setMonthlyContributionAmount(value)}
        />
        <EstimationLabelAmount
          source={estimated.monthlyContributionAmount?.source}
          value={estimated.monthlyContributionAmount?.value}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>積立額（本人負担）</span>
          <HelpButton>
            「積立額（本人負担）」は、お勤め先がマッチング拠出制を導入の場合のみ入力いただけます（月単位で入力ください）。
            制度上の入力上限金額は、事業主掛金以下、且つお勤め先毎に設定する上限金額以下の金額になります（お勤め先にご確認ください）。
          </HelpButton>
        </div>
        <InputReserveAmount
          name={`${prefix}monthlyContributionAmountOfEmployee`}
          defaultValue={monthlyContributionAmountOfEmployee}
          disabled={hasBeJoined === 'NOT_JOINED' || companyFlg !== 6}
          required
          estimatedItem={estimated.monthlyContributionAmount}
          onChange={(value) => setMonthlyContributionAmountOfEmployee(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>資産評価額</span>
          <HelpButton>
            ｢加入済み｣の方には現時点での資産評価額を出力していますが、適宜最新の内容を入力のうえ上書きください。
          </HelpButton>
        </div>
        <InputAssetValuation
          name={`${prefix}presentValue`}
          defaultValue={presentValue}
          disabled={estimated.presentValue?.fixed || hasBeJoined !== 'JOINED'}
          required
          estimatedItem={estimated.presentValue}
          onChange={(value) => setPresentValue(value)}
        />
      </div>
      <div className="fieldset-body">
        <RiskGraph
          datas={riskDatas}
          value={riskValue}
          onChange={((value, expectedReturnValue) => {
            setRiskValue(value);
            setExpectedReturn(expectedReturnValue?.expectedReturn ?? null);
          })}
        />
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  onChange: undefined,
};
export default Fieldset;
