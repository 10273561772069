import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export type EstimatedValueDataItem = {
  fixed: boolean|null,
  source: string|null,
  value: number|null,
  capValue: number|null,
  floorValue:number|null,
}
export type EstimatedValueDataJoinMonth = {
  fixed: boolean|null,
  source: string|null,
  value: string|null,
}

// 企業年金 (DC)
export type EstimatedValueDataDc = {
  startAgeOfPension?: EstimatedValueDataItem,
  presentValue?: EstimatedValueDataItem,
  joinMonth?: EstimatedValueDataJoinMonth,
  monthlyContributionAmount?: EstimatedValueDataItem,
  acquisitionValue?: {
    value: number|null,
  },
}

// 個人年金iDeCo
export type EstimatedValueDataIdeco = {
  startAgeOfPension?: EstimatedValueDataItem,
  presentValue?: EstimatedValueDataItem,
  joinMonth?: EstimatedValueDataJoinMonth,
  monthlyContributionAmount?: EstimatedValueDataItem,
  acquisitionValue?: {
    value: number|null,
  },
}

export type EstimatedValueData = {
  publicPension?: {
    startAgeOfPension: EstimatedValueDataItem,
    monthlyPensionAmount: EstimatedValueDataItem,
  },
  corporatePensionDb?: {
    startAgeOfPension: EstimatedValueDataItem,
    drawTypeOfPension: {
      fixed: boolean|null,
      source: string|null,
      valueDrawType: string|null,
    },
  },
  corporatePensionDc?: EstimatedValueDataDc,
  ideco?: EstimatedValueDataIdeco,
  retirementAllowance?: {
    retirementAge: EstimatedValueDataItem,
    retirementAllowance: EstimatedValueDataItem,
  },
}

export type State = {
  householder: EstimatedValueData,
  spouse: EstimatedValueData,
}

const initialState: State = {
  householder: {},
  spouse: {},
};

export const estimatedSlice = createSlice({
  name: 'estimated',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEstimated: (state, action: PayloadAction<{
      householder?: EstimatedValueData,
      spouse?: EstimatedValueData,
    }>) => {
      state.householder = action.payload.householder ?? {};
      state.spouse = action.payload.spouse ?? {};
    },
  },
});

export const {
  setEstimated,
} = estimatedSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEstimated = (state: RootState) => state.estimated;

// グリーンラベルに表示するラベル名を取得
export const getLabelValue = (source:string|null = null) => {
  if (source === 'NA') {
    return null;
  }
  const data = [
    {
      source: 'BK',
      value: 'モデル値',
    },
    {
      source: 'TB',
      value: '企業年金制度に基づいた推計値',
    },
    {
      source: 'JIST',
      value: 'JIS&Tデータから取得',
    },
    {
      source: 'FT',
      value: '統計に基づく推計値',
    },
    {
      source: 'LAW',
      value: '法律により規定',
    },
  ].find((item) => item.source === source);
  return data?.value ?? source;
};

export default estimatedSlice.reducer;
