// 本人の定年の最大値
export const RETIREMENT_AGE = 65;

// 配偶者の定年
export const RETIREMENT_AGE_SPOUSE = 60;

// 年収入力の最小値
export const MINIMUM_ANNUAL_INCOME = 106;

// 年収入力の最大値
export const MAXIMUM_ANNUAL_INCOME = 9999;

// export const SPOUSE_START_AGE_OF_PENSION = 65;

// 配偶者が追加されたときのデフォルトの年収
export const SPOUSE_DEFAULT_ANNUAL_INCOME = 4350000;

// 配偶者が追加されたときの受給開始年齢
export const PUBLIC_PENSION_START_AGE_OF_PENSION = 65;

// こどもの生年月の有効範囲
export const LOAN_START_AGE_ADDITION = 18;

const Validation = {
  MINIMUM_ANNUAL_INCOME,
  MAXIMUM_ANNUAL_INCOME,
  LOAN_START_AGE_ADDITION,
};
export default Validation;
