import * as React from 'react';
import moment from 'moment';

// components
import InputInteger from './InputInteger';
import EstimationLabelAmount from '../label/EstimationLabelAmount';

// 推定値の型
import type { EstimatedValueDataItem } from '../../reducers/estimatedReducer';

/**
 * 資産評価額入力項目
 * @param props
 * @returns
 */
type InputAssetValuationProps = {
  name: string;
  defaultValue?: number|null;
  disabled?: boolean;
  required?: boolean;
  max?: number;
  estimatedItem?: EstimatedValueDataItem|undefined,
  onChange?: (_:number|null) => void;
};
const InputAssetValuation: React.FC<InputAssetValuationProps> = (
  {
    name, defaultValue, disabled, required, max, estimatedItem, onChange,
  },
) => {
  let minValue: number|undefined;
  let maxValue = max;
  if (estimatedItem !== undefined) {
    // 推計値を受け取ったときに推計値から最小値/最大値を指定する
    if (estimatedItem.floorValue !== null) {
      minValue = estimatedItem.floorValue;
    }
    if (estimatedItem.capValue !== null) {
      maxValue = estimatedItem.capValue;
    }
  }
  return (
    <>
      <InputInteger
        name={name}
        className="w-[120px]"
        unit="円"
        required={required}
        min={minValue}
        max={maxValue}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
      />
      <div className="mt-4 space-y-2">
        <p className="text-xs text-gray-500">{ moment().format('YYYY年M月時点') }</p>
        <EstimationLabelAmount
          source={estimatedItem?.source}
          value={estimatedItem?.value}
        />
        <p className="text-xs text-gray-500">資産評価額に入力された額は、基本情報「預金額」の項目には含めないでください</p>
      </div>
    </>
  );
};
InputAssetValuation.defaultProps = {
  defaultValue: undefined,
  disabled: false,
  required: false,
  max: undefined,
  estimatedItem: undefined,
  onChange: undefined,
};
export default InputAssetValuation;
