import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// components
const PlusIcon = styled.div<{minus: boolean}>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0.625rem;
    display: block;
    width: 1.5rem;
    height: 0.125rem;
    background-color: ${theme`colors.primary`};
    border-radius: 9999px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0. 0.2, 1);
    transition-duration: 150ms;
  }
  &::before {
    transform: ${({ minus }) => (minus ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  &::after {
    transform: ${({ minus }) => (minus ? 'rotate(0deg)' : 'rotate(-90deg)')};
  }
`;

type AppendProps = {
  label: string;
  onClick: (_: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  minus?: boolean;
}
const Append: React.FC<AppendProps> = ({
  label, onClick, disabled, minus,
}) => (
  <div className={`flex flex-col items-center space-y-4 ${disabled && 'opacity-30'}`}>
    <p className="text-base font-bold whitespace-pre-wrap text-center">{label}</p>
    <button type="button" onClick={onClick} disabled={disabled}>
      <PlusIcon minus={minus ?? false} />
    </button>
  </div>
);
Append.defaultProps = {
  disabled: false,
  minus: false,
};
export default Append;
