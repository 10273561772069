import * as React from 'react';

// 退職金・年金の受取額データ
import { useAppSelector } from '../../app/hooks';
import { selectHasBeJoinedCorporateDB, selectRetirementAge } from '../../reducers/userReducer';
import { selectHasSpouseOrWill } from '../../reducers/inputReducer';

// キャッシュフローデータ
import { selectCashflow, selectCashflowAfterRetirementSpouse } from '../../reducers/cashflowReducer';

// config
import { RETIREMENT_AGE_SPOUSE } from '../../config/validation';

// components
import TableGroup from '../table/TableGroup';
import TableRow from '../table/TableRow';
import AmountReceivedTable from '../table/AmountReceivedTable';

/**
 * 退職金・年金の受取額
 * @returns
 */
type PensionTableSectionProps = {
  // 処方箋モード前は○○歳以降の給与表示が非表示になる（PCの仕様に合わせてアドバイスページは別）
  isAdviced?: boolean
};
const PensionTableSection: React.FC<PensionTableSectionProps> = ({ isAdviced = true }) => {
  // 配偶者有無または結婚予定の配偶者有無
  const hasSpouse = useAppSelector(selectHasSpouseOrWill);

  // 企業年金(DB)に加入済みかどうかの判定
  const hasBeJoinedCorporateDB = useAppSelector(selectHasBeJoinedCorporateDB);

  // 歳以降の給与の年齢を取得
  const retirementAge = useAppSelector(selectRetirementAge);

  // 退職金・年金の受取額を表示するために、キャッシュフローを取得
  const cashflows = useAppSelector(selectCashflow);
  const cashflowsSpouse = useAppSelector(selectCashflowAfterRetirementSpouse);

  // 退職金・年金の受取額の値
  const [retirementAllowance, setRetirementAllowance] = React.useState(0);
  const [retirementAllowanceSpouse, setRetirementAllowanceSpouse] = React.useState(0);
  const [corporatePensionDbBulk, setCorporatePensionDbBulk] = React.useState(0);
  const [corporatePensionDbInstallments, setCorporatePensionDbInstallments] = React.useState(0);
  const [corporatePensionDcBulk, setCorporatePensionDcBulk] = React.useState(0);
  const [corporatePensionDcInstallments, setCorporatePensionDcInstallments] = React.useState(0);
  const [corporatePensionDcBulkSpouse, setCorporatePensionDcBulkSpouse] = React.useState(0);
  const [
    corporatePensionDcInstallmentsSpouse, setCorporatePensionDcInstallmentsSpouse,
  ] = React.useState(0);
  const [idecoBulk, setIdecoBulk] = React.useState(0);
  const [idecoInstallments, setIdecoInstallments] = React.useState(0);
  const [idecoBulkSpouse, setIdecoBulkSpouse] = React.useState(0);
  const [idecoInstallmentsSpouse, setIdecoInstallmentsSpouse] = React.useState(0);
  const [publicPension, setPublicPension] = React.useState(0);
  const [publicPensionSpouse, setPublicPensionSpouse] = React.useState(0);
  const [annualReEmploymentIncome, setAnnualReEmploymentIncome] = React.useState(0);
  const [totalBeforeTaxBulk, setTotalBeforeTaxBulk] = React.useState(0);
  const [totalBeforeTaxInstallments, setTotalBeforeTaxInstallments] = React.useState(0);
  const [totalBeforeTaxBulkSpouse, setTotalBeforeTaxBulkSpouse] = React.useState(0);
  const [totalBeforeTaxInstallmentsSpouse, setTotalBeforeTaxInstallmentsSpouse] = React.useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = React.useState(0);
  const [totalAfterTax, setTotalAfterTax] = React.useState(0);

  React.useEffect(() => {
    // キャッシュフローから退職金・年金の分割受け取りイメージデータを絞り込む
    const cashflowIncomes = cashflows.map((cashflow) => cashflow.data.income);
    const cashflowIncomesSpouse = cashflows.map((cashflow) => cashflow.data.income);

    // 退職金・年金の受取額データを生成
    const newRetirementAllowance = cashflowIncomes
      .reduce((sum, income) => (income.householder?.retirementAllowance ?? 0) + sum, 0);
    setRetirementAllowance(newRetirementAllowance);

    const newRetirementAllowanceSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.retirementAllowance ?? 0) + sum, 0)
      : 0;
    setRetirementAllowanceSpouse(newRetirementAllowanceSpouse);

    // 企業年金 (DB)
    const newCorporatePensionDbBulk = hasBeJoinedCorporateDB
      ? cashflowIncomes
        .reduce((sum, income) => (income.householder?.corporatePensionDbBulk ?? 0) + sum, 0)
      : 0;
    setCorporatePensionDbBulk(newCorporatePensionDbBulk);

    const newCorporatePensionDbInstallments = cashflowIncomes
      .reduce((sum, income) => (income.householder?.corporatePensionDbInstallments ?? 0) + sum, 0);
    setCorporatePensionDbInstallments(newCorporatePensionDbInstallments);

    // 企業年金 (DC)
    const newCorporatePensionDcBulk = cashflowIncomes
      .reduce((sum, income) => (income.householder?.corporatePensionDcBulk ?? 0) + sum, 0);
    setCorporatePensionDcBulk(newCorporatePensionDcBulk);

    const newCorporatePensionDcInstallments = cashflowIncomes
      .reduce((sum, income) => (income.householder?.corporatePensionDcInstallments ?? 0) + sum, 0);
    setCorporatePensionDcInstallments(newCorporatePensionDcInstallments);

    const newCorporatePensionDcBulkSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.corporatePensionDcBulk ?? 0) + sum, 0)
      : 0;
    setCorporatePensionDcBulkSpouse(newCorporatePensionDcBulkSpouse);

    const newCorporatePensionDcInstallmentsSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.corporatePensionDcInstallments ?? 0) + sum, 0)
      : 0;
    setCorporatePensionDcInstallmentsSpouse(newCorporatePensionDcInstallmentsSpouse);

    // 個人年金 (iDeco)
    const newIdecoBulk = cashflowIncomes
      .reduce((sum, income) => (income.householder?.idecoBulk ?? 0) + sum, 0);
    setIdecoBulk(newIdecoBulk);

    const newIdecoInstallments = cashflowIncomes
      .reduce((sum, income) => (income.householder?.idecoInstallments ?? 0) + sum, 0);
    setIdecoInstallments(newIdecoInstallments);

    const newIdecoBulkSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.idecoBulk ?? 0) + sum, 0)
      : 0;
    setIdecoBulkSpouse(newIdecoBulkSpouse);

    const newIdecoInstallmentsSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.idecoInstallments ?? 0) + sum, 0)
      : 0;
    setIdecoInstallmentsSpouse(newIdecoInstallmentsSpouse);

    // 公的年金 (100歳まで)
    const newPublicPension = cashflowIncomes
      .reduce((sum, income) => (income.householder?.publicPension ?? 0) + sum, 0);
    setPublicPension(newPublicPension);

    const newPublicPensionSpouse = hasSpouse
      ? cashflowIncomesSpouse
        .reduce((sum, income) => (income.spouse?.publicPension ?? 0) + sum, 0)
      : 0;
    setPublicPensionSpouse(newPublicPensionSpouse);

    // 歳以降の給与
    const newAnnualReEmploymentIncome = cashflowIncomes
      .reduce((sum, income) => (income.householder?.annualReEmploymentIncome ?? 0) + sum, 0);
    setAnnualReEmploymentIncome(newAnnualReEmploymentIncome);

    // 合計 (税引前) - あなたの一括
    const newTotalBeforeTaxBulk = (Math.round(newRetirementAllowance / 10000) * 10000)
      + (Math.round(newCorporatePensionDbBulk / 10000) * 10000)
      + (Math.round(newCorporatePensionDcBulk / 10000) * 10000)
      + (Math.round(newIdecoBulk / 10000) * 10000);
    setTotalBeforeTaxBulk(newTotalBeforeTaxBulk);

    // 合計 (税引後) - あなたの分割（累計）
    const newTotalBeforeTaxInstallments = 0
      + (Math.round(newCorporatePensionDbInstallments / 10000) * 10000)
      + (Math.round(newCorporatePensionDcInstallments / 10000) * 10000)
      + (Math.round(newIdecoInstallments / 10000) * 10000)
      + (Math.round(newPublicPension / 10000) * 10000)
      + (Math.round(newAnnualReEmploymentIncome / 10000) * 10000);
    setTotalBeforeTaxInstallments(newTotalBeforeTaxInstallments);

    // 合計 (税引後) - 配偶者の一括
    const newTotalBeforeTaxBulkSpouse = (Math.round(newRetirementAllowanceSpouse / 10000) * 10000)
      + (Math.round(newCorporatePensionDcBulkSpouse / 10000) * 10000)
      + (Math.round(newIdecoBulkSpouse / 10000) * 10000);
    setTotalBeforeTaxBulkSpouse(newTotalBeforeTaxBulkSpouse);

    // 合計 (税引後) - 配偶者の分割（累計）
    const newTotalBeforeTaxInstallmentsSpouse = 0
      + (Math.round(newCorporatePensionDcInstallmentsSpouse / 10000) * 10000)
      + (Math.round(newIdecoInstallmentsSpouse / 10000) * 10000)
      + (Math.round(newPublicPensionSpouse / 10000) * 10000);
    setTotalBeforeTaxInstallmentsSpouse(newTotalBeforeTaxInstallmentsSpouse);

    // 合計 (税引後) - 総額 (税引前)
    const newTotalBeforeTax = newTotalBeforeTaxBulk
      + newTotalBeforeTaxInstallments
      + newTotalBeforeTaxBulkSpouse
      + newTotalBeforeTaxInstallmentsSpouse;
    setTotalBeforeTax(newTotalBeforeTax);

    // 合計 (税引後) - 総額
    // 払戻金を除く残余利益を取得して、税引後の合計を算出
    const newTotalAfterTaxHouseholder = cashflows
      .filter((cashflow) => (retirementAge !== null && cashflow.age >= retirementAge))
      .reduce((sum, cashflow) => {
        const residualIncome = cashflow.summaries.residualIncomeExcludeRefunds?.householder ?? 0;
        let value = residualIncome;
        if (retirementAge !== null && cashflow.age === retirementAge) {
          const income = cashflow.data.income.householder;
          const n = (income?.annualReEmploymentIncome ?? 0)
            + (income?.retirementAllowance ?? 0)
            + (income?.publicPension ?? 0)
            + (income?.corporatePensionDbBulk ?? 0)
            + (income?.corporatePensionDbInstallments ?? 0)
            + (income?.corporatePensionDcBulk ?? 0)
            + (income?.corporatePensionDcInstallments ?? 0)
            + (income?.idecoBulk ?? 0)
            + (income?.idecoInstallments ?? 0)
            + (income?.annualIncome ?? 0);
          if (n !== 0) {
            value -= ((income?.annualIncome ?? 0) / n)
            * (
              (income?.residualIncomeOfGrossIncome ?? 0)
              + (income?.residualIncomeOfRetirementAllowance ?? 0)
            );
          }
        }
        return value + sum;
      }, 0);
    const newTotalAfterTaxSpouse = hasSpouse
      ? cashflowsSpouse.reduce((sum, cashflow) => {
        const residualIncome = cashflow.summaries.residualIncomeExcludeRefunds?.spouse ?? 0;
        let value = residualIncome;
        const { spouseAge } = cashflow;
        if (spouseAge !== undefined && spouseAge !== null && spouseAge === RETIREMENT_AGE_SPOUSE) {
          const income = cashflow.data.income.spouse;
          const n = (income?.retirementAllowance ?? 0)
            + (income?.publicPension ?? 0)
            + (income?.corporatePensionDcBulk ?? 0)
            + (income?.corporatePensionDcInstallments ?? 0)
            + (income?.idecoBulk ?? 0)
            + (income?.idecoInstallments ?? 0)
            + (income?.annualIncome ?? 0);
          if (n !== 0) {
            value -= ((income?.annualIncome ?? 0) / n)
            * (
              (income?.residualIncomeOfGrossIncome ?? 0)
              + (income?.residualIncomeOfRetirementAllowance ?? 0)
            );
          }
        }
        return value + sum;
      }, 0)
      : 0;
    const newTotalAfterTax = newTotalAfterTaxHouseholder + newTotalAfterTaxSpouse;
    setTotalAfterTax(newTotalAfterTax);
  }, [cashflows, cashflowsSpouse, hasSpouse]);
  return (
    <section>
      <h3 className="fieldset-title">退職金・年金の受取額</h3>
      <div className="fieldset-body">
        <TableGroup label="合計 (税引後)">
          <div className="space-y-2">
            <TableRow label="あなたの一括" value={totalBeforeTaxBulk} />
            <TableRow label="あなたの分割 (累計)" value={totalBeforeTaxInstallments} />
            {hasSpouse && (
              <>
                <TableRow label="配偶者の一括" value={totalBeforeTaxBulkSpouse} />
                <TableRow label="配偶者の分割 (累計)" value={totalBeforeTaxInstallmentsSpouse} />
              </>
            )}
            <TableRow label="総額 (税引前)" value={totalBeforeTax} maxDigits={6} />
          </div>
        </TableGroup>
        <TableRow
          label="総額"
          value={totalAfterTax}
          className="mt-2"
          maxDigits={8}
        />
      </div>
      <div className="fieldset-body">
        <AmountReceivedTable
          label="退職金"
          yourBulk={retirementAllowance}
          spouseBulk={retirementAllowanceSpouse}
          hasSpouse={hasSpouse}
        />
      </div>
      {hasBeJoinedCorporateDB
      && (
        <div className="fieldset-body">
          <AmountReceivedTable
            label="企業年金 (DB)"
            yourBulk={corporatePensionDbBulk}
            yourInstallments={corporatePensionDbInstallments}
            hasSpouse={hasSpouse}
          />
        </div>
      )}
      <div className="fieldset-body">
        <AmountReceivedTable
          label="企業年金 (DC)"
          yourBulk={corporatePensionDcBulk}
          yourInstallments={corporatePensionDcInstallments}
          spouseBulk={corporatePensionDcBulkSpouse}
          spouseInstallments={corporatePensionDcInstallmentsSpouse}
          hasSpouse={hasSpouse}
        />
      </div>
      <div className="fieldset-body">
        <AmountReceivedTable
          label="個人年金 (iDeCo)"
          yourBulk={idecoBulk}
          yourInstallments={idecoInstallments}
          spouseBulk={idecoBulkSpouse}
          spouseInstallments={idecoInstallmentsSpouse}
          hasSpouse={hasSpouse}
        />
      </div>
      <div className="fieldset-body">
        <AmountReceivedTable
          label="公的年金 (100歳まで)"
          yourInstallments={publicPension}
          spouseInstallments={publicPensionSpouse}
          hasSpouse={hasSpouse}
        />
      </div>
      {isAdviced
      && (
        <div className="fieldset-body">
          <AmountReceivedTable
            label={`${retirementAge}歳以降の給与`}
            yourInstallments={annualReEmploymentIncome}
            hasSpouse={hasSpouse}
          />
        </div>
      )}
    </section>
  );
};
PensionTableSection.defaultProps = {
  isAdviced: undefined,
};
export default PensionTableSection;
