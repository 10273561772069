import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../app/store';

// type
import type { FetchResponseEducationExpensesMatrix } from './async/fetchAsync';
import type { RiskTolerance } from './riskReducer';

export type Grade = 'PRIMARY_SCHOOL'|'JUNIOR_HIGH_SCHOOL'|'HIGH_SCHOOL'|'UNIVERSITY'|null;
export type PublicPrivate = 'PUBLIC'|'PRIVATE'|'PRIVATE_SCIENCE'|'PRIVATE_HUMANITIES'|null;

export type BasicJist = {
  dcTotalReservedAmount: number|null,
  pnsnAstEstmAmt: string|null,
  opeAmt: string|null,
  birthday?: string|null,
  joinMonth: string|null,
}
export type EducationExpensesMatrix = {
  lessonMemo?: string|null;
  tuition?: number|null;
  grade?: Grade;
  entranceFee?: number|null;
  publicPrivate?: PublicPrivate;
  lessonFee?: number|null;
}

type State = {
  loginDate: string|null,
  account: {
    jisTid: string|null,
    isGuest: boolean,
    initialSettingStatus: boolean|null,
    nickname: string|null,
    email: string|null,
    requestForEmailNewsLetter: boolean,
    annualIncome: number|null,
    lifeStyle: string|null,
    birthDay: string|null,
    age: number|null,
    retirementAge: number|null,
    loginFlg: boolean,
  }
  bkData?: {
    companyFlg: number|null,
    recommendedSfFlg: boolean|null,
    recommendedPwedFlg: boolean|null,
    recommendedNisaFlg: boolean|null,
    recommendedIdecoFlg: boolean|null,
    dbModelAmountSettingAmount: number|null,
    assetPattern: number|null,
    bankBranchName?: string|null,
    responsible?: string|null,
    bankClerkPhoneNo?: string|null,
    reminder?: string|null,
  },
  tbData?: {
    definedBenefitRetireAmount: number|null,
  },
  jist?: BasicJist,
  educationExpenses?: EducationExpensesMatrix[],
  token: string|null,
};

const initialState: State = {
  loginDate: null,
  account: {
    jisTid: null,
    isGuest: false,
    initialSettingStatus: null,
    nickname: null,
    email: null,
    requestForEmailNewsLetter: false,
    annualIncome: null,
    lifeStyle: null,
    birthDay: null,
    age: null,
    retirementAge: null,
    loginFlg: true,
  },
  token: null,
};

// APIからレスポンスされた型
export type ResponseDataBasic = {
  loginDate?: string|null,
  account: {
    jisTid?: string|null,
    isGuest?: boolean|null,
    userRegistrationStatus?: boolean|null,
    initialSettingStatus?: boolean|null,
    nickname?: string|null,
    email?: string|null,
    requestForEmailNewsLetter?: boolean|null,
    annualIncome?: number|null,
    lifeStyle?: string|null,
    birthDay?: string|null,
    age?: number|null,
    retirementAge?: number|null,
    loginFlg?: boolean|null,
  },
  bkData?: {
    assetPattern?: number|null,
    companyFlg?: number|null,
    dcFutureLatchFuncFlg?:boolean|null,
    recommendedSfFlg?: boolean|null,
    recommendedPwedFlg?:boolean|null,
    recommendedNisaFlg?: boolean|null,
    recommendedIdecoFlg?: boolean|null,
    dbModelAmountSettingAmount?: number|null,
    bankBranchName?: string|null,
    responsible?: string|null,
    bankClerkPhoneNo?: string|null,
    reminder?: string|null,
  },
  tbData?: {
    subscriberNo?: string|null,
    requiredPaymentAmount?: number|null,
    lumpSumRetireAmount?: number|null,
    definedBenefitRetireAmount?: number|null,
  },
  jist?: {
    dcTotalReservedAmount?: number|null,
    pnsnAstEstmAmt?: string|null,
    opeAmt?: string|null,
    birthday?: string|null,
    joinMonth?: string|null,
  },
  riskTolerance?: RiskTolerance,
  // token: string|null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGuest: (state, action: PayloadAction<boolean>) => {
      state.account.isGuest = action.payload;
    },
    setUserConfig: (state, action: PayloadAction<{
      nickname?: string|null,
      email?: string|null,
      requestForEmailNewsLetter?: boolean|null,
      birthdate?: string|null,
      annualIncome?: number|null,
      lifeStyle?: string|null,
      loginFlg?: boolean,
    }>) => {
      if (action.payload.nickname !== undefined) {
        state.account.nickname = action.payload.nickname;
      }
      if (action.payload.email !== undefined) {
        state.account.email = action.payload.email;
      }
      if (action.payload.requestForEmailNewsLetter !== undefined) {
        state.account.requestForEmailNewsLetter = action.payload.requestForEmailNewsLetter ?? false;
      }
      if (action.payload.birthdate !== undefined) {
        state.account.birthDay = action.payload.birthdate;
        state.account.age = moment().diff(moment(action.payload.birthdate), 'years');
      }
      if (action.payload.annualIncome !== undefined) {
        state.account.annualIncome = action.payload.annualIncome;
      }
      if (action.payload.lifeStyle !== undefined) {
        state.account.lifeStyle = action.payload.lifeStyle;
      }
      if (action.payload.loginFlg !== undefined) {
        state.account.loginFlg = action.payload.loginFlg;
      }
    },
    setLoginDate: (state, action: PayloadAction<string>) => {
      state.loginDate = action.payload;
    },
    setBasic: (state, action: PayloadAction<ResponseDataBasic>) => {
      const data = action.payload;
      const { account } = data;
      state.loginDate = data.loginDate ?? null;
      state.account = {
        jisTid: account.jisTid ?? null,
        isGuest: account.isGuest ?? false,
        initialSettingStatus: account.initialSettingStatus ?? false,
        nickname: account.nickname ?? null,
        email: account.email ?? null,
        requestForEmailNewsLetter: account.requestForEmailNewsLetter ?? false,
        annualIncome: account.annualIncome ?? null,
        lifeStyle: account.lifeStyle ?? null,
        birthDay: account.birthDay ?? null,
        age: account.age ?? null,
        retirementAge: account.retirementAge ?? null,
        loginFlg: account.loginFlg ?? true,
      };
      if (data.bkData !== undefined) {
        state.bkData = {
          companyFlg: data.bkData.companyFlg ?? null,
          recommendedSfFlg: data.bkData.recommendedSfFlg ?? null,
          recommendedPwedFlg: data.bkData.recommendedPwedFlg ?? null,
          recommendedNisaFlg: data.bkData.recommendedNisaFlg ?? null,
          recommendedIdecoFlg: data.bkData.recommendedIdecoFlg ?? null,
          dbModelAmountSettingAmount: data.bkData.dbModelAmountSettingAmount ?? null,
          assetPattern: data.bkData.assetPattern ?? null,
          bankBranchName: data.bkData.bankBranchName ?? null,
          responsible: data.bkData.responsible ?? null,
          bankClerkPhoneNo: data.bkData.bankClerkPhoneNo ?? null,
          reminder: data.bkData.reminder ?? null,
        };
      }
      if (data.tbData !== undefined) {
        state.tbData = {
          definedBenefitRetireAmount: data.tbData.definedBenefitRetireAmount ?? null,
        };
      }
      if (data.jist !== undefined) {
        state.jist = {
          dcTotalReservedAmount: data.jist.dcTotalReservedAmount ?? null,
          pnsnAstEstmAmt: data.jist.pnsnAstEstmAmt ?? null,
          opeAmt: data.jist.opeAmt ?? null,
          birthday: data.jist.birthday ?? null,
          joinMonth: data.jist.joinMonth ?? null,
        };
      }
      // state.token = data.token ?? null;
    },
    setToken: (state, action: PayloadAction<string|null>) => {
      state.token = action.payload;
    },
    setInitialSettingStatus: (state, action: PayloadAction<boolean|null>) => {
      state.account.initialSettingStatus = action.payload;
    },
    setEducationExpenseMatrix: (
      state,
      action: PayloadAction<FetchResponseEducationExpensesMatrix[]|undefined>,
    ) => {
      const data = action.payload;
      if (data !== undefined) {
        state.educationExpenses = data.map((d) => {
          return {
            lessonMemo: d.lessonMemo,
            tuition: d.tuition,
            grade: d.grade as Grade,
            entranceFee: d.entranceFee,
            publicPrivate: d.publicPrivate as PublicPrivate,
            lessonFee: d.lessonFee,
          };
        });
      }
    },
    setRetirementAge: (state, action: PayloadAction<number|null>) => {
      state.account.retirementAge = action.payload;
    },
  },
});

export const {
  setGuest,
  setUserConfig,
  setLoginDate,
  setBasic,
  setInitialSettingStatus,
  setEducationExpenseMatrix,
  setRetirementAge,
  setToken,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// アカウント
export const selectAccount = (state: RootState) => state.user.account;

// ログイン状態かどうか
export const selectIsLogined = (state: RootState) => (
  state.user.loginDate !== undefined
  && state.user.loginDate !== null
);

// ニックネーム
export const selectNickname = (state: RootState) => state.user.account.nickname;

// ライフスタイル
export const selectLifeStyle = (state: RootState) => state.user.account.lifeStyle;

// ゲストかどうか
export const selectIsGuest = (state: RootState) => state.user.account.isGuest ?? false;

// 退職年齢
export const selectRetirementAge = (state: RootState) => state.user.account.retirementAge;

// 生年月
export const selectBirthdate = (state: RootState) => state.user.account.birthDay;

// 企業フラグ
export const selectCompanyFlg = (state: RootState) => state.user.bkData?.companyFlg;

// アドバイス - おすすめ商品表示フラグ
export const selectRecommendedDc = (state: RootState) => state.user.bkData?.recommendedSfFlg;
export const selectRecommendedIdeco = (state: RootState) => state.user.bkData?.recommendedIdecoFlg;
export const selectRecommendedNisa = (state: RootState) => state.user.bkData?.recommendedNisaFlg;
export const selectRecommendedPweb = (state: RootState) => state.user.bkData?.recommendedPwedFlg;

export const selectBankBranchName = (state: RootState) => state.user.bkData?.bankBranchName;
export const selectResponsible = (state: RootState) => state.user.bkData?.responsible;
export const selectBankClerkPhoneNo = (state: RootState) => state.user.bkData?.bankClerkPhoneNo;
export const selectReminder = (state: RootState) => state.user.bkData?.reminder;

// 教育費用
export const selectEducationExpenses = (state: RootState) => state.user.educationExpenses;

// 指定された値から教育費用を取得
export const getEducationExpense = (
  // 教育費データ
  educationExpenses: EducationExpensesMatrix[]|undefined,

  // 小学校・中学校・高校・大学
  grade: Grade,

  // 教育費の出費イメージ
  educationPolicy: string|null,

  // 区分
  publicPrivate: string|null = null,
) => {
  const matrix = educationExpenses?.filter((e) => e.grade === grade)
    .find((e) => {
      // 1. あまりかからない
      if (educationPolicy === 'ALL_PUBLIC') {
        return e.publicPrivate === 'PUBLIC';
      }

      // 2. 少しかかる
      if (educationPolicy === 'PRIVATE_FROM_UNIVERSITY') {
        if (grade === 'UNIVERSITY') {
          // 大学から私立
          return e.publicPrivate === 'PRIVATE_SCIENCE';
        }
        return e.publicPrivate === 'PUBLIC';
      }

      // 3. 結構かかる
      if (educationPolicy === 'PRIVATE_FROM_HIGH_SCHOOL') {
        if (grade === 'HIGH_SCHOOL') {
          // 高校から私立
          return e.publicPrivate === 'PRIVATE';
        }
        if (grade === 'UNIVERSITY') {
          // 大学は私立理系
          return e.publicPrivate === 'PRIVATE_SCIENCE';
        }
        return e.publicPrivate === 'PUBLIC';
      }

      // 4. 非常にかかる
      if (educationPolicy === 'VERY_EXPENSIVE') {
        if (grade === 'UNIVERSITY') {
          // 大学は私立理系
          return e.publicPrivate === 'PRIVATE_SCIENCE';
        }
        // 小・中学生から私立
        return e.publicPrivate === 'PRIVATE';
      }

      // 5. カスタマイズ
      if (educationPolicy === 'CUSTOMIZATION') {
        if (publicPrivate === null) {
          if (grade === 'HIGH_SCHOOL') {
            // 高校は私立がデフォルト
            return e.publicPrivate === 'PRIVATE';
          }
          if (grade === 'UNIVERSITY') {
            // 大学は私立理系がデフォルト
            return e.publicPrivate === 'PRIVATE_SCIENCE';
          }
          // 小学校・中学校は公立がデフォルト
          return e.publicPrivate === 'PUBLIC';
        }
        return e.publicPrivate === publicPrivate;
      }
      return false;
    });

  const entranceFee = matrix?.entranceFee ?? null;
  const tuition = matrix?.tuition ?? null;
  const lessonFee = matrix?.lessonFee ?? null;
  return {
    publicPrivate: matrix?.publicPrivate ?? null,
    entranceFee: entranceFee !== null ? Math.round(entranceFee / 100) * 100 : null,
    tuition: tuition !== null ? Math.round(tuition / 100) * 100 : null,
    lessonFee: lessonFee !== null ? Math.round(lessonFee / 100) * 100 : null,
  };
};

// 企業年金（DC）が有効かどうか
// 実質、1, 2, 5 はデータなし
// 企業年金（DC）の加入項目を「加入済み」のみにする
// 4, 6, 8 以外のとき個人年金（iDeco）の加入項目を全表示する
export const getHasDc = (companyFlg: number|null|undefined) => {
  if (companyFlg === undefined || companyFlg === null) {
    return false;
  }
  return [1, 2, 3, 4, 5, 6, 7, 8].indexOf(companyFlg) !== -1;
};
export const selectHasDc = (state: RootState) => getHasDc(state.user.bkData?.companyFlg);

// 個人年金（iDeco）が有効かどうか
// 個人年金（iDeco）の加入項目を「加入済み」のみにする
export const getHasIdeco = (companyFlg: number|null|undefined) => {
  if (companyFlg === undefined || companyFlg === null) {
    return false;
  }
  return [9].indexOf(companyFlg) !== -1;
};
export const selectHasIdeco = (state: RootState) => getHasIdeco(state.user.bkData?.companyFlg);

// 個人年金（iDeco）が無効かどうか
// 個人年金（iDeco）の加入項目を「非加入」のみにする
export const selectHasNotIdeco = (state: RootState) => {
  const companyFlg = state.user.bkData?.companyFlg;
  if (companyFlg === undefined || companyFlg === null) {
    return false;
  }
  return [4, 6, 8].indexOf(companyFlg) !== -1;
};

/**
 * 企業年金(DB)に加入済みかどうかを判定します。
 * 決定ロジックは以下の通りです。
 *
 * 1.static_tb_infoに当該ユーザのデータがあるかないか？
 *   1.1 データがある
 *     1.1.1 static_tb_info.定年時給付額がある => db加入してる
 *     1.1.2 static_tb_info.定年時給付額がない => 2.の判定に進む
 * 2. static_bk_info.dbモデル年齢があるか？
 *   2.1 データがある => db加入してる
 *   2.2 データがない => db未加入
 *
 */
export const selectHasBeJoinedCorporateDB = (state: RootState) => {
  const data = state.user;
  if (data.tbData) {
    if (data.tbData.definedBenefitRetireAmount !== null) {
      return true;
    }
    if (data.bkData) {
      return data.bkData.dbModelAmountSettingAmount !== null;
    }
  }
  return false;
};

// 資産形成 - opeAmt
export const selectOpeAmt = (state: RootState) => {
  const opeAmt = state.user.jist?.opeAmt;
  if (opeAmt !== undefined && opeAmt !== null) {
    return parseInt(opeAmt, 10);
  }
  return null;
};

export default userSlice.reducer;
