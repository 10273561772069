import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
  LabelList,
} from 'recharts';
import { theme } from 'twin.macro';

// 収入の平均と支出の平均
import { useAppSelector } from '../../app/hooks';
import {
  selectAverageIncomeAfterRetirement,
  selectAverageExpensesAfterRetirement,
} from '../../reducers/cashflowReducer';

// components
import Remarks from '../block/Remarks';
import RetirementGraphLabel from '../recharts/content/RetirementGraphLabel';

export type MoneyBarItem = {
  key: string;
  label: string;
  value: number;
  color: string;
}

const RetirementGraphSection: React.FC = ({ children }) => {
  // 収入の平均を算出 (税引前)
  const averageIncome = useAppSelector(selectAverageIncomeAfterRetirement);

  // 支出の平均を算出 (税引前)
  const averageExpenses = useAppSelector(selectAverageExpensesAfterRetirement);

  // グラフデータ
  const [dataSource, setDataSource] = React.useState<MoneyBarItem[]>();

  // グラフデータ生成
  React.useEffect(() => {
    setDataSource([
      {
        key: 'income',
        label: '収入',
        value: Math.round(averageIncome / 10000),
        color: theme('colors.income'),
      },
      {
        key: 'expenses',
        label: '支出',
        value: Math.round(averageExpenses / 10000),
        color: theme('colors.expenses'),
      },
    ]);
  }, [averageIncome, averageExpenses]);

  return (
    <section>
      <h3 className="fieldset-title">
        老後の生活イメージ
        <br />
        (退職時～100歳までの平均)
      </h3>
      <div className="fieldset-body">
        <div className="relative w-full pt-[130px]">
          <div className="absolute inset-0 -mx-3">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                layout="vertical"
                data={dataSource}
                margin={{
                  top: 0, right: 0, left: 0, bottom: 0,
                }}
              >
                <XAxis
                  type="number"
                  padding={{ left: 10, right: 0 }}
                  axisLine={false}
                  tick={false}
                  hide
                />
                <YAxis
                  dataKey="label"
                  type="category"
                  tick={{ stroke: theme('colors.primary') }}
                  axisLine={false}
                  tickLine={false}
                />
                <Bar barSize={60} dataKey="value" isAnimationActive={false}>
                  {dataSource?.map(
                    (data) => (
                      <Cell key={`cell-${data.key}`} fill={data.color} />
                    ),
                  )}
                  <LabelList
                    dataKey="value"
                    position="center"
                    content={<RetirementGraphLabel />}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <Remarks className="mt-4">
          <p>収入の内訳は、画面上部のグラフもしくは｢退職金・年金｣画面の｢分割受取イメージ｣グラフをタップすることでもご確認いただけます。</p>
          <p>支出の内訳は、画面上部のグラフをタップすることでご確認いただけます。</p>
          <Link to="/home/retire/pension" className="arrow-link mt-1">退職金・年金</Link>
        </Remarks>
      </div>
      {children}
    </section>
  );
};
export default RetirementGraphSection;
