import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

type State = {
  faileds: string[];
}

const initialState: State = {
  faileds: [],
};

/**
 * 画面UIでのバリデーションエラー情報の管理
 */
export const validateSlice = createSlice({
  name: 'validate',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetFaileds: (state) => {
      // エラー情報をリセットする
      state.faileds = [];
    },
    setFailed: (state, action: PayloadAction<[string, boolean]>) => {
      const key = action.payload[0];
      const flag = action.payload[1];

      // 一旦キーを削除して、エラーであれば新たに追加する
      let { faileds } = current(state);
      faileds = faileds.filter((failed) => failed !== key);
      if (flag) {
        faileds.push(key);
      }
      state.faileds = faileds;
    },
  },
});

export const {
  resetFaileds, setFailed,
} = validateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectHasFailed = (state: RootState) => state.validate.faileds.length > 0;

export default validateSlice.reducer;
