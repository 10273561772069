import * as React from 'react';
import FAQ, { Q, A } from './FAQ';

const HelpMail: React.FC = () => (
  <FAQ>
    <Q>
      <p>メール配信を停止したいのですが、どうすればいいでしょうか？</p>
    </Q>
    <A>
      <p>
        ログイン後、画面右上にある「三本線」マークより「ユーザ設定」を選択してください。
        ユーザ設定画面に遷移しましたら「みずほ銀行からの情報提供メールを受け取る」のチェックを外して「変更を保存」ボタンを押してください。
      </p>
    </A>
  </FAQ>
);
export default HelpMail;
