import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import {
  selectHasSpouse,
  selectPrepaymentLoan, selectHousingExpenses, setPrepaymentLoan as setDispatchPrepaymentLoan,
} from '../../reducers/inputReducer';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';

// キャッシュフローデータ
import { selectCashflow } from '../../reducers/cashflowReducer';

// images
import imgHero from './assets/hero.svg';

// components
import InputInteger from '../../features/input/InputInteger';
import InputRadioGroup from '../../features/input/InputRadioGroup';
import HelpButton from '../../features/button/HelpButton';
import Remarks from '../../features/block/Remarks';
import TableInputRow from '../../features/table/TableInputRow';
import LoanGraph, { Chart } from './LoanGraph';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeHousePrepayment: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);

  // 配偶者有無または結婚予定の配偶者有無
  const hasSpouse = useAppSelector(selectHasSpouse);

  // 改善モード前は繰り上げ返済計画部分は非活性になる
  const isAdviced = useAppSelector(selectIsAdviceOpened);

  // 入力データ
  const defaultPrepaymentLoan = useAppSelector(selectPrepaymentLoan);
  const [prepaymentLoan, setPrepaymentLoan] = React.useState(defaultPrepaymentLoan);

  // 繰り上げ返済計画
  const [
    willHavePrepaymentOfLoan, setWillHavePrepaymentOfLoan,
  ] = React.useState(defaultPrepaymentLoan.willHavePrepaymentOfLoan);

  // 最低預金確保額
  const [
    minimumDepositAmount, setMinimumDepositAmount,
  ] = React.useState(defaultPrepaymentLoan.minimumDepositAmount);

  // 住宅ローン控除考慮
  const [
    hasConsiderationOfDeduction, setHasConsiderationOfDeduction,
  ] = React.useState(defaultPrepaymentLoan.hasConsiderationOfDeduction);

  // 繰り上げ返済する住宅ローン
  const [targetLoan, setTargetLoan] = React.useState(defaultPrepaymentLoan.targetLoan);

  // 繰り上げ返済する住宅ローンオプション
  const {
    planToBuyHouse, loanOfHouseholder, loanOfSpouse,
  } = useAppSelector(selectHousingExpenses);
  const targetLoanOptions = (() => {
    // 本人が住宅ローンを組んでる
    const hasHouseholderLoan = loanOfHouseholder?.hasMortgageLoan;
    const householderAmount = (loanOfHouseholder?.loanAmount ?? 0) / 10000;
    const householderPeriod = loanOfHouseholder?.loanPeriod ?? 0;
    const householderRate = loanOfHouseholder?.loanRate ?? 0;

    // 配偶者が住宅ローンを組んでる
    const hasSpouseLoan = loanOfSpouse?.hasMortgageLoan;
    const spouseAmount = (loanOfSpouse?.loanAmount ?? 0) / 10000;
    const spousePeriod = loanOfSpouse?.loanPeriod ?? 0;
    const spouseRate = loanOfSpouse?.loanRate ?? 0;

    // 将来の住宅ローンを組んでる
    const willHaveMortgageLoan = planToBuyHouse?.willHaveMortgageLoan ?? false;
    const whoWillHaveMortgageLoan = planToBuyHouse?.whoWillHaveMortgageLoan ?? false;

    if (hasHouseholderLoan || hasSpouseLoan) {
      // 現在の住宅ローンを組んでいる場合、将来の住宅ローンを処理しない
      const targetLoanCurrentYouOption = {
        value: 'CURRENT_YOU',
        label: '現在の住宅ローン (あなた)',
        help: `借入額${householderAmount.toLocaleString()}万円 借入期間${householderPeriod}年 金利 ${householderRate}%`,
      };
      const targetLoanCurrentSpouseOption = {
        value: 'CURRENT_SPOUSE',
        label: '現在の住宅ローン (配偶者)',
        help: `借入額${spouseAmount.toLocaleString()}万円 借入期間${spousePeriod}年 金利 ${spouseRate}%`,
      };
      if (hasHouseholderLoan && hasSpouse && hasSpouseLoan) {
        // 本人 + 配偶者
        return [targetLoanCurrentYouOption, targetLoanCurrentSpouseOption];
      } if (hasSpouse && hasSpouseLoan) {
        // 配偶者
        return [targetLoanCurrentSpouseOption];
        // 本人
      }
      return [targetLoanCurrentYouOption];
    } if (willHaveMortgageLoan) {
      // 将来の住宅ローン

      // 借入額
      let loanAmountHouseholder = 0;
      let loanAmountSpouse = 0;
      const loanAmount = (planToBuyHouse.amountOfPlanToBuyHouse ?? 0)
        - (planToBuyHouse.downPayment ?? 0);
      if (whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
        loanAmountHouseholder = Math.round(loanAmount / 2 / 10000);
        loanAmountSpouse = Math.round(loanAmount / 2 / 10000);
      } else if (whoWillHaveMortgageLoan === 'YOU') {
        loanAmountHouseholder = Math.round(loanAmount / 10000);
        loanAmountSpouse = 0;
      } else if (whoWillHaveMortgageLoan === 'SPOUSE') {
        loanAmountHouseholder = 0;
        loanAmountSpouse = Math.round(loanAmount / 10000);
      }

      const targetLoanFutureYouOption = {
        value: 'FUTURE_YOU',
        label: '購入予定の住宅ローン (あなた)',
        help: `借入額${loanAmountHouseholder.toLocaleString()}万円 借入期間${planToBuyHouse.loanPeriod}年 金利 ${planToBuyHouse.loanRate}%`,
      };
      const targetLoanFutureSpouseOption = {
        value: 'FUTURE_SPOUSE',
        label: '購入予定の住宅ローン (配偶者)',
        help: `借入額${loanAmountSpouse.toLocaleString()}万円 借入期間${planToBuyHouse.loanPeriod}年 金利 ${planToBuyHouse.loanRate}%`,
      };
      if (whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
        // 本人 + 配偶者
        return [targetLoanFutureYouOption, targetLoanFutureSpouseOption];
      } if (whoWillHaveMortgageLoan === 'YOU') {
        // 本人
        return [targetLoanFutureYouOption];
      } if (whoWillHaveMortgageLoan === 'SPOUSE') {
        // 配偶者
        return [targetLoanFutureSpouseOption];
      }
    }
    return [];
  })();

  // 住宅ローンのグラフを表示するために、キャッシュフローを取得
  const cashflows = useAppSelector(selectCashflow);

  //  変更前/変更後グラフデータ
  const [chartData, setChartData] = React.useState<Chart[]>();
  const [beforeTotal, setBeforeTotal] = React.useState<number>();
  const [afterTotal, setAfterTotal] = React.useState<number>();

  // 35～70歳の空データを取得
  const createEmptyDatas = (() => {
    const startAge = 35;
    return Array.from(Array(71 - startAge).keys()).map((n) => {
      return {
        age: n + startAge,
        before: 0,
        after: 0,
        interest: 0,
        interestAfter: 0,
      };
    });
  });

  React.useEffect(() => {
    if (!isAdviced) {
      // 処方箋モード前の場合はグラフを空にする
      setChartData(createEmptyDatas());
      setBeforeTotal(0);
      setAfterTotal(undefined);
      return;
    }

    const newDatas = (() => {
      let loanCashflows;
      if (targetLoan === 'CURRENT_SPOUSE' || targetLoan === 'FUTURE_SPOUSE') {
        loanCashflows = cashflows
          .filter((cashflow) => cashflow.spouseAge !== undefined)
          .filter((cashflow) => cashflow.summaries.flags?.mortgageLoanOfSpouse)
          .map((cashflow) => {
            // 変更前と変更後のデータを取得する
            const expenses = cashflow.data.expenses.spouse;
            return {
              age: cashflow.spouseAge ?? 0,
              before: expenses?.outstandingMortgageLoanAmount ?? 0,
              after: expenses?.outstandingMortgageLoanAmountAfterPrepayment ?? 0,

              // 住宅ローン支払(利息) interest repayment of mortgage loan
              // 繰上後住宅ローン支払(利息) interest repayment of mortgage loan after prepayment
              interest: expenses?.interestRepaymentOfMortgageLoan ?? 0,
              interestAfter: expenses?.interestRepaymentOfMortgageLoanAfterPrepayment ?? 0,
            };
          });
      } else {
        loanCashflows = cashflows
          .filter((cashflow) => cashflow.age !== undefined)
          .filter((cashflow) => cashflow.summaries.flags?.mortgageLoanOfHouseholder)
          .map((cashflow) => {
            // 変更前と変更後のデータを取得する
            const expenses = cashflow.data.expenses.householder;
            return {
              age: cashflow.age ?? 0,
              before: expenses?.outstandingMortgageLoanAmount ?? 0,
              after: expenses?.outstandingMortgageLoanAmountAfterPrepayment ?? 0,

              // 住宅ローン支払(利息) interest repayment of mortgage loan
              // 繰上後住宅ローン支払(利息) interest repayment of mortgage loan after prepayment
              interest: expenses?.interestRepaymentOfMortgageLoan ?? 0,
              interestAfter: expenses?.interestRepaymentOfMortgageLoanAfterPrepayment ?? 0,
            };
          });
      }
      return loanCashflows;
    })();

    if (newDatas.length > 0) {
      // 住宅ローンの利息支払額合計 - 変更前（1万単位で四捨五入）
      setBeforeTotal(
        Math.round(newDatas.reduce((sum, data) => sum + data.interest, 0) / 10000) * 10000,
      );
      if (defaultPrepaymentLoan.willHavePrepaymentOfLoan) {
        // デフォルトで繰り上げ返済計画がある場合のみ、グラフを表示
        setChartData(newDatas);

        // 住宅ローンの利息支払額合計 - 変更後（1万単位で四捨五入）
        setAfterTotal(
          Math.round(newDatas.reduce((sum, data) => sum + data.interestAfter, 0) / 10000) * 10000,
        );
      } else {
        setChartData(createEmptyDatas());
        setAfterTotal(undefined);
      }
    } else {
      // 値が空だったときは35～70歳の空データを代入
      setChartData(createEmptyDatas());
      setBeforeTotal(0);
      setAfterTotal(undefined);
    }
  }, [cashflows, targetLoan]);

  // データ更新
  React.useEffect(() => {
    setPrepaymentLoan((data) => {
      return {
        ...data,
        willHavePrepaymentOfLoan,
        minimumDepositAmount,
        hasConsiderationOfDeduction,
        targetLoan,
      };
    });
  }, [
    willHavePrepaymentOfLoan,
    minimumDepositAmount,
    hasConsiderationOfDeduction,
    targetLoan,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setDispatchPrepaymentLoan(defaultPrepaymentLoan);
      setMinimumDepositAmount(defaultPrepaymentLoan.minimumDepositAmount);
      setHasConsiderationOfDeduction(defaultPrepaymentLoan.hasConsiderationOfDeduction);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>繰り上げ返済計画</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">住宅ローンを早く返済することも資産を増やすテクニックです。</p>
        <p className="text-sm mt-2 mb-6">
          返済期間が長いほど、利息のため返済総額が高くなります。
          ボーナスの時期など資金に余裕があれば、できるだけ早く繰り上げ返済したほうがお得です。
          ただし、住宅ローン控除の期間内であればその限りではありません。シミュレーションで確認しましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">繰り上げ返済計画</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>繰り上げ返済計画</span>
              </div>
              <InputRadioGroup
                name="willHavePrepaymentOfLoan"
                options={[
                  { value: 0, label: 'なし' },
                  { value: 1, label: '繰り上げ返済計画を立てる' },
                ]}
                defaultValue={willHavePrepaymentOfLoan ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setWillHavePrepaymentOfLoan(parseInt(value, 10) === 1);
                }}
                disabled={!isAdviced}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>最低預金確保額</span>
                <HelpButton>
                  入力した預金額を下回らない範囲で毎年住宅ローンの繰り上げ返済をしていきます
                </HelpButton>
              </div>
              <InputInteger
                name="minimumDepositAmount"
                className="w-[120px]"
                unit="万円"
                division={4}
                defaultValue={minimumDepositAmount}
                disabled={!(isAdviced && willHavePrepaymentOfLoan)}
                onChange={(value) => setMinimumDepositAmount(value)}
              />
              <Remarks className="mt-6">
                <p>
                  繰り上げ返済計画はあなたの預金をすべて自動的に毎月返済にあてます。
                  月々の生活に必要な額の3か月程度＋すぐ使う予定のある予算は残しておくとよさそうです。
                </p>
              </Remarks>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>住宅ローン控除考慮</span>
                <HelpButton>
                  ｢あり｣とした場合、繰り上げ返済する住宅ローンの金利水準を考慮したうえで、控除期間中は繰り上げ返済をしない計算結果が表示される場合があります。
                  <br />
                  住宅ローン控除とは、住宅ローン残高に応じて所得税から一定額が控除される制度です。この制度が適応される期間は繰り上げ返済をしないほうがお得になる場合があります。
                </HelpButton>
              </div>
              <InputRadioGroup
                name="hasConsiderationOfDeduction"
                options={[
                  { value: 0, label: 'なし' },
                  { value: 1, label: 'あり' },
                ]}
                defaultValue={hasConsiderationOfDeduction ? 1 : 0}
                disabled={!(isAdviced && willHavePrepaymentOfLoan)}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setHasConsiderationOfDeduction(parseInt(value, 10) === 1);
                }}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>繰り上げ返済する住宅ローン</span>
              </div>
              <InputRadioGroup
                name="targetLoan"
                options={targetLoanOptions}
                defaultValue={targetLoan}
                disabled={!(isAdviced && willHavePrepaymentOfLoan)}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setTargetLoan(value);
                }}
              />
            </div>
            {isAdviced
            && (
              <div className="fieldset-body">
                <div className="form-label">
                  <span>住宅ローンの利息支払額合計</span>
                </div>
                <div className="mb-4">
                  <TableInputRow label="変更前" value={beforeTotal} unit="万円" division={4} fixed />
                  <TableInputRow label="変更後" value={afterTotal} unit="万円" division={4} fixed empty="???" />
                  <TableInputRow label="変化" value={(beforeTotal !== undefined && afterTotal !== undefined) ? afterTotal - beforeTotal : undefined} unit="万円" division={4} fixed empty="???" />
                </div>
                <LoanGraph datas={chartData} />
              </div>
            )}
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultPrepaymentLoan, prepaymentLoan)}
          contentId="prepayment"
          onClick={() => {
            dispatch(setDispatchPrepaymentLoan(prepaymentLoan));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeHousePrepayment;
