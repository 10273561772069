import * as React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Help: React.FC = () => (
  <div className="container py-[50px]">
    <div className="px-xs">
      <h1 className="text-2xl text-primary font-bold text-center">ヘルプ</h1>
      <div>
        <Outlet />
      </div>
      <div className="px-7">
        <Link to="/home" className="btn-back btn-back--lg">シミュレーション結果に戻る</Link>
      </div>
    </div>
  </div>
);
export default Help;
