import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from 'twin.macro';

// components
const lineScale = keyframes`
  0% {
    transform: scaley(1.0);
  }
  50% {
    transform: scaley(0.4);
  }
  100% {
    transform: scaley(1.0);
  }
`;
const Line = styled.div`
  display: inline-block;
  width: 4px;
  height: 35px;
  background-color: ${theme`colors.gray-500`};
  border-radius: 2px;
  margin: 2px;
  animation: ${lineScale} 1s infinite cubic-bezier(.2,.68,.18,1.08);
  &:nth-child(1) {
    animation-delay: -400ms;
  }
  &:nth-child(2) {
    animation-delay: -300ms;
  }
  &:nth-child(3) {
    animation-delay: -200ms;
  }
  &:nth-child(4) {
    animation-delay: -100ms;
  }
  &:nth-child(5) {
    animation-delay: 0ms;
  }
`;

/**
 * ローディングアニメーション
 * @param props
 * @returns
 */
const Loading: React.FC = (props) => {
  const { children } = props;
  return (
    <div className="text-center">
      <div>
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
      </div>
      {children !== undefined && (
        <p className="text-xs text-gray-500 mt-1">{children}</p>
      )}
    </div>
  );
};

export default Loading;
