import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

type State = {
  messages?: string[];
}

const initialState: State = {
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setErrorMessages: (state, action: PayloadAction<string[]>) => {
      state.messages = action.payload;
    },
    resetErrorMessages: (state) => {
      state.messages = undefined;
    },
  },
});

export const {
  setErrorMessages, resetErrorMessages,
} = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMessages = (state: RootState) => state.error.messages;

export default errorSlice.reducer;
