import * as React from 'react';

type InputNicknameProps = {
  className?: string;
  defaultValue?: string|null;
  disabled?: boolean;
  onChange?: (_: string|null) => void;
};

/**
 * ニックネーム入力項目
 * @param props
 * @returns
 */
const InputNickname: React.FC<InputNicknameProps> = ({
  className, defaultValue, disabled,
  onChange,
}) => (
  <div className="flex items-center">
    <input
      type="text"
      placeholder="みずほ"
      className={`form-input w-full disabled:bg-gray-100 ${className}`}
      defaultValue={defaultValue ?? undefined}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        if (onChange !== undefined) {
          onChange(value !== '' ? value : null);
        }
      }}
      disabled={disabled}
    />
  </div>
);
InputNickname.defaultProps = {
  className: '',
  defaultValue: undefined,
  disabled: false,
  onChange: undefined,
};

export default InputNickname;
