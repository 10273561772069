import * as React from 'react';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

/**
 * 金額表示コンポーネント
 * @param props
 * @returns
 */
 type AmountProps = {
   className?: string;
   value?: number|null;
   disabled?: boolean;

   // 単位
   division?: number;

   // 小数点の桁数
   digits?: number;

   // 空のときの値
   empty?: string;

   // 最大桁数
   maxDigits?: number;
 };
const Amount: React.FC<AmountProps> = ({
  className, value, disabled = false,
  division, digits, empty = '-',
  maxDigits = 15,
}) => {
  // 単位
  const divisionNum = (division !== undefined && division > 0) ? 10 ** division : 1;
  const divisionDigits = (digits !== undefined) ? 10 ** digits : 1;

  // 値
  const output = (value !== undefined && value !== null)
    ? Math.round((value / divisionNum) * divisionDigits) / divisionDigits
    : null;

  return (
    <div className={`text-primary ${disabled ? 'opacity-50' : ''} ${className}`}>
      {output !== null
        ? <span className="font-alpha text-2xl break-all">{costValue(output, maxDigits)}</span>
        : <span className="font-alpha text-2xl">{empty}</span>}
      <span className="text-sm ml-1">万円</span>
    </div>
  );
};
Amount.defaultProps = {
  className: '',
  value: undefined,
  disabled: false,
  division: 0,
  digits: undefined,
  empty: undefined,
  maxDigits: undefined,
};
export default Amount;
