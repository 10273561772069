import * as React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// 認証情報
import { useAppDispatch } from '../../app/hooks';
import { fetchAsync } from '../../reducers/authReducer';

// components
import Loading from '../../features/Loading';

const Auth: React.FC = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);
  const authCode = params.get('code');
  const sessionCookie = params.get('ext_code');

  // 必要なURLクエリーがなかったときはエラー表示
  if (authCode === null || sessionCookie === null) {
    return (
      <div className="container pt-5 h-40">
        <p className="font-bold">エラー発生</p>
        <p>
          To continue:
          {' '}
          <Link to="/" className="underline">go to home page</Link>
        </p>
      </div>
    );
  }

  React.useEffect(() => {
    const loginAsync = async (): Promise<void> => {
      await dispatch(fetchAsync({ authCode, sessionCookie }));
      navigate('/home', { replace: true });
    };
    loginAsync();
  }, [authCode, sessionCookie]);

  return (
    <div className="flex justify-center items-center h-40">
      <Loading>
        データを読み込み中です…
      </Loading>
    </div>
  );
};
export default Auth;
