import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// データ
import { useAppSelector } from '../../app/hooks';
import { selectNextStep, selectIsAdviceOpened } from '../../reducers/stepReducer';

// images
import { ReactComponent as IconBasics } from './assets/icon_basics.svg';
import { ReactComponent as IconEducation } from './assets/icon_education.svg';
import { ReactComponent as IconHouse } from './assets/icon_house.svg';
import { ReactComponent as IconRetire } from './assets/icon_retire.svg';
import { ReactComponent as IconAsset } from './assets/icon_asset.svg';
import { ReactComponent as Check } from './assets/check.svg';

// カードコンポーネント
type CardProps = {
  className?: string;
  icon: React.ReactNode;
  text: React.ReactNode;
  isGreen?: boolean;
  isSaved?: boolean;
}
const Card: React.FC<CardProps> = ({
  className = '', icon, text, isGreen = false, isSaved = false, children,
}) => (
  <div className={`card ${className}`}>
    <div className="flex items-center">
      <div className={`${isGreen ? 'text-green' : 'text-primary'} w-20 h-20 flex-shrink-0 relative`}>
        <div className={`${isGreen ? 'bg-green-50' : 'bg-primary-50'} w-full h-full rounded-full flex items-center justify-center`}>
          {icon}
        </div>
        {isSaved && (
          <>
            <div className="absolute left-0 top-0 w-5 h-5">
              <Check />
            </div>
            <div className="absolute inset-x-0 bottom-0 w-full -mb-1 text-center">
              <p className="text-[0.625rem] font-bold leading-none">保存済み</p>
            </div>
          </>
        )}
      </div>
      <div className={`pl-5 text-sm ${isGreen ? 'text-gray-500' : 'text-primary'}`}>
        {text}
      </div>
    </div>
    <div className="px-8 mt-5">
      {children}
    </div>
  </div>
);
Card.defaultProps = {
  className: undefined,
  isGreen: undefined,
  isSaved: undefined,
};

// リンクコンポーネント
type NextStepsLinkProps = {
  to: LinkProps['to'];
  disabled?: boolean;
}
const NextStepsLink: React.FC<NextStepsLinkProps> = ({
  to, disabled, children,
}) => (
  <div>
    {disabled
      ? <p className="btn-link">{children}</p>
      : (
        <Link to={to} className="btn-link">
          {children}
        </Link>
      ) }
  </div>
);
NextStepsLink.defaultProps = {
  disabled: undefined,
};

// 吹き出し
const Tooltip = styled.div`
  position: relative;
  text-align: center;
  font-weight: 700;
  color: ${theme`colors.white`};
  font-size: ${theme`fontSize.base`};
  padding: ${theme`padding.3`} 0;
`;
const TooltipArrow = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: -15px;
  margin-left: -8.5px;
  border-left: 8.5px solid transparent;
  border-right: 8.5px solid transparent;
  border-top-width: 16px;
  border-top-style: solid;
`;

const NextSteps: React.FC = () => {
  const nextSteps = useAppSelector(selectNextStep);
  const isAdviceOpened = useAppSelector(selectIsAdviceOpened);

  // 保存済みかどうか判定
  const isSaved = (...contentIds: string[]) => contentIds
    .every((contentId) => {
      const nextStep = nextSteps.find((step) => step.contentId === contentId);
      if (nextStep !== undefined) {
        return (nextStep.progress === 'SAVED' || nextStep.progress === 'ADVICE_SAVED');
      }
      return false;
    });

  return (
    <>
      <Tooltip className="bg-rose mb-8">
        次はこれを入力してみよう！
        <TooltipArrow className="border-rose" />
      </Tooltip>
      <div className="space-y-5">
        <Card
          icon={<IconBasics className="w-10 h-10" />}
          text={
            <p>正確な結果を得るために「基本情報」を入力してみましょう！</p>
          }
          isSaved={isSaved('family', 'incomings', 'livingCosts')}
        >
          <NextStepsLink to={{ pathname: '/home/basics/family', hash: '#content' }}>
            基本情報を編集する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconEducation className="w-10 h-10" />}
          text={(
            <p>
              小学校〜大学までの教育費を
              <br />
              イメージできてますか？
            </p>
          )}
          isSaved={isSaved('education')}
        >
          <NextStepsLink to={{ pathname: '/home/education', hash: '#content' }}>
            教育費用を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconHouse className="w-10 h-10" />}
          text={
            <p>いま現在住んでいる居住形態を教えてください。</p>
          }
          isSaved={isSaved('present', 'plan')}
        >
          <NextStepsLink to={{ pathname: '/home/house/present', hash: '#content' }}>
            住宅費用を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconRetire className="w-11 h-11" />}
          text={
            <p>老後の貯蓄額のバランスを見て退職金年金の受け取り方を検討しましょう。</p>
          }
          isSaved={isSaved('pension', 'retirement')}
        >
          <NextStepsLink to={{ pathname: '/home/retire/pension', hash: '#content' }}>
            退職・老後を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconAsset className="w-[46px] h-[46px]" />}
          text={
            <p>企業型確定拠出年金 (DC)・iDeCo・つみたてNISA(～2023年)などの運用をされている場合は入力してみましょう！</p>
          }
          isSaved={isSaved('dc', 'ideco', 'trust')}
        >
          <NextStepsLink to={{ pathname: '/home/asset/depositFinAssets', hash: '#content' }}>
            資産形成を入力する
          </NextStepsLink>
        </Card>
      </div>
      {!isAdviceOpened
      && (
        <p className="text-sm text-primary text-center mt-5 font-bold">
          他の画面の入力を進めることで、
          <br />
          こちらも押せるようになります。
        </p>
      )}
      <Tooltip className="bg-green mb-8 mt-5">
        改善！
        <TooltipArrow className="border-green" />
      </Tooltip>
      <div className="space-y-5">
        <Card
          icon={<IconHouse className="w-10 h-10" />}
          isGreen
          text={
            <p>住宅ローンを早く返済することも資産を増やすテクニックです。</p>
          }
          isSaved={isSaved('prepayment')}
        >
          <NextStepsLink to={{ pathname: '/home/house/prepayment', hash: '#content' }} disabled={!isAdviceOpened}>
            繰り上げ返済を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconAsset className="w-[46px] h-[46px]" />}
          isGreen
          text={(
            <p>
              20年におよぶ非課税期間、投資未経験者でもわかりやすい商品が揃っています。
              <br />
              ※つみたてNISAは、2023年末で新規買付は停止になりました。
            </p>
          )}
          isSaved={isSaved('nisa')}
        >
          <NextStepsLink to={{ pathname: '/home/asset/nisa', hash: '#content' }} disabled={!isAdviceOpened}>
            つみたてNISA(～2023年)を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconRetire className="w-[46px] h-[46px]" />}
          isGreen
          text={
            <p>生活費の蓄えに加え、老後の生きがいとしての再就職が増えています。</p>
          }
          isSaved={isSaved('reEmployment')}
        >
          <NextStepsLink to={{ pathname: '/home/retire/reEmployment', hash: '#content' }} disabled={!isAdviceOpened}>
            再雇用・再就職を入力する
          </NextStepsLink>
        </Card>
        <Card
          icon={<IconBasics className="w-[46px] h-[46px]" />}
          isGreen
          text={
            <p>毎月小さな削減でも長い目で見ると大きく変わってきます。</p>
          }
          isSaved={isSaved('livingCostsReduce')}
        >
          <NextStepsLink to={{ pathname: '/home/basics/livingCostsReduce', hash: '#content' }} disabled={!isAdviceOpened}>
            生活費削減を入力する
          </NextStepsLink>
        </Card>
      </div>
    </>
  );
};
export default NextSteps;
