import * as React from 'react';
import ToggleButton from '../button/ToggleButton';
import HelpButton from '../button/HelpButton';

type TableGroupProps = {
  className?: string;
  label?: string;
  help?: React.ReactNode;
};

/**
 * テーブルグループコンポーネント
 * @param props
 * @returns
 */
const TableGroup: React.FC<TableGroupProps> = ({
  className, label, help, children,
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <div className={`${className}`}>
      <div className="flex items-center justify-between mb-5">
        <div className="form-label mb-0">
          <span>{label}</span>
          {help !== undefined && <HelpButton>{help}</HelpButton>}
        </div>
        <ToggleButton open={show} onClick={() => setShow(!show)} />
      </div>
      {show && children}
    </div>
  );
};
TableGroup.defaultProps = {
  className: '',
  label: '',
  help: undefined,
};
export default TableGroup;
