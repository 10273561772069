import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import {
  selectHasSpouse,
  selectAssetNisaHouseholder, selectAssetNisaSpouse,
  setAssetNisaHouseholder, setAssetNisaSpouse,
  getAssetFormationItem,
} from '../../reducers/inputReducer';
import type { AssetFormationDataItem } from '../../reducers/inputReducer';
import { selectRiskNisa } from '../../reducers/riskReducer';

// images
import imgHero from './assets/hero.svg';

// components
import Append from '../../features/button/Append';
import Fieldset from './Fieldset';
import FieldsetSpouse from './FieldsetSpouse';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeAssetNisa: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouse);

  // あなたの運用状況
  const defaultHouseholder = useAppSelector(selectAssetNisaHouseholder);
  const [householder, setHouseholder] = React.useState(defaultHouseholder);

  // 配偶者の確定拠出年金
  const defaultSpouse = useAppSelector(selectAssetNisaSpouse);
  const [spouse, setSpouse] = React.useState(defaultSpouse);

  // リスクデータ
  const riskDatasHouseholder = useAppSelector(selectRiskNisa);
  const riskDatasSpouse = useAppSelector(selectRiskNisa);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setHouseholder(defaultHouseholder);
      setSpouse(defaultSpouse);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>つみたてNISA(～2023年)</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">つみたてNISAは2023年12月末で新規買付が停止になりました。</p>
        <p className="text-sm mt-2 mb-6">
          シミュレーションでは、参考として20年間つみたてNISAで買付・運用した場合のシミュレーションが掲載されますので、ご留意ください。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">あなたの運用状況</h3>
            <Fieldset
              data={householder}
              onChange={(data) => setHouseholder(data)}
            />
          </section>
          <div>
            <div className="py-8 border-primary border-t">
              <Append
                label={showSpouse ? '詳細を閉じる（配偶者）' : '配偶者のつみたてNISA(～2023年)を入力する'}
                onClick={() => setShowSpouse(!showSpouse)}
                minus={showSpouse}
                disabled={!hasSpouse}
              />
            </div>
          </div>
          {showSpouse && (
            <section>
              <h3 className="fieldset-title">配偶者の運用状況</h3>
              <FieldsetSpouse
                prefix="spouse"
                data={spouse}
                onChange={(data) => setSpouse(data)}
              />
            </section>
          ) }
        </div>
        <FixedButton
          disabled={deepEqual(defaultHouseholder, householder) && deepEqual(defaultSpouse, spouse)}
          contentId="nisa"
          onClick={() => {
            dispatch(setAssetNisaHouseholder(getAssetFormationItem(householder, {
              riskDatas: riskDatasHouseholder,
            }) as AssetFormationDataItem));
            dispatch(setAssetNisaSpouse(getAssetFormationItem(spouse, {
              riskDatas: riskDatasSpouse,
            }) as AssetFormationDataItem));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeAssetNisa;
