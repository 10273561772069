import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'twin.macro';
import { Transition } from '@headlessui/react';

// ヘッダーメニュー情報
import { subMenus } from '../../config/navdatas';
import type { NavData, NavItem } from '../../config/navdatas';

// ヘッダー情報、ダイアログ制御
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  setTitle,
  closeHeaderMenu, toggleHeaderMenu,
  closeSubMenu, toggleSubMenu,
  selectTitle, selectShowHeaderMenu, selectShowSubMenu,
} from '../../reducers/headerReducer';
import { selectIsLogined } from '../../reducers/userReducer';

// images
import logo01 from './assets/mizuho_logo01.svg';
import logo02 from './assets/mizuho_logo02.svg';

// components
import ToggleButton from '../button/ToggleButton';
import GlobalHeaderMenu from './GlobalHeaderMenu';
import GlobalSubMenu from './GlobalSubMenu';

// components
const MenuIcon = styled.div<{close: boolean}>`
  position: relative;
  width: 1.4375rem;
  height: 1.4375rem;
  & > span {
    position: absolute;
    display: block;
    height: 0.125rem;
    background-color: ${theme`colors.primary`};
    border-radius: 9999px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0. 0.2, 1);
    transition-duration: 150ms;
    &:nth-child(1) {
      top: ${({ close }) => (close ? '0.625rem' : '0.125rem')};
      width: ${({ close }) => (close ? '1.5625rem' : '1.4375rem')};
      transform: ${({ close }) => (close ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
    &:nth-child(2) {
      top: 0.625rem;
      width: 1.4375rem;
      display: ${({ close }) => (close ? 'none' : 'block')};
    }
    &:nth-child(3) {
      top: ${({ close }) => (close ? '0.625rem' : '1.125rem')};
      width: ${({ close }) => (close ? '1.5625rem' : '1.4375rem')};
      transform: ${({ close }) => (close ? 'rotate(-45deg)' : 'rotate(0deg)')};
    }
  }
`;

const GlobalHeader: React.FC = () => {
  const title = useAppSelector(selectTitle);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  // ログイン状態かどうか
  const isLogined = useAppSelector(selectIsLogined);

  // メニュー開閉フラグ
  const showHeaderMenu = useAppSelector(selectShowHeaderMenu);
  const showSubMenu = useAppSelector(selectShowSubMenu);

  // 現在のナビゲーション情報
  const [data, setData] = React.useState<NavData>();
  const [items, setItems] = React.useState<NavItem[]>();

  React.useEffect(() => {
    // ページが変更されたらメニューを閉じる
    dispatch(closeHeaderMenu());
    dispatch(closeSubMenu());

    // サブメニュー情報を更新する
    let navData = subMenus.find((dat) => dat.to === pathname);
    if (navData === undefined) {
      navData = subMenus.find((dat) => dat.items?.find((item) => item.to === pathname));
    }
    setData(navData);
    setItems(navData?.items);

    // タイトル名を更新
    if (navData !== undefined) {
      const navDataItem = navData.items?.find((item) => item.to === pathname) as NavItem || navData;
      dispatch(setTitle(navDataItem.title === false ? null : navDataItem.name));
    } else {
      dispatch(setTitle(null));
    }
  }, [pathname]);

  return (
    <>
      <header className="fixed z-30 top-0 inset-x-0 bg-white">
        <div className="flex justify-between items-center container h-header-title">
          <div className="w-[110px] pl-[10px]">
            <img src={logo02} alt="MIZUHO" width="87" height="24" />
          </div>
          <div className="w-[86px] mx-auto">
            <img src={logo01} alt="みずほ銀行" width="86" height="16" />
          </div>
          <div className="w-[110px]">
            {isLogined && data !== undefined && (
              <button
                type="button"
                className="h-6 block ml-auto pr-[10px]"
                onClick={() => {
                  // ヘッダーメニューを開閉する
                  dispatch(toggleHeaderMenu(!showHeaderMenu));
                  dispatch(closeSubMenu());
                }}
              >
                <MenuIcon close={showHeaderMenu}>
                  <span />
                  <span />
                  <span />
                </MenuIcon>
              </button>
            )}
          </div>
        </div>
        {title && (
          <div className="bg-primary container flex justify-between items-center h-header-subtitle">
            <div className="w-8" />
            {!showSubMenu && <h1 className="text-white">{ title }</h1>}
            <div className="w-8">
              {items !== undefined && (
                <ToggleButton
                  open={showSubMenu}
                  color="bg-white"
                  onClick={() => dispatch(toggleSubMenu(!showSubMenu))}
                />
              )}
            </div>
          </div>
        ) }
      </header>
      <Transition
        show={showHeaderMenu}
        as="div"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed z-50 inset-x-0 bottom-0 top-header-title"
      >
        <GlobalHeaderMenu />
      </Transition>
      {items !== undefined && (
        <Transition
          show={showSubMenu}
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed z-50 inset-x-0 bottom-0 top-header-subtitle"
        >
          <GlobalSubMenu items={items} />
        </Transition>
      )}
    </>
  );
};
export default GlobalHeader;
