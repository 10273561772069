import * as React from 'react';
import { Link } from 'react-router-dom';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectBirthdate } from '../../reducers/userReducer';
import {
  selectHasSpouse,
  selectPlanToBuyHouse,
  selectHasMortgageLoan,
  setBirthDate,
  setPlanToBuyHouse as setDispatchPlanToBuyHouse,
} from '../../reducers/inputReducer';
import type { WhoWillHaveMortgageLoan } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// validation
import { isPeriodOfFixedRateCheck } from '../../services/validation';

// components
import InputInteger from '../../features/input/InputInteger';
import InputDecimal from '../../features/input/InputDecimal';
import InputRadioGroup, { InputRadioOption } from '../../features/input/InputRadioGroup';
import HelpButton from '../../features/button/HelpButton';
import Remarks from '../../features/block/Remarks';
import Amount from '../../features/block/Amount';
import ToggleDetail from '../../features/block/ToggleDetail';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

// 金利計算方式オプション
const interestCalcMethodOptions: InputRadioOption[] = [
  { value: 'FLOATING', label: '変動金利' },
  { value: 'FIXED', label: '固定金利' },
  { value: 'ALL_PERIOD_FIXED', label: '全期間固定金利' },
];

// 返済方法オプション
const repaymentMethodOptions: InputRadioOption[] = [
  { value: 'EQUAL_INTEREST', label: '元利均等' },
  { value: 'EQUAL_PRINCIPAL', label: '元金均等' },
];

const HomeHousePlan: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouse);
  const birthdate = useAppSelector(selectBirthdate);
  const defaultPlanToBuyHouse = useAppSelector(selectPlanToBuyHouse);

  // 現在の住宅ローンがあるかどうか
  const hasMortgageLoan = useAppSelector(selectHasMortgageLoan);

  // 入力データ
  const [planToBuyHouse, setPlanToBuyHouse] = React.useState(defaultPlanToBuyHouse);

  // 住宅購入予定
  const [willBuyHouse, setWillBuyHouse] = React.useState(defaultPlanToBuyHouse.willBuyHouse);

  // 購入年齢 (あなた)
  const [
    ageOfPlanToBuyHouseHouseholder, setAgeOfPlanToBuyHouseHouseholder,
  ] = React.useState(defaultPlanToBuyHouse.ageOfPlanToBuyHouseHouseholder);

  // 購入金額 (自己負担分)
  const [
    amountOfPlanToBuyHouse, setAmountOfPlanToBuyHouse,
  ] = React.useState(defaultPlanToBuyHouse.amountOfPlanToBuyHouse);

  // 住宅ローン
  const [
    willHaveMortgageLoan, setWillHaveMortgageLoan,
  ] = React.useState(defaultPlanToBuyHouse.willHaveMortgageLoan);

  // ローンを組む人
  const [
    whoWillHaveMortgageLoan, setWhoWillHaveMortgageLoan,
  ] = React.useState(defaultPlanToBuyHouse.whoWillHaveMortgageLoan);

  // ローンを組む人オプション
  const mortgageLoanOptions = (() => {
    const options = [
      { value: 'YOU', label: 'あなた' },
    ];
    if (hasSpouse) {
      options.push({ value: 'SPOUSE', label: '配偶者' });
      options.push({ value: 'YOU_SPOUSE', label: 'あなた＋配偶者' });
    }
    return options;
  })();

  // ローン頭金
  const [downPayment, setDownPayment] = React.useState(defaultPlanToBuyHouse.downPayment);

  // 借入期間
  const [loanPeriod, setLoanPeriod] = React.useState(defaultPlanToBuyHouse.loanPeriod);

  // 金利計算方式
  const [
    interestCalculationMethod, setInterestCalculationMethod,
  ] = React.useState(defaultPlanToBuyHouse.interestCalculationMethod);

  // 金利
  const [loanRate, setLoanRate] = React.useState(defaultPlanToBuyHouse.loanRate);

  // 固定期間
  const [
    periodOfFixedRate, setPeriodOfFixedRate,
  ] = React.useState(defaultPlanToBuyHouse.periodOfFixedRate);

  // 返済方法
  const [
    repaymentMethod, setRepaymentMethod,
  ] = React.useState(defaultPlanToBuyHouse.repaymentMethod);

  // データ更新
  React.useEffect(() => {
    setPlanToBuyHouse((data) => {
      return {
        ...data,
        willBuyHouse,
        ageOfPlanToBuyHouseHouseholder,
        amountOfPlanToBuyHouse,
        willHaveMortgageLoan,
        whoWillHaveMortgageLoan,
        downPayment,
        loanPeriod,
        interestCalculationMethod,
        loanRate,
        periodOfFixedRate,
        repaymentMethod,
      };
    });
  }, [
    willBuyHouse,
    ageOfPlanToBuyHouseHouseholder,
    amountOfPlanToBuyHouse,
    willHaveMortgageLoan,
    whoWillHaveMortgageLoan,
    downPayment,
    loanPeriod,
    interestCalculationMethod,
    loanRate,
    periodOfFixedRate,
    repaymentMethod,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setPlanToBuyHouse(defaultPlanToBuyHouse);

      // 住宅購入予定を切り替えたとき値をリセットする
      setWillHaveMortgageLoan(defaultPlanToBuyHouse.willHaveMortgageLoan);
      setInterestCalculationMethod(defaultPlanToBuyHouse.interestCalculationMethod);
      setAgeOfPlanToBuyHouseHouseholder(defaultPlanToBuyHouse.ageOfPlanToBuyHouseHouseholder);
      setAmountOfPlanToBuyHouse(defaultPlanToBuyHouse.amountOfPlanToBuyHouse);
      setPeriodOfFixedRate(defaultPlanToBuyHouse.periodOfFixedRate);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>住宅購入予定</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">人生の3大支出の1つとも言われる住宅購入。計画的に検討していきましょう。</p>
        <p className="text-sm mt-2 mb-6">
          住宅ローンを組む場合、あなただけではなく配偶者もしくはあなた＋配偶者というパターンでもシミュレーションをすることができます。
          購入年齢時点での収入や支出のバランスを考えながらローンシミュレーションを検討していきましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">住宅購入</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>住宅購入予定</span>
              </div>
              <InputRadioGroup
                name="willBuyHouse"
                options={[
                  { value: 0, label: 'なし' },
                  { value: 1, label: 'あり' },
                ]}
                defaultValue={willBuyHouse ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setWillBuyHouse(parseInt(value, 10) === 1);
                }}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>購入年齢 (あなた)</span>
              </div>
              <InputInteger
                name="ageOfPlanToBuyHouseHouseholder"
                className="w-[120px]"
                unit="歳"
                defaultValue={defaultPlanToBuyHouse.ageOfPlanToBuyHouseHouseholder}
                maxlength={2}
                disabled={!willBuyHouse}
                onChange={(value) => setAgeOfPlanToBuyHouseHouseholder(value)}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>購入金額 (自己負担分)</span>
                <HelpButton>
                  住宅購入にあたり、ご両親等から援助等を受ける見込がある方は、当該金額を除いた自己負担金額をご入力ください。
                </HelpButton>
              </div>
              <InputInteger
                name="amountOfPlanToBuyHouse"
                className="w-[120px]"
                unit="万円"
                division={4}
                defaultValue={defaultPlanToBuyHouse.amountOfPlanToBuyHouse}
                disabled={!willBuyHouse}
                onChange={(value) => setAmountOfPlanToBuyHouse(value)}
              />
            </div>
          </section>
          <section>
            <h3 className="fieldset-title">購入予定の住宅ローン</h3>
            <div className="fieldset-body">
              <Remarks className="mb-6">
                <p>現在の住宅の画面で住宅ローンがある場合は、購入予定の住宅ローンは入力できません。</p>
                <Link to="/home/house/present" className="arrow-link mt-5">現在の住宅</Link>
              </Remarks>
              <div className="form-label">
                <span>住宅ローン</span>
                <HelpButton>
                  ｢現在の住宅ローン｣を入力している場合は、購入予定の住宅ローンは入力できません。
                </HelpButton>
              </div>
              <InputRadioGroup
                name="willHaveMortgageLoan"
                options={[
                  { value: 0, label: '組まない' },
                  { value: 1, label: '組む' },
                ]}
                defaultValue={willHaveMortgageLoan ? 1 : 0}
                disabled={!(willBuyHouse && !hasMortgageLoan)}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setWillHaveMortgageLoan(parseInt(value, 10) === 1);
                }}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>ローンを組む人</span>
              </div>
              <InputRadioGroup
                name="whoWillHaveMortgageLoan"
                options={mortgageLoanOptions}
                defaultValue={whoWillHaveMortgageLoan}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setWhoWillHaveMortgageLoan(value as WhoWillHaveMortgageLoan);
                }}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>ローン頭金</span>
                <HelpButton>
                  購入見込年齢時の貯蓄予想額(上記出力グラフご参照)の範囲内でご入力ください。
                </HelpButton>
              </div>
              <InputInteger
                name="downPayment"
                className="w-[120px]"
                unit="万円"
                division={4}
                defaultValue={defaultPlanToBuyHouse.downPayment}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                onChange={(value) => setDownPayment(value)}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>借入額</span>
              </div>
              <Amount
                value={(() => {
                  if (whoWillHaveMortgageLoan === 'YOU' || whoWillHaveMortgageLoan === 'SPOUSE') {
                    return (amountOfPlanToBuyHouse ?? 0) - (planToBuyHouse.downPayment ?? 0);
                  }
                  if (whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
                    return ((amountOfPlanToBuyHouse ?? 0) - (planToBuyHouse.downPayment ?? 0)) / 2;
                  }
                  return 0;
                })()}
                division={4}
                digits={1}
                disabled={!(willHaveMortgageLoan && willBuyHouse)}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>借入期間</span>
              </div>
              <InputInteger
                name="loanPeriod"
                className="w-[120px]"
                unit="年"
                defaultValue={defaultPlanToBuyHouse.loanPeriod}
                maxlength={2}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                onChange={(value) => setLoanPeriod(value)}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>金利計算方式</span>
                <HelpButton>
                  ■変動金利：金利が変動する金利タイプ
                  <br />
                  ■固定金利：一定期間は固定の金利、その期間がすぎると変動する金利タイプ
                  <br />
                  ■全期間固定金利：全期間、金利が固定する金利タイプ(フラット35など)
                </HelpButton>
              </div>
              <InputRadioGroup
                name="interestCalculationMethod"
                options={interestCalcMethodOptions}
                defaultValue={interestCalculationMethod}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setInterestCalculationMethod(value);
                }}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>金利</span>
              </div>
              <InputDecimal
                name="loanRate"
                className="w-[150px]"
                unit="%"
                defaultValue={loanRate}
                size={3}
                digits={5}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                onChange={(value) => setLoanRate(value)}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>固定期間</span>
              </div>
              <InputInteger
                name="periodOfFixedRate"
                className="w-[120px]"
                unit="年"
                defaultValue={defaultPlanToBuyHouse.periodOfFixedRate}
                maxlength={2}
                disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan && interestCalculationMethod === 'FIXED')}
                onChange={(value) => setPeriodOfFixedRate(value)}
              />
            </div>
            <div className="fieldset-body">
              <ToggleDetail>
                <div className="fieldset-body">
                  <div className="form-label">
                    <span>返済方法</span>
                    <HelpButton>
                      ■元利均等返済：毎月のご返済額(元金と利息の合計)が一定となる返済方法です。
                      <br />
                      ■元金均等返済：毎月のご返済元金が一定となる返済方法です。毎月のご返済額はこれに利息を加えたものとなります。
                    </HelpButton>
                  </div>
                  <InputRadioGroup
                    name="repaymentMethod"
                    options={repaymentMethodOptions}
                    defaultValue={repaymentMethod}
                    disabled={!(willBuyHouse && !hasMortgageLoan && willHaveMortgageLoan)}
                    onChange={(event) => {
                      const { value } = event.currentTarget;
                      setRepaymentMethod(value);
                    }}
                  />
                </div>
              </ToggleDetail>
            </div>
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultPlanToBuyHouse, planToBuyHouse)}
          contentId="plan"
          onClick={() => {
            dispatch(setBirthDate(birthdate));
            dispatch(setDispatchPlanToBuyHouse(planToBuyHouse));
          }}
          validation={isPeriodOfFixedRateCheck(null, planToBuyHouse, 'plan')}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeHousePlan;
