import React from 'react';
import { theme } from 'twin.macro';
import { LabelProps } from '../props';

type XAxisLabelProps = {
  dy?: number;
  fill?: string;
  fontSize?: string;
} & LabelProps;
const XAxisLabel: React.FC<XAxisLabelProps> = ({
  viewBox = {
    x: 0, y: 0, width: 100, height: 100,
  },
  value,
  dy = 30,
  fill = theme('colors.gray-500'),
  fontSize = '10px',
}) => {
  let { x } = viewBox;
  if (x !== undefined && viewBox.width) {
    x += viewBox.width / 2;
  }
  return (
    <g>
      <text
        x={x}
        y={viewBox.y}
        textAnchor="middle"
        fill={fill}
        dy={dy}
        style={{ fontFamily: theme('fontFamily.alpha'), fontSize }}
      >
        {value}
      </text>
    </g>
  );
};
XAxisLabel.defaultProps = {
  dy: undefined,
  fill: undefined,
  fontSize: undefined,
};

export default XAxisLabel;
