import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// components
const Checkbox = styled.input`
  display: none;
`;
const CheckboxIcon = styled.span`
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  border-style: solid;
  border-width: 1px;
  border-radius: 2.5px;
  background-color: white;
  flex-shrink: 0;
  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 70%;
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-color: ${theme`colors.primary`};
    transform: translate(76%, 0) rotate(45deg);
  }
  ${Checkbox}:checked + &:before {
    display: block;
  }
`;

/**
 * チェックボックス項目
 * @param props
 * @returns
 */
type InputCheckboxProps = {
  className?: string;
  defaultChecked?: boolean|null;
  onChange?: (_: boolean) => void;
};
const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const {
    className, defaultChecked, children,
    onChange,
  } = props;

  return (
    <label htmlFor="inputRequestForEmailNewsLetter" className={`inline-flex items-start ${className}`}>
      <Checkbox
        type="checkbox"
        id="inputRequestForEmailNewsLetter"
        defaultChecked={defaultChecked ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = event.currentTarget;
          if (onChange !== undefined) {
            onChange(checked);
          }
        }}
      />
      <CheckboxIcon className="border-gray-200" />
      <span className="ml-2 text-sm">{children}</span>
    </label>
  );
};
InputCheckbox.defaultProps = {
  className: '',
  defaultChecked: false,
  onChange: undefined,
};

export default InputCheckbox;
