import * as React from 'react';

export type ChartLegendOption = {
  color: string,
  label: string,
  type?: 'Bar'|'Line',
};

type ChartLegendProps = {
  className?: string,
  options: ChartLegendOption[]
}
const ChartLegend: React.FC<ChartLegendProps> = ({ className, options }) => (
  <div className={`flex items-center justify-center space-x-4 ${className}`}>
    {options.map((option) => (
      <div className="flex items-center" key={option.color}>
        <span className={`mr-1.5 ${option.type === 'Line' ? 'h-0.5 w-5' : 'h-2.5 w-2.5'} ${option.color}`} />
        <span className="text-xs text-gray-500">{option.label}</span>
      </div>
    ))}
  </div>
);
ChartLegend.defaultProps = {
  className: '',
};
export default ChartLegend;
