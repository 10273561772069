import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Routes, Route, Navigate, Outlet, useLocation,
} from 'react-router-dom';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

// 認証情報、ヘッダー情報、ユーザ情報
import { useAppSelector, useAppDispatch } from './app/hooks';
import { selectAuthCode } from './reducers/authReducer';
import { selectHeaderHeight } from './reducers/headerReducer';
import { selectNickname, selectLifeStyle, selectIsGuest } from './reducers/userReducer';
import { reset } from './reducers/inputReducer';
import { resetErrorMessages } from './reducers/errorReducer';
import { resetFaileds } from './reducers/validateReducer';

// scenes
import Top from './scenes/Top';
import Auth from './scenes/Auth';
import Guest from './scenes/Guest';
import Help from './scenes/Help';
import HelpOverview from './scenes/Help/HelpOverview';
import HelpLogin from './scenes/Help/HelpLogin';
import HelpIdeco from './scenes/Help/HelpIdeco';
import HelpPersonalInfo from './scenes/Help/HelpPersonalInfo';
import HelpMail from './scenes/Help/HelpMail';
import HelpQuit from './scenes/Help/HelpQuit';
import HelpContact from './scenes/Help/HelpContact';
import Terms from './scenes/Terms';
import Home from './scenes/Home';
import HomeAdvice from './scenes/HomeAdvice';
import HomeBasicsFamily from './scenes/HomeBasicsFamily';
import HomeBasicsIncomings from './scenes/HomeBasicsIncomings';
import HomeBasicsLivingCosts from './scenes/HomeBasicsLivingCosts';
import HomeBasicsLivingCostsReduce from './scenes/HomeBasicsLivingCostsReduce';
import HomeEducation from './scenes/HomeEducation';
import HomeHousePresent from './scenes/HomeHousePresent';
import HomeHousePlan from './scenes/HomeHousePlan';
import HomeHousePrepayment from './scenes/HomeHousePrepayment';
import HomeRetirePension from './scenes/HomeRetirePension';
import HomeRetireRetirement from './scenes/HomeRetireRetirement';
import HomeRetireReEmployment from './scenes/HomeRetireReEmployment';
import HomeAssetDepositFinAssets from './scenes/HomeAssetDepositFinAssets';
import HomeAssetDc from './scenes/HomeAssetDc';
import HomeAssetIdeco from './scenes/HomeAssetIdeco';
import HomeAssetNisa from './scenes/HomeAssetNisa';
import HomeAssetTrust from './scenes/HomeAssetTrust';
import HomeAssetOtherInvestments from './scenes/HomeAssetOtherInvestments';
import ConfigScene from './scenes/ConfigScene';
import InitialSetting from './scenes/InitialSetting';
import Registration from './scenes/Registration';

// components
import GlobalHeader from './features/GlobalHeader';
import GlobalFooter from './features/GlobalFooter';
import Logout from './scenes/Logout';

// main
const Main: React.FC = (props) => {
  const { children } = props;

  // ヘッダーの高さを取得
  const height = useAppSelector(selectHeaderHeight);
  const padding = height > 70 ? 'pt-header-subtitle' : 'pt-header-title';

  return (
    <main className={`bg-gray-100 text-base ${padding}`}>
      {children}
    </main>
  );
};

const Location: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    // 画面遷移するたびにスクロール位置をリセットする
    window.scrollTo(0, 0);

    // 画面遷移ごとにサーバーエラーメッセージをリセットする
    dispatch(resetErrorMessages());
    dispatch(resetFaileds());

    // 画面遷移時に一時データをリセットする
    dispatch(reset());
  }, [pathname]);
  return null;
};

// ログイン画面からの認証が必要なページ
const AuthRoute: React.FC = () => {
  // 認証コードが未定の場合はtopへ戻す
  const authCode = useAppSelector(selectAuthCode);
  return authCode !== undefined ? <Outlet /> : <Navigate to="/top" replace />;
};

// ホーム画面の画面遷移
const HomeRoute: React.FC = () => {
  // 状態によって適切な画面へ遷移させるためにユーザ情報を取得
  const nickname = useAppSelector(selectNickname);
  const lifeStyle = useAppSelector(selectLifeStyle);
  const guest = useAppSelector(selectIsGuest);

  // ユーザアカウントが無い時はアカウント設定画面へ
  if (!(nickname !== undefined && nickname !== null)) {
    return guest ? <Navigate to="/registration/guest" replace /> : <Navigate to="/registration" replace />;
  }

  // 初期設定未済み時は初期設定画面へ
  if (!(lifeStyle !== undefined && lifeStyle !== null)) {
    return <Navigate to="/initialSetting" replace />;
  }

  return <Home />;
};

const RouterConfig:React.FC = () => {
  // ゲストかどうかで状況を判別するために、ユーザ情報を取得
  const guest = useAppSelector(selectIsGuest);
  return (
    <BrowserRouter>
      <GlobalHeader />
      <Main>
        <Routes>
          <Route index element={<Top />} />
          <Route path="top" element={<Top />} />
          <Route path="auth" element={<Auth />} />
          <Route path="guest" element={<Guest />} />
          <Route path="help" element={<Help />}>
            <Route path="overview" element={<HelpOverview />} />
            <Route path="login" element={<HelpLogin />} />
            <Route path="ideco" element={<HelpIdeco />} />
            <Route path="personalInfo" element={<HelpPersonalInfo />} />
            <Route path="mail" element={<HelpMail />} />
            <Route path="quit" element={<HelpQuit />} />
            <Route path="contact" element={<HelpContact />} />
            <Route index element={<Navigate to="overview" replace />} />
          </Route>
          <Route path="terms" element={<Terms />} />
          <Route element={<AuthRoute />}>
            <Route path="home" element={<HomeRoute />}>
              {!guest && <Route path="advice" element={<HomeAdvice />} />}
              <Route path="basics">
                <Route path="family" element={<HomeBasicsFamily />} />
                <Route path="incomings" element={<HomeBasicsIncomings />} />
                <Route path="livingCosts" element={<HomeBasicsLivingCosts />} />
                {!guest && <Route path="livingCostsReduce" element={<HomeBasicsLivingCostsReduce />} />}
                <Route index element={<Navigate to="family" replace />} />
              </Route>
              {!guest && <Route path="education" element={<HomeEducation />} />}
              {!guest && (
                <Route path="house">
                  <Route path="present" element={<HomeHousePresent />} />
                  <Route path="plan" element={<HomeHousePlan />} />
                  <Route path="prepayment" element={<HomeHousePrepayment />} />
                  <Route index element={<Navigate to="present" replace />} />
                </Route>
              )}
              {!guest && (
                <Route path="retire">
                  <Route path="pension" element={<HomeRetirePension />} />
                  <Route path="retirement" element={<HomeRetireRetirement />} />
                  <Route path="reEmployment" element={<HomeRetireReEmployment />} />
                  <Route index element={<Navigate to="pension" replace />} />
                </Route>
              )}
              <Route path="asset">
                <Route path="depositFinAssets" element={<HomeAssetDepositFinAssets />} />
                <Route path="dc" element={<HomeAssetDc />} />
                {!guest && (
                  <>
                    <Route path="ideco" element={<HomeAssetIdeco />} />
                    <Route path="nisa" element={<HomeAssetNisa />} />
                    <Route path="trust" element={<HomeAssetTrust />} />
                    <Route path="otherInvestments" element={<HomeAssetOtherInvestments />} />
                  </>
                )}
                <Route index element={<Navigate to="depositFinAssets" replace />} />
              </Route>
              <Route index element={<Navigate to={guest ? 'basics' : 'advice'} replace />} />
            </Route>
            {!guest && <Route path="config" element={<ConfigScene />} />}
            <Route path="initialSetting" element={<InitialSetting />} />
            <Route path="registration" element={<Registration />} />
            <Route
              path="registration/guest"
              element={<Registration guest />}
            />
            {!guest && <Route path="logout" element={<Logout />} />}
          </Route>
          <Route path="/*" element={<Navigate to="/top" replace />} />
        </Routes>
      </Main>
      <GlobalFooter />
      <Location />
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterConfig />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
