import * as React from 'react';
import { Link } from 'react-router-dom';

// 預金・収入要素の型
import type { IncomeData } from '../../reducers/inputReducer';

// config
import { MINIMUM_ANNUAL_INCOME, MAXIMUM_ANNUAL_INCOME } from '../../config/validation';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputInteger from '../../features/input/InputInteger';
import InputSelect, { InputSelectOption } from '../../features/input/InputSelect';
import HelpButton from '../../features/button/HelpButton';
import ToggleDetail from '../../features/block/ToggleDetail';
import Remarks from '../../features/block/Remarks';

// 業種選択項目
const industryTypeOptions: InputSelectOption[] = [
  { value: 'INDUSTRIAL_TOTAL', label: '産業計' },
  {
    value: 'MINING_QUARRYING_GRAVEL_COLLECTION',
    label: '鉱業，採石業，砂利採取業',
  },
  { value: 'CONSTRUCTION', label: '建設業' },
  { value: 'MANUFACTURING', label: '製造業' },
  {
    value: 'ELECTRICITY_GAS_HEAT_WATER',
    label: '電気・ガス・熱供給・水道業',
  },
  { value: 'INFORMATION_COMMUNICATION', label: '情報通信業' },
  { value: 'TRANSPORTATION_POSTAL', label: '運輸業，郵便業' },
  { value: 'WHOLESALE_RETAIL', label: '卸売業，小売業' },
  { value: 'FINANCIAL_INSURANCE', label: '金融業，保険業' },
  { value: 'REAL_ESTATE_GOODS_RENTAL', label: '不動産業，物品賃貸業' },
  {
    value: 'ACADEMIC_RESEARCH_PROFESSIONAL_TECHNICAL_SERVICE',
    label: '学術研究，専門・技術サービス業',
  },
  { value: 'HOTEL_FOOD_SERVICE', label: '宿泊業，飲食サービス業' },
  {
    value: 'LIFE_RELATED_ENTERTAINMENT',
    label: '生活関連サービス業，娯楽業',
  },
  { value: 'EDUCATION_LEARNING_SUPPORT', label: '教育，学習支援業' },
  { value: 'MEDICAL_WELFARE', label: '医療，福祉' },
  { value: 'COMBINED_SERVICE', label: '複合サービス事業' },
  { value: 'SERVICE', label: 'サービス業 (他に分類されないもの)' },
];

type FieldsetProps = {
  prefix?: string;
  data: IncomeData;
  employmentTypeOptions: InputSelectOption[];
  isSpouse?: boolean;
  onChange?: (_:IncomeData) => void;
};
const Fieldset: React.FC<FieldsetProps> = ({
  prefix, data, employmentTypeOptions, isSpouse = false, onChange,
}) => {
  // 預金額
  const [depositAmount, setDepositAmount] = React.useState(data.depositAmount);

  // 職業
  const [employmentStatus, setEmploymentStatus] = React.useState(data.employmentStatus);

  // 年収 (額面)
  const [annualIncome, setAnnualIncome] = React.useState(data.annualIncome);

  // 業種
  const [industry, setIndustry] = React.useState(data.industry);

  // 現勤務先の勤続年数
  const [lengthOfService, setLengthOfService] = React.useState(data.lengthOfService);

  // 年収の増加率
  const [incomeGrowthRate, setIncomeGrowthRate] = React.useState(data.incomeGrowthRate);

  // 職系
  const [occupation, setOccupation] = React.useState(data.occupation);

  // 育休
  const [
    willHaveChildcareLeave,
    setWillHaveChildcareLeave,
  ] = React.useState(data.willHaveChildcareLeave);

  // 育休後の就業スタイル
  const [postpartumWorkStyle, setPostpartumWorkStyle] = React.useState(data.postpartumWorkStyle);

  // 値に変化があったらイベントを呼び出す
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        depositAmount,
        employmentStatus,
        annualIncome,
        industry,
        numberOfEmployees: data.numberOfEmployees,
        lengthOfService,
        incomeGrowthRate,
        occupation,
        willHaveChildcareLeave,
        postpartumWorkStyle,
      });
    }
  }, [
    depositAmount,
    employmentStatus,
    annualIncome,
    industry,
    lengthOfService,
    incomeGrowthRate,
    occupation,
    willHaveChildcareLeave,
    postpartumWorkStyle,
  ]);

  return (
    <>
      <div className="fieldset-body">
        <div className="form-label">
          <span>預金額</span>
          <HelpButton>
            {isSpouse
              ? (
                <>
                  ｢あなたの預金額｣と同様に、配偶者預金額を入力してください。
                </>
              )
              : (
                <>
                  ■貯金/預金額に含まれる：あなたの預金/家計の預金/タンス貯金 ←一般的な普通預金・定期預金は含めてください。
                  <br />
                  ■貯金/預金額に含まれない：iDeCo/つみたてNISA(～2023年)/投資信託/積立式の定期預金/その他運用残高については資産形成の画面で入力してください。
                </>
              )}
          </HelpButton>
        </div>
        <InputInteger
          name={`${prefix}depositAmount`}
          className="w-[120px]"
          unit="万円"
          division={4}
          defaultValue={depositAmount}
          maxlength={4}
          onChange={(value) => setDepositAmount(value)}
        />
        <Remarks className="mt-6">
          <p>iDeCo/つみたてNISA(～2023年)/投資信託/積立定期預金/その他の運用残高について資産形成の画面からご入力ください。</p>
          <Link to="/home/asset" className="arrow-link mt-1">資産形成</Link>
        </Remarks>
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>職業</span>
        </div>
        <InputSelect
          className="block w-[200px]"
          options={employmentTypeOptions}
          defaultValue={employmentStatus}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setEmploymentStatus(value);

            if (value === 'FULLTIME_EMPLOYEE') {
              // 正社員のときに、業種が選択項目に含まれていなければ最初の値を指定
              if (industryTypeOptions.findIndex((option) => option.value === industry) === -1) {
                setIndustry(industryTypeOptions[0].value);
              }
              setIncomeGrowthRate('STATISTICAL');
            } else {
              setIncomeGrowthRate('DOES_NOT_INCREASE');
            }
          }}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>年収 (額面)</span>
          <HelpButton>
            額面年収(社会保険料や厚生年金、税金が引かれる前の金額)を入力してください。
          </HelpButton>
        </div>
        <InputInteger
          name={`${prefix}annualIncome`}
          className="w-[120px]"
          unit="万円"
          required
          division={4}
          defaultValue={annualIncome}
          maxlength={4}
          onChange={(value) => setAnnualIncome(value)}
          min={(() => {
            if (employmentStatus === 'FULLTIME_EMPLOYEE' || employmentStatus === 'TEMPORARY_STAFF') {
              return MINIMUM_ANNUAL_INCOME;
            }
            return 0;
          })()}
          max={MAXIMUM_ANNUAL_INCOME}
          disabled={employmentStatus === 'HOUSEWIFE_HUSBAND'}
        />
        <p className="text-xs color-gray-500 mt-5">育休を取っている方は育休前の金額を入力してください。</p>
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>業種</span>
        </div>
        <InputSelect
          className="block w-full"
          options={industryTypeOptions}
          defaultValue={industry}
          disabled={!(employmentStatus === 'FULLTIME_EMPLOYEE')}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setIndustry(value);
          }}
        />
      </div>
      <div className="fieldset-body">
        <ToggleDetail>
          <div className="fieldset-body">
            <div className="form-label">
              <span>現勤務先の勤続年数</span>
            </div>
            <InputInteger
              name={`${prefix}lengthOfService`}
              className="w-[120px]"
              unit="年"
              defaultValue={lengthOfService}
              maxlength={2}
              onChange={(value) => setLengthOfService(value)}
              disabled={isSpouse && employmentStatus === 'HOUSEWIFE_HUSBAND'}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>年収の増加率</span>
              <HelpButton>
                将来の年収増加を想定するために、統計データを使って算出しています。
              </HelpButton>
            </div>
            <InputRadioGroup
              name={`${prefix}incomeGrowthRate`}
              options={[
                { value: 'STATISTICAL', label: '統計的な増加率' },
                { value: 'LOWER_THAN_STATISTICS', label: '統計的な増加率よりも低め' },
                { value: 'DOES_NOT_INCREASE', label: '増加しない' },
              ]}
              defaultValue={incomeGrowthRate ?? 'STATISTICAL'}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setIncomeGrowthRate(value);
              }}
              disabled={isSpouse && employmentStatus === 'HOUSEWIFE_HUSBAND'}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>職系</span>
              <HelpButton>
                将来の平均的な収入や支出を計算するために必要な項目になります。
                <br />
                ■総合職：
                <br />
                収入・支出をダイナミックに計算いたします。
                <br />
                ■一般職：
                <br />
                収入・支出を保守的に計算いたします。
              </HelpButton>
            </div>
            <InputRadioGroup
              name={`${prefix}occupation`}
              options={[
                { value: 'COMPREHENSIVE_WORK', label: '総合職' },
                { value: 'GENERAL_STAFF', label: '一般職' },
                { value: 'UNKNOWN', label: 'わからない' },
              ]}
              defaultValue={occupation ?? 'UNKNOWN'}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setOccupation(value);
              }}
              disabled={isSpouse && employmentStatus === 'HOUSEWIFE_HUSBAND'}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>育休</span>
            </div>
            <InputRadioGroup
              name={`${prefix}willHaveChildcareLeave`}
              options={[
                { value: 0, label: 'とらない' },
                { value: 1, label: 'とる・とっている' },
              ]}
              defaultValue={willHaveChildcareLeave ? 1 : 0}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setWillHaveChildcareLeave(parseInt(value, 10) === 1);
              }}
              disabled={isSpouse && employmentStatus === 'HOUSEWIFE_HUSBAND'}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>育休後の就業スタイル</span>
            </div>
            <InputRadioGroup
              name={`${prefix}postpartumWorkStyle`}
              options={[
                { value: 'MUCH_AS_NOW', label: '今と同じくらい働く', help: '末っ子が1歳までは現在年収の50%、\nその後は現在年収と同額' },
                { value: 'REDUCE', label: 'ペースを落とす', help: '末っ子が1歳までは現在年収の50%、\nその後は75%' },
                { value: 'RETIRED_RE_EMPLOYED', label: '退職し、子供が小学生になったら再就職', help: '末っ子が小学校卒業までは収入なし、\nその後は現在年収の90%' },
                { value: 'RETIRED_WILL_NOT_WORK', label: '退職し、以降働かない', help: '出産して退職以降は収入なし' },
              ]}
              defaultValue={postpartumWorkStyle ?? 'MUCH_AS_NOW'}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setPostpartumWorkStyle(value);
              }}
              disabled={(isSpouse && employmentStatus === 'HOUSEWIFE_HUSBAND') || !willHaveChildcareLeave}
            />
          </div>
        </ToggleDetail>
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  isSpouse: undefined,
  onChange: undefined,
};
export default Fieldset;
