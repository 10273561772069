import { createAsyncThunk, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

// error
import checkApiError from './checkApiError';

// set
import { setBasic, setEducationExpenseMatrix, setToken } from '../userReducer';
import { setLoginData, setBasic as setBasicInput, setEstimated as setEstimatedInput } from '../inputReducer';
import { setCashflow } from '../cashflowReducer';
import { setEstimated } from '../estimatedReducer';
import { setRiskTolerance } from '../riskReducer';
import { setNextStep, setCompanyFlg } from '../stepReducer';

// type
import type {
  Family, Income,
  LivingExpenses,
  HousingExpensesLoanData, PrepaymentLoan,
  EducationExpensesChildren,
} from '../inputReducer';
import type { Cashflow } from '../cashflowReducer';
import type { EstimatedValueData } from '../estimatedReducer';
import type { RiskTolerance } from '../riskReducer';
import type { NextStep } from '../stepReducer';

// テストデータ
import mockSkip from '../../mock/skip.json';
import mockSkip2 from '../../mock/skip2.json';
import mockSkip3 from '../../mock/skip3.json';
import mockSkip4 from '../../mock/skip4.json';
import mockSkip5 from '../../mock/skip5.json';
import mockC6 from '../../mock/c6.json';
import mockC8 from '../../mock/c8.json';
import mockC9 from '../../mock/c9.json';
import mockMaximum from '../../mock/maximum.json';
import mockMin3 from '../../mock/min3.json';

// ローカルモックデータ
export const mockObjects = [
  {
    // モックデータ
    jistid: 'skip',
    label: 'モックデータ（カニ使用中）',
    mock: mockSkip,
  },
  {
    // モックデータ（配偶者なし）
    jistid: 'skip2',
    label: 'モックデータ（配偶者なし）',
    mock: mockSkip2,
  },
  {
    // モックデータ（初期値）
    jistid: 'skip3',
    label: 'モックデータ（初期値）',
    mock: mockSkip3,
  },
  {
    // モックデータ（初期値 - アドバイス）
    jistid: 'skip4',
    label: 'モックデータ（初期値 - アドバイス）',
    mock: mockSkip4,
  },
  {
    // モックデータ（結婚予定の配偶者）
    jistid: 'skip5',
    label: 'モックデータ（結婚予定の配偶者）',
    mock: mockSkip5,
  },
  {
    // 企業フラグ6（DC有効、iDeco無効、積立金入力可能）
    jistid: 'c6',
    label: '企業フラグ6（DC有効、iDeco無効、積立金入力可能）',
    mock: mockC6,
  },
  {
    // 企業フラグ8（DC有効、iDeco無効、積立金入力可能）
    jistid: 'c8',
    label: '企業フラグ8（DC有効、iDeco無効、積立金入力可能）',
    mock: mockC8,
  },
  {
    // 企業フラグ9（DCが有効ではない、iDeco有効）
    jistid: 'c9',
    label: '企業フラグ9（DCが有効ではない、iDeco有効）',
    mock: mockC9,
  },
  {
    // 桁数あふれ表示
    jistid: 'maximum',
    label: '桁数あふれ表示',
    mock: mockMaximum,
  },
  {
    // シナリオ#3
    jistid: 'min3',
    label: 'シナリオ#3',
    mock: mockMin3,
  },
];

// 5. 資産形成 - 企業年金 (DC)
type AssetFormationDcItem = {
  presentValue: number|null,
  monthlyContributionAmount: number|null,
  monthlyContributionAmountOfEmployee: number|null,
  riskValue: number|null,
  hasBeJoined: string|null,
  expectedReturn: number|null,
  joinMonth: string|null,
  acquisitionValue: string|number|null,
  volatility: number|null,
}

// 5. 資産形成 - 個人年金 (iDeco) 、つみたてNISA、積立投信・投資信託
type AssetFormationDataItem = {
  presentValue?: number|null,
  monthlyContributionAmount?: number|null,
  riskValue: number|null,
  hasBeJoined: string|null,
  expectedReturn?: number|null,
  joinMonth: string|null,
  acquisitionValue?: string|number|null,
  volatility?: number|null,
}

// 5. 資産形成 - 積立定期・その他
type AssetFormationOtherItem = {
  presentValue?: number|null,
  monthlyContributionAmount?: number|null,
  startMonth?: string|null,
  endMonth?: string|null,
  totalReturn?: number|null,
  hasBeJoined?: string|null,
  acquisitionValue?: string|number|null,
}

export type FetchResponseEducationExpensesMatrix = {
  lessonMemo?: string|null;
  tuition?: number|null;
  grade?: string|null;
  entranceFee?: number|null;
  publicPrivate?: string|null;
  lessonFee?: number|null;
}

export type FetchResponseInputData = {
  family?: Family,
  income?: Income,
  expenses?: {
    livingExpenses: LivingExpenses,
    educationExpenses: {
      educationPolicy: string|null,
      children: EducationExpensesChildren[]
    },
    housingExpenses: {
      presentHouse: string|null,
      monthlyRent: number|null,
      monthlyHousingExpense: number|null,
      maintenanceFee: number|null,
      loanOfHouseholder: HousingExpensesLoanData,
      loanOfSpouse: HousingExpensesLoanData,
      planToBuyHouse: {
        willBuyHouse: boolean|null,
        ageOfPlanToBuyHouseHouseholder: number|null,
        ageOfPlanToBuyHouseSpouse: number|null,
        amountOfPlanToBuyHouse: number|null,
        willHaveMortgageLoan: boolean|null,
        whoWillHaveMortgageLoan: string|null,
        downPayment: number|null,
        loanAmountHouseholder: number|null,
        loanAmountSpouse: number|null,
        loanPeriod: number|null,
        interestCalculationMethod: string|null,
        loanRate: number|null,
        periodOfFixedRate: number|null,
        repaymentMethod: string|null,
      },
      prepaymentLoan: PrepaymentLoan,
    },
  },
  afterRetirement?: {
    reEmploymentAfterRetirement: {
      startAgeOfReEmployment: number|null,
      willBeReEmployed: boolean|null,
      annualIncome: number|null,
      endAgeOfReEmployment: number|null,
    },
    lifeAfterRetirement: string|null,
    householder: {
      corporatePensionDc: {
        startAgeOfPension: number|null,
        yearsOfPensionDrawing: string|null,
        drawTypeOfPension: string|null,
      },
      ideco: {
        startAgeOfPension: number|null,
        yearsOfPensionDrawing: string|null,
        drawTypeOfPension: string|null,
      },
      publicPension: {
        startAgeOfPension: number|null,
        monthlyPensionAmount: number|null,
      },
      corporatePensionDb: {
        startAgeOfPension: number|null,
        drawTypeOfPension: string|null,
      },
      retirementAllowance: number|null,
    },
    spouse: {
      corporatePensionDc: {
        startAgeOfPension: number|null,
        yearsOfPensionDrawing: string|null,
        drawTypeOfPension: string|null,
      },
      ideco: {
        startAgeOfPension: number|null,
        yearsOfPensionDrawing: string|null,
        drawTypeOfPension: string|null,
      },
      publicPension: {
        startAgeOfPension: number|null,
        monthlyPensionAmount: number|null,
      },
      retirementAllowance: {
        retirementAge: number|null,
        retirementAllowance: number|null,
      },
    },
  },
  assetFormation?: {
    householder: {
      corporatePensionDc: AssetFormationDcItem,
      ideco: AssetFormationDataItem,
      tsumitateNisa: AssetFormationDataItem,
      investmentTrust: AssetFormationDataItem,
      otherInvestments: AssetFormationOtherItem[],
    },
    spouse: {
      corporatePensionDc: AssetFormationDcItem,
      ideco: AssetFormationDataItem,
      tsumitateNisa: AssetFormationDataItem,
      investmentTrust: AssetFormationDataItem,
    },
  },
}

// ログインAPIからリクエストされたデータの型
type FetchResponseData = {
  basics: {
    loginDate?: string|null,
    jisTid?: string|null,
    isGuest?: boolean|null,
    userRegistrationStatus?: boolean|null,
    initialSettingStatus?: boolean|null,
    nickname?: string|null,
    email?: string|null,
    requestForEmailNewsLetter?: boolean|null,
    annualIncome?: number|null,
    lifeStyle?: string|null,
    birthDay?: string|null,
    age?: number|null,
    retirementAge?: number|null,
    bkData?: {
      assetPattern?: number|null,
      companyFlg?: number|null,
      dcFutureLatchFuncFlg?:boolean|null,
      recommendedSfFlg?: boolean|null,
      recommendedPwedFlg?:boolean|null,
      recommendedNisaFlg?: boolean|null,
      recommendedIdecoFlg?: boolean|null,
      dbModelAmountSettingAmount?: number|null,
      bankBranchName?: string|null,
      responsible?: string|null,
      bankClerkPhoneNo?: string|null,
      reminder?: string|null,
    },
    tbData?: {
      subscriberNo?: string|null,
      requiredPaymentAmount?: number|null,
      lumpSumRetireAmount?: number|null,
      definedBenefitRetireAmount?: number|null,
    },
    jist?: {
      dcTotalReservedAmount?: number|null,
      pnsnAstEstmAmt?: string|null,
      opeAmt?: string|null,
      birthday?: string|null,
      joinMonth?: string|null,
    },
    riskTolerance?: RiskTolerance,
    educationExpenses?: FetchResponseEducationExpensesMatrix[],
  },
  inputHistory?: FetchResponseInputData,
  apiResultHistory?: {
    cashflows?: Cashflow[]
  },
  estimatedValue?: {
    householder?: EstimatedValueData,
    spouse?: EstimatedValueData,
  },
  nextStep?: NextStep[],
  token: string|null,
}

// ログインAPIからリクエストされたデータを読み込み
export const fetchData = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  data: FetchResponseData,
) => {
  if (data.basics !== undefined) {
    dispatch(setBasic({
      ...data.basics,
      account: {
        ...data.basics,
      },
      // token: data.token,
    }));
    dispatch(setToken(data.token));
    dispatch(setEducationExpenseMatrix(data.basics.educationExpenses));
    dispatch(setCompanyFlg(data.basics.bkData?.companyFlg));
  }
  if (data.inputHistory !== undefined) {
    dispatch(setBasicInput({
      birthDate: data.basics.birthDay ?? null,
      companyFlg: data.basics.bkData?.companyFlg ?? null,
      opeAmt: data.basics.jist?.opeAmt ?? null,
    }));
    dispatch(setEstimatedInput(data.estimatedValue));
    dispatch(setLoginData(data.inputHistory));
  }
  if (data.apiResultHistory?.cashflows !== undefined) {
    dispatch(setCashflow(data.apiResultHistory.cashflows));
  }
  if (data.estimatedValue !== undefined) {
    dispatch(setEstimated(data.estimatedValue));
  }
  if (data.basics?.riskTolerance !== undefined) {
    dispatch(setRiskTolerance(data.basics.riskTolerance));
  }
  if (data.nextStep !== undefined) {
    dispatch(setNextStep(data.nextStep));
  }
};

// ログイン時にAPIを読み込む
type Request = {
  authInfo: {
    authCode: string;
    sessionCookie: string;
    loginDate: string;
  },
  mode?: {
    dev: boolean,
  },
  dev?: {
    jisTid: string,
  }
}
type Fetch = {
  authCode:string|null,
  sessionCookie:string|null,
  jisTid?: string,
}
export const fetchAsync = createAsyncThunk<{authCode:string, sessionCookie:string}|null, Fetch>('auth/fetchAuth', async (params, thunkApi) => {
  const { dispatch } = thunkApi;
  const domain = process.env.REACT_APP_API_DOMAIN;

  // パラメータ取得
  const { authCode, sessionCookie, jisTid } = params;
  if (authCode === null || sessionCookie === null) {
    // 認証情報が渡ってきていません。
    return null;
  }

  try {
    const request: Request = {
      authInfo: {
        authCode,
        sessionCookie,
        loginDate: moment().format('YYYY-MM-DD'),
      },
    };
    if (jisTid !== undefined) {
      // JIS&TID Hash値を直接入力したときのデバッグログイン
      request.mode = { dev: true };
      request.dev = { jisTid };
    }

    // ログインAPIを読み込み
    const response = await axios.post(`${domain}/login`, request, {
      headers: {
        accept: 'application/json',
      },
    });

    // JSONデータからエラーかどうか判断する
    checkApiError(response);

    // APIからリクエストされたデータを読み込み
    const data = response.data as FetchResponseData;
    if (data !== null) {
      fetchData(dispatch, data);
      return {
        authCode,
        sessionCookie,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
  }
  return null;
});
export default fetchAsync;

// ゲストログイン実装
export const guestAsync = createAsyncThunk<{authCode:string, sessionCookie:string}|null>('auth/guestAsync', async (_, thunkApi) => {
  const { dispatch } = thunkApi;
  const domain = process.env.REACT_APP_API_DOMAIN;

  // パラメータ取得
  const authCode = 'GUEST';
  const sessionCookie = 'GUEST';

  try {
    // ログインAPIを読み込み
    const response = await axios.post(`${domain}/login`, {
      authInfo: {
        authCode,
        isGuest: true,
        sessionCookie,
        loginDate: moment().format('YYYY-MM-DD'),
      },
    }, {
      headers: {
        accept: 'application/json',
      },
    });

    // JSONデータからエラーかどうか判断する
    checkApiError(response);

    // APIからリクエストされたデータを読み込み
    const data = response.data as FetchResponseData;
    if (data !== null) {
      fetchData(dispatch, data);
      return {
        authCode,
        sessionCookie,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
  }
  return null;
});

// デバッグ用jistIdを読み込む
export const fetchDebug = createAsyncThunk('auth/fetchDebug', async (jistid:string, thunkApi) => {
  const { dispatch } = thunkApi;
  let data: FetchResponseData|null = null;

  // モックデータがあれば使用する
  const mockObject = mockObjects.find((obj) => obj.jistid === jistid);
  data = mockObject !== undefined ? mockObject.mock : null;

  if (data !== null) {
    fetchData(dispatch, data);
  }
  return {
    code: 'debug',
    sessionCookie: 'debug',
  };
});
