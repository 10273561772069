import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';
import FAQ, { Q, A } from './FAQ';

const Detail = styled.div`
  font-size: ${theme`fontSize.sm`};
  border-width: 1px;
  border-color: ${theme`colors.gray-200`};
  padding: ${theme`padding.5`};
  h3 {
    color: ${theme`colors.gray-500`};
    text-align: center;
  }
  dt {
    color: ${theme`colors.primary`};
    margin-top: ${theme`margin.2`};
  }
  dd {
    color: ${theme`colors.gray-500`};
  }
`;

const HelpOverview: React.FC = () => (
  <>
    <FAQ>
      <Q>
        <p>みずほの人生100年デザイナーとはどんなサービスでしょうか？</p>
      </Q>
      <A>
        <p>
          確定拠出年金加入者の皆様が、将来・老後を見据えたライフプランをご検討いただくためのツールです。
          JIS&amp;Tにご登録をいただいている情報やお勤め先の退職金推計値(※)に、あなたの家族や生活のこと、
          統計データを掛け合わせることで、現実味のあるライフプランシミュレーションが行えるサービスです。
        </p>
        <p>また、シミュレーション内容に応じて、ライフプランを改善するための具体的なアドバイスを提示します。定期的にその時の生活・状況に応じてライフプランを見直していきましょう。</p>
        <p>(※) 対象企業・対象者のみ</p>
      </A>
    </FAQ>
    <FAQ>
      <Q>
        <p>年齢に応じて年収に変化が見られるのはなぜでしょうか？</p>
      </Q>
      <A>
        <p>あなたの年齢、ご入力いただく現在の年収、勤務先の業種や職系から統計データと掛け合わせて将来の年収を算出しているためです。</p>
      </A>
    </FAQ>
    <FAQ>
      <Q>
        <p>すでに入力項目に数値（推測値）が入っているのはなぜでしょうか？</p>
      </Q>
      <A>
        <p>あらかじめ推測値が入力されている場合がございます。推測値はあなたの年齢、ご登録いただく現在の年収、勤務先の業種や職系から統計データと掛け合わせて算出しています。</p>
        <div className="space-y-4 mt-4">
          <h2 className="font-bold text-base text-primary text-center">[退職金・年金に係る推計値について]</h2>
          <Detail>
            <h3>お勤め先から推計値のご提供がある場合</h3>
            <dl>
              <dt>退職金</dt>
              <dd>お勤め先からご提供の推計値</dd>
              <dt>公的年金</dt>
              <dd>-</dd>
              <dt>企業年金(DB)</dt>
              <dd>お勤め先からご提供の推計値</dd>
              <dt>確定拠出企業年金(DC)</dt>
              <dd>-</dd>
              <dt>個人年金(iDeCo)</dt>
              <dd>-</dd>
            </dl>
          </Detail>
          <Detail>
            <h3>お勤め先から推計値のご提供がない場合</h3>
            <dl>
              <dt>退職金</dt>
              <dd>年収・年齢に基づく推計値</dd>
              <dt>公的年金</dt>
              <dd>年収・年齢に基づく推計値</dd>
              <dt>企業年金(DB)</dt>
              <dd>-</dd>
              <dt>確定拠出企業年金(DC)</dt>
              <dd>現行掛金・年齢に基づく推計値</dd>
              <dt>個人年金(iDeCo)</dt>
              <dd>現行掛金・年齢に基づく推計値</dd>
            </dl>
          </Detail>
        </div>
      </A>
    </FAQ>
  </>
);
export default HelpOverview;
