import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'twin.macro';

const Section = styled.section`
  color: ${theme`colors.primary`};
  font-size: ${theme`fontSize.sm`};
  padding: ${theme`padding.0`};
  h2 {
    font-size: ${theme`fontSize.xl`};
    font-weight: 700;
    margin-bottom: ${theme`margin.4`};
  }
  h3 {
    font-size: ${theme`fontSize.base`};
    font-weight: 700;
  }
  ol {
    counter-reset: item;
    & > li {
      position: relative;
      padding-left: ${theme`padding.7`};
      margin-top: ${theme`margin.2`};
      &::before {
        counter-increment: item;
        content: counter(item)'.';
        position: absolute;
        left: 0;
        top: 0;
      };
    }
  }
  ul {
    & > li {
      position: relative;
      padding-left: ${theme`padding.7`};
      margin-top: ${theme`margin.2`};
      &::before {
        content: '・';
        position: absolute;
        left: 0;
        top: 0;
      };
    }
  }
  a.arrow-link {
    color: ${theme`colors.gray-500`};
    margin-top: ${theme`margin.4`};
  }
`;

const Terms: React.FC = () => (
  <div className="container py-[50px]">
    <div className="px-xs">
      <h1 className="text-2xl text-primary font-bold text-center">ご利用規約</h1>
      <div className="space-y-8 mt-10 mb-12">
        <Section>
          <h2>第1条（目的）</h2>
          <div>
            <p>
              本規約は、お客さま（以下、「利用者」といいます）が、「みずほの人生100年デザイナー」
              （以下、「本シミュレーション」といいます）を利用するにあたり適用されるものとします。
            </p>
          </div>
        </Section>
        <Section>
          <h2>第2条（本シミュレーションの利用に伴う個人情報のお取り扱いについて）</h2>
          <ol>
            <li>
              利用者は、利用者が所属する企業が保有する利用者の企業年金情報を、利用者が本シミュレーションを利用する目的のため、
              利用者が所属する企業から株式会社みずほ銀行に提供されることに同意するものとします。
            </li>
            <li>
              利用者は、利用者が所属する企業が利用者の同意を得てみずほ信託銀行株式会社に提供した利用者の退職一時金、
              確定給付企業年金に関する情報（提供された情報をもとに加工された情報を含みます）を、利用者が本シミュレーションを利用する目的のため、
              みずほ信託銀行株式会社から株式会社みずほ銀行に提供されることに同意するものとします（本項は、利用者が所属する企業とみずほ信託銀行株式会社との間に、
              当該情報の授受に関する契約が有効に存続している場合に限り、適用されます）。
            </li>
            <li>
              利用者は、利用者が所属する企業が第１項に基づき株式会社みずほ銀行に提供した利用者の退職一時金、
              確定給付企業年金に関する情報（提供された情報をもとに加工された情報を含みます）を、利用者が本シミュレーションを利用する目的のため、
              株式会社みずほ銀行からみずほ信託銀行株式会社に提供されることに同意するものとします
              （本項は、利用者が所属する企業が、退職一時金、確定給付企業年金に関する情報を活用したサービスを利用する場合に限り、適用されます）。
            </li>
            <li>
              利用者は、利用者本人の確定拠出年金に関する情報を、利用者が本シミュレーションを利用する目的のため、
              日本インベスター・ソリューション・アンド・テクノロジー株式会社から株式会社みずほ銀行に提供されることに同意するものとします。
            </li>
            <li>
              株式会社みずほ銀行は、本シミュレーションの利用に伴い、利用者にご入力いただく情報（※）につきまして、
              「『個人情報の保護に関する法律』に基づく公表事項」に記載されている利用目的の範囲内で利用いたします。
              (※)ご入力いただく利用者に関する情報には、メールアドレス、生年月日、掛金等拠出情報、商品ごとの持分残高などを含みます。
            </li>
          </ol>
        </Section>
        <Section>
          <h2>第3条（本シミュレーションの利用）</h2>
          <div>
            <ol>
              <li>
                利用者は、①本シミュレーション結果が正確性・確実性・完全性等が保証されるものでないこと、
                ②本シミュレーション結果が特定銘柄や特定商品の投資勧誘や推奨を目的としたものではないこと、
                ③本シミュレーション結果が将来の運用成果等を約束するものでないこと、④本シミュレーションで示される数値は統計を用いた傾向値であり、
                実際の金額と異なることを承認し、あくまで参考情報として本シミュレーション結果を利用するものとします。
              </li>
              <li>
                本シミュレーションにおいて提供しているシミュレーション機能は、みずほ第一フィナンシャルテクノロジー株式会社の情報をもとに作成しており、
                その著作権その他の知的財産権はみずほ第一フィナンシャルテクノロジー株式会社に帰属します。
                Webの著作権その他の知的財産権は株式会社電通国際情報サービスに帰属し、許可なく複製、転載、引用することを禁じます。
              </li>
              <li>
                本シミュレーションにおける給与推移・生活費推移・教育費等の数値は、公的機関等が公表している各種統計データに基づき計算しており、
                内容については万全を期しているものの統計情報の正確性・確実性・完全性および提供情報が最新のものであること、もしくはその提供が遅延されないことを保証するものではなく、
                運用成果を含む将来の結果を保証するものでもありません。万一この情報により被ったいかなる損害についても、株式会社みずほ銀行、みずほ信託銀行株式会社、
                みずほ第一フィナンシャルテクノロジー株式会社、株式会社電通国際情報サービスは一切責任を負わないものとします。なお、本シミュレーションのサービス内容は、
                予告なしに変更されることがあります。
                <br />
                ※本シミュレーションにおける各種税制の取り扱いについては、法令に基づき計算しておりますが、今後、税制の変更に伴い取り扱いが変わることがあります。
                また、入力日時点の年齢に基づいて算出しております。具体的な税金の取扱、年金の受給額等については、専門機関にご相談ください。
                なお、計算上数値によっては結果のグラフ等が正しく反映されない場合がございます。
                <br />
                ※2018年1月より掛金の年単位化が可能となりましたが、本シミュレーションにおいては毎月定額拠出を想定し、一定の条件の下で計算しております。
              </li>
              <li>
                本シミュレーションにおける退職金・年金額の数値および試算結果はあくまで現時点における推計値であり、
                将来の退職金・年金額について、正確性・確実性・完全性を保証するものではありません。
                利用者の個人属性により、実際に受け取る金額が推計値や試算結果とは異なる金額となることを予めご承知置きください。
              </li>
              <li>
                利用者は、株式会社みずほ銀行が推奨する環境下において本シミュレーションを利用するほか、
                株式会社みずほ銀行所定の条件を承諾したうえで本シミュレーションを利用するものとします。
              </li>
              <li>本シミュレーションにて出力される「アドバイス」は、あくまで一例であり、投資等実行にあたっての意思決定、最終判断は利用者の責任において行うものとします。</li>
              <li>
                本シミュレーションに掲載されている情報によって、何らかの損害を被った場合でも、株式会社みずほ銀行、みずほ信託銀行株式会社、
                みずほ第一フィナンシャルテクノロジー株式会社、株式会社電通国際情報サービスは一切責任を負いません。
              </li>
              <li>
                本シミュレーションに掲載されている内容に関しては株式会社みずほ銀行、みずほ信託銀行株式会社、みずほ第一フィナンシャルテクノロジー株式会社、
                株式会社電通国際情報サービスはその内容および情報の正確性・確実性・完全性等について保証を行っておらず、また、いかなる責任を持つものではありません。
              </li>
              <li>利用者が入力いただいた数値は、一定期間保存されますが、利用者の年齢により、診断結果が異なる場合がございます。</li>
              <li>本シミュレーションに掲載されている内容は、予告なく変更することがあります。</li>
            </ol>
          </div>
        </Section>
        <Section>
          <h2>推奨環境</h2>
          <div className="space-y-8">
            <section>
              <h3>推奨ブラウザ</h3>
              <ul>
                <li>Android Chrome 最新版</li>
                <li>iOS Safari 最新版</li>
              </ul>
              <p className="mt-4">
                ※上記一覧は推奨環境であり、全ての環境で動作を保証するものではありません。
                上記以外のブラウザ、また推奨環境下でもブラウザとOSとの組み合わせ、利用者のブラウザの設定によっては、正しく表示されない場合があります。
              </p>
            </section>
            <section>
              <h3>JavaScriptについて</h3>
              <p className="mt-4">
                このサイトは、JavaScriptを使用しています。お使いのブラウザでJavaScript機能を無効にされていると、正しく機能しないことや、
                正しく表示されない場合があります。
                ブラウザ設定でJavaScriptを有効にしてご利用ください。
              </p>
            </section>
          </div>
        </Section>
        <Section>
          <h2>各種商品について</h2>
          <div className="space-y-8">
            <section>
              <h3>投資信託に関するご留意事項</h3>
              <ul>
                <li>投資信託は預金、金融債、保険契約ではありません。また、預金保険制度、保険契約者保護制度の対象ではありません。</li>
                <li>みずほ銀行が取り扱う投資信託は、投資者保護基金の規定による支払いの対象ではありません。</li>
                <li>
                  投資信託には、元本割れなどのリスクや手数料などのコスト等、商品性にかかわる注意点がございます。
                  ご購入の際は必ず最新の「投資信託説明書（交付目論見書）」および「目論見書補完書面」「商品基本資料」をご確認のうえ、ご自身でご判断ください。
                  くわしくは当行ウェブサイト内商品説明をご確認ください。
                </li>
              </ul>
              <a href="https://www.mizuhobank.co.jp/retail/products/fund/attention.html" target="_blank" className="arrow-link" rel="noopener noreferrer">投資信託ご購入にあたってのご注意事項</a>
            </section>
            <section>
              <h3>NISA制度（NISA・つみたてNISA）に関するご留意事項</h3>
              <h3>※以下は2023年までのNISA制度についての記載です。</h3>
              <ul>
                <li>
                  NISA制度では、すべての金融機関を通じて満20歳以上の個人のお客さまが基本的におひとりさま1口座に限り
                  NISA・つみたてNISA口座を開設いただけます。
                </li>
                <li>
                  非課税投資枠にて保有する商品を売却した場合、損失が発生しても他の口座との損益通算や損失の繰越控除はできません。
                  また、非課税投資枠の再利用や非課税投資枠の残額を翌年以降へ繰り越すこともできません。
                </li>
                <li>みずほ銀行では、NISA制度を利用して購入できる金融商品のうち、公募株式投資信託のみ取り扱います。</li>
                <li>現在、特定口座や一般口座で保有している公募株式投資信託等をNISA・つみたてNISAの口座へ移管することはできません。</li>
                <li>NISAとつみたてNISAは選択制であり、同一年に両方の適用は受けられません。</li>
                <li>このほかにもNISA制度についてご留意いただきたい事項がございます。くわしくは当行ウェブサイトにてご確認ください。</li>
              </ul>
              <a href="https://www.mizuhobank.co.jp/retail/products/nisa/index.html" target="_blank" className="arrow-link" rel="noopener noreferrer">NISA・つみたてNISA制度に関するご注意事項</a>
            </section>
            <section>
              <h3>iDeCoに関するご留意事項</h3>
              <ul>
                <li>iDeCo（個人型確定拠出年金）は、原則60歳まで引き出すことはできません。</li>
                <li>資産運用の結果によっては元本を下回る可能性があります。</li>
                <li>加入中は所定の手数料がかかります。</li>
                <li>このほかにもご留意いただきたい事項がございます。くわしくは当行ウェブサイト内商品説明をご確認ください。</li>
              </ul>
              <a href="https://www.mizuhobank.co.jp/retail/products/ideco/index.html#ryuui" target="_blank" className="arrow-link" rel="noopener noreferrer">個人型確定拠出年金（iDeCo：イデコ）お申し込みに関するご留意事項</a>
            </section>
          </div>
        </Section>
        <Section>
          <h2>参照データについて</h2>
          <div className="space-y-8">
            <section>
              <h3>[賃金推計]</h3>
              <ul>
                <li>令和3年賃金構造基本統計調査（厚生労働省）</li>
              </ul>
            </section>
            <section>
              <h3>[社会保険料推計]</h3>
              <ul>
                <li>平成29年度市町村国民健康保険における保険料の地域差分析</li>
                <li>令和4年3月分（4月納付分）からの健康保険・厚生年金保険の保険料額表（全国健康保険協会）</li>
                <li>令和4年度雇用保険料率のご案内（厚生労働省）</li>
                <li>協会けんぽの介護保険料率について（全国健康保険協会HP）</li>
                <li>2022年度江東区・港区介護保険料</li>
              </ul>
            </section>
            <section>
              <h3>[各種税制]</h3>
              <ul>
                <li>源泉所得税の改正のあらまし（国税庁令和4年4月）</li>
                <li>各種税制（国税庁HP）</li>
              </ul>
            </section>
            <section>
              <h3>[退職金推計]</h3>
              <ul>
                <li>令和3年賃金事情等総合調査（厚生労働省）</li>
              </ul>
            </section>
            <section>
              <h3>[教育費推計]</h3>
              <ul>
                <li>平成30年度子供の学習費調査（文部科学省）</li>
                <li>令和3年度国公私立大学の授業料等の推移（文部科学省）</li>
                <li>令和3年度 私立大学入学者に係る初年度学生納付金平均額（定員1人当たり）の調査結果について（文部科学省）</li>
              </ul>
            </section>
            <section>
              <h3>[育児休業給付上限]</h3>
              <ul>
                <li>東京ハローワーク：支給限度額変更のお知らせ</li>
              </ul>
            </section>
            <section>
              <h3>[公的年金推計]</h3>
              <ul>
                <li>2019（令和元）年財政検証結果のポイント</li>
                <li>日本年金機構（厚生年金保険料率、基礎年金額、加給年金額、再評価率、他）</li>
              </ul>
            </section>
            <section>
              <h3>[住宅ローン将来金利推計]</h3>
              <ul>
                <li>日本円スワップレート（Bloomberg）</li>
                <li>みずほ銀行ローン金利一覧</li>
              </ul>
            </section>
            <section>
              <h3>[デフォルト値推計]</h3>
              <ul>
                <li>「家計調査結果」2021年（総務省統計局）</li>
              </ul>
            </section>
          </div>
        </Section>
      </div>
      <div className="px-7">
        <Link to="/home" className="btn-back btn-back--lg">シミュレーション結果に戻る</Link>
      </div>
    </div>
  </div>
);
export default Terms;
