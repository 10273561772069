// 検証エラーメッセージ
export const failed = {
  required: (name: string): string => `${name}に入力がありません。`,
  integer: (name?: string): string => {
    if (name !== undefined) {
      return `${name}は半角整数で入力してください。`;
    }
    return '半角整数で入力してください。';
  },
  decimal: (value: string): string => `${value}は有効な入力値ではありません。`,
  range: (from: number, to: number, name?: string): string => {
    if (name !== undefined) {
      return `${name}の値は${from}以上${to}以下である必要があります。`;
    }
    return `値は${from}以上${to}以下である必要があります。`;
  },
  length: (len: number): string => `内容が規定文字数(${len})に達していません。`,
  email: (name: string): string => `${name}は正しいメールアドレスの形式ではありません。`,
  more: (more: number): string => `値は(${more})以上である必要があります。`,
  less: (less: number): string => `値は(${less})以下である必要があります。`,
  isLessThanToday: (name: string): string => `${name}は今月以下でなければなりません。`,
  zeroFirst: (value: string): string => `${value}は有効な入力値ではありません。`,
  isPeriodOfFixedRateCheck: (): string => '固定期間が借入期間を上回っています。',
};

// グラフ未反映エラーメッセージ
export const NOT_REFRESHED_GRAPH = '変更内容はグラフに未反映です。保存して反映してください。';

const Messages = {
  failed,
  NOT_REFRESHED_GRAPH,
};
export default Messages;
