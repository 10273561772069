import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// 背景がスクロールしないように固定する
const fixedHtmlOverflow = (showHeaderMenu: boolean) => {
  if (showHeaderMenu) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  } else {
    document.getElementsByTagName('html')[0].style.removeProperty('overflow');
  }
};

type State = {
  title: string|null;
  height: 70|130;
  showHeaderMenu: boolean,
  showSubMenu: boolean,
}

const initialState: State = {
  title: null,
  height: 70,
  showHeaderMenu: false,
  showSubMenu: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTitle: (state, action: PayloadAction<string|null>) => {
      state.title = action.payload;
      state.height = action.payload !== null ? 130 : 70;
    },
    closeHeaderMenu: (state) => {
      state.showHeaderMenu = false;
      fixedHtmlOverflow(false);
    },
    toggleHeaderMenu: (state, action: PayloadAction<boolean>) => {
      state.showHeaderMenu = action.payload;
      fixedHtmlOverflow(action.payload);
    },
    closeSubMenu: (state) => {
      state.showSubMenu = false;
    },
    toggleSubMenu: (state, action: PayloadAction<boolean>) => {
      state.showSubMenu = action.payload;
    },
  },
});

export const {
  setTitle,
  closeHeaderMenu, toggleHeaderMenu,
  closeSubMenu, toggleSubMenu,
} = headerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTitle = (state: RootState) => state.header.title;
export const selectHeaderHeight = (state: RootState) => state.header.height;
export const selectShowHeaderMenu = (state: RootState) => state.header.showHeaderMenu;
export const selectShowSubMenu = (state: RootState) => state.header.showSubMenu;

export default headerSlice.reducer;
