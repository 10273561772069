import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectHasDc, selectOpeAmt } from '../../reducers/userReducer';
import {
  selectHasSpouse,
  selectAssetDcHouseholder, selectAssetDcSpouse,
  setAssetDcHouseholder, setAssetDcSpouse,
  getAssetFormationItem,
} from '../../reducers/inputReducer';
import type { AssetFormationDcItem } from '../../reducers/inputReducer';
import { selectRiskDc, selectRiskDcSpouse } from '../../reducers/riskReducer';

// images
import imgHero from './assets/hero.svg';

// components
import Append from '../../features/button/Append';
import Fieldset from './Fieldset';
import FieldsetSpouse from './FieldsetSpouse';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeAssetDc: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouse);

  // DCが有効かどうか
  const isDcLogin = useAppSelector(selectHasDc);
  const opeAmt = useAppSelector(selectOpeAmt);

  // あなたの運用状況
  const defaultHouseholder = useAppSelector(selectAssetDcHouseholder);
  const [householder, setHouseholder] = React.useState(defaultHouseholder);

  // 配偶者の確定拠出年金 (DC)
  const defaultSpouse = useAppSelector(selectAssetDcSpouse);
  const [spouse, setSpouse] = React.useState(defaultSpouse);

  // リスクデータ
  const riskDatasHouseholder = useAppSelector(selectRiskDc);
  const riskDatasSpouse = useAppSelector(selectRiskDcSpouse);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setHouseholder(defaultHouseholder);
      setSpouse(defaultSpouse);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>企業年金 (DC)</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">企業年金 (DC) に入ったきりになっていませんか？</p>
        <p className="text-sm mt-2 mb-6">
          企業年金 (DC) ＝企業型確定拠出年金は、しっかりと運用をしていくことで将来の受取金額を増やせる可能性を秘めた制度です。
          いま一度、自身の運用状況を確認したうえでシミュレーションしてみましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">あなたの運用状況</h3>
            <Fieldset
              data={householder}
              onChange={(data) => setHouseholder(data)}
            />
          </section>
          <div>
            <div className="py-8 border-primary border-t">
              <Append
                label={showSpouse ? '詳細を閉じる（配偶者）' : '配偶者の確定拠出年金 (DC)を入力する'}
                onClick={() => setShowSpouse(!showSpouse)}
                minus={showSpouse}
                disabled={!hasSpouse}
              />
            </div>
          </div>
          {showSpouse && (
            <section>
              <h3 className="fieldset-title">配偶者の運用状況</h3>
              <FieldsetSpouse
                prefix="spouse"
                data={spouse}
                onChange={(data) => setSpouse(data)}
              />
            </section>
          ) }
        </div>
        <FixedButton
          disabled={deepEqual(defaultHouseholder, householder) && deepEqual(defaultSpouse, spouse)}
          contentId="dc"
          onClick={() => {
            dispatch(setAssetDcHouseholder(getAssetFormationItem(householder, {
              riskDatas: riskDatasHouseholder,
              isDcLogin,
              opeAmt,
            }) as AssetFormationDcItem));
            dispatch(setAssetDcSpouse(getAssetFormationItem(spouse, {
              riskDatas: riskDatasSpouse,
            }) as AssetFormationDcItem));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeAssetDc;
