import * as React from 'react';
import deepEqual from 'deep-equal';
import type { ValidationError } from '../../services/validation';
import { RETIREMENT_AGE } from '../../config/validation';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectRetirementAge } from '../../reducers/userReducer';
import {
  selectReEmploymentAfterRetirement,
  setAfterRetirementReEmployment as setDispatchAfterRetirementReEmployment,
} from '../../reducers/inputReducer';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';

// images
import imgHero from './assets/hero.svg';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputInteger from '../../features/input/InputInteger';
import HelpButton from '../../features/button/HelpButton';
import FailedMessage from '../../features/input/FailedMessage';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeRetireReEmployment: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);

  // 改善モード前は繰り上げ返済計画部分は非活性になる
  const isAdviced = useAppSelector(selectIsAdviceOpened);

  // 年齢の指定範囲を判断するために、退職年齢を取得
  const retirementAge = useAppSelector(selectRetirementAge);

  // 入力データ
  const defaultReEmploymentAfterRetirement = useAppSelector(selectReEmploymentAfterRetirement);
  const [
    reEmploymentAfterRetirement, setReEmploymentAfterRetirement,
  ] = React.useState(defaultReEmploymentAfterRetirement);

  // 老後の再就職
  const [
    willBeReEmployed, setWillBeReEmployed,
  ] = React.useState(defaultReEmploymentAfterRetirement?.willBeReEmployed);

  // 期間
  const [
    startAgeOfReEmployment, setStartAgeOfReEmployment,
  ] = React.useState(defaultReEmploymentAfterRetirement?.startAgeOfReEmployment);
  const [
    startAgeOfReEmploymentErrors, setStartAgeOfReEmploymentErrors,
  ] = React.useState<ValidationError[]>([]);
  const [
    endAgeOfReEmployment, setEndAgeOfReEmployment,
  ] = React.useState(defaultReEmploymentAfterRetirement?.endAgeOfReEmployment);
  const [
    endAgeOfReEmploymentErrors, setEndAgeOfReEmploymentErrors,
  ] = React.useState<ValidationError[]>([]);

  // 年収 (額面)
  const [
    annualIncome, setAnnualIncome,
  ] = React.useState(defaultReEmploymentAfterRetirement?.annualIncome);

  // データ更新
  React.useEffect(() => {
    setReEmploymentAfterRetirement((data) => {
      return {
        ...data,
        willBeReEmployed,
        startAgeOfReEmployment,
        endAgeOfReEmployment,
        annualIncome,
      };
    });
  }, [
    willBeReEmployed,
    startAgeOfReEmployment,
    endAgeOfReEmployment,
    annualIncome,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setReEmploymentAfterRetirement(defaultReEmploymentAfterRetirement);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>老後の再就職</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">生活費の蓄えに加え、老後の生きがいとしての再就職が増えています。</p>
        <p className="text-sm mt-2 mb-6">
          人生100年時代といわれているいま、定年後も働くことが一般的になりつつあります。
          シミュレーションによって、もし老後の資金が足りないことがわかったら、できるだけ長く働くことも視野に入れて考えてみましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">再就職・再雇用</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>老後の再就職</span>
              </div>
              <InputRadioGroup
                name="willBeReEmployed"
                options={[
                  { value: 1, label: 'する' },
                  { value: 0, label: 'しない' },
                ]}
                defaultValue={willBeReEmployed ? 1 : 0}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setWillBeReEmployed(parseInt(value, 10) === 1);
                }}
                disabled={!isAdviced}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>期間</span>
                <HelpButton>
                  開始年齢：退職年齢以上の年齢をご入力ください。
                  <br />
                  終了年齢：
                  {RETIREMENT_AGE}
                  歳以下の年齢をご入力ください。
                </HelpButton>
              </div>
              <div className="flex items-center">
                <InputInteger
                  name="startAgeOfReEmployment"
                  className="w-[80px]"
                  unit="歳"
                  defaultValue={startAgeOfReEmployment}
                  maxlength={2}
                  min={retirementAge ?? 0}
                  disabled={!(isAdviced && willBeReEmployed)}
                  onChange={(value) => setStartAgeOfReEmployment(value)}
                  onError={(value) => setStartAgeOfReEmploymentErrors(value)}
                />
                <div className="mr-3">〜</div>
                <InputInteger
                  name="endAgeOfReEmployment"
                  className="w-[80px]"
                  unit="歳"
                  defaultValue={endAgeOfReEmployment}
                  maxlength={2}
                  max={RETIREMENT_AGE}
                  disabled={!(isAdviced && willBeReEmployed)}
                  onChange={(value) => setEndAgeOfReEmployment(value)}
                  onError={(value) => setEndAgeOfReEmploymentErrors(value)}
                />
              </div>
              <FailedMessage errors={[
                ...startAgeOfReEmploymentErrors,
                ...endAgeOfReEmploymentErrors,
              ]}
              />
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>年収 (額面)</span>
              </div>
              <InputInteger
                name="annualIncome"
                className="w-[100px]"
                unit="万円"
                division={4}
                defaultValue={annualIncome}
                maxlength={4}
                disabled={!(isAdviced && willBeReEmployed)}
                onChange={(value) => setAnnualIncome(value)}
              />
            </div>
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultReEmploymentAfterRetirement, reEmploymentAfterRetirement)}
          contentId="reEmployment"
          onClick={() => {
            dispatch(setDispatchAfterRetirementReEmployment(reEmploymentAfterRetirement));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeRetireReEmployment;
