import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// グローバルメニュー制御
import { useAppDispatch } from '../../app/hooks';
import { closeHeaderMenu, closeSubMenu } from '../../reducers/headerReducer';

/**
 * クリックしたら必ずグローバルメニューを閉じる
 * @param props
 * @returns
 */
const LinkForceRemount: React.FC<LinkProps> = ({ children, to, className }) => {
  const dispatch = useAppDispatch();
  return (
    <Link
      to={to}
      className={className}
      onClick={() => {
        dispatch(closeHeaderMenu());
        dispatch(closeSubMenu());
      }}
    >
      {children}
    </Link>
  );
};

export default LinkForceRemount;
