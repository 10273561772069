import * as React from 'react';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// ユーザ情報
import { useAppSelector } from '../../app/hooks';
import { selectNickname, selectRetirementAge } from '../../reducers/userReducer';
import { selectHasChildren } from '../../reducers/inputReducer';
import {
  selectDeadline,
  selectTotalIncomeAfterRetirement,
  selectTotalExpensesAfterRetirement,
  selectTotalBalanceAfterRetirement,
  selectChildrenIndependentAge,
  selectEducationExpenses,
  selectIsDisplayLoan,
  selectLoanAmountNow,
  selectLoanCompletion,
  selectRetirementAssets,
} from '../../reducers/cashflowReducer';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';

// components
import HelpButton from '../../features/button/HelpButton';

// images
import imgHero01 from './assets/diagnosis01.svg';
import imgHero02 from './assets/diagnosis02.svg';
import imgHero03 from './assets/diagnosis03.svg';

// 貯蓄0メッセージ
type HeroDeadlineProps = {
  age: number;
}
const HeroDeadline: React.FC<HeroDeadlineProps> = ({ age }) => {
  const isAdviceOpened = useAppSelector(selectIsAdviceOpened);
  return (
    <>
      <div className="balloon bg-caution before:border-t-caution mt-6">{`${age}歳で貯蓄がゼロになりそうです！`}</div>
      <figure className="relative mt-6">
        {isAdviceOpened
          ? (
            <img src={imgHero02} alt="" width="295" height="180" className="block mx-auto" />
          )
          : (
            <img src={imgHero01} alt="" width="295" height="180" className="block mx-auto" />
          )}
        <div className="absolute left-1/2 top-1 ml-2 text-caution">
          <em className="text-[2.5rem] not-italic font-alpha leading-none">{age}</em>
          <span className="text-xl ml-1">歳</span>
        </div>
      </figure>
      <div className="text-primary text-center mt-5">
        <p className="text-base font-bold">
          {isAdviceOpened
            ? (
              <>
                100歳までもう一息、
                <br />
                シミュレーションを続けましょう。
              </>
            )
            : (
              <>
                100歳を目指して
                <br />
                シミュレーションを続けましょう！
              </>
            )}
        </p>
      </div>
    </>
  );
};

// 100歳まで安心メッセージ
const HeroFinish: React.FC = () => (
  <>
    <div className="balloon bg-green before:border-t-green mt-6">100歳まで安心して暮らせそう！</div>
    <figure className="relative mt-6">
      <img src={imgHero03} alt="" width="295" height="220" className="block mx-auto" />
    </figure>
    <div className="text-primary text-center mt-5">
      <p className="text-base font-bold">シミュレーションお疲れ様でした。</p>
    </div>
  </>
);

// Cell
type CellProps = {
  value: number;
}
const Cell: React.FC<CellProps> = ({ children, value }) => (
  <div className="flex justify-between items-center text-sm relative">
    <div className="flex items-center flex-shrink-0">
      {children}
    </div>
    <div className="text-right">
      <em className="text-2xl not-italic font-alpha break-all">{costValue(Math.round(value / 10000), 7)}</em>
      <span className="ml-1 whitespace-nowrap">万円</span>
    </div>
  </div>
);

// 100歳まで安心して暮らせそう！のときの「意識しておきたい！今後に関する数字」
type FuturesCardProps = {
  label: string;
  value: number|null;
  num: number;
  factor: string;
  age: number|null;
}
const FuturesCard: React.FC<FuturesCardProps> = ({
  label, value, num, factor, children, age,
}) => (
  <div className="bg-green-50 text-primary rounded overflow-hidden">
    <div className="p-5 space-y-5 bg-green-100">
      <h4 className="text-lg font-bold">{label}</h4>
      <div className="text-right">
        <em className="inline-block text-2xl leading-none not-italic font-alpha break-all">{value !== null ? costValue(Math.round(value / 10000), 15) : '-'}</em>
        <span className="inline-block text-sm ml-1">万円</span>
      </div>
    </div>
    <div className="p-5 space-y-5">
      <div className="text-sm leading-[1.57]">{children}</div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="bg-green text-white rounded-full w-7.5 h-7.5 flex items-center justify-center">
            <em className="inline-block text-base leading-none not-italic font-alpha">{num}</em>
          </div>
          <span className="inline-block text-base ml-3 font-bold">{factor}</span>
        </div>
        <div>
          <em className="inline-block text-2xl leading-none not-italic font-alpha">{age}</em>
          <span className="inline-block text-sm ml-1">歳</span>
        </div>
      </div>
    </div>
  </div>
);

const Futures: React.FC = () => {
  const retirementAge = useAppSelector(selectRetirementAge);

  // 子供がいるか、または予定があるか
  const hasChildren = useAppSelector(selectHasChildren);

  // 今後の教育費
  const educationExpenses = useAppSelector(selectEducationExpenses);
  const childrenIndependentAge = useAppSelector(selectChildrenIndependentAge);

  // 住宅ローン
  const isDisplayLoan = useAppSelector(selectIsDisplayLoan);
  const loanAmountNow = useAppSelector(selectLoanAmountNow);
  const loanCompletion = useAppSelector(selectLoanCompletion);

  // 退職時資産
  const retirementAssets = useAppSelector(selectRetirementAssets);

  return (
    <div className="border-gray-200 border-t text-primary space-y-5 pt-7 mt-8">
      <h3 className="text-base text-center font-bold">意識しておきたい！今後に関する数字</h3>
      {hasChildren && educationExpenses !== 0 && (
        <FuturesCard
          label="今後の教育費"
          value={educationExpenses}
          num={1}
          factor="子供の独立"
          age={childrenIndependentAge}
        >
          進学費用は私立文系で1,647万円 (理系で1,778万円)、国公立で550万円と言われています。
        </FuturesCard>
      )}
      {isDisplayLoan && (
        <FuturesCard
          label="住宅ローン"
          value={loanAmountNow}
          num={2}
          factor="住宅ローン完済"
          age={loanCompletion?.age ?? null}
        >
          住宅ローンの完済は
          {loanCompletion?.thisYear}
          年予定。定年のタイミングも考慮して積極的な繰り上げ返済をご検討ください！
        </FuturesCard>
      )}
      <FuturesCard
        label="退職時資産"
        value={retirementAssets}
        num={3}
        factor="退職年齢"
        age={retirementAge ?? null}
      >
        {retirementAge}
        歳までに
        {retirementAssets !== null ? Math.round(retirementAssets / 10000).toLocaleString() : '-'}
        万円の貯蓄があれば、退職後の収入と合わせて100歳まで安心して暮らせそうです！
      </FuturesCard>
    </div>
  );
};

const Diagnosis: React.FC = () => {
  const nickname = useAppSelector(selectNickname);

  // 貯蓄が0になりそうな年齢の情報を取得
  const deadline = useAppSelector(selectDeadline);

  // 老後の収入総額を取得
  const totalIncome = useAppSelector(selectTotalIncomeAfterRetirement);

  // 老後の支出総額を取得
  const totalExpenses = useAppSelector(selectTotalExpensesAfterRetirement);

  // 老後の収支
  const totalBalance = useAppSelector(selectTotalBalanceAfterRetirement);

  // 枠の色
  const borderColor = deadline !== undefined ? 'border-pink' : 'border-green';

  return (
    <div className="container px-5 mb-5">
      <div className={`border-[5px] ${borderColor} px-4 pt-md pb-4 `}>
        <h2 className="text-center text-xl text-primary font-bold">
          {nickname}
          さんの
          <br />
          ライフプラン診断
        </h2>
        {deadline === undefined
          ? <HeroFinish />
          : <HeroDeadline age={deadline.age} />}
        <div className="text-primary mt-2">
          <Cell value={totalIncome}>
            <span>老後の収入総額</span>
            <HelpButton className="text-xl ml-3">
              退職金 + 企業年金 + 公的年金 + 貯蓄 - 税
            </HelpButton>
          </Cell>
          <Cell value={totalExpenses}>
            老後の支出総額
          </Cell>
        </div>
        <div className="bg-primary text-white px-5 py-1 mt-3">
          <Cell value={totalBalance}>
            老後の収支
          </Cell>
        </div>
        {deadline === undefined && <Futures />}
      </div>
    </div>
  );
};
export default Diagnosis;
