import * as React from 'react';
import { Link } from 'react-router-dom';

// 入力データ
import { useAppSelector } from '../../app/hooks';
import { selectIsGuest } from '../../reducers/userReducer';
import { selectHasSpouse, selectIncome, selectAssetFormation } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// components
import TableRow from '../../features/table/TableRow';
import ServerError from '../../features/error/ServerError';

const HomeAssetDepositFinAssets: React.FC = () => {
  // ゲストかどうか
  const guest = useAppSelector(selectIsGuest);

  // 配偶者有無
  const hasSpouse = useAppSelector(selectHasSpouse);

  // 預金
  const defaultIncome = useAppSelector(selectIncome);
  const incomeHouseholder = defaultIncome.householder?.depositAmount;
  const incomeSpouse = defaultIncome.spouse?.depositAmount;

  // 資産形成データ取得
  const defaultAssetFormation = useAppSelector(selectAssetFormation);
  const assetHouseholder = defaultAssetFormation.householder;
  const assetSpouse = defaultAssetFormation.spouse;

  // 企業年金 (DC)
  const corporatePensionDcHouseholder = (assetHouseholder.corporatePensionDc.hasBeJoined !== 'NOT_JOINED')
    ? assetHouseholder.corporatePensionDc.presentValue
    : null;
  const corporatePensionDcSpouse = (assetSpouse.corporatePensionDc.hasBeJoined !== 'NOT_JOINED')
    ? assetSpouse.corporatePensionDc.presentValue
    : null;

  // 個人年金 (iDeCo)
  const idecoHouseholder = (assetHouseholder.ideco.hasBeJoined !== 'NOT_JOINED')
    ? assetHouseholder.ideco.presentValue
    : null;
  const idecoSpouse = (assetSpouse.ideco.hasBeJoined !== 'NOT_JOINED')
    ? assetSpouse.ideco.presentValue
    : null;

  // つみたてNISA
  const tsumitateNisaHouseholder = (assetHouseholder.tsumitateNisa.hasBeJoined !== 'NOT_JOINED')
    ? assetHouseholder.tsumitateNisa.presentValue
    : null;
  const tsumitateNisaSpouse = (assetSpouse.tsumitateNisa.hasBeJoined !== 'NOT_JOINED')
    ? assetSpouse.tsumitateNisa.presentValue
    : null;

  // 積立投信・投資信託
  const investmentTrustHouseholder = (assetHouseholder.investmentTrust.hasBeJoined !== 'NOT_JOINED')
    ? assetHouseholder.investmentTrust.presentValue
    : null;
  const investmentTrustSpouse = (assetSpouse.investmentTrust.hasBeJoined !== 'NOT_JOINED')
    ? assetSpouse.investmentTrust.presentValue
    : null;

  // 積立定期・その他
  const otherInvestmentsHouseholder = (() => {
    const hasJoinedOrTobeJoined = assetHouseholder.otherInvestments.findIndex((otherInvestment) => otherInvestment.hasBeJoined !== 'NOT_JOINED');
    if (hasJoinedOrTobeJoined !== -1) {
      return assetHouseholder.otherInvestments.reduce(
        (sum, otherInvestment) => {
          const presentValue = (otherInvestment.hasBeJoined !== 'NOT_JOINED') ? (otherInvestment.presentValue ?? 0) : 0;
          return presentValue + sum;
        },
        0,
      );
    }
    return null;
  })();

  // 合計
  const totalHouseholder = (Math.round((incomeHouseholder ?? 0) / 10000) * 10000)
    + (Math.round((corporatePensionDcHouseholder ?? 0) / 10000) * 10000)
    + (Math.round((idecoHouseholder ?? 0) / 10000) * 10000)
    + (Math.round((tsumitateNisaHouseholder ?? 0) / 10000) * 10000)
    + (Math.round((investmentTrustHouseholder ?? 0) / 10000) * 10000)
    + (Math.round((otherInvestmentsHouseholder ?? 0) / 10000) * 10000);
  const totalSpouse = (Math.round((incomeSpouse ?? 0) / 10000) * 10000)
    + (Math.round((corporatePensionDcSpouse ?? 0) / 10000) * 10000)
    + (Math.round((idecoSpouse ?? 0) / 10000) * 10000)
    + (Math.round((tsumitateNisaSpouse ?? 0) / 10000) * 10000)
    + (Math.round((investmentTrustSpouse ?? 0) / 10000) * 10000);

  return (
    <div className="container">
      <section className="card">
        <h2>貯蓄・金融資産</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">預金だけでなく資産運用も行い老後の資金を増やしましょう。</p>
        <p className="text-sm mt-2 mb-6">
          老後資金のための準備も必要ですが住宅資金や教育資金、日々の生活費も考えライフプランをたてることが大切です。
          つみたてNISA(～2023年)やiDeCo、投資信託など、あなたに合わせた資産づくりを目標に向けて計画的に準備しましょう。
        </p>
        <ServerError />
        <div className="relative">
          <section>
            <h3 className="fieldset-title">現在残高</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>預金</span>
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={incomeHouseholder} maxDigits={8} digits={hasSpouse ? undefined : 1} />
                {hasSpouse
                && <TableRow label="配偶者" value={incomeSpouse} maxDigits={8} />}
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label mt-1">
                <Link to="/home/asset/dc" className="arrow-link">企業年金 (DC)</Link>
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={corporatePensionDcHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" value={corporatePensionDcSpouse} maxDigits={8} />}
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label mt-1">
                {guest
                  ? <p>個人年金 (iDeCo)</p>
                  : <Link to="/home/asset/ideco" className="arrow-link">個人年金 (iDeCo)</Link>}
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={idecoHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" value={idecoSpouse} maxDigits={8} />}
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label mt-1">
                {guest
                  ? <p>つみたてNISA(～2023年)</p>
                  : <Link to="/home/asset/nisa" className="arrow-link">つみたてNISA(～2023年)</Link>}
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={tsumitateNisaHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" value={tsumitateNisaSpouse} maxDigits={8} />}
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label mt-1">
                {guest
                  ? <p>積立投信・投資信託</p>
                  : <Link to="/home/asset/trust" className="arrow-link">積立投信・投資信託</Link>}
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={investmentTrustHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" value={investmentTrustSpouse} maxDigits={8} />}
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label mt-1">
                {guest
                  ? <p>積立定期・その他</p>
                  : <Link to="/home/asset/otherInvestments" className="arrow-link">積立定期・その他</Link>}
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={otherInvestmentsHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" />}
              </div>
            </div>
            <div className="fieldset-body pb-0">
              <div className="form-label">
                <span>合計</span>
              </div>
              <div className="space-y-2">
                <TableRow label="あなた" value={totalHouseholder} maxDigits={8} />
                {hasSpouse
                && <TableRow label="配偶者" value={totalSpouse} maxDigits={8} />}
                <TableRow label="世帯合計" value={totalHouseholder + (hasSpouse ? totalSpouse : 0)} maxDigits={8} />
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};
export default HomeAssetDepositFinAssets;
