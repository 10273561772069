import * as React from 'react';
import FAQ, { Q, A } from './FAQ';

const HelpContact: React.FC = () => (
  <FAQ>
    <Q>
      <p>問い合わせ先はありますか？</p>
    </Q>
    <A>
      <p>画面下の「利用方法」を選択のうえ、「利用マニュアル」（最終頁）にて確認下さい。</p>
    </A>
  </FAQ>
);
export default HelpContact;
