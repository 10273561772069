import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// ユーザ情報
import { useAppSelector } from '../../app/hooks';
import { selectNickname, selectIsGuest } from '../../reducers/userReducer';

// ヘッダーメニュー情報
import { headerMenus, subMenus } from '../../config/navdatas';

// images
import iconProfile from './assets/icon-profile.svg';
import iconArrow from './assets/icon-arrow.svg';

// components
import ToggleButton from '../button/ToggleButton';
import LinkForceRemount from '../LinkForceRemount';

// components
const SubNavItem = styled.li`
  display: block;
  padding-left: 50px;
  margin-top: 1.25rem;
  a {
    display: inline-block;
    font-size: ${theme`fontSize.sm`};
    background-image: url(${iconArrow});
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center left;
    padding-left: 30px;
    & > img {
      margin-right: 10px;
    }
  }
`;

type NavItemProps = {
  name: string;
  to: string;
  icon: string|undefined;
}
const NavItem: React.FC<NavItemProps> = ({
  children, name, to, icon,
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <li className="p-sm border-solid broder-gray-200 border-b-[1px]">
      <div className="flex items-center justify-between">
        <LinkForceRemount to={to} className="flex items-center text-md outline-none">
          <img src={icon} alt="" width={30} height={30} className="mr-5" />
          <span>{name}</span>
        </LinkForceRemount>
        {children && (
          <ToggleButton
            open={show}
            color="bg-primary"
            onClick={() => setShow(!show)}
          />
        ) }
      </div>
      {show && children}
    </li>
  );
};

const GlobalHeaderMenu: React.FC = () => {
  const nickname = useAppSelector(selectNickname);
  const guest = useAppSelector(selectIsGuest);
  return (
    <div className="h-full overflow-scroll bg-white text-primary">
      <div className="bg-gray-100 py-md">
        <div className="container flex items-center">
          <figure className="px-sm">
            <img src={iconProfile} alt="" width={30} height={30} />
          </figure>
          <div className="flex items-end">
            <span className="text-xl">{nickname}</span>
            <span className="text-sm ml-6">さん</span>
          </div>
        </div>
      </div>
      <div className="container">
        <ul>
          {headerMenus
            .filter((data) => data.ignore !== true)
            .filter((data) => (data.guest && guest) || !guest)
            .map(
              (data) => (
                <NavItem name={data.name} to={data.to} icon={data.icon} key={data.to}>
                  {data.items !== undefined && (
                    <ul className="pb-1">
                      {data.items
                        .filter((item) => (item.guest && guest) || !guest)
                        .map((item) => (
                          <SubNavItem key={item.to}>
                            <LinkForceRemount to={item.to}>{item.name}</LinkForceRemount>
                          </SubNavItem>
                        ))}
                    </ul>
                  )}
                </NavItem>
              ),
            )}
        </ul>
        <div className="pt-4 pb-6">
          {subMenus
            .filter((data) => data.ignore === true)
            .filter((data) => (data.guest && guest) || !guest)
            .map(
              (data) => (
                <div className="flex items-center justify-between px-sm p-1.5" key={data.to}>
                  <LinkForceRemount to={data.to} className="flex items-center text-sm">
                    <img src={data.icon} alt="" width={30} height={30} className="mr-3" />
                    <span>{data.name}</span>
                  </LinkForceRemount>
                </div>
              ),
            )}
        </div>
      </div>
    </div>
  );
};
export default GlobalHeaderMenu;
