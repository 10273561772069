import * as React from 'react';
import { useLocation } from 'react-router-dom';

// ユーザ情報
import { useAppSelector } from '../../app/hooks';
import { selectIsGuest } from '../../reducers/userReducer';

// ヘッダーメニュー情報
import type { NavItem } from '../../config/navdatas';

// images
import iconCheck from './assets/icon-check.svg';

// components
import LinkForceRemount from '../LinkForceRemount';

type GlobalSubMenuProps = {
  items: NavItem[];
}
const GlobalSubMenu: React.FC<GlobalSubMenuProps> = ({ items }) => {
  const { pathname } = useLocation();
  const guest = useAppSelector(selectIsGuest);
  return (
    <ul>
      {items.filter((data) => (data.guest && guest) || !guest).map((item) => {
        const active = item.to === pathname;
        return (
          <li className="text-center relative bg-white" key={item.to}>
            <LinkForceRemount to={item.to} className={`block text-md bg-primary text-white py-4 outline-none ${active && 'opacity-90'}`}>
              {item.name}
            </LinkForceRemount>
            {active && <img src={iconCheck} alt="" width={24} height={24} className="absolute top-1/2 left-10 -mt-3" />}
          </li>
        );
      })}
    </ul>
  );
};
export default GlobalSubMenu;
