import * as React from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';

// 企業年金(DB)に加入済みかどうかの判定
import { selectHasBeJoinedCorporateDB } from '../../reducers/userReducer';
// 個人年金(iDeco)の非活性状況判定
import { selectAssetFormation } from '../../reducers/inputReducer';

// 4. 退職金・年金 - 本人
import type { AfterRetirementHouseholder } from '../../reducers/inputReducer';

// 推定値
import type { EstimatedValueData } from '../../reducers/estimatedReducer';

// components
import InputInteger from '../../features/input/InputInteger';
import InputRadioGroup from '../../features/input/InputRadioGroup';
import HelpButton from '../../features/button/HelpButton';
import Remarks from '../../features/block/Remarks';
import EstimationLabelAmount from '../../features/label/EstimationLabelAmount';

type FieldsetProps = {
  prefix?: string;
  datas: AfterRetirementHouseholder,
  estimated: EstimatedValueData,
  defaultRetirementAge: number|null;

  // 「正社員」以外かどうか
  isNotFullEmployee?: boolean,

  onChange?: (_:AfterRetirementHouseholder, __:number|null) => void,
}
const Fieldset: React.FC<FieldsetProps> = ({
  prefix = '', datas, estimated, defaultRetirementAge,
  isNotFullEmployee = false,
  onChange,
}) => {
  const defaultAssetFormation = useAppSelector(selectAssetFormation);

  // 企業年金(DB)に加入済みかどうかの判定
  const hasBeJoinedCorporateDB = useAppSelector(selectHasBeJoinedCorporateDB);

  // 退職年齢
  const [retirementAge, setRetirementAge] = React.useState(defaultRetirementAge);

  // 退職金
  const [
    retirementAllowance, setRetirementAllowance,
  ] = React.useState(datas.retirementAllowance);

  // 公的年金
  const [
    startAgeOfPensionPP, setStartAgeOfPensionPP,
  ] = React.useState(datas.publicPension?.startAgeOfPension);
  const [
    monthlyPensionAmountPP, setMonthlyPensionAmountPP,
  ] = React.useState(datas.publicPension?.monthlyPensionAmount);

  // 企業年金 (DB)
  const [
    startAgeOfPensionDB, setStartAgeOfPensionDB,
  ] = React.useState(datas.corporatePensionDb?.startAgeOfPension);
  const [
    drawTypeOfPensionDB, setDrawTypeOfPensionDB,
  ] = React.useState(datas.corporatePensionDb?.drawTypeOfPension);

  // 企業年金 (DC)
  const [
    startAgeOfPensionDC, setStartAgeOfPensionDC,
  ] = React.useState(datas.corporatePensionDc?.startAgeOfPension);
  const [
    drawTypeOfPensionDC, setDrawTypeOfPensionDC,
  ] = React.useState(datas.corporatePensionDc?.drawTypeOfPension);
  const [
    yearsOfPensionDrawingDC, setYearsOfPensionDrawingDC,
  ] = React.useState(datas.corporatePensionDc?.yearsOfPensionDrawing);

  // 個人年金 (iDeCo)
  const [
    startAgeOfPensionIDeCo, setStartAgeOfPensionIDeCo,
  ] = React.useState(datas.ideco?.startAgeOfPension);
  const [
    drawTypeOfPensionIDeCo, setDrawTypeOfPensionIDeCo,
  ] = React.useState(datas.ideco?.drawTypeOfPension);
  const [
    yearsOfPensionDrawingIDeCo, setYearsOfPensionDrawingIDeCo,
  ] = React.useState(datas.ideco?.yearsOfPensionDrawing);

  // iDeco非活性フラグ
  const idecoHasBeJoined = defaultAssetFormation.householder.ideco?.hasBeJoined === 'NOT_JOINED';

  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        retirementAllowance,
        publicPension: {
          startAgeOfPension: startAgeOfPensionPP,
          monthlyPensionAmount: monthlyPensionAmountPP,
        },
        corporatePensionDb: {
          startAgeOfPension: startAgeOfPensionDB,
          drawTypeOfPension: drawTypeOfPensionDB,
        },
        corporatePensionDc: {
          startAgeOfPension: startAgeOfPensionDC,
          drawTypeOfPension: drawTypeOfPensionDC,
          yearsOfPensionDrawing: yearsOfPensionDrawingDC,
        },
        ideco: {
          startAgeOfPension: startAgeOfPensionIDeCo,
          drawTypeOfPension: drawTypeOfPensionIDeCo,
          yearsOfPensionDrawing: yearsOfPensionDrawingIDeCo,
        },
      }, retirementAge);
    }
  }, [
    retirementAge,
    retirementAllowance,
    startAgeOfPensionPP,
    monthlyPensionAmountPP,
    startAgeOfPensionDB,
    drawTypeOfPensionDB,
    startAgeOfPensionDC,
    drawTypeOfPensionDC,
    yearsOfPensionDrawingDC,
    startAgeOfPensionIDeCo,
    drawTypeOfPensionIDeCo,
    yearsOfPensionDrawingIDeCo,
  ]);

  // グリーンラベルに表示する金額の値を取得
  const getMaxOrMin = (value:number|null|undefined) => {
    if (value !== null && value !== undefined) {
      const n = Math.round(value / 10000);
      return n;
    }
    return undefined;
  };

  return (
    <div className="fieldset relative">
      <section>
        <h3 className="fieldset-title">退職金</h3>
        <div className="fieldset-body">
          <div className="form-label">
            <span>退職年齢</span>
            <HelpButton>
              お勤めの企業からあなたの退職年齢データを受領している場合は当該年齢を、受領していない場合は｢60歳｣を表示しております。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}retirementAge`}
            className="w-[120px]"
            unit="歳"
            defaultValue={retirementAge}
            maxlength={2}
            min={estimated.retirementAllowance?.retirementAge.floorValue ?? undefined}
            max={estimated.retirementAllowance?.retirementAge.capValue ?? undefined}
            disabled={estimated.retirementAllowance?.retirementAge.fixed === true}
            onChange={(value) => setRetirementAge(value)}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>想定退職金</span>
            <HelpButton>
              お勤めの企業からあなたの退職金データ(推計値)を受領している場合は当該金額を、受領していない場合はあなたの年齢・年収から一般的な推計値を表示しております。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}retirementAllowance`}
            className="w-[120px]"
            unit="万円 (額面)"
            required
            division={4}
            defaultValue={retirementAllowance}
            maxlength={4}
            min={getMaxOrMin(estimated.retirementAllowance?.retirementAllowance.floorValue)}
            max={getMaxOrMin(estimated.retirementAllowance?.retirementAllowance.capValue)}
            disabled={
              estimated.retirementAllowance?.retirementAllowance.fixed === true
              || isNotFullEmployee
            }
            onChange={(value) => setRetirementAllowance(value)}
          />
          <EstimationLabelAmount
            source={estimated.retirementAllowance?.retirementAllowance.source}
            value={getMaxOrMin(estimated.retirementAllowance?.retirementAllowance.value)}
            unit="万"
          />
        </div>
      </section>
      <section>
        <h3 className="fieldset-title">公的年金</h3>
        <div className="fieldset-body">
          <div className="form-label">
            <span>受給開始年齢</span>
            <HelpButton>
              公的年金を受給開始する年齢を｢65歳｣とさせていただいております。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}startAgeOfPensionPP`}
            className="w-[120px]"
            unit="歳"
            defaultValue={startAgeOfPensionPP}
            maxlength={2}
            min={estimated.publicPension?.startAgeOfPension.floorValue ?? undefined}
            max={estimated.publicPension?.startAgeOfPension.capValue ?? undefined}
            disabled={estimated.publicPension?.startAgeOfPension.fixed === true}
            onChange={(value) => setStartAgeOfPensionPP(value)}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>想定受給額</span>
            <HelpButton>
              あなたの年齢・年収から想定受給額を推計しております。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}monthlyPensionAmountPP`}
            className="w-[120px]"
            unit="万円／月 (額面)"
            required
            division={4}
            defaultValue={monthlyPensionAmountPP}
            maxlength={3}
            min={getMaxOrMin(estimated.publicPension?.monthlyPensionAmount.floorValue)}
            max={getMaxOrMin(estimated.publicPension?.monthlyPensionAmount.capValue)}
            disabled={estimated.publicPension?.monthlyPensionAmount.fixed === true}
            onChange={(value) => setMonthlyPensionAmountPP(value)}
          />
          <EstimationLabelAmount
            source={estimated.publicPension?.monthlyPensionAmount.source}
            value={getMaxOrMin(estimated.publicPension?.monthlyPensionAmount.value)}
            unit="万"
          />
        </div>
      </section>
      {hasBeJoinedCorporateDB && (
        <section>
          <h3 className="fieldset-title">企業年金 (DB)</h3>
          <div className="fieldset-body">
            <div className="form-label">
              <span>受給開始年齢</span>
              <HelpButton>
                お勤めの企業からあなたの企業年金 (DB)受給開始年齢データを受領している場合は当該年齢を、受領していない場合は非表示としております。
              </HelpButton>
            </div>
            <InputInteger
              name={`${prefix}startAgeOfPensionDB`}
              className="w-[120px]"
              unit="歳"
              defaultValue={startAgeOfPensionDB}
              maxlength={2}
              min={estimated.corporatePensionDb?.startAgeOfPension.floorValue ?? undefined}
              max={estimated.corporatePensionDb?.startAgeOfPension.capValue ?? undefined}
              disabled={estimated.corporatePensionDb?.startAgeOfPension.fixed === true}
              onChange={(value) => setStartAgeOfPensionDB(value)}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>受取方法</span>
              <HelpButton>
                受け取り方により受取総額が異なるケースがあり、上記の｢退職金・年金の受け取り額｣｢分割受け取りイメージ｣にてご確認をいただけます
                <br />
                （お勤めの企業とのご契約内容によっては、「一括」のみが表示されます）。
              </HelpButton>
            </div>
            <InputRadioGroup
              name={`${prefix}drawTypeOfPensionDB`}
              options={[
                { value: 'BULK', label: '一括' },
                { value: 'INSTALLMENTS', label: '分割' },
              ]}
              defaultValue={drawTypeOfPensionDB}
              disabled={estimated.corporatePensionDb?.drawTypeOfPension.fixed === true}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setDrawTypeOfPensionDB(value);
              }}
            />
            {estimated.corporatePensionDb?.drawTypeOfPension.source === 'BK'
              && estimated.corporatePensionDb?.drawTypeOfPension.valueDrawType === 'BULK'
              && <p className="green-label">「一括」のみ（モデル）</p>}
            {estimated.corporatePensionDb?.drawTypeOfPension.source === 'BK'
              && estimated.corporatePensionDb?.drawTypeOfPension.valueDrawType === 'INSTALLMENTS'
              && <p className="green-label">「一括」及び「分割」</p>}
            {estimated.corporatePensionDb?.drawTypeOfPension.source === 'TB'
              && estimated.corporatePensionDb?.drawTypeOfPension.valueDrawType === 'BULK'
              && <p className="green-label">「一括」のみ</p>}
            {estimated.corporatePensionDb?.drawTypeOfPension.source === 'TB'
              && estimated.corporatePensionDb?.drawTypeOfPension.valueDrawType === 'INSTALLMENTS'
              && <p className="green-label">「一括」及び「分割」</p>}
          </div>
        </section>
      )}
      <section>
        <h3 className="fieldset-title">企業年金 (DC)</h3>
        <div className="fieldset-body">
          <Remarks className="mb-6">
            <p>本項目の積立額・資産評価額は資産形成からご入力ください</p>
            <Link to="/home/asset/dc" className="arrow-link mt-2">資産形成</Link>
          </Remarks>
          <div className="form-label">
            <span>受給開始年齢</span>
            <HelpButton>
              お勤めの企業からあなたの企業型確定拠出年金 (DC)受給開始年齢データを受領している場合は当該年齢を、受領していない場合は｢60歳｣を表示しております。
              <br />
              本年齢については、｢60～75歳｣の範囲内でご変更をいただくことが可能です。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}startAgeOfPensionDC`}
            className="w-[120px]"
            unit="歳"
            required
            defaultValue={startAgeOfPensionDC}
            maxlength={2}
            min={estimated.corporatePensionDc?.startAgeOfPension?.floorValue ?? undefined}
            max={estimated.corporatePensionDc?.startAgeOfPension?.capValue ?? undefined}
            disabled={estimated.corporatePensionDc?.startAgeOfPension?.fixed === true}
            onChange={(value) => setStartAgeOfPensionDC(value)}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>受取方法</span>
            <HelpButton>
              受け取り方により受取総額が異なるケースがあり、上記の「退職金・年金の受け取り額」にてご確認をいただけます。
              また「一括＋分割」の場合は、「退職所得控除額」の範囲に収まる範囲で「一括」受け取りを行い、当該範囲外の金額は「分割」受け取りを行うよう計算しております。
            </HelpButton>
          </div>
          <InputRadioGroup
            name={`${prefix}drawTypeOfPensionDC`}
            options={[
              { value: 'BULK', label: '一括' },
              { value: 'INSTALLMENTS', label: '分割' },
              { value: 'BULK_INSTALLMENTS', label: '一括＋分割' },
            ]}
            defaultValue={drawTypeOfPensionDC}
            onChange={(event) => {
              const { value } = event.currentTarget;
              setDrawTypeOfPensionDC(value);
            }}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>受取年数</span>
            <HelpButton>
              受け取り年数のご選択により、各年齢で月額いくら受け取れるのかを、上記｢分割受け取りイメージ｣にてご確認いただけます。
            </HelpButton>
          </div>
          <InputRadioGroup
            name={`${prefix}yearsOfPensionDrawingDC`}
            options={[
              { value: 'FIVE', label: '5年' },
              { value: 'TEN', label: '10年' },
              { value: 'FIFTEEN', label: '15年' },
              { value: 'TWENTY', label: '20年' },
            ]}
            defaultValue={yearsOfPensionDrawingDC}
            disabled={drawTypeOfPensionDC === null || drawTypeOfPensionDC === 'BULK'}
            onChange={(event) => {
              const { value } = event.currentTarget;
              setYearsOfPensionDrawingDC(value);
            }}
          />
        </div>
      </section>
      <section>
        <h3 className="fieldset-title">個人年金 (iDeCo)</h3>
        <div className="fieldset-body">
          <Remarks className="mb-6">
            <p>本項目の積立額・資産評価額は資産形成からご入力ください</p>
            <Link to="/home/asset/ideco" className="arrow-link mt-2">資産形成</Link>
          </Remarks>
          <div className="form-label">
            <span>受給開始年齢</span>
            <HelpButton>
              お勤めの企業からあなたの企業型確定拠出年金(DC)受給開始年齢データを受領している場合は当該年齢を、受領していない場合は｢60歳｣を表示しております。
              <br />
              本年齢について、個人年金(iDeCo)IDでログインされた方は、｢60～75歳｣の範囲内でご変更をいただくことが可能です
              （個人年金(iDeCo)IDでのご利用は、事前のデータ登録が必要です）。
            </HelpButton>
          </div>
          <InputInteger
            name={`${prefix}startAgeOfPensionIDeCo`}
            className="w-[120px]"
            unit="歳"
            required
            defaultValue={startAgeOfPensionIDeCo}
            maxlength={2}
            min={estimated.ideco?.startAgeOfPension?.floorValue ?? undefined}
            max={estimated.ideco?.startAgeOfPension?.capValue ?? undefined}
            disabled={estimated.ideco?.startAgeOfPension?.fixed === true || idecoHasBeJoined}
            onChange={(value) => setStartAgeOfPensionIDeCo(value)}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>受取方法</span>
            <HelpButton>
              受け取り方により受取総額が異なるケースがあり、上記の「退職金・年金の受け取り額」にてご確認をいただけます。
              また「一括＋分割」の場合は、「退職所得控除額」の範囲に収まる範囲で「一括」受け取りを行い、当該範囲外の金額は「分割」受け取りを行うよう計算しております。
            </HelpButton>
          </div>
          <InputRadioGroup
            name={`${prefix}drawTypeOfPensionIDeCo`}
            options={[
              { value: 'BULK', label: '一括' },
              { value: 'INSTALLMENTS', label: '分割' },
              { value: 'BULK_INSTALLMENTS', label: '一括＋分割' },
            ]}
            defaultValue={drawTypeOfPensionIDeCo}
            disabled={idecoHasBeJoined}
            onChange={(event) => {
              const { value } = event.currentTarget;
              setDrawTypeOfPensionIDeCo(value);
            }}
          />
        </div>
        <div className="fieldset-body">
          <div className="form-label">
            <span>受取年数</span>
            <HelpButton>
              受け取り年数のご選択により、各年齢で月額いくら受け取れるのかを、上記｢分割受け取りイメージ｣にてご確認いただけます。
            </HelpButton>
          </div>
          <InputRadioGroup
            name={`${prefix}yearsOfPensionDrawingIDeCo`}
            options={[
              { value: 'FIVE', label: '5年' },
              { value: 'TEN', label: '10年' },
              { value: 'FIFTEEN', label: '15年' },
              { value: 'TWENTY', label: '20年' },
            ]}
            defaultValue={yearsOfPensionDrawingIDeCo}
            disabled={drawTypeOfPensionIDeCo === null || drawTypeOfPensionIDeCo === 'BULK' || idecoHasBeJoined}
            onChange={(event) => {
              const { value } = event.currentTarget;
              setYearsOfPensionDrawingIDeCo(value);
            }}
          />
        </div>
      </section>
    </div>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  isNotFullEmployee: false,
  onChange: undefined,
};
export default Fieldset;
