import * as React from 'react';
import { useLocation } from 'react-router-dom';

// データ
import { useAppSelector } from '../../app/hooks';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';

const DisabledMessage: React.FC = () => {
  const { pathname } = useLocation();
  const isAdviced = useAppSelector(selectIsAdviceOpened);

  // 非活性になるページ
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    // 改善モード後は全て非活性
    if (isAdviced) {
      setDisabled(false);
      return;
    }
    const pages = [
      // 生活費削減
      '/home/basics/livingCostsReduce',
      // 繰り上げ返済計画
      '/home/house/prepayment',
      // 老後の再就職
      '/home/retire/reEmployment',
    ];
    setDisabled((pages.findIndex((p) => p === pathname) !== -1));
  }, [isAdviced, pathname]);

  if (!disabled) {
    return null;
  }
  return (
    <div className="text-caution border-2 border-rose px-3 py-2 mt-5">
      <p className="text-sm font-bold">この画面はまだ入力できません。上記メッセージを参考に入力を進めてみましょう。</p>
    </div>
  );
};
export default DisabledMessage;
