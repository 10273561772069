import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../app/store';
import { selectHasSpouseOrWill } from './inputReducer';

// config
import { RETIREMENT_AGE_SPOUSE } from '../config/validation';

// 積立金、取得金額、現在残高、期待リターン
export type CashflowFinancialAssetsData = {
  contributionAmount?: number|null,
  acquisitionValue?: number|null,
  presentValue?: number|null,
  expectedReturn?: number|null,
}

// 預金、金融資産（DC, iDeCo, つみたてNISA, 投信, その他の資産）
export type CashflowFinancialAssets = {
  depositAmount?: number|null,
  corporatePensionDc?: CashflowFinancialAssetsData,
  ideco?: CashflowFinancialAssetsData,
  tsumitateNisa? :CashflowFinancialAssetsData,
  investmentTrust?: CashflowFinancialAssetsData,
  otherInvestments?: CashflowFinancialAssetsData[],
  unexpectedIncome: {
    dissavingAssetsOfTsumitateNisaAmount?: number|null,
    dissavingAssetsOfOtherInvestmentsAmount?: number|null,
    dissavingAssetsOfInvestmentTrustAmount?: number|null,
  },
}

// キャッシュアウトデータ
export type CashflowExpenses = {
  principalRepaymentOfMortgageLoan?: number|null,
  interestRepaymentOfMortgageLoan?: number|null,
  outstandingMortgageLoanAmount?: number|null,
  principalRepaymentOfMortgageLoanAfterPrepayment?: number|null,
  interestRepaymentOfMortgageLoanAfterPrepayment?: number|null,
  outstandingMortgageLoanAmountAfterPrepayment?: number|null,
  unexpectedExpenses: {
    downPaymentOfMortgageLoan?: number|null,
  },
}

// キャッシュインデータ
export type CashflowIncome = {
  idecoBulk?: number|null,
  corporatePensionDbBulk?: number|null,
  retirementAllowanceDeduction?: number|null,
  annualIncome?: number|null,
  taxableIncomeOfRetirementAllowance?: number|null,
  residualIncome?: number|null,
  corporatePensionDcRefund?: number|null,
  mortgageLoanDeduction?: number|null,
  annualReEmploymentIncome?: number|null,
  corporatePensionDbInstallments?: number|null,
  corporatePensionDcInstallments?: number|null,
  idecoRefundForIncomeTax?: number|null,
  incomeTaxOfGrossIncome?: number|null,
  retirementAllowance?: number|null,
  dependentDeduction?: number|null,
  publicPension?: number|null,
  incomeDeduction?: number|null,
  socialSecurityCostBeforeRetirement?: number|null,
  spousalDeduction?: number|null,
  idecoRefundForResidentTax?: number|null,
  publicPensionDeduction?: number|null,
  taxableIncomeOfResidentTax?: number|null,
  totalIncome?: number|null,
  residentTaxOfGrossIncome?: number|null,
  idecoInstallments?: number|null,
  residualIncomeOfRetirementAllowance?: number|null,
  residualIncomeOfGrossIncome?: number|null,
  taxableIncomeOfIncomeTax?: number|null,
  corporatePensionDcBulk?: number|null,
  incomeTaxOfRetirementAllowance?: number|null,
  residentTaxOfRetirementAllowance?: number|null,
}

export type CashflowSummariesMonthlyAmount = {
  monthlyAmountOfHouseholder?: number|null,
  monthlyAmountOfSpouse?: number|null,
}

// キャッシュフロー
export type Cashflow = {
  thisYear: number,
  age: number,
  spouseAge?: number|null,
  data: {
    income: {
      householder?: CashflowIncome,
      spouse?: CashflowIncome,
    },
    expenses: {
      householder?: CashflowExpenses,
      spouse?: CashflowExpenses,
      household?: {
        housingExpenses?: number|null,
        livingExpenses?: number|null,
        termInsurance?: number|null,
        carMaintenanceExpenses?: number|null,
        taxes?: number|null,
        travelExpenses?: number|null,
        otherExpenses?: number|null,
        educationExpenses?: number[]|null,
        housePurchaseExpenses?: number|null,
        socialSecurityCostAfterRetirement?: number|null,
      }
    },
    financialAssets: {
      householder?: CashflowFinancialAssets,
      spouse?: CashflowFinancialAssets,
    },
  },
  summaries: {
    mortgageLoan?: {
      loanAmount?: number|null
    },
    reEmploymentIncome?: {
      monthlyAmountOfHouseholder?: number|null
    },
    residualIncome?: {
      household?: number|null,
      householder?: number|null,
      spouse?: number|null
    },
    flags?: {
      afterRetirement?: boolean|null,
      mortgageLoanOfHouseholder?: boolean|null,
      mortgageLoanOfSpouse?: boolean|null,
      childrenIndependent?: boolean|null,
      payOffMortgageLoan?: boolean|null,
      ageOfMinusBalance?: boolean|null,
    },
    financialAssets?: {
      totalAssets?: number|null
    },
    residualIncomeExcludeRefunds?: {
      household?: number|null,
      householder?: number|null,
      spouse?: number|null
    },
    refunds?: {
      household?: number|null,
      householder?: number|null,
      spouse?: number|null,
    },
    corporatePensionDb?: {
      monthlyAmountOfHouseholder?: number|null,
    },
    corporatePensionDc?: CashflowSummariesMonthlyAmount,
    ideco?: CashflowSummariesMonthlyAmount,
    faceValueIncome?: CashflowSummariesMonthlyAmount,
    publicPension?: CashflowSummariesMonthlyAmount,
    incomeAndExpenses?: {
      household?: number|null,
    },
    expenses?: {
      totalExpenses?: number|null,
      livingExpenses?:number|null,
      housingExpenses?: number|null,
      educationExpenses?: number|null,
      otherExpenses?: number|null,
      monthlyHousingExpensesExcludeUnexpected?: number|null,
    }
  }
}

export type State = {
  list: Cashflow[],
}

const initialState: State = {
  list: [],
};

export const cashflowSlice = createSlice({
  name: 'cashflow',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCashflow: (state, action: PayloadAction<Cashflow[]|object>) => {
      const list = action.payload as Cashflow[];
      if (list.length > 0 && typeof list[0].age === 'number') {
        state.list = list ?? [];
      } else {
        // APIからの型がたまにobject型になるため、空の配列に変換
        state.list = [];
      }
    },
  },
});

export const {
  setCashflow,
} = cashflowSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCashflow = (state: RootState) => state.cashflow.list;

// シミュレーション結果のグラフデータ
export type CashflowChart = {
  age: number;
  thisYear: number;
  incomings: number;
  expenses: number;
  savings: number;
  residualIncomeExcludeRefunds: number;
  refunds: number;
  living: number;
  education: number;
  house: number;
  other: number;
}
export const selectCashflowChart = (state: RootState):CashflowChart[] => {
  if (state.cashflow.list !== undefined) {
    return state.cashflow.list
      .map((c) => {
        return {
          age: c.age,
          thisYear: c.thisYear,

          // 収入
          incomings: c.summaries.residualIncome?.household ?? 0,

          // 支出
          expenses: c.summaries.expenses?.totalExpenses ?? 0,

          // 貯蓄
          savings: c.summaries.financialAssets?.totalAssets ?? 0,

          // 手取り
          residualIncomeExcludeRefunds: c.summaries.residualIncomeExcludeRefunds?.household ?? 0,

          // その他
          refunds: c.summaries.refunds?.household ?? 0,

          // 生活費
          living: c.summaries.expenses?.livingExpenses ?? 0,

          // 教育費
          education: c.summaries.expenses?.educationExpenses ?? 0,

          // 住宅費
          house: c.summaries.expenses?.housingExpenses ?? 0,

          // その他
          other: c.summaries.expenses?.otherExpenses ?? 0,
        };
      });
  }
  return [];
};

// 貯蓄が0になりそうな年齢の情報を取得
export const selectDeadline = (state: RootState) => state.cashflow.list
  .find((item) => (item.summaries.financialAssets?.totalAssets ?? 0) < 0);

// 老後のデータを絞り込む
export const selectCashflowAfterRetirement = (state: RootState) => state.cashflow.list
  .filter((cashflow) => cashflow.summaries.flags?.afterRetirement);
export const selectCashflowAfterRetirementSpouse = (state: RootState) => state.cashflow.list
  .filter((cashflow) => {
    const { spouseAge } = cashflow;
    return (spouseAge !== undefined && spouseAge !== null && spouseAge >= RETIREMENT_AGE_SPOUSE);
  });

// アドバイス - 老後の収入総額
export const selectTotalIncomeAfterRetirement = (state: RootState) => {
  const cashflows = selectCashflowAfterRetirement(state);
  return cashflows
    .reduce((sum, cashflow) => (cashflow.summaries.residualIncome?.household ?? 0) + sum, 0);
};

// アドバイス - 老後の支出総額
export const selectTotalExpensesAfterRetirement = (state: RootState) => {
  const cashflows = selectCashflowAfterRetirement(state);
  return cashflows
    .reduce((sum, cashflow) => (cashflow.summaries.expenses?.totalExpenses ?? 0) + sum, 0);
};

// アドバイス - 老後の収支
export const selectTotalBalanceAfterRetirement = (state: RootState) => {
  const cashflows = selectCashflowAfterRetirement(state);
  return cashflows
    .reduce((sum, cashflow) => (cashflow.summaries.incomeAndExpenses?.household ?? 0) + sum, 0);
};

// アドバイス - 今後の教育費
export const selectEducationExpenses = (state: RootState) => state.cashflow.list
  .reduce((sum, cashflow) => (cashflow.summaries.expenses?.educationExpenses ?? 0) + sum, 0);

// アドバイス - 子供の独立の年齢
export const selectChildrenIndependentAge = (state: RootState) => {
  const childrenIndependent = state.cashflow.list.find(
    (cashflow) => cashflow.summaries.flags?.childrenIndependent,
  );
  return childrenIndependent?.age ?? null;
};

// アドバイス - 住宅ローン表示
export const selectIsDisplayLoan = (state: RootState) => {
  // 支出.居住費.住宅ローン(本人).住宅ローン有無＝False
  // AND 支出.居住費.住宅ローン(配偶者).住宅ローン有無＝False
  // AND (支出.居住費.住宅購入予定.住宅ローン予定＝False
  //     OR (支出.居住費.住宅購入予定.住宅ローン予定＝True AND 支出.居住費.住宅購入予定.将来の住宅ローン有無＝False))
  // ならば表示しない;
  const { housingExpenses } = state.input.expenses;
  return housingExpenses.loanOfHouseholder?.hasMortgageLoan
    || (housingExpenses.loanOfSpouse ? housingExpenses.loanOfSpouse.hasMortgageLoan : false)
    || ((housingExpenses.planToBuyHouse ? housingExpenses.planToBuyHouse.willBuyHouse : false)
      && (housingExpenses.planToBuyHouse ? housingExpenses.planToBuyHouse.willHaveMortgageLoan
        : false));
};

// アドバイス - 住宅ローン
export const selectLoanAmountNow = (state: RootState) => {
  // 今年度のキャッシュフローデータから住宅ローンを取得
  const thisYear = moment().year();
  const cashflow = state.cashflow.list.find((c) => c.thisYear === thisYear);
  return cashflow?.summaries.mortgageLoan?.loanAmount ?? 0;
};

// アドバイス - 住宅ローンの完済
export const selectLoanCompletion = (state: RootState) => {
  const cashflow = state.cashflow.list.find((c) => c.summaries.flags?.payOffMortgageLoan);
  return cashflow;
};

// アドバイス - 退職時資産
export const selectRetirementAssets = (state: RootState) => {
  const { retirementAge } = state.user.account;
  const cashflow = state.cashflow.list.find((c) => c.age === retirementAge);
  return cashflow?.summaries.financialAssets?.totalAssets ?? null;
};

// 生活費 - 世帯年収
export const selectHouseholdIncome = (state: RootState) => {
  const thisYear = moment().year();
  const cashflow = state.cashflow.list.find((c) => c.thisYear === thisYear);
  if (cashflow === undefined) {
    return 0;
  }
  const { householder } = cashflow.data.income;
  const income = (householder?.mortgageLoanDeduction ?? 0)
    + (householder?.corporatePensionDcRefund ?? 0)
    + (householder?.idecoRefundForIncomeTax ?? 0)
    + (householder?.idecoRefundForResidentTax ?? 0)
    + (householder?.residualIncomeOfGrossIncome ?? 0)
    + (householder?.residualIncomeOfRetirementAllowance ?? 0);

  const { hasSpouse } = state.input.family.spouse;
  if (hasSpouse) {
    const { spouse } = cashflow.data.income;
    return income
      + (spouse?.mortgageLoanDeduction ?? 0)
      + (spouse?.corporatePensionDcRefund ?? 0)
      + (spouse?.idecoRefundForIncomeTax ?? 0)
      + (spouse?.idecoRefundForResidentTax ?? 0)
      + (spouse?.residualIncomeOfGrossIncome ?? 0)
      + (spouse?.residualIncomeOfRetirementAllowance ?? 0);
  }
  return income;
};

// 生活費削減 - 毎月の住居費
export const selectMonthlyHousingExpenses = (state: RootState) => {
  const thisYear = moment().year();
  const cashflow = state.cashflow.list.find((c) => c.thisYear === thisYear);
  if (cashflow === undefined) {
    return 0;
  }

  const monthlyHousing = cashflow.summaries.expenses?.monthlyHousingExpensesExcludeUnexpected;
  if (monthlyHousing !== null && monthlyHousing !== undefined) {
    return Math.round(monthlyHousing / 10000) * 10000;
  }
  return 0;
};

// 退職金・年金 - 退職金・年金の分割受取イメージ (月額(税引前))
export const selectPension = (state: RootState) => {
  const hasSpouse = selectHasSpouseOrWill(state);
  const { retirementAge } = state.user.account;
  return selectCashflow(state)
    .filter((cashflow) => (retirementAge !== null && cashflow.age >= retirementAge))
    .map((cashflow) => {
      const {
        faceValueIncome,
        reEmploymentIncome,
        publicPension,
        corporatePensionDb,
        corporatePensionDc,
        ideco,
      } = cashflow.summaries;
      const householderAmount = faceValueIncome?.monthlyAmountOfHouseholder ?? 0;
      const spouseAmount = hasSpouse ? faceValueIncome?.monthlyAmountOfSpouse ?? 0 : null;
      return {
        age: cashflow.age,
        thisYear: cashflow.thisYear,
        householderAmount,
        spouseAmount,
        totalAmount: householderAmount + (spouseAmount ?? 0),
        reEmploymentIncome: reEmploymentIncome?.monthlyAmountOfHouseholder ?? 0,
        publicPensionHouseholder: publicPension?.monthlyAmountOfHouseholder ?? 0,
        publicPensionSpouse: hasSpouse ? publicPension?.monthlyAmountOfSpouse ?? 0 : null,
        corporatePensionDbHouseholder: corporatePensionDb?.monthlyAmountOfHouseholder ?? 0,
        corporatePensionDcHouseholder: corporatePensionDc?.monthlyAmountOfHouseholder ?? 0,
        corporatePensionDcSpouse: hasSpouse ? corporatePensionDc?.monthlyAmountOfSpouse ?? 0 : null,
        idecoHouseholder: ideco?.monthlyAmountOfHouseholder ?? 0,
        idecoSpouse: hasSpouse ? ideco?.monthlyAmountOfSpouse ?? 0 : null,
      };
    });
};

// 老後の生活 - 老後の生活イメージ(退職時～100歳までの平均) の収入
export const selectAverageIncomeAfterRetirement = (state: RootState) => {
  // 老後の生活イメージの収入の平均 (税引前)
  const { retirementAge } = state.user.account;
  const cashflowsHouseholder = selectCashflow(state)
    .filter((cashflow) => (retirementAge !== null && cashflow.age >= retirementAge));
  if (cashflowsHouseholder.length > 0) {
    const totalHouseholder = cashflowsHouseholder
      .reduce((sum, cashflow) => {
        const income = cashflow.summaries.residualIncome?.household;
        return (income ?? 0) + sum;
      }, 0);
    return totalHouseholder / (cashflowsHouseholder.length * 12);
  }
  return 0;
};

// 老後の生活 - 老後の生活イメージ(退職時～100歳までの平均) の支出（小数点切り上げ）
export const selectAverageExpensesAfterRetirement = (state: RootState) => {
  const { retirementAge } = state.user.account;
  const cashflows = selectCashflow(state)
    .filter((cashflow) => (retirementAge !== null && cashflow.age >= retirementAge));
  const totalExpenses = cashflows
    .reduce((sum, cashflow) => (cashflow.summaries.expenses?.totalExpenses ?? 0) + sum, 0);
  return ((cashflows.length > 0)
    ? (Math.ceil(totalExpenses / cashflows.length / 12)) : 0);
};

// 企業年金 (DC)、企業年金 (iDeco)、積立投信・投資信託
// 資産形成用に定年までにデータを絞り込んだもの
export const getAssetsCashflows = (state: RootState) => {
  const age = state.user.account.age ?? 0;
  const retirementAge = state.user.account.retirementAge ?? 100;
  return state.cashflow.list
    .filter((cashflow) => (cashflow.age >= age && cashflow.age <= retirementAge));
};
// 資産形成用に定年までにデータを絞り込んだもの（配偶者）
export const getAssetsCashflowsSpouse = (state: RootState) => {
  // 配偶者の年齢
  const birthMonth = state.input.family.spouse.birthMonth ?? null;
  const spouseAge = (birthMonth !== null) ? moment().diff(moment(birthMonth), 'years') : 0;

  return state.cashflow.list
    .filter((cashflow) => {
      const cashflowSpouseAge = cashflow.spouseAge ?? 0;
      return cashflowSpouseAge >= spouseAge && cashflowSpouseAge <= RETIREMENT_AGE_SPOUSE;
    });
};

// 企業年金 (DC) - あなたの運用状況
export const selectAssetsDc = (state: RootState) => {
  const cashflows = getAssetsCashflows(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.householder?.corporatePensionDc;
    const income = cashflow?.data.income.householder;
    return {
      age: cashflow.age,
      contributionAmount: (income !== null) ? (datas?.contributionAmount ?? 0) : 0,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,

      // 企業年金（DC）一括受取額
      income: (income?.corporatePensionDcBulk ?? 0) + (income?.corporatePensionDcInstallments ?? 0),
    };
  });
  if (newDatas.length > 1) {
    const secondLastData = newDatas[newDatas.length - 2];
    const lastData = newDatas[newDatas.length - 1];

    // 最後の歳の元本積立額は、手前の歳の元本積立額と寄付金額を足した値
    lastData.acquisitionValue = (Math.round(secondLastData.acquisitionValue / 10000) * 10000)
      + (Math.round(lastData.contributionAmount / 10000) * 10000);

    // 最後の予想資産残高は、企業年金（DC）一括受取額を足した値
    lastData.presentValue += lastData.income;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// 企業年金 (DC) - 配偶者の運用状況
export const selectAssetsDcSpouse = (state: RootState) => {
  const cashflows = getAssetsCashflowsSpouse(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.spouse?.corporatePensionDc;
    const income = cashflow?.data.income.spouse;
    return {
      age: cashflow.spouseAge ?? 0,
      contributionAmount: (income !== null) ? (datas?.contributionAmount ?? 0) : 0,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,

      // 企業年金（DC）一括受取額
      income: (income?.corporatePensionDcBulk ?? 0) + (income?.corporatePensionDcInstallments ?? 0),
    };
  });
  if (newDatas.length > 1) {
    const secondLastData = newDatas[newDatas.length - 2];
    const lastData = newDatas[newDatas.length - 1];

    // 最後の歳の元本積立額は、手前の歳の元本積立額と寄付金額を足した値
    lastData.acquisitionValue = (Math.round(secondLastData.acquisitionValue / 10000) * 10000)
      + (Math.round(lastData.contributionAmount / 10000) * 10000);

    // 最後の予想資産残高は、企業年金（DC）一括受取額を足した値
    lastData.presentValue += lastData.income;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// 個人年金 (iDeCo) - あなたの運用状況
export const selectAssetsIdeco = (state: RootState) => {
  const cashflows = getAssetsCashflows(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.householder?.ideco;
    const income = cashflow?.data.income.householder;
    return {
      age: cashflow.age,
      contributionAmount: (income !== null) ? (datas?.contributionAmount ?? 0) : 0,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,

      // 企業年金（iDeco）一括受取額
      income: (income?.idecoBulk ?? 0) + (income?.idecoInstallments ?? 0),
    };
  });
  if (newDatas.length > 1) {
    // 最後のデータは最後から2番目に寄付金額を足した値
    const secondLastData = newDatas[newDatas.length - 2];
    const lastData = newDatas[newDatas.length - 1];

    // 最後の歳の元本積立額は、手前の歳の元本積立額と寄付金額を足した値
    lastData.acquisitionValue = (Math.round(secondLastData.acquisitionValue / 10000) * 10000)
      + (Math.round(lastData.contributionAmount / 10000) * 10000);

    // 最後の予想資産残高は、企業年金（iDeco）一括受取額を足した値
    lastData.presentValue += lastData.income;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// 個人年金 (iDeCo) - 配偶者の運用状況
export const selectAssetsIdecoSpouse = (state: RootState) => {
  const cashflows = getAssetsCashflowsSpouse(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.spouse?.ideco;
    const income = cashflow?.data.income.spouse;
    return {
      age: cashflow.spouseAge ?? 0,
      contributionAmount: (income !== null) ? (datas?.contributionAmount ?? 0) : 0,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,

      // 企業年金（iDeco）一括受取額
      income: (income?.idecoBulk ?? 0) + (income?.idecoInstallments ?? 0),
    };
  });
  if (newDatas.length > 1) {
    // 最後のデータは最後から2番目に寄付金額を足した値
    const secondLastData = newDatas[newDatas.length - 2];
    const lastData = newDatas[newDatas.length - 1];

    // 最後の歳の元本積立額は、手前の歳の元本積立額と寄付金額を足した値
    lastData.acquisitionValue = (Math.round(secondLastData.acquisitionValue / 10000) * 10000)
      + (Math.round(lastData.contributionAmount / 10000) * 10000);

    // 最後の予想資産残高は、企業年金（iDeco）一括受取額を足した値
    lastData.presentValue += lastData.income;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// つみたてNISA - あなたの運用状況
// 資産形成用に定年までにデータを絞り込んだもの
// NISAのみ加入年月から20年を扱う
export const selectAssetsNisa = (state: RootState) => {
  const defaultJoinMonth = state.input.defaultNisaJoinMonthHouseholder;
  if (defaultJoinMonth === null || defaultJoinMonth === undefined) {
    // 新規登録後の加入年月がないときはグラフを非表示にするためundefinedを渡す
    return undefined;
  }
  const data = state.cashflow;
  // つみたてNISAの加入年月（本人）
  const nisaJoinMonth = state.input.assetFormation.householder.tsumitateNisa?.joinMonth;
  const year = moment(nisaJoinMonth).year();
  const newDatas = (nisaJoinMonth !== null && nisaJoinMonth !== undefined)
    ? data.list
      .filter((cashflow) => (cashflow.thisYear >= year && cashflow.thisYear <= year + 20))
      .map((cashflow) => {
        const datas = cashflow.data.financialAssets.householder;
        const tsumitateNisa = datas?.tsumitateNisa;
        return {
          age: cashflow.age,
          acquisitionValue: tsumitateNisa?.acquisitionValue ?? 0,
          presentValue: tsumitateNisa?.presentValue ?? 0,

          // 資産売却額
          dissavingAssetsAmount: datas?.unexpectedIncome.dissavingAssetsOfTsumitateNisaAmount ?? 0,
        };
      })
    : [];
  if (newDatas.length > 1) {
    const lastData = newDatas[newDatas.length - 1];

    // 最後の予想資産残高は、資産売却額の値
    lastData.presentValue = lastData.dissavingAssetsAmount;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// つみたてNISA - 配偶者の運用状況
// 資産形成用に定年までにデータを絞り込んだもの
// NISAのみ加入年月から20年を扱う
export const selectAssetsNisaSpouse = (state: RootState) => {
  const defaultJoinMonth = state.input.defaultNisaJoinMonthSpouse;
  if (defaultJoinMonth === null || defaultJoinMonth === undefined) {
    // 新規登録後の加入年月がないときはグラフを非表示にするためundefinedを渡す
    return undefined;
  }
  const data = state.cashflow;
  // つみたてNISAの加入年月（配偶者）
  const nisaJoinMonth = state.input.assetFormation.spouse.tsumitateNisa?.joinMonth;
  const year = moment(nisaJoinMonth).year();
  const newDatas = (nisaJoinMonth !== null && nisaJoinMonth !== undefined)
    ? data.list
      .filter((cashflow) => (cashflow.thisYear >= year && cashflow.thisYear <= year + 20))
      .map((cashflow) => {
        const datas = cashflow.data.financialAssets.spouse;
        const tsumitateNisa = datas?.tsumitateNisa;
        return {
          age: cashflow.spouseAge ?? 0,
          acquisitionValue: tsumitateNisa?.acquisitionValue ?? 0,
          presentValue: tsumitateNisa?.presentValue ?? 0,

          // 資産売却額
          dissavingAssetsAmount: datas?.unexpectedIncome.dissavingAssetsOfTsumitateNisaAmount ?? 0,
        };
      })
    : [];
  if (newDatas.length > 1) {
    const lastData = newDatas[newDatas.length - 1];

    // 最後の予想資産残高は、資産売却額の値
    lastData.presentValue = lastData.dissavingAssetsAmount;

    newDatas[newDatas.length - 1] = lastData;
  }
  return newDatas;
};

// 積立投信・投資信託 - あなたの運用状況
export const selectAssetsTrust = (state: RootState) => {
  const cashflows = getAssetsCashflows(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.householder?.investmentTrust;
    return {
      age: cashflow.age,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,
    };
  });
  return newDatas;
};

// 積立投信・投資信託 - 配偶者の運用状況
export const selectAssetsTrustSpouse = (state: RootState) => {
  const cashflows = getAssetsCashflowsSpouse(state);
  const newDatas = cashflows.map((cashflow) => {
    const datas = cashflow.data.financialAssets.spouse?.investmentTrust;
    return {
      age: cashflow.spouseAge ?? 0,
      acquisitionValue: datas?.acquisitionValue ?? 0,
      presentValue: datas?.presentValue ?? 0,
    };
  });
  return newDatas;
};

export default cashflowSlice.reducer;
