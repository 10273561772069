import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// images
import arrow from './assets/footer_nav_arrow.svg';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const manualPdf = require('./assets/Life100Manual.pdf');

// components
const NavLink = styled(Link)`
  display: inline-block;
  background-image: url('${arrow}');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
`;
const NavExternalLink = styled.a`
  display: inline-block;
  background-image: url('${arrow}');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
`;

const GlobalHeader: React.FC = () => {
  const copyright = '©2019 Mizuho Bank, Ltd.';
  return (
    <footer>
      <div className="bg-gray-200 text-gray-500">
        <div className="container">
          <section className="px-xs py-[50px]">
            <h3 className="text-xl font-bold">みずほの人生100年デザイナー</h3>
            <p className="text-base mt-2">
              確定拠出年金(DC)加入者向け
              <br />
              ライフプランシミュレーション
            </p>
            <nav>
              <ul className="mt-6 text-base">
                <li><NavLink to="/help">ヘルプ</NavLink></li>
                <li className="pt-1"><NavExternalLink href="https://www.mizuhobank.co.jp/privacy/index.html" target="_blank" rel="noopener noreferrer">個人情報のお取り扱いについて</NavExternalLink></li>
                <li className="pt-1"><NavLink to="/terms">ご利用規約</NavLink></li>
                <li className="pt-1"><NavExternalLink href="https://www.mizuhobank.co.jp/policy/index.html" target="_blank" rel="noopener noreferrer">金融商品勧誘方針</NavExternalLink></li>
                <li className="pt-1"><NavExternalLink href={manualPdf} target="_blank" rel="noopener noreferrer">利用方法</NavExternalLink></li>
              </ul>
            </nav>
          </section>
        </div>
      </div>
      <div className="bg-gray-500 text-white">
        <div className="container">
          <section className="px-xs pt-[50px] pb-[40px]">
            <h3 className="text-base font-bold">株式会社みずほ銀行</h3>
            <div className="text-sm mt-2">
              <p>確定拠出年金 運営管理業 登録番号 第71号</p>
              <p>登録金融機関 関東財務局長 (登金) 第6号</p>
              <p>加入協会：日本証券業協会</p>
              <p>一般社団法人金融先物取引業協会</p>
              <p>一般社団法人第二種金融商品取引業協会</p>
              <p>金融機関コード：0001</p>
            </div>
          </section>
        </div>
      </div>
      <div className="bg-gray-700 text-white">
        <div className="py-[30px] text-center">
          <p className="text-base font-alpha">{copyright}</p>
        </div>
      </div>
    </footer>
  );
};
export default GlobalHeader;
