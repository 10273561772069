import moment, { Moment } from 'moment';
import * as React from 'react';
// components
import FailedMessage from './FailedMessage';

// services
import { ValidationError } from '../../services/validation';
/**
 * 年月用セレクト項目
 * @param props
 * @returns
 */
 type InputYearMonthProps = {
   name?: string;
   defaultValue?: string|null;
   value?: string|null;
   startYear?: number;
   endYear?: number;
   onChange?: (_: string|null) => void;
   disabled?: boolean;
   showAge?: boolean;
   showDuring?: boolean;
   onError?: (_: ValidationError[]) => void;
   validate?: (_: string) => string | null;

   // 日付固定
   dayValue?: number;

 };
const InputYearMonth: React.FC<InputYearMonthProps> = ({
  name, defaultValue, value,
  startYear, endYear, onChange, disabled, showAge, showDuring,
  dayValue, onError, validate: propsValidate,
}) => {
  const validate = propsValidate;
  // デフォルト値
  const [defaultYear, setDefaultYear] = React.useState<number|null|undefined>(null);
  const [defaultMonth, setDefaultMonth] = React.useState<number|null|undefined>(null);
  const [defaultDay, setDefaultDay] = React.useState<number|null|undefined>(null);

  // 更新する値
  const [year, setYear] = React.useState<number|undefined>();
  const [month, setMonth] = React.useState<number|undefined>();
  const [day, setDay] = React.useState<number|undefined>();

  // ref属性の参照
  const yearRef = React.useRef<HTMLSelectElement>(null);
  const monthRef = React.useRef<HTMLSelectElement>(null);

  // 選択されている年月日と年齢
  const [date, setDate] = React.useState<moment.Moment>();
  const [age, setAge] = React.useState<number>();

  // ユニークなprefix
  const prefix = name;

  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<ValidationError[]>([]);

  // 年数リストを生成
  const createYears = () => {
    if (startYear !== undefined && endYear !== undefined) {
      return new Array(endYear - startYear + 1).fill(null).map((_, i) => i + startYear);
    } if (defaultValue !== undefined && defaultValue !== null) {
      return [moment(defaultValue).year()];
    } if (value !== undefined && value !== null) {
      return [moment(value).year()];
    }
    return [];
  };

  // 選択されている日付を取得
  const getCurrentDate = () => {
    const $year = yearRef.current;
    const $month = monthRef.current;
    if ($year !== null && $month !== null) {
      const y = parseInt($year.value, 10);
      const m = parseInt($month.value, 10) - 1;
      const d = ((defaultDay !== undefined && defaultDay !== null) ? defaultDay : day) ?? 1;
      const newDate = moment(new Date(y, m, d));
      return newDate;
    }
    return null;
  };

  // 年数リスト
  const [years, setYears] = React.useState(createYears());

  const onChangeValidate = (currentDate: Moment) => {
    const errorMessages: ValidationError[] = [];
    if (validate !== undefined) {
      const result = validate(currentDate.format('YYYY-MM-DD'));
      if (result !== null) {
        errorMessages.push({ key: 'range', message: result });
      }
      if (onError !== undefined) {
        onError(errorMessages);
      }
      setErrors(errorMessages);
    }
  };

  // 年月を変更したときに日付を送る
  const handleChange = (force = false) => {
    if (force) {
      const currentDate = getCurrentDate();
      if (currentDate !== null) {
        setDate(currentDate);
        if (onChange !== undefined) {
          onChange(currentDate.format('YYYY-MM-DD'));
        }
        onChangeValidate(currentDate);
      }
    }
  };

  React.useEffect(() => {
    // 年数リストを作成
    setYears(createYears());
  }, [startYear, endYear]);

  React.useEffect(() => {
    // デフォルトの年月指定
    if (defaultValue !== undefined && defaultValue !== null) {
      const defaultDate = moment(defaultValue);
      setDefaultYear(defaultDate.year());
      setDefaultMonth(defaultDate.month() + 1);
      setDefaultDay(dayValue !== undefined ? dayValue : defaultDate.date());

      // 選択されている年月日データを更新
      setDate(defaultDate);
    } else {
      setDefaultYear(undefined);
      setDefaultMonth(undefined);
      setDefaultDay(undefined);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    // 年月指定
    if (value !== undefined && value !== null) {
      const newDate = moment(value);
      setYear(newDate.year());
      setMonth(newDate.month() + 1);
      setDay(newDate.date());

      // 選択されている年月日データを更新
      setDate(newDate);
    } else {
      setYear(undefined);
      setMonth(undefined);
      setDay(undefined);
    }
  }, [value]);

  React.useEffect(() => {
    if (dayValue !== undefined && dayValue !== null) {
      setDay(dayValue);
    }
  }, [dayValue]);

  React.useEffect(() => {
    if (!showAge) return;

    // 年齢を更新
    if (date !== undefined) {
      const newAge = moment().diff(date, 'years');
      setAge(newAge);
    }
  }, [date]);

  return (
    <>
      {defaultYear !== null && defaultMonth !== null && (
        <div className="flex items-center">
          <div className={`form-select-wrap font-alpha w-[100px] ${disabled && 'disabled'}`}>
            <select
              name={`${prefix}-year`}
              id={`${prefix}-year`}
              className="form-select"
              defaultValue={defaultYear}
              value={year}
              ref={yearRef}
              onChange={() => {
                handleChange(true);
              }}
              disabled={disabled}
            >
              {
                years.map((n) => (
                  <option value={n} key={`${prefix}-year-${n}`}>{n}</option>
                ))
              }
            </select>
          </div>
          <span className="form-unit">年</span>
          <div className={`form-select-wrap font-alpha w-[80px] ${disabled && 'disabled'}`}>
            <select
              name={`${prefix}-month`}
              id={`${prefix}-month`}
              className="form-select"
              defaultValue={defaultMonth}
              value={month}
              ref={monthRef}
              onChange={() => {
                handleChange(true);
              }}
              disabled={disabled}
            >
              {Array.from(Array(12).keys()).map((n) => (
                <option value={n + 1} key={`${prefix}-month-${n}`}>{n + 1}</option>
              ))}
            </select>
          </div>
          <span className="form-unit">月</span>
          {showDuring && (<div>〜</div>)}
        </div>
      ) }
      {showAge && age !== undefined && (
        <p className="text-gray-500 text-base mt-4">
          <span>現在</span>
          <span className="font-alpha text-md mx-1">{age}</span>
          <span>歳</span>
        </p>
      )}
      {
        onError === undefined
        && <FailedMessage errors={errors} />
      }
    </>
  );
};
InputYearMonth.defaultProps = {
  name: '',
  defaultValue: undefined,
  value: undefined,
  startYear: undefined,
  endYear: undefined,
  onChange: undefined,
  disabled: false,
  showAge: false,
  showDuring: false,
  dayValue: undefined,
  onError: undefined,
  validate: undefined,
};

export default InputYearMonth;
