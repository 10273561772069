import * as React from 'react';
import type { ValidationError } from '../../services/validation';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// components
import FailedMessage from '../input/FailedMessage';

type SumTableProps = {
  className?: string;
  label?: string;
  value?: number|null;
  unit?: string;
  division?: number;
  empty?: string;
  errors?: ValidationError[];
  unitWidth?: string;
};

/**
 * 合計の表コンポーネント
 * @param props
 * @returns
 */
const SumTable: React.FC<SumTableProps> = ({
  className, label, value, unit, division, empty = '-', errors, unitWidth = 'w-15',
}) => {
  const divisionNum = (division !== undefined && division > 0) ? 10 ** division : 1;
  const output = (value !== undefined && value !== null)
    ? Math.round(value / divisionNum) : undefined;
  return (
    <div>
      <div className={`flex justify-between items-end ${className}`}>
        <p className="flex-shrink-0 text-lg font-bold">{label}</p>
        <div className="flex-auto text-alpha flex flex-col items-end text-2xl px-2 break-all">
          {output !== undefined && output !== null ? costValue(output, 10) : empty}
        </div>
        <p className={`${unitWidth} -mr-3 text-sm`}>{unit}</p>
      </div>
      <FailedMessage errors={errors} />
    </div>
  );
};
SumTable.defaultProps = {
  className: '',
  label: '',
  value: undefined,
  unit: undefined,
  division: undefined,
  empty: undefined,
  errors: undefined,
  unitWidth: undefined,
};

export default SumTable;
