import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectLifeAfterRetirement, setAfterRetirementLife } from '../../reducers/inputReducer';

// images
import imgHero from './assets/hero.svg';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import RetirementGraphSection from '../../features/section/RetirementGraphSection';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeRetireRetirement: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const defaultLifeAfterRetirement = useAppSelector(selectLifeAfterRetirement);

  // 入力データ
  const [lifeAfterRetirement, setLifeAfterRetirement] = React.useState(defaultLifeAfterRetirement);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setLifeAfterRetirement(defaultLifeAfterRetirement);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>老後の生活</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">老後も現状の生活水準を維持するための準備をしていきましょう。</p>
        <p className="text-sm mt-2 mb-6">
          老後に仕事をしない場合、公的年金だけの収入だと支出のほうが上回り不足が出てしまう可能性が高いです。
          その不足分をイメージし今と同じ生活水準を保てる準備を進めていきましょう。
          早ければ早いほどゆとりをもって資産形成の準備が行なえます。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <RetirementGraphSection>
            <div className="fieldset-body">
              <div className="form-label">
                <span>老後の暮らし方</span>
              </div>
              <InputRadioGroup
                name="lifeAfterRetirement"
                options={[
                  { value: 'FRUGALLY', label: '質素な暮らし', help: '老後の生活費 : 90%' },
                  { value: 'ASIS', label: '現状維持', help: '老後の生活費 : 100%' },
                  { value: 'LUXURY', label: '贅沢な暮らし', help: '老後の生活費 : 110%' },
                ]}
                defaultValue={lifeAfterRetirement}
                onChange={(event) => {
                  const { value } = event.currentTarget;
                  setLifeAfterRetirement(value);
                }}
              />
            </div>
          </RetirementGraphSection>
        </div>
        <FixedButton
          disabled={deepEqual(defaultLifeAfterRetirement, lifeAfterRetirement)}
          contentId="retirement"
          onClick={() => {
            dispatch(setAfterRetirementLife(lifeAfterRetirement));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeRetireRetirement;
