import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

// error
import checkApiError from './checkApiError';

// set
import { setUserConfig, setInitialSettingStatus } from '../userReducer';
import { setErrorMessages, resetErrorMessages } from '../errorReducer';

// ユーザ情報更新APIからリクエストされたデータの型
type UpdateAccountResponseData = {
  annualIncome?: number|null,
  birthDay?: string|null,
  currentToken?: string|null,
  email?: string|null,
  initialSettingStatus?: boolean|null,
  jisTid?: string|null,
  lifeStyle?: string|null,
  nickname?: string|null,
  requestForEmailNewsLetter?: boolean|null,
}

// ユーザ情報更新
// ユーザ登録、ユーザ登録情報の変更
type UpdateAccount = {
  nickname?: string|null,
  email?: string|null,
  requestForEmailNewsLetter?: boolean,
  annualIncome?: number|null,
  initialSettingStatus?: boolean,
  loginFlg?: boolean,
}
export const createAccountAsync = createAsyncThunk<boolean, UpdateAccount, { state: RootState }>('auth/createAccountAsync', async (params, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState();
  const domain = process.env.REACT_APP_API_DOMAIN;

  // サーバーエラーを消す
  dispatch(resetErrorMessages());

  if (domain === '') {
    // ローカルテストのときは、APIのアクセスをスキップ
    dispatch(setUserConfig(params));
    return true;
  }

  try {
    const response = await axios.put(`${domain}/userAccount`, {
      userAccount: {
        jisTid: state.user.account.jisTid,
        nickname: params.nickname ?? state.user.account.nickname,
        email: params.email ?? state.user.account.email,
        requestForEmailNewsLetter: params.requestForEmailNewsLetter
          ?? state.user.account.requestForEmailNewsLetter,
        annualIncome: params.annualIncome ?? state.user.account.annualIncome,
        birthDay: state.user.account.birthDay,
        lifeStyle: state.user.account.lifeStyle,
        initialSettingStatus: params.initialSettingStatus
          ?? state.user.account.initialSettingStatus,
        loginFlg: state.user.account.loginFlg,
      },
    }, {
      headers: {
        accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'jis-tid': state.user.account.jisTid,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        'cf-api-token': state.user.token,
      },
    });

    // }, getRequestOption(state.user.account.jisTid, state.user.token));

    // JSONデータからエラーかどうか判断する
    const error = checkApiError(response);
    if (error) {
      dispatch(setErrorMessages(error));
      return false;
    }
    // 値を保存
    dispatch(setUserConfig(params));

    // APIからリクエストされたデータを読み込み
    const data = response.data as UpdateAccountResponseData;
    if (data !== null) {
      if (data.initialSettingStatus !== undefined) {
        // initialSettingStatusを更新
        dispatch(setInitialSettingStatus(data.initialSettingStatus));
      }
    }

    return true;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      // eslint-disable-next-line no-alert
      alert(errorData);
      throw new Error(errorData);
    }
  }
  return false;
});
export default createAccountAsync;
