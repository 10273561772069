import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// components
const InputRadio = styled.input`
  display: none;
`;
const InputRadioIcon = styled.span`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: ${theme`colors.gray-200`};
  background-color: white;
  flex-shrink: 0;
  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    margin-left: -30%;
    margin-top: -30%;
    border-radius: 50%;
    background-color: ${theme`colors.primary`};
  }
  ${InputRadio}:checked + &:before {
    display: block;
  }
`;

export type InputRadioOption = {
  label: string,
  value: string|number
  help?: string,
}

type InputRadioGroupProps = {
  name?: string;
  className?: string;
  defaultValue?: string|number|null;
  options: InputRadioOption[];
  onChange?: (_: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

/**
 * ラジオボタン項目
 * @param props
 * @returns
 */
const InputRadioGroup: React.FC<InputRadioGroupProps> = ({
  name, className, defaultValue, options, onChange, disabled,
}) => {
  // ユニークなprefix
  const prefix = name;

  return (
    <div className={`flex flex-col space-y-3 ${className} ${disabled && 'opacity-50'}`}>
      {
        options.map((option) => (
          <label htmlFor={`${prefix}-radio-${option.value}`} className="flex items-start" key={`${prefix}-radio-${option.value}`}>
            <InputRadio type="radio" id={`${prefix}-radio-${option.value}`} name={`${prefix}-radio`} value={option.value} checked={defaultValue === option.value} onChange={onChange} disabled={disabled} />
            <InputRadioIcon className="mt-0.5" />
            <div className="ml-2">
              <p className="text-base leading-normal whitespace-pre-wrap break-all">{option.label}</p>
              {option.help !== undefined && <p className="text-xs whitespace-pre-wrap mt-1 break-all">{option.help}</p>}
            </div>
          </label>
        ))
      }
    </div>
  );
};
InputRadioGroup.defaultProps = {
  name: '',
  className: '',
  defaultValue: undefined,
  onChange: undefined,
  disabled: false,
};

export default InputRadioGroup;
