import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

const Tooltip = styled.div`
  position: absolute;
  left: 0;
  color: ${theme`colors.white`};
  background-color: ${theme`colors.primary`};
  font-size: ${theme`fontSize.sm`};
  line-height: 1.7;
  padding: ${theme`padding.4`} ${theme`padding.5`};
  font-weight: 400;
`;
const TooltipArrow = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  margin-bottom: -15px;
  border-left: 8.5px solid transparent;
  border-right: 8.5px solid transparent;
  border-top: 16px solid ${theme`colors.primary`};
`;

type HelpButtonProps = {
  className?: string;
}
const HelpButton: React.FC<HelpButtonProps> = ({ className, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // ref属性の参照
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const tooltipArrowRef = React.useRef<HTMLSpanElement>(null);

  // 開閉する
  const toggle = () => {
    if (buttonRef.current !== null && tooltipRef.current !== null) {
      const { offsetTop, offsetLeft } = buttonRef.current;
      const { clientHeight } = tooltipRef.current;
      tooltipRef.current.style.top = `${offsetTop - clientHeight - 25}px`;
      if (tooltipArrowRef.current !== null) {
        tooltipArrowRef.current.style.left = `${offsetLeft}px`;
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={toggle}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
        ref={buttonRef}
      >
        <span className="icon-question" />
      </button>
      <Tooltip ref={tooltipRef} className={isOpen ? 'visible' : 'invisible'}>
        {children}
        <TooltipArrow ref={tooltipArrowRef} />
      </Tooltip>
    </>
  );
};
HelpButton.defaultProps = {
  className: '',
};
export default HelpButton;
