import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectHasIdeco, selectOpeAmt } from '../../reducers/userReducer';
import {
  selectHasSpouse,
  selectAssetIdecoHouseholder, selectAssetIdecoSpouse,
  setAssetIdecoHouseholder, setAssetIdecoSpouse,
  getAssetFormationItem,
} from '../../reducers/inputReducer';
import type { AssetFormationDataItem } from '../../reducers/inputReducer';
import { selectRiskIdeco, selectRiskIdecoSpouse } from '../../reducers/riskReducer';

// images
import imgHero from './assets/hero.svg';

// components
import Append from '../../features/button/Append';
import Fieldset from './Fieldset';
import FieldsetSpouse from './FieldsetSpouse';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeAssetIdeco: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const hasSpouse = useAppSelector(selectHasSpouse);

  // iDecoが有効かどうか
  const isIdeCoLogin = useAppSelector(selectHasIdeco);
  const opeAmt = useAppSelector(selectOpeAmt);

  // あなたの運用状況
  const defaultHouseholder = useAppSelector(selectAssetIdecoHouseholder);
  const [householder, setHouseholder] = React.useState(defaultHouseholder);

  // 配偶者の確定拠出年金
  const defaultSpouse = useAppSelector(selectAssetIdecoSpouse);
  const [spouse, setSpouse] = React.useState(defaultSpouse);

  // リスクデータ
  const riskDatasHouseholder = useAppSelector(selectRiskIdeco);
  const riskDatasSpouse = useAppSelector(selectRiskIdecoSpouse);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setHouseholder(defaultHouseholder);
      setSpouse(defaultSpouse);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>個人年金 (iDeCo)</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">若いうちから始めやすい、老後資金の準備方法として注目のiDeCo。</p>
        <p className="text-sm mt-2 mb-6">
          掛金を5,000円以上1,000円単位で自分で選べるので、まとまったお金が無くても手軽に始められます。
          毎月コツコツ貯めることができ受取は60歳以降なので「預金をつい引き出して使ってしまう」という方でも老後資金を着実に作ることができます。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">あなたの運用状況</h3>
            <Fieldset
              data={householder}
              onChange={(data) => setHouseholder(data)}
            />
          </section>
          <div>
            <div className="py-8 border-primary border-t">
              <Append
                label={showSpouse ? '詳細を閉じる（配偶者）' : '配偶者のiDeCoを入力する'}
                onClick={() => setShowSpouse(!showSpouse)}
                minus={showSpouse}
                disabled={!hasSpouse}
              />
            </div>
          </div>
          {showSpouse && (
            <section>
              <h3 className="fieldset-title">配偶者の運用状況</h3>
              <FieldsetSpouse
                prefix="spouse"
                data={spouse}
                onChange={(data) => setSpouse(data)}
              />
            </section>
          ) }
        </div>
        <FixedButton
          disabled={deepEqual(defaultHouseholder, householder) && deepEqual(defaultSpouse, spouse)}
          contentId="ideco"
          onClick={() => {
            dispatch(setAssetIdecoHouseholder(getAssetFormationItem(householder, {
              riskDatas: riskDatasHouseholder,
              isIdeCoLogin,
              opeAmt,
            }) as AssetFormationDataItem));
            dispatch(setAssetIdecoSpouse(getAssetFormationItem(spouse, {
              riskDatas: riskDatasSpouse,
            }) as AssetFormationDataItem));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeAssetIdeco;
