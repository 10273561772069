import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// 認証情報、ユーザ情報
import { useAppDispatch } from '../../app/hooks';
import {
  fetchDebug, fetchAsync, guestAsync, mockObjects,
} from '../../reducers/authReducer';

// デバッグモード判定
const debug = process.env.REACT_APP_DEBUG === '1';

const Debug: React.FC = () => {
  if (!debug) {
    return null;
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ref属性の参照
  const debugIdRef = React.useRef<HTMLInputElement>(null);
  const mockSelectRef = React.useRef<HTMLSelectElement>(null);

  return (
    <div className="fixed left-4 top-20 z-50 flex flex-col w-60">
      <button
        type="button"
        className="btn-link"
        onClick={async () => {
          // 疑似ゲストログイン
          await dispatch(guestAsync());
          navigate('/registration/guest', { replace: true });
        }}
      >
        ゲストログイン
      </button>
      <div className="mt-8">
        <input type="text" placeholder="デバッグ用JIST ID" className="form-input w-full" ref={debugIdRef} />
        <button
          type="button"
          className="btn-link mt-2"
          onClick={async () => {
            // デバッグログイン
            const debugIdValue = debugIdRef.current !== null ? debugIdRef.current.value : '';
            const loginAsync = async (): Promise<void> => {
              await dispatch(fetchAsync({ authCode: 'debug', sessionCookie: 'debug', jisTid: debugIdValue }));
              navigate('/home', { replace: true });
            };
            loginAsync();
          }}
        >
          Debug ログイン
        </button>
      </div>
      <div className="mt-8">
        <select
          className="form-select w-full mt-2"
          ref={mockSelectRef}
        >
          <option value="">選択</option>
          {mockObjects
            .map((obj) => <option value={obj.jistid} key={obj.jistid}>{obj.label}</option>)}
        </select>
        <button
          type="button"
          className="btn-link mt-2"
          onClick={async () => {
            // 疑似ログイン
            const debugIdValue = mockSelectRef.current !== null ? mockSelectRef.current.value : '';
            await dispatch(fetchDebug(debugIdValue));
            navigate('/home');
          }}
        >
          Mock ログイン
        </button>
      </div>
    </div>
  );
};
export default Debug;
