import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../app/store';

import { getEducationExpense, getHasDc, getHasIdeco } from './userReducer';
import type { EducationExpensesMatrix, Grade } from './userReducer';
import type { FetchResponseInputData } from './async/fetchAsync';
import {
  selectRiskDc,
  selectRiskDcSpouse,
  selectRiskIdeco,
  selectRiskIdecoSpouse,
  selectRiskNisa,
  selectRiskTrust,
} from './riskReducer';
import type { Risk } from './riskReducer';
import type { EstimatedValueData } from './estimatedReducer';
import {
  LOAN_START_AGE_ADDITION,
  PUBLIC_PENSION_START_AGE_OF_PENSION,
  SPOUSE_DEFAULT_ANNUAL_INCOME,
  MINIMUM_ANNUAL_INCOME,
} from '../config/validation';

// つみたてNISAがnullだったときのデフォルトの開始年月日
const defaultJoinMonthNisa = moment().format('YYYY-MM-DD');
// 積立投信・投資信託がnullだったときのデフォルトの開始年月日
const defaultJoinMonthTrust = moment().format('YYYY-MM-DD');

// 1. 家族構成
export type Family = {
  spouse: {
    hasSpouse: boolean|null,
    birthMonth: string|null,
    willHaveSpouse: boolean,
    ageOfMarriagePlanHouseholder: number|null,
    ageOfMarriagePlanSpouse: number|null,
  },
  children: {
    hasChildren: boolean|null,
    birthMonth: string[],
    willHaveChildren: boolean,
    expectedBirthYear: string[],
  },
}

// 2. 収入 - 本人/配偶者
export type IncomeData = {
  depositAmount: number|null,
  employmentStatus: string|null,
  annualIncome: number|null,
  industry: string|null,
  numberOfEmployees: number|null,
  lengthOfService: number|null,
  incomeGrowthRate: string|null,
  occupation: string|null,
  willHaveChildcareLeave: boolean|null,
  postpartumWorkStyle: string|null,
}

// 2. 収入
export type Income = {
  householder: IncomeData,
  spouse: IncomeData,
}

// 3. 支出 -  生活費
export type LivingExpenses = {
  monthlyLivingExpenses: number|null,
  monthlyTermInsurance: number|null,
  carMaintenanceExpensesMonthly: number|null,
  endAgeOfCarMaintenance: number|null,
  annualTaxes: number|null,
  annualTravelExpenses: number|null,
  annualOtherExpenses: number|null,
  inflationRate: string|null,
  savingExpensesRate: number|null,
}

// 3. 支出 -  教育費 - こどもの要素 - 各学年ごとの情報
export type EducationExpensesChildrenSchool = {
  publicPrivate: string|null,
  entranceFee: number|null,
  annualTuition: number|null,
  annualLessonFee: number|null,
}

// 3. 支出 -  教育費 - こどもの要素
export type EducationExpensesChildren = {
  primarySchool: EducationExpensesChildrenSchool,
  juniorHighSchool: EducationExpensesChildrenSchool,
  highSchool: EducationExpensesChildrenSchool,
  university: EducationExpensesChildrenSchool,
}

// 3. 支出 -  教育費
export type EducationPolicy = 'ALL_PUBLIC'|'PRIVATE_FROM_UNIVERSITY'|'PRIVATE_FROM_HIGH_SCHOOL'|'VERY_EXPENSIVE'|'CUSTOMIZATION';
export type EducationExpenses = {
  educationPolicy: EducationPolicy|null,
  children: EducationExpensesChildren[],
}

// 3. 支出 - 居住費 - 住宅ローン（本人/配偶者）
export type HousingExpensesLoanData = {
  hasMortgageLoan: boolean|null,
  loanStartYear: string|null,
  loanAmount: number|null,
  loanPeriod: number|null,
  interestCalculationMethod:string|null,
  loanRate: number|null,
  periodOfFixedRate: number|null,
  repaymentMethod: string|null,
  presentLoanAmount: number|null,
  baseDateOfPresentAmount: string|null,
}

// 3. 支出 - 居住費 - 住宅購入予定
export type WhoWillHaveMortgageLoan = 'YOU'|'SPOUSE'|'YOU_SPOUSE';
export type PlanToBuyHouse = {
  willBuyHouse: boolean|null,
  ageOfPlanToBuyHouseHouseholder: number|null,
  ageOfPlanToBuyHouseSpouse: number|null,
  amountOfPlanToBuyHouse: number|null,
  willHaveMortgageLoan: boolean|null,
  whoWillHaveMortgageLoan: WhoWillHaveMortgageLoan|null,
  downPayment: number|null,
  loanAmountHouseholder: number|null,
  loanAmountSpouse: number|null,
  loanPeriod: number|null,
  interestCalculationMethod: string|null,
  loanRate: number|null,
  periodOfFixedRate: number|null,
  repaymentMethod: string|null,
}

// 3. 支出 - 居住費 - 繰り上げ返済
export type PrepaymentLoan = {
  willHavePrepaymentOfLoan: boolean|null,
  minimumDepositAmount: number|null,
  hasConsiderationOfDeduction: boolean|null,
  targetLoan: string|null,
}

// 3. 支出 - 居住費
export type HousingExpenses = {
  presentHouse: string|null,
  monthlyRent: number|null,
  monthlyHousingExpense: number|null,
  maintenanceFee: number|null,
  loanOfHouseholder: HousingExpensesLoanData,
  loanOfSpouse: HousingExpensesLoanData,
  planToBuyHouse: PlanToBuyHouse,
  prepaymentLoan: PrepaymentLoan,
}

// 3. 支出
export type Expenses = {
  livingExpenses: LivingExpenses,
  educationExpenses: EducationExpenses,
  housingExpenses: HousingExpenses,
}

// 4. 退職金・年金 - 本人
export type AfterRetirementHouseholder = {
  corporatePensionDc: {
    startAgeOfPension: number|null,
    yearsOfPensionDrawing: string|null,
    drawTypeOfPension: string|null,
  },
  ideco: {
    startAgeOfPension: number|null,
    yearsOfPensionDrawing: string|null,
    drawTypeOfPension: string|null,
  },
  publicPension: {
    startAgeOfPension: number|null,
    monthlyPensionAmount: number|null,
  },
  corporatePensionDb: {
    startAgeOfPension: number|null,
    drawTypeOfPension: string|null,
  },
  retirementAllowance: number|null,
}

// 4. 退職金・年金 - 配偶者
export type AfterRetirementSpouse = {
  corporatePensionDc: {
    startAgeOfPension: number|null,
    yearsOfPensionDrawing: string|null,
    drawTypeOfPension: string|null,
  },
  ideco: {
    startAgeOfPension: number|null,
    yearsOfPensionDrawing: string|null,
    drawTypeOfPension: string|null,
  },
  publicPension: {
    startAgeOfPension: number|null,
    monthlyPensionAmount: number|null,
  },
  retirementAllowance: {
    retirementAge: number|null,
    retirementAllowance: number|null,
  },
}

// 4. 退職金・年金 - 老後の再就職
export type AfterRetirementReEmployment = {
  startAgeOfReEmployment: number|null,
  willBeReEmployed: boolean|null,
  annualIncome: number|null,
  endAgeOfReEmployment: number|null,
}

// 4. 退職金・年金
export type AfterRetirement = {
  reEmploymentAfterRetirement: AfterRetirementReEmployment,
  lifeAfterRetirement: string|null,
  householder: AfterRetirementHouseholder,
  spouse: AfterRetirementSpouse,
}

// 5. 資産形成 - 企業年金 (DC)
export type AssetFormationDcItem = {
  presentValue: number|null,
  monthlyContributionAmount: number|null,
  monthlyContributionAmountOfEmployee: number|null,
  riskValue: number|null,
  hasBeJoined: string,
  expectedReturn: number|null,
  joinMonth: string|null,
  acquisitionValue: string|number|null,
  volatility: number|null,
}

// 5. 資産形成 - 個人年金 (iDeco) 、つみたてNISA、積立投信・投資信託
export type AssetFormationDataItem = {
  hasBeJoined: string,
  joinMonth: string|null,
  monthlyContributionAmount?: number|null,
  presentValue?: number|null,
  acquisitionValue?: string|number|null,
  riskValue: number|null,
  expectedReturn?: number|null,
  volatility?: number|null,
}

// 5. 資産形成 - 積立定期・その他
export type AssetFormationOtherItem = {
  presentValue: number|null,
  monthlyContributionAmount: number|null,
  startMonth: string|null,
  endMonth: string|null,
  totalReturn: number|null,
  hasBeJoined: string,
  acquisitionValue: string|number|null,
}

// 5. 資産形成 - 本人
export type AssetFormationHouseholder = {
  corporatePensionDc: AssetFormationDcItem,
  ideco: AssetFormationDataItem,
  tsumitateNisa: AssetFormationDataItem,
  investmentTrust: AssetFormationDataItem,
  otherInvestments: AssetFormationOtherItem[],
}

// 5. 資産形成 - 配偶者
export type AssetFormationSpouse = {
  corporatePensionDc: AssetFormationDcItem,
  ideco: AssetFormationDataItem,
  tsumitateNisa: AssetFormationDataItem,
  investmentTrust: AssetFormationDataItem,
}

// 5. 資産形成
export type AssetFormation = {
  householder: AssetFormationHouseholder,
  spouse: AssetFormationSpouse,
}

type InputData = {
  family?: Family,
  income?: Income,
  expenses?: Expenses,
  afterRetirement?: AfterRetirement,
  assetFormation?: AssetFormation,
}

export type InputBasic = {
  birthDate: string|null,
  companyFlg: number|null,
  opeAmt: string|null
}

export type InputEstimatedValue = {
  householder?: EstimatedValueData,
  spouse?: EstimatedValueData,
}

type State = {
  // 1. 家族構成
  family: Family,

  // 2. 収入
  income: Income,

  // 3. 支出
  expenses: Expenses,

  // 4. 退職金・年金
  afterRetirement: AfterRetirement,

  // 5. 資産形成
  assetFormation: AssetFormation,

  // 一時データ
  temp?: InputData,

  // 本人の生年月日
  birthDate?: string|null,

  // 基本情報
  basic: InputBasic,

  // 教育費用情報
  educationExpensesMatrix?: EducationExpensesMatrix[],

  // つみたてNISAのデフォルトの加入年月
  defaultNisaJoinMonthHouseholder: string|null,
  defaultNisaJoinMonthSpouse: string|null,

  // 推計値
  estimatedValue?: InputEstimatedValue
}

const initialState: State = {
  family: {
    spouse: {
      hasSpouse: null,
      birthMonth: null,
      willHaveSpouse: false,
      ageOfMarriagePlanHouseholder: null,
      ageOfMarriagePlanSpouse: null,
    },
    children: {
      hasChildren: null,
      birthMonth: [],
      willHaveChildren: false,
      expectedBirthYear: [],
    },
  },
  income: {
    householder: {
      depositAmount: null,
      annualIncome: null,
      employmentStatus: null,
      industry: null,
      numberOfEmployees: null,
      lengthOfService: null,
      incomeGrowthRate: null,
      occupation: null,
      willHaveChildcareLeave: null,
      postpartumWorkStyle: null,
    },
    spouse: {
      depositAmount: null,
      annualIncome: null,
      employmentStatus: null,
      industry: null,
      numberOfEmployees: null,
      lengthOfService: null,
      incomeGrowthRate: null,
      occupation: null,
      willHaveChildcareLeave: null,
      postpartumWorkStyle: null,
    },
  },
  expenses: {
    livingExpenses: {
      monthlyLivingExpenses: null,
      monthlyTermInsurance: null,
      carMaintenanceExpensesMonthly: null,
      endAgeOfCarMaintenance: null,
      annualTaxes: null,
      annualTravelExpenses: null,
      annualOtherExpenses: null,
      inflationRate: null,
      savingExpensesRate: null,
    },
    educationExpenses: {
      educationPolicy: null,
      children: [],
    },
    housingExpenses: {
      presentHouse: null,
      monthlyRent: null,
      monthlyHousingExpense: null,
      maintenanceFee: null,
      loanOfHouseholder: {
        hasMortgageLoan: null,
        loanStartYear: null,
        loanAmount: null,
        loanPeriod: null,
        interestCalculationMethod: null,
        loanRate: null,
        periodOfFixedRate: null,
        repaymentMethod: null,
        presentLoanAmount: null,
        baseDateOfPresentAmount: null,
      },
      loanOfSpouse: {
        hasMortgageLoan: null,
        loanStartYear: null,
        loanAmount: null,
        loanPeriod: null,
        interestCalculationMethod: null,
        loanRate: null,
        periodOfFixedRate: null,
        repaymentMethod: null,
        presentLoanAmount: null,
        baseDateOfPresentAmount: null,
      },
      planToBuyHouse: {
        willBuyHouse: null,
        ageOfPlanToBuyHouseHouseholder: null,
        amountOfPlanToBuyHouse: null,
        willHaveMortgageLoan: null,
        whoWillHaveMortgageLoan: null,
        loanAmountHouseholder: null,
        loanAmountSpouse: null,
        downPayment: null,
        loanPeriod: null,
        interestCalculationMethod: null,
        loanRate: null,
        periodOfFixedRate: null,
        repaymentMethod: null,
        ageOfPlanToBuyHouseSpouse: null,
      },
      prepaymentLoan: {
        willHavePrepaymentOfLoan: null,
        minimumDepositAmount: null,
        hasConsiderationOfDeduction: null,
        targetLoan: null,
      },
    },
  },
  afterRetirement: {
    householder: {
      retirementAllowance: null,
      publicPension: {
        startAgeOfPension: null,
        monthlyPensionAmount: null,
      },
      corporatePensionDb: {
        startAgeOfPension: null,
        drawTypeOfPension: null,
      },
      corporatePensionDc: {
        startAgeOfPension: null,
        yearsOfPensionDrawing: null,
        drawTypeOfPension: null,
      },
      ideco: {
        startAgeOfPension: null,
        yearsOfPensionDrawing: null,
        drawTypeOfPension: null,
      },
    },
    spouse: {
      retirementAllowance: {
        retirementAge: null,
        retirementAllowance: null,
      },
      publicPension: {
        startAgeOfPension: null,
        monthlyPensionAmount: null,
      },
      corporatePensionDc: {
        startAgeOfPension: null,
        yearsOfPensionDrawing: null,
        drawTypeOfPension: null,
      },
      ideco: {
        startAgeOfPension: null,
        yearsOfPensionDrawing: null,
        drawTypeOfPension: null,
      },
    },
    lifeAfterRetirement: null,
    reEmploymentAfterRetirement: {
      willBeReEmployed: null,
      startAgeOfReEmployment: null,
      endAgeOfReEmployment: null,
      annualIncome: null,
    },
  },
  assetFormation: {
    householder: {
      corporatePensionDc: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        monthlyContributionAmountOfEmployee: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
      ideco: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
      tsumitateNisa: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
      },
      investmentTrust: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
      otherInvestments: [],
    },
    spouse: {
      corporatePensionDc: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        monthlyContributionAmountOfEmployee: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
      ideco: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
      tsumitateNisa: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
      },
      investmentTrust: {
        hasBeJoined: 'NOT_JOINED',
        joinMonth: null,
        monthlyContributionAmount: null,
        presentValue: null,
        acquisitionValue: null,
        riskValue: null,
        expectedReturn: null,
        volatility: null,
      },
    },
  },
  basic: {
    birthDate: null,
    companyFlg: null,
    opeAmt: null,
  },
  defaultNisaJoinMonthHouseholder: null,
  defaultNisaJoinMonthSpouse: null,
};

// APIからの入力データから整形データを生成
const createData = (
  data: FetchResponseInputData,
  basic: InputBasic,
  estimatedValue?:InputEstimatedValue,
): {
  family: Family,
  income: Income,
  expenses: Expenses,
  afterRetirement: AfterRetirement,
  assetFormation: AssetFormation,
} => {
  const {
    family, income, expenses, afterRetirement, assetFormation,
  } = data;

  // 支出
  const { livingExpenses, educationExpenses, housingExpenses } = expenses ?? {};
  const {
    loanOfHouseholder, loanOfSpouse, planToBuyHouse, prepaymentLoan,
  } = housingExpenses ?? {};

  // 退職金・年金
  const { reEmploymentAfterRetirement } = afterRetirement ?? {};
  const afterHh = afterRetirement?.householder;
  const afterSpouse = afterRetirement?.spouse;

  // 資産形成
  const assetHh = assetFormation?.householder;
  const assetSpouse = assetFormation?.spouse;

  // DCログイン
  const isDcLogin = getHasDc(basic.companyFlg);

  // iDecoログイン
  const isIdecoLogin = getHasIdeco(basic.companyFlg);

  return {
    family: {
      spouse: {
        hasSpouse: family?.spouse?.hasSpouse ?? null,
        birthMonth: family?.spouse?.birthMonth ?? null,
        willHaveSpouse: family?.spouse?.willHaveSpouse ?? false,
        ageOfMarriagePlanHouseholder: family?.spouse?.ageOfMarriagePlanHouseholder ?? null,
        ageOfMarriagePlanSpouse: family?.spouse?.ageOfMarriagePlanSpouse ?? null,
      },
      children: {
        hasChildren: data.family?.children?.hasChildren ?? null,
        birthMonth: data.family?.children?.birthMonth ?? [],
        willHaveChildren: data.family?.children?.willHaveChildren ?? false,
        expectedBirthYear: data.family?.children?.expectedBirthYear ?? [],
      },
    },
    income: {
      householder: {
        depositAmount: (() => {
          const value = income?.householder?.depositAmount ?? null;
          if (value !== null) {
            return Math.round(value / 100) * 100;
          }
          return null;
        })(),
        annualIncome: (() => {
          const value = income?.householder?.annualIncome ?? null;
          if (value !== null) {
            return Math.round(value / 1000) * 1000;
          }
          return null;
        })(),
        employmentStatus: income?.householder?.employmentStatus ?? null,
        industry: income?.householder?.industry ?? null,
        numberOfEmployees: income?.householder?.numberOfEmployees ?? null,
        lengthOfService: income?.householder?.lengthOfService ?? null,
        incomeGrowthRate: income?.householder?.incomeGrowthRate ?? null,
        occupation: income?.householder?.occupation ?? null,
        willHaveChildcareLeave: income?.householder?.willHaveChildcareLeave ?? null,
        postpartumWorkStyle: income?.householder?.postpartumWorkStyle ?? null,
      },
      spouse: {
        depositAmount: (() => {
          const value = income?.spouse?.depositAmount ?? null;
          if (value !== null) {
            return Math.round(value / 100) * 100;
          }
          return null;
        })(),
        annualIncome: (() => {
          const value = income?.spouse?.annualIncome ?? null;
          if (value !== null) {
            return Math.round(value / 1000) * 1000;
          }
          return null;
        })(),
        employmentStatus: income?.spouse?.employmentStatus ?? null,
        industry: income?.spouse?.industry ?? null,
        numberOfEmployees: income?.spouse?.numberOfEmployees ?? null,
        lengthOfService: income?.spouse?.lengthOfService ?? null,
        incomeGrowthRate: income?.spouse?.incomeGrowthRate ?? null,
        occupation: income?.spouse?.occupation ?? null,
        willHaveChildcareLeave: income?.spouse?.willHaveChildcareLeave ?? null,
        postpartumWorkStyle: income?.spouse?.postpartumWorkStyle ?? null,
      },
    },
    expenses: {
      livingExpenses: {
        monthlyLivingExpenses: (() => {
          const value = livingExpenses?.monthlyLivingExpenses ?? null;
          if (value !== null) {
            // 生活費（月間）は千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        monthlyTermInsurance: (() => {
          const value = livingExpenses?.monthlyTermInsurance ?? null;
          if (value !== null) {
            // 保険料（掛け捨て）は千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        carMaintenanceExpensesMonthly: (() => {
          const value = livingExpenses?.carMaintenanceExpensesMonthly ?? null;
          if (value !== null) {
            // 千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        endAgeOfCarMaintenance: livingExpenses?.endAgeOfCarMaintenance ?? null,
        annualTaxes: (() => {
          const value = livingExpenses?.annualTaxes ?? null;
          if (value !== null) {
            // 100単位で四捨五入する
            return Math.round(value / 100) * 100;
          }
          return null;
        })(),
        annualTravelExpenses: (() => {
          const value = livingExpenses?.annualTravelExpenses ?? null;
          if (value !== null) {
            // 100単位で四捨五入する
            return Math.round(value / 100) * 100;
          }
          return null;
        })(),
        annualOtherExpenses: (() => {
          const value = livingExpenses?.annualOtherExpenses ?? null;
          if (value !== null) {
            // 100単位で四捨五入する
            return Math.round(value / 100) * 100;
          }
          return null;
        })(),
        inflationRate: livingExpenses?.inflationRate ?? null,
        savingExpensesRate: livingExpenses?.savingExpensesRate ?? null,
      },
      educationExpenses: {
        educationPolicy: (() => {
          if (educationExpenses?.educationPolicy !== undefined
            && educationExpenses?.educationPolicy !== null) {
            return educationExpenses.educationPolicy as EducationPolicy;
          }
          return null;
        })(),
        children: (() => {
          const children = educationExpenses?.children ?? [];
          return children.map(((c) => {
            // 教育費を100単位で四捨五入する
            const round = ((school: EducationExpensesChildrenSchool) => {
              return {
                annualLessonFee: school.annualLessonFee !== null
                  ? Math.round(school.annualLessonFee / 100) * 100 : 0,
                annualTuition: school.annualTuition !== null
                  ? Math.round(school.annualTuition / 100) * 100 : 0,
                entranceFee: school.entranceFee !== null
                  ? Math.round(school.entranceFee / 100) * 100 : 0,
                publicPrivate: school.publicPrivate,
              };
            });
            return {
              highSchool: round(c.highSchool),
              juniorHighSchool: round(c.juniorHighSchool),
              primarySchool: round(c.primarySchool),
              university: round(c.university),
            };
          }));
        })(),
      },
      housingExpenses: {
        presentHouse: housingExpenses?.presentHouse ?? null,
        monthlyRent: (() => {
          const value = housingExpenses?.monthlyRent ?? null;
          if (value !== null) {
            // 千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        monthlyHousingExpense: (() => {
          const value = housingExpenses?.monthlyHousingExpense ?? null;
          if (value !== null) {
            // 千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        maintenanceFee: (() => {
          const value = housingExpenses?.maintenanceFee ?? null;
          if (value !== null) {
            // 千円単位で四捨五入する
            return Math.round((Math.round(value / 100) * 100) / 1000) * 1000;
          }
          return null;
        })(),
        loanOfHouseholder: {
          hasMortgageLoan: loanOfHouseholder?.hasMortgageLoan ?? null,
          loanStartYear: loanOfHouseholder?.loanStartYear ?? null,
          loanAmount: (() => {
            const value = loanOfHouseholder?.loanAmount ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          loanPeriod: loanOfHouseholder?.loanPeriod ?? null,
          interestCalculationMethod: loanOfHouseholder?.interestCalculationMethod ?? null,
          loanRate: loanOfHouseholder?.loanRate ?? null,
          periodOfFixedRate: loanOfHouseholder?.periodOfFixedRate ?? null,
          repaymentMethod: loanOfHouseholder?.repaymentMethod ?? null,
          presentLoanAmount: (() => {
            const value = loanOfHouseholder?.presentLoanAmount ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          baseDateOfPresentAmount: loanOfHouseholder?.baseDateOfPresentAmount ?? null,
        },
        loanOfSpouse: {
          hasMortgageLoan: loanOfSpouse?.hasMortgageLoan ?? null,
          loanStartYear: loanOfSpouse?.loanStartYear ?? null,
          loanAmount: (() => {
            const value = loanOfSpouse?.loanAmount ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          loanPeriod: loanOfSpouse?.loanPeriod ?? null,
          interestCalculationMethod: loanOfSpouse?.interestCalculationMethod ?? null,
          loanRate: loanOfSpouse?.loanRate ?? null,
          periodOfFixedRate: loanOfSpouse?.periodOfFixedRate ?? null,
          repaymentMethod: loanOfSpouse?.repaymentMethod ?? null,
          presentLoanAmount: (() => {
            const value = loanOfSpouse?.presentLoanAmount ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          baseDateOfPresentAmount: loanOfSpouse?.baseDateOfPresentAmount ?? null,
        },
        planToBuyHouse: {
          willBuyHouse: planToBuyHouse?.willBuyHouse ?? null,
          ageOfPlanToBuyHouseHouseholder: planToBuyHouse?.ageOfPlanToBuyHouseHouseholder ?? null,
          amountOfPlanToBuyHouse: (() => {
            const value = planToBuyHouse?.amountOfPlanToBuyHouse ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          willHaveMortgageLoan: planToBuyHouse?.willHaveMortgageLoan ?? null,
          whoWillHaveMortgageLoan:
            (planToBuyHouse?.whoWillHaveMortgageLoan ?? null) as WhoWillHaveMortgageLoan,
          loanAmountHouseholder: (() => {
            const value = planToBuyHouse?.loanAmountHouseholder ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          loanAmountSpouse: (() => {
            const value = planToBuyHouse?.loanAmountSpouse ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          downPayment: (() => {
            const value = planToBuyHouse?.downPayment ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          loanPeriod: planToBuyHouse?.loanPeriod ?? null,
          interestCalculationMethod: planToBuyHouse?.interestCalculationMethod ?? null,
          loanRate: planToBuyHouse?.loanRate ?? null,
          periodOfFixedRate: planToBuyHouse?.periodOfFixedRate ?? null,
          repaymentMethod: planToBuyHouse?.repaymentMethod ?? null,
          ageOfPlanToBuyHouseSpouse: planToBuyHouse?.ageOfPlanToBuyHouseSpouse ?? null,
        },
        prepaymentLoan: {
          willHavePrepaymentOfLoan: prepaymentLoan?.willHavePrepaymentOfLoan ?? null,
          minimumDepositAmount: (() => {
            const value = prepaymentLoan?.minimumDepositAmount ?? null;
            if (value !== null) {
              // 100円単位で四捨五入する
              return Math.round(value / 100) * 100;
            }
            return null;
          })(),
          hasConsiderationOfDeduction: prepaymentLoan?.hasConsiderationOfDeduction ?? null,
          targetLoan: (() => {
            let value = null;
            // 既に値が設定されている場合は何もしない
            if (prepaymentLoan?.targetLoan != null) {
              return prepaymentLoan?.targetLoan;
            }
            // 現在ローンを見る。
            if (loanOfHouseholder?.hasMortgageLoan) {
              value = 'CURRENT_YOU';
            } else if (loanOfSpouse?.hasMortgageLoan) {
              value = 'CURRENT_SPOUSE';
            }
            // 将来も見るが、ローンは現在と将来はどちらか一方しかない。
            if (planToBuyHouse?.willBuyHouse) {
              const whoLoan = planToBuyHouse?.whoWillHaveMortgageLoan;
              if (whoLoan === 'YOU') {
                value = 'FUTURE_YOU';
              } else if (whoLoan === 'YOU_SPOUSE') {
                // 本人と配偶者の場合は本人優先
                value = 'FUTURE_YOU';
              } else if (whoLoan === 'SPOUSE') {
                value = 'FUTURE_SPOUSE';
              }
            }
            return value;
          })(),
        },
      },
    },
    afterRetirement: {
      householder: {
        retirementAllowance: (() => {
          // 想定退職金がNULLだったとき推定値を入れる
          let value = afterHh?.retirementAllowance ?? (
            estimatedValue?.householder?.retirementAllowance?.retirementAllowance.value ?? null
          );
          if (value !== null) {
            value = Math.round(value / 10000) * 10000;
          }
          return value;
        })(),
        publicPension: {
          startAgeOfPension: afterHh?.publicPension?.startAgeOfPension ?? null,
          monthlyPensionAmount: (() => {
            // 公的年金の想定受給額がNULLだったとき推定値を入れる
            let value = afterHh?.publicPension?.monthlyPensionAmount ?? (
              estimatedValue?.householder?.publicPension?.monthlyPensionAmount.value ?? null
            );
            if (value !== null) {
              value = Math.round(value / 10000) * 10000;
            }
            return value;
          })(),
        },
        corporatePensionDb: {
          startAgeOfPension: afterHh?.corporatePensionDb?.startAgeOfPension ?? null,
          drawTypeOfPension: afterHh?.corporatePensionDb?.drawTypeOfPension ?? null,
        },
        corporatePensionDc: {
          startAgeOfPension: afterHh?.corporatePensionDc?.startAgeOfPension ?? null,
          yearsOfPensionDrawing: afterHh?.corporatePensionDc?.yearsOfPensionDrawing ?? null,
          drawTypeOfPension: afterHh?.corporatePensionDc?.drawTypeOfPension ?? null,
        },
        ideco: {
          startAgeOfPension: afterHh?.ideco?.startAgeOfPension ?? null,
          yearsOfPensionDrawing: afterHh?.ideco?.yearsOfPensionDrawing ?? null,
          drawTypeOfPension: afterHh?.ideco?.drawTypeOfPension ?? null,
        },
      },
      spouse: {
        retirementAllowance: {
          retirementAge: afterSpouse?.retirementAllowance?.retirementAge ?? null,
          retirementAllowance: (() => {
            // 想定退職金がNULLだったとき推定値を入れる
            let value = afterSpouse?.retirementAllowance?.retirementAllowance ?? (
              estimatedValue?.spouse?.retirementAllowance?.retirementAllowance.value ?? null
            );
            if (value !== null) {
              value = Math.round(value / 10000) * 10000;
            }
            return value;
          })(),
        },
        publicPension: {
          startAgeOfPension:
            afterSpouse?.publicPension?.startAgeOfPension ?? null,
          monthlyPensionAmount: (() => {
            // 公的年金の想定受給額がNULLだったとき推定値を入れる
            let value = afterSpouse?.publicPension?.monthlyPensionAmount ?? (
              estimatedValue?.spouse?.publicPension?.monthlyPensionAmount.value ?? null
            );
            if (value !== null) {
              value = Math.round(value / 10000) * 10000;
            }
            return value;
          })(),
        },
        corporatePensionDc: {
          startAgeOfPension: afterSpouse?.corporatePensionDc?.startAgeOfPension ?? null,
          yearsOfPensionDrawing: afterSpouse?.corporatePensionDc?.yearsOfPensionDrawing ?? null,
          drawTypeOfPension: afterSpouse?.corporatePensionDc?.drawTypeOfPension ?? null,
        },
        ideco: {
          startAgeOfPension: afterSpouse?.ideco?.startAgeOfPension ?? null,
          yearsOfPensionDrawing: afterSpouse?.ideco?.yearsOfPensionDrawing ?? null,
          drawTypeOfPension: afterSpouse?.ideco?.drawTypeOfPension ?? null,
        },
      },
      lifeAfterRetirement: afterRetirement?.lifeAfterRetirement ?? null,
      reEmploymentAfterRetirement: {
        willBeReEmployed: reEmploymentAfterRetirement?.willBeReEmployed ?? null,
        startAgeOfReEmployment: reEmploymentAfterRetirement?.startAgeOfReEmployment ?? null,
        endAgeOfReEmployment: reEmploymentAfterRetirement?.endAgeOfReEmployment ?? null,
        annualIncome: reEmploymentAfterRetirement?.annualIncome ?? null,
      },
    },
    assetFormation: {
      householder: {
        corporatePensionDc: {
          hasBeJoined: assetHh?.corporatePensionDc?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetHh?.corporatePensionDc?.joinMonth ?? null,
          monthlyContributionAmount: assetHh?.corporatePensionDc?.monthlyContributionAmount ?? null,
          monthlyContributionAmountOfEmployee:
            assetHh?.corporatePensionDc?.monthlyContributionAmountOfEmployee ?? null,
          presentValue: assetHh?.corporatePensionDc?.presentValue ?? null,
          acquisitionValue: (() => {
            // DCログイン（BKデータ、企業DB、企業フラグ:1～8）の場合、JISTから取得した、年金資産評価額
            if (isDcLogin === true) {
              return basic.opeAmt;
            }
            return assetHh?.corporatePensionDc?.presentValue ?? null;
          })(),
          riskValue: assetHh?.corporatePensionDc?.riskValue ?? null,
          expectedReturn: assetHh?.corporatePensionDc?.expectedReturn ?? null,
          volatility: assetHh?.corporatePensionDc?.volatility ?? null,
        },
        ideco: {
          hasBeJoined: assetHh?.ideco?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetHh?.ideco?.joinMonth ?? null,
          monthlyContributionAmount: assetHh?.ideco?.monthlyContributionAmount ?? null,
          presentValue: assetHh?.ideco?.presentValue ?? null,
          acquisitionValue: (() => {
            // iDecoログイン（BKデータ、企業DB、企業フラグ:9）の場合、JISTから取得した、年金資産評価額
            if (isIdecoLogin === true) {
              return basic.opeAmt;
            }
            return assetHh?.ideco?.presentValue ?? null;
          })(),
          riskValue: assetHh?.ideco?.riskValue ?? null,
          expectedReturn: assetHh?.ideco?.expectedReturn ?? null,
          volatility: assetHh?.ideco?.volatility ?? null,
        },
        tsumitateNisa: {
          ...assetHh?.tsumitateNisa,
          hasBeJoined: assetHh?.tsumitateNisa?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetHh?.tsumitateNisa?.joinMonth ?? null,
          riskValue: assetHh?.tsumitateNisa?.riskValue ?? null,
          expectedReturn: assetHh?.tsumitateNisa?.expectedReturn ?? null,
          volatility: assetHh?.tsumitateNisa?.volatility ?? null,
          presentValue: assetHh?.tsumitateNisa?.presentValue ?? null,
          monthlyContributionAmount: assetHh?.tsumitateNisa?.monthlyContributionAmount ?? null,
          acquisitionValue: assetHh?.tsumitateNisa?.acquisitionValue ?? null,
        },
        investmentTrust: {
          hasBeJoined: assetHh?.investmentTrust?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetHh?.investmentTrust?.joinMonth ?? null,
          monthlyContributionAmount: assetHh?.investmentTrust?.monthlyContributionAmount ?? null,
          presentValue: assetHh?.investmentTrust?.presentValue ?? null,
          acquisitionValue: assetHh?.investmentTrust?.acquisitionValue ?? null,
          riskValue: assetHh?.investmentTrust?.riskValue ?? null,
          expectedReturn: assetHh?.investmentTrust?.expectedReturn ?? null,
          volatility: assetHh?.investmentTrust?.volatility ?? null,
        },
        otherInvestments: assetHh?.otherInvestments.map((other) => {
          return {
            presentValue: other.presentValue ?? null,
            monthlyContributionAmount: other.monthlyContributionAmount ?? null,
            startMonth: other.startMonth ?? null,
            endMonth: other.endMonth ?? null,
            totalReturn: other.totalReturn ?? null,
            hasBeJoined: other.hasBeJoined ?? 'NOT_JOINED',
            acquisitionValue: other.acquisitionValue ?? null,
          };
        }) ?? [],
      },
      spouse: {
        corporatePensionDc: {
          hasBeJoined: assetSpouse?.corporatePensionDc?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetSpouse?.corporatePensionDc?.joinMonth ?? null,
          monthlyContributionAmount:
            assetSpouse?.corporatePensionDc?.monthlyContributionAmount ?? null,
          monthlyContributionAmountOfEmployee:
            assetSpouse?.corporatePensionDc?.monthlyContributionAmountOfEmployee ?? 0,
          presentValue: assetSpouse?.corporatePensionDc?.presentValue ?? null,
          acquisitionValue: assetSpouse?.corporatePensionDc?.acquisitionValue ?? null,
          riskValue: assetSpouse?.corporatePensionDc?.riskValue ?? null,
          expectedReturn: assetSpouse?.corporatePensionDc?.expectedReturn ?? null,
          volatility: assetSpouse?.corporatePensionDc?.volatility ?? null,
        },
        ideco: {
          hasBeJoined: assetSpouse?.ideco?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetSpouse?.ideco?.joinMonth ?? null,
          monthlyContributionAmount: assetSpouse?.ideco?.monthlyContributionAmount ?? null,
          presentValue: assetSpouse?.ideco?.presentValue ?? null,
          acquisitionValue: assetSpouse?.ideco?.acquisitionValue ?? null,
          riskValue: assetSpouse?.ideco?.riskValue ?? null,
          expectedReturn: assetSpouse?.ideco?.expectedReturn ?? null,
          volatility: assetSpouse?.ideco?.volatility ?? null,
        },
        tsumitateNisa: {
          ...assetSpouse?.tsumitateNisa,
          hasBeJoined: assetSpouse?.tsumitateNisa?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetSpouse?.tsumitateNisa?.joinMonth ?? null,
          riskValue: assetSpouse?.tsumitateNisa?.riskValue ?? null,
          expectedReturn: assetSpouse?.tsumitateNisa?.expectedReturn ?? null,
          volatility: assetSpouse?.tsumitateNisa?.volatility ?? null,
          presentValue: assetSpouse?.tsumitateNisa?.presentValue ?? null,
          monthlyContributionAmount: assetSpouse?.tsumitateNisa?.monthlyContributionAmount ?? null,
          acquisitionValue: assetSpouse?.tsumitateNisa?.acquisitionValue ?? null,
        },
        investmentTrust: {
          hasBeJoined: assetSpouse?.investmentTrust?.hasBeJoined ?? 'NOT_JOINED',
          joinMonth: assetSpouse?.investmentTrust?.joinMonth ?? null,
          monthlyContributionAmount:
            assetSpouse?.investmentTrust?.monthlyContributionAmount ?? null,
          presentValue: assetSpouse?.investmentTrust?.presentValue ?? null,
          acquisitionValue: assetSpouse?.investmentTrust?.acquisitionValue ?? null,
          riskValue: assetSpouse?.investmentTrust?.riskValue ?? null,
          expectedReturn: assetSpouse?.investmentTrust?.expectedReturn ?? null,
          volatility: assetSpouse?.investmentTrust?.volatility ?? null,
        },
      },
    },
  };
};

// 入力値から企業年金（DC）、企業年金（iDeco）、つみたてNISA、積立投信・投資信託 の値を最適化
export const getAssetFormationItem = (datas: AssetFormationDcItem|AssetFormationDataItem, {
  riskDatas,
  opeAmt = null,
  isDcLogin = false,
  isIdeCoLogin = false,
}: {
  riskDatas: Risk[]|undefined,
  opeAmt?: number|null,
  isDcLogin?: boolean,
  isIdeCoLogin?: boolean,
}): AssetFormationDcItem|AssetFormationDataItem => {
  const riskData = riskDatas?.find((d) => d.riskTolerance === datas.riskValue);
  return {
    ...datas,
    expectedReturn: (() => {
      if (datas.hasBeJoined !== 'NOT_JOINED') {
        return riskData?.expectedReturn ?? null;
      }
      return null;
    })(),
    presentValue: (() => {
      if (datas.hasBeJoined === 'TOBE_JOINED') {
        // 今から加入の場合は取得金額と現在残高をゼロにする
        return 0;
      }
      return datas.presentValue;
    })(),
    acquisitionValue: (() => {
      if (datas.hasBeJoined === 'TOBE_JOINED') {
        // 今から加入の場合は取得金額と現在残高をゼロにする
        return 0;
      }
      if (isDcLogin === true) {
        return opeAmt ?? null;
      }
      if (isIdeCoLogin === true) {
        return opeAmt ?? null;
      }
      return datas.presentValue;
    })(),
    volatility: (() => {
      if (datas.hasBeJoined !== 'NOT_JOINED') {
        return riskData?.sigma ?? null;
      }
      return null;
    })(),
  };
};

// 加入年月開始日を取得 - 企業年金（DC）、個人年金（iDeco）、積立投信・投資信託
export const getAssetStartDate = (hasBeJoined:string, birthdate: string|null) => {
  if (hasBeJoined === 'JOINED' && birthdate !== null) {
    // 加入済みの場合は、年の選択エリアを誕生日以降にする
    return moment(birthdate).add(18, 'years').set('date', 1);
  } if (hasBeJoined === 'TOBE_JOINED') {
    // 今から加入の場合は現在以降にする
    return moment().set('date', 1);
  }
  // 未加入の場合は、年の選択エリアを今年度以降にする
  const date = moment();
  date.set('month', 0);
  date.set('date', 1);
  return date;
};

// 加入年月開始日を取得 - つみたてNISA
export const getAssetStartDateNisa = () => {
  const date = moment('2018-01-01');
  return date;
};

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoginData: (state, action: PayloadAction<FetchResponseInputData>) => {
      const { basic, estimatedValue } = current(state);
      const data = createData(action.payload, basic, estimatedValue);
      state.family = data.family;
      state.income = data.income;
      state.expenses = data.expenses;
      state.afterRetirement = data.afterRetirement;
      state.assetFormation = data.assetFormation;
      state.temp = data;
      state.defaultNisaJoinMonthHouseholder = action.payload
        .assetFormation?.householder.tsumitateNisa.joinMonth ?? null;
      state.defaultNisaJoinMonthSpouse = action.payload
        .assetFormation?.spouse.tsumitateNisa.joinMonth ?? null;
    },
    reset: (state) => {
      // 画面遷移時など、一時データをリセットする
      const data = current(state);
      state.temp = {
        family: data.family,
        income: data.income,
        expenses: data.expenses,
        afterRetirement: data.afterRetirement,
        assetFormation: data.assetFormation,
      };
    },
    sync: (state) => {
      // 一時データと同期する
      const { temp, basic, estimatedValue } = current(state);
      if (temp !== undefined) {
        const data = createData(temp, basic, estimatedValue);
        state.family = data.family;
        state.income = data.income;
        state.expenses = data.expenses;
        state.afterRetirement = data.afterRetirement;
        state.assetFormation = data.assetFormation;
      }
    },
    setBirthDate: (state, action: PayloadAction<string|null>) => {
      state.birthDate = action.payload;
    },
    setBasic: (state, action: PayloadAction<InputBasic>) => {
      state.basic = action.payload;
    },
    setEstimated: (state, action: PayloadAction<InputEstimatedValue|undefined>) => {
      state.estimatedValue = action.payload;
    },
    setEducationExpenseMatrix: (
      state,
      action: PayloadAction<EducationExpensesMatrix[]|undefined>,
    ) => {
      state.educationExpensesMatrix = action.payload;
    },
    setFamily: (state, action: PayloadAction<Family>) => {
      if (state.temp !== undefined) {
        const data = action.payload;
        const {
          educationExpensesMatrix,
          family,
          income,
          expenses,
          afterRetirement,
          assetFormation,
          birthDate,
        } = current(state);
        let spouseWillHaveSpouse = data.spouse.willHaveSpouse;
        let spouseAgeOfMarriagePlanHouseholder = data.spouse.ageOfMarriagePlanHouseholder;
        let spouseAgeOfMarriagePlanSpouse = data.spouse.ageOfMarriagePlanSpouse;

        if (!data.spouse.hasSpouse) {
          // 配偶者がいないときの条件

          // 配偶者がいないとき資産掲載の配偶者枠をすべて非加入にする
          if (state.temp.assetFormation !== undefined) {
            state.temp.assetFormation.spouse = {
              corporatePensionDc: {
                ...assetFormation.spouse.corporatePensionDc,
                hasBeJoined: 'NOT_JOINED',
                expectedReturn: null,
                volatility: null,
              },
              ideco: {
                ...assetFormation.spouse.ideco,
                hasBeJoined: 'NOT_JOINED',
                expectedReturn: null,
                volatility: null,
              },
              tsumitateNisa: {
                ...assetFormation.spouse.tsumitateNisa,
                hasBeJoined: 'NOT_JOINED',
                expectedReturn: null,
                volatility: null,
              },
              investmentTrust: {
                ...assetFormation.spouse.investmentTrust,
                hasBeJoined: 'NOT_JOINED',
                expectedReturn: null,
                volatility: null,
              },
            };
          }
        }

        if (data.spouse.hasSpouse) {
          // 配偶者がいるときの条件

          // 配偶者がいるときは、結婚の予定を強制的になしにする
          spouseWillHaveSpouse = false;
          spouseAgeOfMarriagePlanHouseholder = null;
          spouseAgeOfMarriagePlanSpouse = null;
        }

        let {
          whoWillHaveMortgageLoan,
          willHaveMortgageLoan,
          loanAmountHouseholder,
          loanAmountSpouse,
        } = expenses.housingExpenses.planToBuyHouse;
        const { planToBuyHouse } = expenses.housingExpenses;

        const { employmentStatus } = income.householder;
        if ((!family.spouse.hasSpouse && data.spouse.hasSpouse)
          || (!family.spouse.willHaveSpouse && spouseWillHaveSpouse)) {
          // 配偶者いない => いる
          // 結婚の予定なし => あり

          // 配偶者の職業を本人に合わせる
          if (state.temp.income !== undefined) {
            if (employmentStatus === 'FULLTIME_EMPLOYEE') {
              // 正社員
              state.temp.income.spouse = {
                ...income.spouse,
                depositAmount: 0,
                annualIncome: SPOUSE_DEFAULT_ANNUAL_INCOME,
                employmentStatus: 'FULLTIME_EMPLOYEE',
                incomeGrowthRate: 'STATISTICAL',
                industry: 'INDUSTRIAL_TOTAL',
                numberOfEmployees: 1000, // numberOfEmployeesは見えないAPIインプットで1000固定
              };

              if (state.temp.afterRetirement !== undefined) {
                // 配偶者の職業が正社員のとき、想定退職金をnullにする
                state.temp.afterRetirement.spouse.retirementAllowance.retirementAllowance = null;
              }
            } else {
              // 派遣社員
              state.temp.income.spouse = {
                ...income.spouse,
                depositAmount: 0,
                annualIncome: MINIMUM_ANNUAL_INCOME * 10000,
                employmentStatus: 'TEMPORARY_STAFF',
                incomeGrowthRate: 'DOES_NOT_INCREASE',
                industry: 'TEMPORARY_STAFF',
                numberOfEmployees: 1000, // numberOfEmployeesは見えないAPIインプットで1000固定
              };

              if (state.temp.afterRetirement !== undefined) {
                // 配偶者の職業が派遣社員のとき、退職金を0にする
                state.temp.afterRetirement.spouse.retirementAllowance.retirementAllowance = 0;
              }
            }

            if (state.temp.afterRetirement !== undefined) {
              // 配偶者の退職年齢を固定で登録
              if (afterRetirement.spouse.publicPension.startAgeOfPension === null) {
                state.temp.afterRetirement
                  .spouse.publicPension.startAgeOfPension = PUBLIC_PENSION_START_AGE_OF_PENSION;
              }
            }
          }
        } else if (family.spouse.hasSpouse && !data.spouse.hasSpouse) {
          // 配偶者いる => いない

          let {
            willHavePrepaymentOfLoan,
          } = expenses.housingExpenses.prepaymentLoan;

          if (willHaveMortgageLoan) {
            // 配偶者のみが住宅ローンを組んでいた場合、ローンを組まないにする
            if (whoWillHaveMortgageLoan === 'SPOUSE') {
              willHaveMortgageLoan = false;
            }

            // ローンを組む人を「あなた」にする
            if (whoWillHaveMortgageLoan === 'SPOUSE' || whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
              whoWillHaveMortgageLoan = 'YOU';
            }
          }

          if (planToBuyHouse.willBuyHouse) {
            // 住宅購入予定がある場合は借入額（あなた）と借入額（配偶者）を再計算
            const loanAmount = (planToBuyHouse.amountOfPlanToBuyHouse ?? 0)
              - (planToBuyHouse.downPayment ?? 0);
            if (whoWillHaveMortgageLoan === 'YOU') {
              loanAmountHouseholder = loanAmount;
              loanAmountSpouse = null;
            } else if (whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
              loanAmountHouseholder = loanAmount / 2;
              loanAmountSpouse = loanAmount / 2;
            } else {
              loanAmountHouseholder = null;
              loanAmountSpouse = loanAmount;
            }
          } else {
            // 借入額（あなた）と借入額（配偶者）をnullにする
            loanAmountHouseholder = null;
            loanAmountSpouse = null;
          }

          // 繰り上げ返済計画に配偶者が含まれていたら繰り上げ返済計画をなしにする
          const { prepaymentLoan } = expenses.housingExpenses;
          if (prepaymentLoan.targetLoan === 'CURRENT_SPOUSE' || prepaymentLoan.targetLoan === 'FUTURE_SPOUSE') {
            willHavePrepaymentOfLoan = false;
          }

          if (state.temp.expenses !== undefined) {
            state.temp.expenses.housingExpenses.prepaymentLoan = {
              ...expenses.housingExpenses.prepaymentLoan,
              willHavePrepaymentOfLoan,
            };
          }
        }

        if (!data.spouse.hasSpouse && !spouseWillHaveSpouse) {
          // 配偶者がいないときは、配偶者の退職金を0にする
          if (state.temp.afterRetirement !== undefined) {
            state.temp.afterRetirement.spouse.retirementAllowance.retirementAllowance = 0;
          }
        }

        // expenses.housingExpenses.planToBuyHouse の内容を更新
        if (state.temp.expenses !== undefined) {
          state.temp.expenses.housingExpenses.planToBuyHouse = {
            ...expenses.housingExpenses.planToBuyHouse,
            willHaveMortgageLoan,
            whoWillHaveMortgageLoan,
            loanAmountHouseholder,
            loanAmountSpouse,
            ageOfPlanToBuyHouseSpouse: (() => {
              // 配偶者の購入予定の年齢の再計算（配偶者の年齢が更新される場合にも再計算する必要あり）
              if (
                planToBuyHouse.willBuyHouse
                && planToBuyHouse.willHaveMortgageLoan
                && (whoWillHaveMortgageLoan === 'SPOUSE' || whoWillHaveMortgageLoan === 'YOU_SPOUSE')
              ) {
                const householderAge = moment().diff(birthDate, 'years');
                const spouseAge = moment().diff(data.spouse.birthMonth, 'years');
                const yearsAgoBuy = householderAge
                  - (planToBuyHouse.ageOfPlanToBuyHouseHouseholder ?? 0);
                return spouseAge - yearsAgoBuy;
              }
              return null;
            })(),
          };
        }

        // --- こどもの数を教育費用と合わせる

        // 教育費の出費イメージ
        let { educationPolicy } = expenses.educationExpenses;
        if ((data.children.birthMonth.length + data.children.expectedBirthYear.length) > 0) {
          // こども・将来のこどもの数が合わせて1以上のときは、教育費の出費イメージはデフォルトで [結構かかる] になる
          if (educationPolicy === null) {
            educationPolicy = 'PRIVATE_FROM_HIGH_SCHOOL';
          }
        } else {
          // こども・将来のこどもの数が合わせて0のときは、教育費の出費イメージはnullになる
          educationPolicy = null;
        }

        // 教育費データを整形する
        const getExpensesData = (grade: Grade) => {
          const matrix = getEducationExpense(educationExpensesMatrix, grade, educationPolicy);
          return {
            publicPrivate: matrix?.publicPrivate ?? null,
            entranceFee: matrix?.entranceFee ?? null,
            annualTuition: matrix?.tuition ?? null,
            annualLessonFee: matrix?.lessonFee ?? null,
          };
        };

        // 登録済みの教育費用のこどもと将来のこども
        const expensesChildren = expenses.educationExpenses.children;
        const childrenLength = family.children.birthMonth.length;
        const expenses1 = expensesChildren.slice(0, childrenLength);
        const expenses2 = expensesChildren.slice(childrenLength, expensesChildren.length);

        if (!data.children.hasChildren) {
          // こども有無を「いない」にしたときにこどもの数をリセットする
          data.children.birthMonth = [];
        }

        if (!data.children.willHaveChildren) {
          // 将来の子供の予定を「ない」にしたときに将来のこどもの数をリセットする
          data.children.expectedBirthYear = [];
        }

        // こども
        const childrenList = data.children.birthMonth.map((birthMonth, i) => {
          const expensesData = expenses1[i];
          if (expensesData === undefined) {
            return {
              date: birthMonth,
              expenses: {
                primarySchool: getExpensesData('PRIMARY_SCHOOL'),
                juniorHighSchool: getExpensesData('JUNIOR_HIGH_SCHOOL'),
                highSchool: getExpensesData('HIGH_SCHOOL'),
                university: getExpensesData('UNIVERSITY'),
              },
            };
          }
          return {
            date: birthMonth,
            expenses: expensesData,
          };
        });

        // 将来のこども
        const expectedChildrenList = data.children.expectedBirthYear.map((birthYear, i) => {
          const expensesData = expenses2[i];
          if (expensesData === undefined) {
            return {
              date: (birthYear !== '') ? birthYear : moment(birthDate).add(18, 'years').format('YYYY-MM-DD'),
              expenses: {
                primarySchool: getExpensesData('PRIMARY_SCHOOL'),
                juniorHighSchool: getExpensesData('JUNIOR_HIGH_SCHOOL'),
                highSchool: getExpensesData('HIGH_SCHOOL'),
                university: getExpensesData('UNIVERSITY'),
              },
            };
          }
          return {
            date: birthYear,
            expenses: expensesData,
          };
        });

        // 日付データでソートする
        childrenList.sort((a, b) => {
          if (a.date > b.date) {
            return 1;
          }
          return -1;
        });
        expectedChildrenList.sort((a, b) => {
          if (a.date > b.date) {
            return 1;
          }
          return -1;
        });

        // 家族構成データ更新
        state.temp.family = {
          spouse: {
            ...data.spouse,
            willHaveSpouse: spouseWillHaveSpouse,
            ageOfMarriagePlanHouseholder: spouseAgeOfMarriagePlanHouseholder,
            ageOfMarriagePlanSpouse: spouseAgeOfMarriagePlanSpouse,
          },
          children: {
            hasChildren: data.children.hasChildren,
            willHaveChildren: data.children.willHaveChildren,
            birthMonth: childrenList.map((c) => c.date),
            expectedBirthYear: expectedChildrenList.map((c) => c.date),
          },
        };

        // 教育費用データも子供の数と合わせる
        if (state.temp.expenses !== undefined) {
          state.temp.expenses.educationExpenses = {
            educationPolicy,
            children: [
              ...childrenList.map((c) => c.expenses),
              ...expectedChildrenList.map((c) => c.expenses),
            ],
          };
        }
      }
    },
    setIncome: (state, action: PayloadAction<Income>) => {
      if (state.temp !== undefined) {
        const { income } = current(state);
        const data = action.payload;
        let householderIndustry = data.householder.industry;
        let spouseIndustry = data.spouse.industry;
        let spouseAnnualIncome = data.spouse.annualIncome;

        // 本人
        if (data.householder.employmentStatus !== 'FULLTIME_EMPLOYEE') {
          // 本人の職業が正社員以外のとき、業種の値を職業と合わせる
          householderIndustry = data.householder.employmentStatus;
        }

        if (state.temp.afterRetirement !== undefined) {
          // 本人の職業が正社員以外を選択した場合、想定退職金をゼロにする対応。
          // また、本人の職業が正社員以外から正社員に変更して選択した場合、想定退職金をNullにする対応。（FT推計値を得るため）
          if (data.householder.employmentStatus !== 'FULLTIME_EMPLOYEE') {
            state.temp.afterRetirement.householder.retirementAllowance = 0;
          } else if (income.householder.employmentStatus !== 'FULLTIME_EMPLOYEE') {
            state.temp.afterRetirement.householder.retirementAllowance = null;
          }

          // 配偶者の職業が正社員以外を選択した場合、想定退職金をゼロにする対応。
          // また、配偶者の職業が正社員以外から正社員に変更して選択した場合、想定退職金をNullにする対応。（FT推計値を得るため）
          if (data.spouse.employmentStatus !== 'FULLTIME_EMPLOYEE') {
            state.temp.afterRetirement.spouse.retirementAllowance.retirementAllowance = 0;
          } else if (income.spouse.employmentStatus !== 'FULLTIME_EMPLOYEE') {
            state.temp.afterRetirement.spouse.retirementAllowance.retirementAllowance = null;
          }
        }

        if (data.spouse.employmentStatus === 'FULLTIME_EMPLOYEE') {
          // 配偶者の職業が正社員の場合は、業種を変えない
        } else if (data.spouse.employmentStatus === 'HOUSEWIFE_HUSBAND') {
          // 配偶者の職業が専業主婦・主夫の場合は、業種を産業計にする
          spouseIndustry = 'INDUSTRIAL_TOTAL';
          // 配偶者の職業が専業主婦・主夫のとき、年収を0にする
          spouseAnnualIncome = 0;
        } else {
          // 配偶者の職業が正社員、専業主婦・主夫以外のとき、職業と同じ値を入れる
          // ※ PCのソースコードに合わせる
          spouseIndustry = data.spouse.employmentStatus;
        }

        state.temp.income = {
          householder: {
            ...data.householder,
            industry: householderIndustry,
          },
          spouse: {
            ...data.spouse,
            industry: spouseIndustry,
            annualIncome: spouseAnnualIncome,
          },
        };
      }
    },
    setLivingExpenses: (state, action: PayloadAction<LivingExpenses>) => {
      if (state.temp !== undefined && state.temp.expenses !== undefined) {
        state.temp.expenses.livingExpenses = action.payload;
      }
    },
    setEducationExpenses: (state, action: PayloadAction<EducationExpenses>) => {
      if (state.temp !== undefined && state.temp.expenses !== undefined) {
        const data = action.payload;
        const { educationPolicy } = data;
        const { educationExpensesMatrix } = current(state);

        // 教育費データを整形する
        const getExpensesData = (grade: Grade, school: EducationExpensesChildrenSchool) => {
          const matrix = getEducationExpense(
            educationExpensesMatrix,
            grade,
            educationPolicy,
            school.publicPrivate,
          );
          if (educationPolicy === 'CUSTOMIZATION') {
            return {
              ...school,
              entranceFee: matrix?.entranceFee ?? null,
              annualTuition: matrix?.tuition ?? null,
            };
          }
          return {
            publicPrivate: matrix?.publicPrivate ?? null,
            entranceFee: matrix?.entranceFee ?? null,
            annualTuition: matrix?.tuition ?? null,
            annualLessonFee: matrix?.lessonFee ?? null,
          };
        };

        state.temp.expenses.educationExpenses = {
          ...data,

          // そのまま整形しない数字を渡すとPC版で数字が合わなくなるため、教育費データを整形する
          children: data.children.map((c) => {
            return {
              primarySchool: getExpensesData('PRIMARY_SCHOOL', c.primarySchool),
              juniorHighSchool: getExpensesData('JUNIOR_HIGH_SCHOOL', c.juniorHighSchool),
              highSchool: getExpensesData('HIGH_SCHOOL', c.highSchool),
              university: getExpensesData('UNIVERSITY', c.university),
            };
          }),
        };
      }
    },
    setHousingExpenses: (state, action: PayloadAction<HousingExpenses>) => {
      if (state.temp !== undefined && state.temp.expenses !== undefined) {
        const data = action.payload;
        state.temp.expenses.housingExpenses = {
          ...data,
          monthlyRent: data.presentHouse === 'RENTAL_HOUSING' ? data.monthlyRent : null,
          maintenanceFee: data.presentHouse === 'OWNING_HOUSE' ? data.maintenanceFee : null,
          monthlyHousingExpense: data.presentHouse === 'HOME' ? data.monthlyHousingExpense : null,
          loanOfHouseholder: {
            ...data.loanOfHouseholder,
            hasMortgageLoan: (() => {
              // 持ち家以外を選択したときに、ローンをリセットする
              if (data.presentHouse !== 'OWNING_HOUSE') {
                return false;
              }
              return data.loanOfHouseholder.hasMortgageLoan;
            })(),
            periodOfFixedRate: (() => {
              if (data.loanOfHouseholder.interestCalculationMethod === 'FLOATING') {
                // 金利計算方式が変動金利のときは、固定期間をnullにする
                return null;
              } if (data.loanOfHouseholder.interestCalculationMethod === 'ALL_PERIOD_FIXED') {
                // 金利計算方式が全期間固定金利のときは、固定期間を借入期間に合わせる
                return data.loanOfHouseholder.loanPeriod;
              }
              return data.loanOfHouseholder.periodOfFixedRate;
            })(),
          },
          loanOfSpouse: {
            ...data.loanOfSpouse,
            hasMortgageLoan: (() => {
              // 持ち家以外を選択したときに、ローンをリセットする
              if (data.presentHouse !== 'OWNING_HOUSE') {
                return false;
              }
              return data.loanOfSpouse.hasMortgageLoan;
            })(),
            periodOfFixedRate: (() => {
              if (data.loanOfSpouse.interestCalculationMethod === 'FLOATING') {
                // 金利計算方式が変動金利のときは、固定期間をnullにする
                return null;
              } if (data.loanOfSpouse.interestCalculationMethod === 'ALL_PERIOD_FIXED') {
                // 金利計算方式が全期間固定金利のときは、固定期間を借入期間に合わせる
                return data.loanOfSpouse.loanPeriod;
              }
              return data.loanOfSpouse.periodOfFixedRate;
            })(),
          },
        };
      }
    },
    setPlanToBuyHouse: (state, action: PayloadAction<PlanToBuyHouse>) => {
      if (state.temp !== undefined && state.temp.expenses !== undefined) {
        const data = action.payload;
        const {
          birthDate,
          family,
          expenses,
        } = current(state);
        let {
          ageOfPlanToBuyHouseSpouse,
          willHaveMortgageLoan,
          loanAmountHouseholder,
          loanAmountSpouse,
          periodOfFixedRate,
          loanRate,
        } = data;

        if (data.willBuyHouse) {
          // 住宅購入予定あり

          // 借入額の値を合わせる
          const loanAmount = (data.amountOfPlanToBuyHouse ?? 0) - (data.downPayment ?? 0);
          if (data.whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
            loanAmountHouseholder = loanAmount / 2;
            loanAmountSpouse = loanAmount / 2;
          } else if (data.whoWillHaveMortgageLoan === 'YOU') {
            loanAmountHouseholder = loanAmount;
            loanAmountSpouse = null;
          } else if (data.whoWillHaveMortgageLoan === 'SPOUSE') {
            loanAmountHouseholder = null;
            loanAmountSpouse = loanAmount;
          }
        } else {
          // 住宅購入予定なしにしたとき、住宅ローンを組まないに設定する
          ageOfPlanToBuyHouseSpouse = null;
          willHaveMortgageLoan = false;
          loanAmountHouseholder = null;
          loanAmountSpouse = null;
        }

        if (data.interestCalculationMethod === 'FLOATING') {
          // 金利計算方式が変動金利のときは、固定期間をnullにする
          periodOfFixedRate = null;
        } if (data.interestCalculationMethod === 'ALL_PERIOD_FIXED') {
          // 金利計算方式が全期間固定金利のときは、固定期間を借入期間に合わせる
          periodOfFixedRate = data.loanPeriod;
        }

        if (willHaveMortgageLoan) {
          // ローンを組む人に配偶者が含まれていたら、購入年齢を適応
          if (data.whoWillHaveMortgageLoan === 'SPOUSE' || data.whoWillHaveMortgageLoan === 'YOU_SPOUSE') {
            if (family.spouse.birthMonth !== null) {
              const houserholderAge = moment().diff(birthDate, 'years');
              const spouseAge = moment().diff(family.spouse.birthMonth, 'years');
              const yearsAgoBuy = houserholderAge - (data.ageOfPlanToBuyHouseHouseholder ?? 0);
              ageOfPlanToBuyHouseSpouse = spouseAge - yearsAgoBuy;
            } else {
              ageOfPlanToBuyHouseSpouse = null;
            }
          } else {
            ageOfPlanToBuyHouseSpouse = null;
          }

          // 住宅ローンを組む設定にしたとき、借入年月のデフォルトにする
          if (expenses.housingExpenses.loanOfHouseholder.loanStartYear === null) {
            const date = moment(birthDate);
            date.add(LOAN_START_AGE_ADDITION, 'years');
            date.set('month', 0);
            date.set('date', 1);
            state.temp.expenses.housingExpenses.loanOfHouseholder.loanStartYear = date.format('YYYY-MM-DD');
          }
        } else {
          // 住宅ローンを組まないに設定したとき、配偶者の購入年齢のnullにする
          ageOfPlanToBuyHouseSpouse = null;

          // 住宅ローンを組まないに設定したとき、金利が0のときはnullにする
          if (loanRate === 0) {
            loanRate = null;
          }
        }

        state.temp.expenses.housingExpenses.planToBuyHouse = {
          ...data,
          ageOfPlanToBuyHouseSpouse,
          willHaveMortgageLoan,
          loanAmountHouseholder,
          loanAmountSpouse,
          periodOfFixedRate,
          loanRate,
        };
      }
    },
    setPrepaymentLoan: (state, action: PayloadAction<PrepaymentLoan>) => {
      if (state.temp !== undefined && state.temp.expenses !== undefined) {
        state.temp.expenses.housingExpenses.prepaymentLoan = action.payload;
      }
    },
    setAfterRetirement: (state, action: PayloadAction<AfterRetirement>) => {
      if (state.temp !== undefined) {
        state.temp.afterRetirement = action.payload;
      }
    },
    setAfterRetirementLife: (state, action: PayloadAction<string|null>) => {
      if (state.temp !== undefined && state.temp.afterRetirement !== undefined) {
        state.temp.afterRetirement.lifeAfterRetirement = action.payload;
      }
    },
    setAfterRetirementReEmployment: (state, action: PayloadAction<AfterRetirementReEmployment>) => {
      if (state.temp !== undefined && state.temp.afterRetirement !== undefined) {
        state.temp.afterRetirement.reEmploymentAfterRetirement = action.payload;
      }
    },

    // 企業年金（DC） - 本人
    setAssetDcHouseholder: (state, action: PayloadAction<AssetFormationDcItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.householder.corporatePensionDc = action.payload;
      }
    },

    // 企業年金（DC） - 配偶者
    setAssetDcSpouse: (state, action: PayloadAction<AssetFormationDcItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.spouse.corporatePensionDc = action.payload;
      }
    },

    // 個人年金（iDeco） - 本人
    setAssetIdecoHouseholder: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.householder.ideco = action.payload;
      }
    },

    // 個人年金（iDeco） - 配偶者
    setAssetIdecoSpouse: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.spouse.ideco = action.payload;
      }
    },

    // つみたてNISA - 本人
    setAssetNisaHouseholder: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.householder.tsumitateNisa = action.payload;
      }
    },

    // つみたてNISA - 配偶者
    setAssetNisaSpouse: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.spouse.tsumitateNisa = action.payload;
      }
    },

    // 積立投信・投資信託 - 本人
    setAssetTrustHouseholder: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.householder.investmentTrust = action.payload;
      }
    },

    // 積立投信・投資信託 - 配偶者
    setAssetTrustSpouse: (state, action: PayloadAction<AssetFormationDataItem>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.spouse.investmentTrust = action.payload;
        const data = action.payload;
        state.temp.assetFormation.spouse.investmentTrust = data;
      }
    },

    // 積立定期・その他
    setAssetFormationOthers: (state, action: PayloadAction<AssetFormationOtherItem[]>) => {
      if (state.temp !== undefined && state.temp.assetFormation !== undefined) {
        state.temp.assetFormation.householder.otherInvestments = action.payload;
      }
    },

    // 更新時に共通となる処理
    updateRequestData: (state) => {
      if (state.temp !== undefined) {
        const { temp, basic } = current(state);
        if (state.temp?.income !== undefined && temp?.income !== undefined) {
          state.temp.income = {
            ...temp.income,
            householder: {
              ...temp.income.householder,
              // 見えないAPIインプットで1000固定
              numberOfEmployees: temp.income.householder.numberOfEmployees ?? 1000,
            },
            spouse: {
              ...temp.income.spouse,
              // 見えないAPIインプットで1000固定
              numberOfEmployees: temp.income.spouse.numberOfEmployees ?? 1000,
            },
          };
        }

        if (state.temp?.expenses !== undefined && temp?.expenses !== undefined) {
          const {
            whoWillHaveMortgageLoan,
            willBuyHouse,
          } = temp.expenses.housingExpenses.planToBuyHouse;
          if (whoWillHaveMortgageLoan === 'YOU' || !willBuyHouse) {
            // 住宅購入予定なしのとき、借入額(配偶者)をnullにする
            state.temp.expenses.housingExpenses.planToBuyHouse.loanAmountSpouse = null;
          }

          // ローン借入年月のデフォルト値設定
          const defaultLoanStartYear = moment(basic.birthDate)
            .add(LOAN_START_AGE_ADDITION, 'years')
            .set('month', 0).set('date', 1)
            .format('YYYY-MM-DD');
          const { housingExpenses } = temp.expenses;
          if (housingExpenses.loanOfHouseholder.loanStartYear === null) {
            state.temp.expenses
              .housingExpenses.loanOfHouseholder.loanStartYear = defaultLoanStartYear;
          }
        }

        if (state.temp?.assetFormation !== undefined && temp?.assetFormation !== undefined) {
          // 資産掲載の受給開始年齢がnullのとき調整用にデフォルトの開始年月日を用意
          const joinMonth1 = moment(basic.birthDate)
            .add(18, 'years')
            .set('month', 0).set('date', 1)
            .format('YYYY-MM-DD');
          const joinMonth2 = moment().format('YYYY-MM-DD');
          const assetHh = temp.assetFormation?.householder;
          const assetSpouse = temp.assetFormation?.spouse;

          // 企業年金 (DC)
          if (assetHh.corporatePensionDc.joinMonth === null) {
            state.temp.assetFormation.householder.corporatePensionDc.joinMonth = joinMonth1;
          }
          if (assetSpouse.corporatePensionDc.joinMonth === null) {
            state.temp.assetFormation.spouse.corporatePensionDc.joinMonth = joinMonth1;
          }

          // 個人年金 (iDeCo)
          if (assetHh.ideco.joinMonth === null) {
            state.temp.assetFormation.householder.ideco.joinMonth = joinMonth1;
          }
          if (assetSpouse.ideco.joinMonth === null) {
            state.temp.assetFormation.spouse.ideco.joinMonth = joinMonth1;
          }

          // つみたてNISA
          if (assetHh.tsumitateNisa.joinMonth === null) {
            state.temp.assetFormation.householder.tsumitateNisa.joinMonth = joinMonth2;
          }
          if (assetSpouse.tsumitateNisa.joinMonth === null) {
            state.temp.assetFormation.spouse.tsumitateNisa.joinMonth = joinMonth2;
          }

          //  積立投信・投資信託
          if (assetHh.investmentTrust.joinMonth === null) {
            state.temp.assetFormation.householder.investmentTrust.joinMonth = joinMonth2;
          }
          if (assetSpouse.investmentTrust.joinMonth === null) {
            state.temp.assetFormation.spouse.investmentTrust.joinMonth = joinMonth2;
          }
        }
      }
    },
  },
});

export const {
  setLoginData, reset, sync,
  setBirthDate, setBasic, setEducationExpenseMatrix, setEstimated,
  setFamily, setIncome,
  setLivingExpenses, setEducationExpenses, setHousingExpenses, setPlanToBuyHouse, setPrepaymentLoan,
  setAfterRetirement, setAfterRetirementLife, setAfterRetirementReEmployment,
  setAssetDcHouseholder, setAssetDcSpouse,
  setAssetIdecoHouseholder, setAssetIdecoSpouse,
  setAssetNisaHouseholder, setAssetNisaSpouse,
  setAssetTrustHouseholder, setAssetTrustSpouse,
  setAssetFormationOthers,
  updateRequestData,
} = inputSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFamily = (state: RootState): Family => state.input.family;
export const selectIncome = (state: RootState) => state.input.income;
export const selectLivingExpenses = (state: RootState) => state.input.expenses.livingExpenses;
export const selectEducationExpenses = (state: RootState) => state.input.expenses.educationExpenses;
export const selectHousingExpenses = (state: RootState) => state.input.expenses.housingExpenses;

// 住宅購入予定
export const selectPlanToBuyHouse = (state: RootState): PlanToBuyHouse => {
  const { planToBuyHouse } = state.input.expenses.housingExpenses;
  return {
    ...planToBuyHouse,
    loanRate: planToBuyHouse.loanRate ?? 0,
  };
};

export const selectPrepaymentLoan = (state: RootState) => {
  const { prepaymentLoan } = state.input.expenses.housingExpenses;
  return prepaymentLoan;
};
export const selectAfterRetirement = (state: RootState) => state.input.afterRetirement;
export const selectLifeAfterRetirement = (state: RootState) => {
  const { lifeAfterRetirement } = state.input.afterRetirement;
  return lifeAfterRetirement;
};
export const selectReEmploymentAfterRetirement = (state: RootState) => {
  const { reEmploymentAfterRetirement } = state.input.afterRetirement;
  return reEmploymentAfterRetirement;
};

export const selectAssetFormation = (state: RootState) => state.input.assetFormation;

// 加入年月がnullのときに開始日に変換 - 企業年金（DC）、個人年金（iDeco）、積立投信・投資信託
const getAssetJoinMonth = (
  data:AssetFormationDcItem|AssetFormationDataItem,
  birthdate: string|null,
) => {
  if (data.joinMonth !== null) {
    return data.joinMonth;
  }
  const startDate = getAssetStartDate(data.hasBeJoined, birthdate);
  return startDate.format('YYYY-MM-DD');
};

// 企業年金（DC） - 本人
export const selectAssetDcHouseholder = (state: RootState): AssetFormationDcItem => {
  const { corporatePensionDc } = state.input.assetFormation.householder;

  // 年利回り
  const { riskValue } = corporatePensionDc;
  const riskDatas = selectRiskDc(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...corporatePensionDc,
    joinMonth: getAssetJoinMonth(corporatePensionDc, state.user.account.birthDay),
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// 企業年金（DC） - 配偶者
export const selectAssetDcSpouse = (state: RootState): AssetFormationDcItem => {
  const { corporatePensionDc } = state.input.assetFormation.spouse;

  // 年利回り
  const { riskValue } = corporatePensionDc;
  const riskDatas = selectRiskDcSpouse(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...corporatePensionDc,
    joinMonth: getAssetJoinMonth(corporatePensionDc, state.user.account.birthDay),
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// 個人年金（iDeco） - 本人
export const selectAssetIdecoHouseholder = (state: RootState): AssetFormationDataItem => {
  const { ideco } = state.input.assetFormation.householder;

  // 年利回り
  const { riskValue } = ideco;
  const riskDatas = selectRiskIdeco(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...ideco,
    joinMonth: getAssetJoinMonth(ideco, state.user.account.birthDay),
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// 個人年金（iDeco） - 配偶者
export const selectAssetIdecoSpouse = (state: RootState): AssetFormationDataItem => {
  const { ideco } = state.input.assetFormation.spouse;

  // 年利回り
  const { riskValue } = ideco;
  const riskDatas = selectRiskIdecoSpouse(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...ideco,
    joinMonth: getAssetJoinMonth(ideco, state.user.account.birthDay),
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// つみたてNISA - 本人
export const selectAssetNisaHouseholder = (state: RootState): AssetFormationDataItem => {
  const { tsumitateNisa } = state.input.assetFormation.householder;

  // 年利回り
  const { riskValue } = tsumitateNisa;
  const riskDatas = selectRiskNisa(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...tsumitateNisa,
    joinMonth: tsumitateNisa.joinMonth ?? defaultJoinMonthNisa,
    expectedReturn: riskData?.expectedReturn ?? null,
    monthlyContributionAmount: tsumitateNisa.monthlyContributionAmount ?? null,
    presentValue: tsumitateNisa.presentValue ?? null,
  };
};

// つみたてNISA - 配偶者
export const selectAssetNisaSpouse = (state: RootState): AssetFormationDataItem => {
  const { tsumitateNisa } = state.input.assetFormation.spouse;

  // 年利回り
  const { riskValue } = tsumitateNisa;
  const riskDatas = selectRiskNisa(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...tsumitateNisa,
    joinMonth: tsumitateNisa.joinMonth ?? defaultJoinMonthNisa,
    expectedReturn: riskData?.expectedReturn ?? null,
    monthlyContributionAmount: tsumitateNisa.monthlyContributionAmount ?? null,
    presentValue: tsumitateNisa.presentValue ?? null,
  };
};

// 積立投信・投資信託 - 本人
export const selectAssetTrustHouseholder = (state: RootState): AssetFormationDataItem => {
  const { investmentTrust } = state.input.assetFormation.householder;

  // 年利回り
  const { riskValue } = investmentTrust;
  const riskDatas = selectRiskTrust(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...investmentTrust,
    joinMonth: investmentTrust.joinMonth ?? defaultJoinMonthTrust,
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// 積立投信・投資信託 - 配偶者
export const selectAssetTrustSpouse = (state: RootState): AssetFormationDataItem => {
  const { investmentTrust } = state.input.assetFormation.spouse;

  // 年利回り
  const { riskValue } = investmentTrust;
  const riskDatas = selectRiskTrust(state);
  const riskData = riskDatas?.find((risk) => risk.riskTolerance === riskValue);

  return {
    ...investmentTrust,
    joinMonth: investmentTrust.joinMonth ?? defaultJoinMonthTrust,
    expectedReturn: riskData?.expectedReturn ?? null,
  };
};

// 積立定期・その他
export const selectAssetFormationOthers = (state: RootState) => {
  const { otherInvestments } = state.input.assetFormation.householder;
  return otherInvestments;
};

// ローンを組んでいるかどうか
export const selectHasMortgageLoan = (state: RootState) => {
  const { hasMortgageLoan } = state.input.expenses.housingExpenses.loanOfHouseholder;
  return hasMortgageLoan ?? false;
};

// 子供がいるか、または予定があるか
export const selectHasChildren = (state: RootState) => {
  const { children } = state.input.family;
  return (children.hasChildren ?? false) || (children.willHaveChildren ?? false);
};

// 配偶者有無
export const selectHasSpouse = (state: RootState) => state.input.family.spouse.hasSpouse;

// 配偶者有無（今後結婚予定のある配偶者も含む）
export const selectHasSpouseOrWill = (state: RootState) => {
  const { spouse } = state.input.family;
  return (spouse.hasSpouse ?? false) || (spouse.willHaveSpouse ?? false);
};

export default inputSlice.reducer;
