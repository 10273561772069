import * as React from 'react';

type RemarksProps = {
  className?: string;
};

/**
 * 備考コンポーネント
 * @param props
 * @returns
 */
const Remarks: React.FC<RemarksProps> = ({ className, children }) => (
  <div className={`bg-primary bg-opacity-5 text-primary text-sm p-4 ${className}`}>
    {children}
  </div>
);
Remarks.defaultProps = {
  className: '',
};
export default Remarks;
