import * as React from 'react';
import styled, { keyframes } from 'styled-components';

// データ
import { useAppSelector } from '../../app/hooks';
import { selectDeadline } from '../../reducers/cashflowReducer';

// images
import imgBalloonRed from './assets/balloon_red.svg';
import imgBalloonBlue from './assets/balloon_blue.svg';
import imgBalloonYellow from './assets/balloon_yellow.svg';
import imgBalloonGreen from './assets/balloon_green.svg';
import imgFireworks from './assets/fireworks.svg';

// フレームアニメーション
const useAnimationFrame = (isCount:boolean, callback:React.EffectCallback) => {
  const reqIdRef = React.useRef(0);
  const loop = React.useCallback(() => {
    if (isCount) {
      reqIdRef.current = requestAnimationFrame(loop);
      callback();
    }
  }, [isCount, callback]);

  React.useEffect(() => {
    reqIdRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(reqIdRef.current);
  }, [loop]);
};

// 風船コンポーネント
type BalloonProps = {
  className: string,
  height: number,
  speed: number,
  delay: number,
}
const Balloon: React.FC<BalloonProps> = ({
  children, className, height, speed, delay,
}) => {
  const [top, setTop] = React.useState(window.innerHeight + height);
  const [isShow, setIsShow] = React.useState(false);

  const tick = React.useCallback(() => {
    // 下から上へアニメーションさせる座標
    setTop((prevTop) => {
      if (prevTop < -height) {
        setIsShow(false);
      }
      setTop((pre) => pre - speed);
      return prevTop;
    });
  }, []);
  useAnimationFrame(isShow, tick);

  React.useEffect(() => {
    // 待機中
    setTimeout(() => {
      setIsShow(true);
    }, delay);
  }, []);

  if (!isShow) {
    return null;
  }
  return (
    <div className={`absolute left-1/2 z-10 ${className}`} style={{ top: `${top}px` }}>
      {children}
    </div>
  );
};

const FireworksAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
export const FireworksImage = styled.img`
  position: fixed;
  animation: ${FireworksAnimation}
    ${(): string => '1.5s ease-out  1 normal forwards running'};
  opacity: 0;
  transform: scale(0);
  z-index: 1000;
  pointer-events: none;
`;

// 花火コンポーネント
type FireworksProps = {
  className: string,
  delay: number,
}
const Fireworks: React.FC<FireworksProps> = ({
  children, className, delay,
}) => {
  const [isShow, setIsShow] = React.useState(false);

  React.useEffect(() => {
    // 待機中
    setTimeout(() => {
      setIsShow(true);
    }, delay);
  }, []);

  if (!isShow) {
    return null;
  }
  return (
    <div className={`absolute left-1/2 z-0 ${className}`}>
      {children}
    </div>
  );
};

const FinishBalloons: React.FC = () => {
  // 貯蓄が0になりそうな年齢の情報を取得
  const deadline = useAppSelector(selectDeadline);
  if (deadline !== undefined) {
    return null;
  }

  // 花火打ち上げの開始タイミング
  const startFireworks = 0;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <Fireworks className="-ml-[237px] mt-[125px]" delay={0 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Fireworks className="ml-[10px] mt-[277px]" delay={500 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Fireworks className="-ml-[140px] mt-[260px]" delay={1200 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Fireworks className="-ml-[237px] mt-[125px]" delay={2000 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Fireworks className="ml-[10px] mt-[277px]" delay={2500 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Fireworks className="-ml-[140px] mt-[260px]" delay={3200 + startFireworks}>
        <FireworksImage src={imgFireworks} alt="" width={200} height={200} />
      </Fireworks>
      <Balloon className="-ml-[102px]" height={74} speed={2} delay={0}>
        <img src={imgBalloonGreen} alt="" width={29} height={74} />
      </Balloon>
      <Balloon className="ml-[7px]" height={74} speed={2} delay={250}>
        <img src={imgBalloonBlue} alt="" width={29} height={74} />
      </Balloon>
      <Balloon className="ml-[46px]" height={126} speed={3} delay={0}>
        <img src={imgBalloonRed} alt="" width={50} height={126} />
      </Balloon>
      <Balloon className="-ml-[148px]" height={126} speed={3} delay={500}>
        <img src={imgBalloonBlue} alt="" width={50} height={126} />
      </Balloon>
      <Balloon className="ml-[97px]" height={126} speed={3} delay={1700}>
        <img src={imgBalloonYellow} alt="" width={50} height={126} />
      </Balloon>
      <Balloon className="-ml-[78px]" height={126} speed={3} delay={2000}>
        <img src={imgBalloonRed} alt="" width={50} height={126} />
      </Balloon>
    </div>
  );
};
export default FinishBalloons;
