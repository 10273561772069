import * as React from 'react';
import styled from 'styled-components';

// images
import imgHeroLBg from './assets/hero_lbg.png';
import imgHeroRBg from './assets/hero_rbg.png';
import imgSiteLogo from './assets/site_logo.svg';
import imgPeople01 from './assets/people01.svg';
import imgPeople02 from './assets/people02.svg';
import imgCloud from './assets/cloud.svg';
import imgFeature01 from './assets/feature01.png';
import imgFeature02 from './assets/feature02.png';
import imgFeature03 from './assets/feature03.png';
import imgStepMobile from './assets/step_mobile.png';
import imgStepBg from './assets/step_bg.png';
import imgStep1 from './assets/step1.png';
import imgStep2 from './assets/step2.png';
import imgStep3 from './assets/step3.png';
import imgTopFooterBg from './assets/top_footer.png';

// components
import Debug from './Debug';

const HeroContent = styled.div`
  position: relative;
  &:before, &:after {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left -80px;
  }
  &:before {
    left: 0;
    background-image: url('${imgHeroLBg}');
    width: 189px;
    height: 442px;
  }
  &:after {
    right: 0;
    background-image: url('${imgHeroRBg}');
    width: 186px;
    height: 672px;
  }
`;
const StepContent = styled.div`
  background-image: url('${imgStepBg}');
  background-position: left 30px;
  background-repeat: no-repeat;
  background-size: 100% auto;
`;
const TopFooterContent = styled.div`
  background-image: url('${imgTopFooterBg}');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto 194px;
  width: 100%;
  height: 194px;
`;

// StepCard
type StepCardProps = {
  num: string;
  src: string;
}
const StepCard: React.FC<StepCardProps> = (props) => {
  const { children, num, src } = props;
  return (
    <div className="pt-[17px] relative">
      <span className="flex justify-center items-center mx-auto absolute top-0 inset-x-0 z-10 bg-primary text-white font-bold rounded-full w-[34px] h-[34px]">{num}</span>
      <div className="bg-gray-200 pt-[54.54%] w-full rounded relative z-0 overflow-hidden">
        <figure className="absolute inset-0">
          <img src={src} alt="" className="w-full" />
        </figure>
      </div>
      <p className="mt-[20px]">{children}</p>
    </div>
  );
};

// メンテナンス日時（値がある場合はメンテナンス中）
const maintenanceDate = process.env.REACT_APP_MAINTENANCE_DATE;
const isMaintenance = maintenanceDate !== '';

const Top: React.FC = () => {
  // ログインページURL用にランダムな文字列の生成
  const seed = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let state = '';
  [...Array(32)].forEach(() => {
    state += seed.charAt(Math.floor(Math.random() * seed.length));
  });

  // ログインページURLを生成
  const domain = process.env.REACT_APP_IB_LOGIN_URL;
  const context = process.env.REACT_APP_IB_CONTEXT;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_LOGIN_REDIRECT_URI;
  const loginUrl = `${domain}/${context}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=read&state=${state}`;

  return (
    <>
      <HeroContent className="text-center text-primary pt-[47px] pb-[30px]">
        <div className="relative z-10">
          <p>
            確定拠出年金(DC)加入者向け
            <br />
            ライフプランシミュレーション
          </p>
          <figure className="my-7">
            <img src={imgSiteLogo} alt="みずほの人生100年デザイナー" width="261" height="131" className="mx-auto" />
          </figure>
          <p className="font-bold">
            ずっと先の未来のお金のことを一緒に考える
            <br />
            ライフプランシミュレーションです。
          </p>
          <div className="container pt-[30px]">
            {isMaintenance && (
              <div className="text-gray-700 text-center space-y-4 px-sm mb-16">
                <p className="text-xl font-bold text-caution">ただいまメンテナンス中です</p>
                <div className="text-base">
                  <p>【メンテナンス日時】</p>
                  <p>{maintenanceDate}</p>
                </div>
                <div className="text-left">
                  <p className="text-base">ご利用の皆様にはご迷惑をおかけし、申し訳ございません。メンテナンス終了までしばらくお待ちください。</p>
                  <p className="text-xs mt-4">※実施時間は前後する可能性があります。</p>
                </div>
              </div>
            ) }
            <section className="text-center text-primary border-[5px] border-white pt-[40px] pb-[52px] relative z-0">
              <h2 className="text-2xl font-bold">
                人生100年時代
                <br />
                想像できますか？
              </h2>
              <p className="mt-6">
                結婚、住宅、子供、老後のこと。
                <br />
                ずっと先だと思っている未来は、
                <br />
                いまからのアクションで
                <br />
                大きく変化します。
                <br />
                とはいえ、何十年も先のことを
                <br />
                想像することは難しいですよね？
                <br />
                人生100年デザイナーは、いまを知り
                <br />
                未来を見えるようにすることで、
                <br />
                未来に向けてのアクションを手助けする
                <br />
                ライフプランシミュレーションです。
              </p>
              <div className="text-[10px] text-left px-[25px] mt-6">
                <p>ある研究(*1)では、2007年に日本で生まれた子供の半数が107歳より長く生きると推計されています。</p>
                <p className="text-gray-500 mt-1">
                  *1: Human Mortality Database,
                  U.C. Berkeley(USA) and Max Planck Institute for Demographic Research(Germany)
                </p>
              </div>
              <figure className="absolute right-0 top-0 -mt-[32px] -mr-[13px]">
                <img src={imgPeople01} alt="" width="43" height="90" />
              </figure>
              <figure className="absolute left-0 top-0 -ml-[15x]">
                <img src={imgPeople02} alt="" width="38" height="107" />
              </figure>
              <figure className="absolute left-0 bottom-0 -mb-[30px] -ml-[48px]">
                <img src={imgCloud} alt="" width="144" height="61" />
              </figure>
            </section>
          </div>
        </div>
      </HeroContent>
      <div className="container mt-[100px] ">
        <div className="px-xs text-primary">
          <section>
            <figure>
              <img src={imgFeature01} alt="" width="295" height="133" className="mx-auto" />
            </figure>
            <h2 className="text-xl font-bold mt-[30px]">見えなかった未来がリアルに見えます。</h2>
            <p className="mt-[45px]">退職金・年金、いくらもらえるかご存知ですか。あなたの情報を基に、リアルに推計します。</p>
          </section>
          <section className="mt-[74px]">
            <figure>
              <img src={imgFeature02} alt="" width="225" height="204" className="mx-auto" />
            </figure>
            <h2 className="text-xl font-bold mt-[30px]">あなたのライフプラン、より良くするためにアドバイスします。</h2>
            <p className="mt-3">具体的なオプションを提示します。各々選択した場合どれくらい効果があるのかシミュレーションも可能です。</p>
          </section>
          <section className="mt-[74px]">
            <figure>
              <img src={imgFeature03} alt="" width="275" height="163" className="mx-auto" />
            </figure>
            <h2 className="text-xl font-bold mt-[30px]">
              ライフプランは日々変化します。
              <br />
              定期的に見直していきましょう。
            </h2>
            <p className="mt-3">定期的にその時の生活や状況に応じて見直していくことで、あなたのライフプランをコントロールできます。</p>
          </section>
        </div>
      </div>
      <StepContent className="container mt-[90px]">
        <h2 className="text-2xl font-bold text-center text-primary">
          簡単3ステップで
          <br />
          シミュレーション！
        </h2>
        <figure className="mt-7 -mb-[236px]">
          <img src={imgStepMobile} alt="" width="305" height="444" className="mx-auto" />
        </figure>
        <div className="px-md pt-[236px] pb-[183px] bg-white rounded">
          <div className="mt-[40px]">
            <StepCard num="1" src={imgStep1}>
              簡単な初期設定で簡易的なシミュレーション結果を表示
            </StepCard>
          </div>
          <div className="mt-[25px]">
            <StepCard num="2" src={imgStep2}>
              詳細を入力して、より正確なシミュレーションを進める
            </StepCard>
          </div>
          <div className="mt-[25px]">
            <StepCard num="3" src={imgStep3}>
              100歳までの貯蓄維持を目指しましょう！
            </StepCard>
          </div>
        </div>
      </StepContent>
      <div className="w-full sticky bottom-6 z-20 -mt-[110px]">
        <div className="w-[275px] mx-auto">
          {isMaintenance
            ? <p className="btn-start">ただいまメンテナンス中です</p>
            : <a href={loginUrl} className="btn-start">シミュレーションを始める！</a>}
        </div>
      </div>
      <TopFooterContent className="mt-[34px]" />
      <Debug />
    </>
  );
};
export default Top;
