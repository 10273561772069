import * as React from 'react';

// ヘッダー情報
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectMessages } from '../../reducers/errorReducer';

// components
import Alert, { Error } from './Alert';

/**
 * サーバーエラーコンポーネント
 * @param props
 * @returns
 */
type ServerErrorProps = {
  className?: string;
};
const Dialogs = {
  LOGIN_EXPIRED: 'ログイン有効期限が切れました。再度ログインしてください。',
};
const ServerError: React.FC<ServerErrorProps> = ({ className }) => {
  // エラーメッセージを取得
  const errorMessages = useAppSelector(selectMessages);
  const [errors, setErrors] = React.useState<Error[]>();
  const navigate = useNavigate();

  React.useEffect(() => {
    setErrors(errorMessages?.map((message, i) => {
      return {
        key: i.toString(),
        message,
      };
    }));
    if (errorMessages?.includes(Dialogs.LOGIN_EXPIRED)) {
      // eslint-disable-next-line no-alert
      alert(Dialogs.LOGIN_EXPIRED);
      navigate('/top', { replace: true });
    }
  }, [errorMessages]);

  if (errors === undefined || errors.length === 0) {
    return null;
  }

  return (
    <Alert errors={errors} className={`mb-8 ${className}`}>
      入力項目で以下のエラーが発生しました
    </Alert>
  );
};
ServerError.defaultProps = {
  className: '',
};
export default ServerError;
