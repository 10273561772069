import * as React from 'react';

// バリデーションエラー情報の保持
import { useAppDispatch } from '../../app/hooks';
import { setFailed } from '../../reducers/validateReducer';

// services
import validation, { ValidationError } from '../../services/validation';

// components
import FailedMessage from './FailedMessage';

type InputDecimalProps = {
  name?: string; // 画面UIでのバリデーションエラーを監視するためにユニークな名前を指定
  className?: string;
  unit?: string;
  division?: number;
  defaultValue?: number|null;
  inputRef?: React.RefObject<HTMLInputElement>;
  min?: number;
  max?: number;
  size?: number;
  digits?: number;
  onChange?: (_: number|null) => void;
  onError?: (_: ValidationError[]) => void;
  disabled?: boolean;
};

/**
 * 数字入力項目
 * @param props
 * @returns
 */
const InputDecimal: React.FC<InputDecimalProps> = ({
  name, className, unit, division, defaultValue, inputRef, min, max, size, digits,
  onChange, onError, disabled,
}) => {
  const dispatch = useAppDispatch();

  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<ValidationError[]>([]);

  // 単位
  const divisionNum = (division !== undefined && division > 0) ? 10 ** division : 1;
  const divisionDigits = (digits !== undefined) ? 10 ** digits : 1;

  React.useEffect(() => {
    if (name !== undefined) {
      // 検証エラーをリセット
      dispatch(setFailed([name, false]));
    }

    return () => {
      // コンポーネントがアンマウントしたときに、検証エラーをリセット
      if (name !== undefined) {
        dispatch(setFailed([name, false]));
      }
    };
  }, []);

  return (
    <>
      <div className="flex items-center">
        <input
          type="text"
          inputMode="decimal"
          className={`form-input font-alpha text-right disabled:bg-gray-100 ${className}`}
          defaultValue={(defaultValue !== undefined && defaultValue !== null)
            ? Math.round((defaultValue / divisionNum) * divisionDigits) / divisionDigits
            : undefined}
          ref={inputRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            // 値をが正しいか検証する
            const { value } = event.currentTarget;
            const errorMessages: ValidationError[] = [];
            if (value !== '') {
              const errorMessage1 = validation(value, { decimal: { size, digits } });
              if (errorMessage1 !== null) {
                errorMessages.push({ key: 'decimal', message: errorMessage1 });
              } else if (min !== undefined && max !== undefined) {
                const errorMessage2 = validation(value, { range: { min, max } });
                if (errorMessage2 !== null) {
                  errorMessages.push({ key: 'range', message: errorMessage2 });
                }
              }
            }
            if (onChange !== undefined) {
              let changeValue = null;
              if (value !== '') {
                changeValue = Number.isNaN(parseFloat(value))
                  ? null : parseFloat(value) * divisionNum;
              }
              onChange(changeValue);
            }
            setErrors(errorMessages);
            if (onError !== undefined) {
              onError(errorMessages);
            }

            if (name !== undefined) {
              // 検証エラー情報をセット
              dispatch(setFailed([name, errorMessages.length !== 0]));
            }
          }}
          disabled={disabled}
        />
        {unit !== undefined
          ? <span className="form-unit">{unit}</span>
          : null}
      </div>
      {
        onError === undefined
        && <FailedMessage errors={errors} />
      }
    </>
  );
};
InputDecimal.defaultProps = {
  name: undefined,
  className: '',
  unit: undefined,
  division: 0,
  defaultValue: undefined,
  inputRef: undefined,
  min: undefined,
  max: undefined,
  size: undefined,
  digits: undefined,
  onChange: undefined,
  onError: undefined,
  disabled: false,
};

export default InputDecimal;
