import * as React from 'react';

// グリーンラベルに表示するラベル名を取得
import { getLabelValue } from '../../reducers/estimatedReducer';

type EstimationLabelAmountProps = {
  source: string|null|undefined;
  value: number|null|undefined;
  unit?: string|null|undefined;
};

/**
 * グリーンラベル部品コンポーネント（金額）
 * @param props
 * @returns
 */
const EstimationLabelAmount: React.FC<EstimationLabelAmountProps> = (
  { source, value, unit },
) => {
  const [label, setLabel] = React.useState<string|null>(null);

  React.useEffect(() => {
    setLabel(getLabelValue(source));
  }, [source]);

  if (label === null) {
    return null;
  }

  return (
    <p className="green-label">
      {label}
      { `「${value}${unit}」円` }
    </p>
  );
};
EstimationLabelAmount.defaultProps = {
  unit: '',
};
export default EstimationLabelAmount;
