import * as React from 'react';

// services
import { ValidationError } from '../../services/validation';

type FailedMessageProps = {
  errors?: ValidationError[];
};

/**
 * エラーメッセージ
 * @param props
 * @returns
 */
const FailedMessage: React.FC<FailedMessageProps> = ({ errors = [] }) => {
  if (errors.length === 0) {
    return null;
  }
  return (
    <ul className="mt-2">
      {
        errors.map((error) => <li className="failed-message break-all my-2" key={error.key}>{error.message}</li>)
      }
    </ul>
  );
};
FailedMessage.defaultProps = {
  errors: [],
};

export default FailedMessage;
