import * as React from 'react';

// バリデーションエラー情報の保持
import { useAppDispatch } from '../../app/hooks';
import { setFailed } from '../../reducers/validateReducer';

// services
import validation, { ValidationError } from '../../services/validation';

// components
import FailedMessage from './FailedMessage';

type InputIntegerProps = {
  name?: string; // 画面UIでのバリデーションエラーを監視するためにユニークな名前を指定
  className?: string;
  defaultValue?: number|null;
  unit?: string;
  division?: number;
  min?: number;
  max?: number;
  maxlength?: number;
  disabled?: boolean;
  required?: boolean;
  defauleValueWhenNull?: number|null;
  onChange?: (_: number|null) => void;
  onError?: (_: ValidationError[]) => void;
};

/**
 * 数字入力項目
 * @param props
 * @returns
 */
const InputInteger: React.FC<InputIntegerProps> = ({
  name, className, defaultValue, unit, division, min, max, maxlength, disabled, required,
  defauleValueWhenNull, onChange, onError,
}) => {
  const dispatch = useAppDispatch();

  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<ValidationError[]>([]);

  // 単位
  const divisionNum = (division !== undefined && division > 0) ? 10 ** division : 1;

  // ref属性の参照
  const ref = React.useRef<HTMLInputElement>(null);

  // 入力フォームが変更されたかどうか
  const [hasChanged, setHasChanged] = React.useState(false);

  // 検証
  const validate = ((force = false) => {
    const $el = ref.current;
    if ($el !== null) {
      const { value } = $el;
      const errorMessages: ValidationError[] = [];
      if (value !== '' || (required && !disabled && force)) {
        const errorMessage1 = value !== '' ? validation(value, { integer: true }) : null;
        if (errorMessage1 !== null) {
          errorMessages.push({ key: 'integer', message: errorMessage1 });
        } else if (min !== undefined && max !== undefined) {
          const errorMessage2 = validation(value, { range: { min, max } });
          if (errorMessage2 !== null) {
            errorMessages.push({ key: 'range', message: errorMessage2 });
          }
        } else if (min !== undefined) {
          const errorMessage2 = validation(value, { more: min });
          if (errorMessage2 !== null) {
            errorMessages.push({ key: 'range', message: errorMessage2 });
          }
        } else if (max !== undefined) {
          const errorMessage2 = validation(value, { less: max });
          if (errorMessage2 !== null) {
            errorMessages.push({ key: 'range', message: errorMessage2 });
          }
        }
      }
      setErrors(errorMessages);
      if (onError !== undefined) {
        onError(errorMessages);
      }

      if (name !== undefined) {
        // 検証エラー情報をセット
        dispatch(setFailed([name, errorMessages.length !== 0]));
      }

      return errorMessages.length === 0;
    }
    return false;
  });

  const getShowValue = (() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      return Math.round(defaultValue / divisionNum);
    } if (defauleValueWhenNull !== undefined && defauleValueWhenNull !== null) {
      return defauleValueWhenNull;
    }
    return undefined;
  });

  const onChangeHandler = ((value: string) => {
    if (onChange !== undefined) {
      let changeValue = null;
      if (value !== '') {
        changeValue = Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10) * divisionNum;
      }
      onChange(changeValue);
    }
  });

  React.useEffect(() => {
    if (name !== undefined) {
      // 検証エラーをリセット
      dispatch(setFailed([name, false]));
    }

    return () => {
      // コンポーネントがアンマウントしたときに、検証エラーをリセット
      if (name !== undefined) {
        dispatch(setFailed([name, false]));
      }
    };
  }, []);

  React.useEffect(() => {
    validate();
  }, [min, max]);

  // アクティブになったら値を渡す
  React.useEffect(() => {
    if (!disabled) {
      if (hasChanged) {
        const $el = ref.current;
        if ($el !== null) {
          const { value } = $el;
          onChangeHandler(value);
        }
      } else if (onChange !== undefined) {
        onChange(defaultValue ?? null);
      }
    }
  }, [disabled]);

  return (
    <>
      <div className="flex items-center">
        <input
          type="tel"
          className={`form-input font-alpha text-right disabled:bg-gray-100 ${className}`}
          defaultValue={getShowValue()}
          maxLength={maxlength}
          ref={ref}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;
            validate(true);
            setHasChanged(true);
            onChangeHandler(value);
          }}
          disabled={disabled}
        />
        {unit !== undefined
          ? <span className="form-unit">{unit}</span>
          : null}
      </div>
      {
        onError === undefined
        && <FailedMessage errors={errors} />
      }
    </>
  );
};
InputInteger.defaultProps = {
  name: undefined,
  className: '',
  defaultValue: undefined,
  unit: undefined,
  division: 0,
  min: undefined,
  max: undefined,
  maxlength: undefined,
  disabled: false,
  required: false,
  defauleValueWhenNull: undefined,
  onChange: undefined,
  onError: undefined,
};

export default InputInteger;
