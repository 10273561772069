import React from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer,
} from 'recharts';
import { theme } from 'twin.macro';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// キャッシュフローデータの型
import type { CashflowChart } from '../../reducers/cashflowReducer';

type Chart = {
  name: string,
  value: number,
  color: string,
  class: string,
}

type CashflowDetailProps = {
  datas: CashflowChart[];
  active?: number;
};
const CashflowDetail: React.FC<CashflowDetailProps> = ({ datas, active }) => {
  // 現在の年齢と次の年齢と前の年齢
  const [currentValue, setCurrentValue] = React.useState(active);
  const [prevValue, setPrevValue] = React.useState<number>();
  const [nextValue, setNextValue] = React.useState<number>();

  // 現在選択中のグラフデータ
  const [currentData, setCurrentData] = React.useState<CashflowChart>();

  // 円グラフデータ
  const [chartData, setChartData] = React.useState<Chart[]>();
  const [detailDatas, setDetailDatas] = React.useState<Chart[][]>();

  React.useEffect(() => {
    if (currentValue !== undefined) {
      // 選択した年齢からグラフデータを取得
      const activeData = datas.find((data) => data.age === currentValue);
      setCurrentData(activeData);

      // 前の年齢を取得
      const prevActive = currentValue - 1;
      const prevData = datas.find((data) => data.age === prevActive);
      setPrevValue(prevData?.age);

      // 次の年齢を取得
      const nextActive = currentValue + 1;
      const nextData = datas.find((data) => data.age === nextActive);
      setNextValue(nextData?.age);

      setChartData([
        {
          name: '手取り', value: Math.round((activeData?.residualIncomeExcludeRefunds ?? 0) / 10000), color: theme('colors.primary'), class: 'bg-primary',
        },
        {
          name: 'その他', value: Math.round((activeData?.refunds ?? 0) / 10000), color: theme('colors.primary-700'), class: 'bg-primary-700',
        },
        {
          name: '生活費', value: Math.round((activeData?.living ?? 0) / 10000), color: theme('colors.error'), class: 'bg-error',
        },
        {
          name: '教育費', value: Math.round((activeData?.education ?? 0) / 10000), color: '#DE6666', class: 'bg-[#DE6666]',
        },
        {
          name: '住宅費', value: Math.round((activeData?.house ?? 0) / 10000), color: '#D84343', class: 'bg-[#D84343]',
        },
        {
          name: 'その他', value: Math.round((activeData?.other ?? 0) / 10000), color: '#999999', class: 'bg-[#999999]',
        },
      ]);
    }
  }, [datas, currentValue]);

  // 詳細情報を表示するために、配列を2分割する
  React.useEffect(() => {
    if (chartData !== undefined) {
      const length = Math.ceil(chartData.length / 2);
      const newDetailDatas = new Array(length)
        .fill(0)
        .map((_, i) => chartData.slice(i * 2, (i + 1) * 2));
      setDetailDatas(newDetailDatas);
    }
  }, [chartData]);

  return (
    <div className="px-6 py-7 relative">
      {currentData !== undefined && (
        <>
          {prevValue !== undefined && (
            <button type="button" className="btn-prev absolute left-0 top-1/2" onClick={() => { setCurrentValue(prevValue); }}>
              <span />
            </button>
          )}
          {nextValue !== undefined && (
            <button type="button" className="btn-next absolute right-0 top-1/2" onClick={() => { setCurrentValue(nextValue); }}>
              <span />
            </button>
          )}
          <div className="text-center text-primary">
            <p className="text-base font-bold">
              {currentData.thisYear}
              年
            </p>
            <h3 className="text-xl font-bold mt-2">
              あなたの
              {currentData.age}
              歳の収支状況
            </h3>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className="text-primary">
              <span className="text-sm mr-2">収入</span>
              <span className="text-xl font-alpha">{costValue(currentData.incomings, 6)}</span>
            </div>
            <div className="text-error">
              <span className="text-sm mr-2">支出</span>
              <span className="text-xl font-alpha">{costValue(currentData.expenses, 6)}</span>
            </div>
          </div>
          <div className="flex justify-center mt-1">
            <div className="text-primary">
              <span className="text-sm mr-2">貯蓄</span>
              <span className="text-base font-alpha">{costValue(currentData.savings, 15)}</span>
            </div>
          </div>
          {chartData !== undefined && (
            <div className="relative w-full pt-[180px] mt-4">
              <div className="absolute inset-0">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={180} height={180}>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      isAnimationActive={false}
                      innerRadius={45}
                      outerRadius={90}
                      paddingAngle={1}
                      minAngle={5}
                      dataKey="value"
                    >
                      {chartData.map((data_) => (
                        <Cell
                          key={`cell-${data_.name}`}
                          fill={data_.color}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {detailDatas !== undefined && (
            <div className="text-primary mt-4">
              {detailDatas.map((detailData) => (
                <div className="flex items-center justify-between -mx-2" key={`row-${detailData[0].name}`}>
                  {detailData.map((data) => (
                    <div className="w-1/2 px-2 flex items-center justify-start" key={data.name}>
                      <span className={`w-3.5 h-3.5 rounded-full mr-1 ${data.class}`} />
                      <span className="text-xs mr-2">{data.name}</span>
                      <span className="text-sm font-alpha">{costValue(data.value, 6)}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          <div className="text-right mt-2">
            <span className="text-[0.625rem] text-gray-500">※単位：万円</span>
          </div>
        </>
      )}
    </div>
  );
};
CashflowDetail.defaultProps = {
  active: undefined,
};
export default CashflowDetail;
