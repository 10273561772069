import * as React from 'react';

// components
import TableGroup from './TableGroup';
import TableRow from './TableRow';

// 退職金・年金の受取額専用テーブル
type AmountReceivedTableProps = {
  label: string,
  yourBulk?: number|null,
  yourInstallments?: number|null,
  spouseBulk?: number|null,
  spouseInstallments?: number|null,

  // 配偶者がいるかどうかの判定
  hasSpouse?: boolean|null,
}
const AmountReceivedTable: React.FC<AmountReceivedTableProps> = (
  {
    label,
    yourBulk = null, yourInstallments = null, spouseBulk = null, spouseInstallments = null,
    hasSpouse = false,
  },
) => {
  // 総額を計算
  const total = (Math.round((yourBulk ?? 0) / 10000) * 10000)
    + (Math.round((yourInstallments ?? 0) / 10000) * 10000)
    + (Math.round((spouseBulk ?? 0) / 10000) * 10000)
    + (Math.round((spouseInstallments ?? 0) / 10000) * 10000);

  return (
    <>
      <TableGroup label={label}>
        <div className="space-y-2">
          <TableRow label="あなたの一括" value={yourBulk} />
          <TableRow label="あなたの分割 (累計)" value={yourInstallments} />
          {hasSpouse && (
            <>
              <TableRow label="配偶者の一括" value={spouseBulk} />
              <TableRow label="配偶者の分割 (累計)" value={spouseInstallments} />
            </>
          )}

        </div>
      </TableGroup>
      <TableRow label="総額" value={total} className="mt-2" maxDigits={8} />
    </>
  );
};
AmountReceivedTable.defaultProps = {
  yourBulk: null,
  yourInstallments: null,
  spouseBulk: null,
  spouseInstallments: null,
  hasSpouse: false,
};
export default AmountReceivedTable;
