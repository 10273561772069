import * as React from 'react';
import { Link } from 'react-router-dom';
import deepEqual from 'deep-equal';
import moment from 'moment';
import type { ValidationError } from '../../services/validation';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import {
  selectFamily,
  selectEducationExpenses,
  selectHousingExpenses,
  selectLivingExpenses,
  setLivingExpenses as setDispatchLivingExpenses,
} from '../../reducers/inputReducer';
import { selectHouseholdIncome } from '../../reducers/cashflowReducer';
import { selectIsGuest } from '../../reducers/userReducer';
// images
import imgHero from './assets/hero.svg';

// components
import InputInteger from '../../features/input/InputInteger';
import InputDecimal from '../../features/input/InputDecimal';
import InputSelect from '../../features/input/InputSelect';
import TableInputRow from '../../features/table/TableInputRow';
import Amount from '../../features/block/Amount';
import Remarks from '../../features/block/Remarks';
import HelpButton from '../../features/button/HelpButton';
import ToggleDetail from '../../features/block/ToggleDetail';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeBasicsLivingCosts: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const guest = useAppSelector(selectIsGuest);

  // 教育費
  // 教育費は、子供オブジェクトに変換しつつ(その過程で10000分の一)、計算後に整数に四捨五入
  const family = useAppSelector(selectFamily);
  const educationExpenses = useAppSelector(selectEducationExpenses);
  const educationCosts = (() => family.children.birthMonth
    .reduce((sum, birthMonth, index) => {
      // 満年齢
      const birthDate = moment(birthMonth).clone();
      if (birthDate.month() + 1 < 4) {
        birthDate.subtract(1, 'year');
      }
      const aprilDate = birthDate.set('month', 3).set('date', 2);
      const age = moment().diff(aprilDate, 'years');

      // 教育費を取得
      const child = educationExpenses.children[index];
      const schoolConfig = [
        {
        // 小学
          range: [7, 12],
          school: child.primarySchool,
        },
        {
        // 中学
          range: [13, 15],
          school: child.juniorHighSchool,
        },
        {
        // 高校
          range: [16, 18],
          school: child.highSchool,
        },
        {
        // 大学
          range: [19, 22],
          school: child.university,
        },
      ];
      const config = schoolConfig.find((c) => age >= c.range[0] && age <= c.range[1]);

      let income = 0;
      if (config !== undefined) {
        income += config.school.annualTuition ?? 0;
        income += config.school.annualLessonFee ?? 0;
      }
      return income + sum;
    }, 0)
  )();

  // 住宅費
  const housingExpenses = useAppSelector(selectHousingExpenses);
  const { presentHouse } = housingExpenses;
  const housingCosts = (() => {
    if (presentHouse === 'RENTAL_HOUSING') {
      return housingExpenses.monthlyRent;
    } if (presentHouse === 'OWNING_HOUSE') {
      return housingExpenses.maintenanceFee;
    } if (presentHouse === 'HOME') {
      return housingExpenses.monthlyHousingExpense;
    }
    return 0;
  })();

  // 入力データ
  const defaultLivingExpenses = useAppSelector(selectLivingExpenses);
  const [livingExpenses, setLivingExpenses] = React.useState(defaultLivingExpenses);

  // 生活費
  const [
    monthlyLivingExpenses, setMonthlyLivingExpenses,
  ] = React.useState(defaultLivingExpenses.monthlyLivingExpenses);
  const [
    monthlyLivingExpensesErros, setMonthlyLivingExpensesErrors,
  ] = React.useState<ValidationError[]>([]);

  // 保険料（掛け捨て）
  const [
    monthlyTermInsurance, setMonthlyTermInsurance,
  ] = React.useState(defaultLivingExpenses.monthlyTermInsurance);
  const [
    monthlyTermInsuranceErros, setMonthlyTermInsuranceErrors,
  ] = React.useState<ValidationError[]>([]);

  // 自動車購入費
  const [
    carMaintenanceExpensesMonthly, setCarMaintenanceExpensesMonthly,
  ] = React.useState(defaultLivingExpenses.carMaintenanceExpensesMonthly);
  const [
    carMaintenanceExpensesMonthlyErros, setCarMaintenanceExpensesMonthlyErrors,
  ] = React.useState<ValidationError[]>([]);

  // 自動車購入費（年齢）
  const [
    endAgeOfCarMaintenance,
    setEndAgeOfCarMaintenance,
  ] = React.useState(defaultLivingExpenses.endAgeOfCarMaintenance);
  const [
    endAgeOfCarMaintenanceErros,
    setEndAgeOfCarMaintenanceErrors,
  ] = React.useState<ValidationError[]>([]);

  // 固定資産税・その他税金
  const [
    annualTaxes, setAnnualTaxes,
  ] = React.useState(defaultLivingExpenses.annualTaxes);
  const [
    annualTaxesErros, setAnnualTaxesErrors,
  ] = React.useState<ValidationError[]>([]);

  // 旅行/帰省
  const [
    annualTravelExpenses, setAnnualTravelExpenses,
  ] = React.useState(defaultLivingExpenses.annualTravelExpenses);
  const [
    annualTravelExpensesErros, setAnnualTravelExpensesErrors,
  ] = React.useState<ValidationError[]>([]);

  // その他
  const [
    annualOtherExpenses, setAnnualOtherExpenses,
  ] = React.useState(defaultLivingExpenses.annualOtherExpenses);
  const [
    annualOtherExpensesErros, setAnnualOtherExpensesErrors,
  ] = React.useState<ValidationError[]>([]);

  // 物価上昇率
  const [inflationRate, setInflationRate] = React.useState(defaultLivingExpenses.inflationRate);

  // 物価上昇率リスト
  const inflationRateOptions = (() => Array.from(Array(6).keys()).map((n) => {
    return {
      value: `PER_${n}`,
      label: `${n}%`,
    };
  }))();

  // 世帯年収
  const householdIncome = useAppSelector(selectHouseholdIncome);

  // 年間の値に変換
  const getAnnual = (value: number|null) => {
    if (value === null) {
      return 0;
    }
    return Math.round(value * 12);
  };

  // 合計（月間）
  const getTotal = () => (Math.round((housingCosts ?? 0) / 1000) * 1000)
    + (Math.round((monthlyLivingExpenses ?? 0) / 1000) * 1000)
    + (Math.round((monthlyTermInsurance ?? 0) / 1000) * 1000)
    + (Math.round((carMaintenanceExpensesMonthly ?? 0) / 1000) * 1000);

  // 合計（年間）
  const getTotalAnnual = () => (educationCosts ?? 0)
    + (Math.round(getAnnual(housingCosts ?? 0) / 10000) * 10000)
    + (Math.round(getAnnual(monthlyLivingExpenses ?? 0) / 10000) * 10000)
    + (Math.round(getAnnual(monthlyTermInsurance ?? 0) / 10000) * 10000)
    + (Math.round(getAnnual(carMaintenanceExpensesMonthly ?? 0) / 10000) * 10000)
    + (annualTaxes ?? 0)
    + (annualTravelExpenses ?? 0)
    + (annualOtherExpenses ?? 0);

  // データ更新
  React.useEffect(() => {
    setLivingExpenses((data) => {
      return {
        ...data,
        monthlyLivingExpenses,
        monthlyTermInsurance,
        carMaintenanceExpensesMonthly,
        endAgeOfCarMaintenance,
        annualTaxes,
        annualTravelExpenses,
        annualOtherExpenses,
        inflationRate,
      };
    });
  }, [
    monthlyLivingExpenses,
    monthlyTermInsurance,
    carMaintenanceExpensesMonthly,
    endAgeOfCarMaintenance,
    annualTaxes,
    annualTravelExpenses,
    annualOtherExpenses,
    inflationRate,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setLivingExpenses(defaultLivingExpenses);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>生活費</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">月間や年間の収支を把握していますか？生活にかかるお金を整理してみましょう。</p>
        <p className="text-sm mt-2 mb-6">
          食費、光熱費、家庭用品、旅行、医療費さらには税金なども、毎月様々なお金が出ていきます。現状の生活費を知ることが正確なシミュレーションの第一歩です。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <section>
            <h3 className="fieldset-title">生活費</h3>
            <div className="fieldset-body">
              <div className="form-label">
                {guest ? <span>教育費</span> : <Link to="/home/education">教育費</Link>}
                <HelpButton>
                  「教育費用」の画面でご入力をいただきます。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={null} unit="" />
                <TableInputRow label="年間 (今年の支出)" value={educationCosts} unit="万円" division={4} fixed />
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                {guest ? <span>住宅費</span> : <Link to="/home/house/present">住宅費</Link>}
                <HelpButton>
                  「住宅費用」の画面でご入力をいただきます。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={housingCosts} unit="万円" division={4} digits={1} fixed />
                <TableInputRow label="年間 (今年の支出)" value={getAnnual(housingCosts)} unit="万円" division={4} fixed />
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>生活費</span>
                <HelpButton>
                  食費、水道・光熱費、家庭用品、衣類費、医療費、交通費、通信費など生活維持のための費用を合計したものを生活費とします。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" unit="万円/月" errors={monthlyLivingExpensesErros}>
                  <InputDecimal
                    name="monthlyLivingExpenses"
                    className="w-[100px]"
                    defaultValue={monthlyLivingExpenses}
                    size={3}
                    digits={1}
                    division={4}
                    onChange={(value) => setMonthlyLivingExpenses(value)}
                    onError={(errors) => setMonthlyLivingExpensesErrors(errors)}
                  />
                </TableInputRow>
                <TableInputRow label="年間 (今年の支出)" value={getAnnual(monthlyLivingExpenses)} unit="万円" division={4} fixed />
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>保険料 (掛け捨て)</span>
                <HelpButton>
                  生命保険・火災保険・自動車保険などを保険料とします。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" unit="万円/月" errors={monthlyTermInsuranceErros}>
                  <InputDecimal
                    name="monthlyTermInsurance"
                    className="w-[100px] text-right"
                    defaultValue={monthlyTermInsurance}
                    size={3}
                    digits={1}
                    division={4}
                    onChange={(value) => setMonthlyTermInsurance(value)}
                    onError={(errors) => setMonthlyTermInsuranceErrors(errors)}
                  />
                </TableInputRow>
                <TableInputRow label="年間 (今年の支出)" value={getAnnual(monthlyTermInsurance)} unit="万円" division={4} fixed />
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>自動車購入費</span>
                <HelpButton>
                  自動車ローンを自動車購入費とします (駐車場代やガソリン費などの維持費は生活費に入れてください)。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" unit="万円/月" errors={carMaintenanceExpensesMonthlyErros}>
                  <InputDecimal
                    name="carMaintenanceExpensesMonthly"
                    className="w-[100px] text-right"
                    defaultValue={carMaintenanceExpensesMonthly}
                    size={3}
                    digits={1}
                    division={4}
                    onChange={(value) => setCarMaintenanceExpensesMonthly(value)}
                    onError={(errors) => setCarMaintenanceExpensesMonthlyErrors(errors)}
                  />
                </TableInputRow>
                <TableInputRow unit="歳まで" errors={endAgeOfCarMaintenanceErros}>
                  <InputInteger
                    name="endAgeOfCarMaintenance"
                    className="w-[100px] text-right"
                    defaultValue={endAgeOfCarMaintenance}
                    disabled={!(carMaintenanceExpensesMonthly !== undefined
                      && carMaintenanceExpensesMonthly !== null
                      && carMaintenanceExpensesMonthly !== 0)}
                    maxlength={2}
                    onChange={(value) => setEndAgeOfCarMaintenance(value)}
                    onError={(errors) => setEndAgeOfCarMaintenanceErrors(errors)}
                  />
                </TableInputRow>
                <TableInputRow label="年間 (今年の支出)" value={getAnnual(carMaintenanceExpensesMonthly)} unit="万円" division={4} fixed />
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>固定資産税・その他税金</span>
                <HelpButton>
                  固定資産税・自動車税等を入力してください。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={null} unit="" />
                <TableInputRow label="年間 (今年の支出)" unit="万円/年" errors={annualTaxesErros}>
                  <InputInteger
                    name="annualTaxes"
                    className="w-[100px] text-right"
                    defaultValue={annualTaxes}
                    defauleValueWhenNull={0}
                    division={4}
                    maxlength={4}
                    onChange={(value) => setAnnualTaxes(value)}
                    onError={(errors) => setAnnualTaxesErrors(errors)}
                  />
                </TableInputRow>
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>旅行/帰省</span>
                <HelpButton>
                  家族での旅行や帰省の費用を旅行/帰省とします。
                </HelpButton>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={null} unit="" />
                <TableInputRow label="年間 (今年の支出)" unit="万円/年" errors={annualTravelExpensesErros}>
                  <InputInteger
                    name="annualTravelExpenses"
                    className="w-[100px] text-right"
                    defaultValue={annualTravelExpenses}
                    defauleValueWhenNull={0}
                    division={4}
                    maxlength={4}
                    onChange={(value) => setAnnualTravelExpenses(value)}
                    onError={(errors) => setAnnualTravelExpensesErrors(errors)}
                  />
                </TableInputRow>
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>その他</span>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={null} unit="" />
                <TableInputRow label="年間 (今年の支出)" unit="万円/年" errors={annualOtherExpensesErros}>
                  <InputInteger
                    name="annualOtherExpenses"
                    className="w-[100px] text-right"
                    defaultValue={annualOtherExpenses}
                    defauleValueWhenNull={0}
                    division={4}
                    maxlength={4}
                    onChange={(value) => setAnnualOtherExpenses(value)}
                    onError={(errors) => setAnnualOtherExpensesErrors(errors)}
                  />
                </TableInputRow>
              </div>
            </div>
            <div className="fieldset-body">
              <div className="form-label">
                <span>合計</span>
              </div>
              <div className="space-y-2">
                <TableInputRow label="月間" value={getTotal()} unit="万円" division={4} fixed />
                <TableInputRow label="年間 (今年の支出)" value={getTotalAnnual()} unit="万円" division={4} fixed />
              </div>
              <Remarks className="mt-6">
                <p>
                  「教育費用」は、シミュレーション実施時点で各「子ども」が「一年生」に該当すると見なした場合、「入学金」も含めた金額を出力しています。
                  また、「資産形成」のための費用（＝積立金額（本人負担含む））は、生活費に含めていません。
                </p>
              </Remarks>
            </div>
          </section>
          <section>
            <h3 className="fieldset-title">世帯の収支</h3>
            <div className="fieldset-body">
              <div className="form-label">
                <span>年間の収支</span>
                <HelpButton>
                  本項目に表示される金額が、あなたがご認識される｢実際の世帯の貯蓄額(年間)｣とかけ離れている場合は、上記の各生活費入力項目の入力内容を見直してください。
                </HelpButton>
              </div>
              <Amount
                value={
                  Math.round(householdIncome / (10 ** 4)) - Math.round(getTotalAnnual() / (10 ** 4))
                }
              />
              <Remarks className="mt-6">
                <p className="break-all">
                  内訳：世帯年収 (手取り)
                  {' '}
                  { costValue(Math.round(householdIncome / (10 ** 4)), 15) }
                  万円 - 年間の支出
                  {' '}
                  { costValue(Math.round(getTotalAnnual() / (10 ** 4)), 15) }
                  万円
                </p>
              </Remarks>
              <ToggleDetail className="mt-6">
                <div className="fieldset-body">
                  <div className="form-label">
                    <span>物価上昇率</span>
                    <HelpButton>
                      前年を基準にした価格変動の指標になります。
                      <br />
                      過去20年の物価はほぼ一定の水準で推移してきましたが、40年前と比較すると現在の物価は約1.7倍※となっています。
                      <br />
                      ※出典：総務省｢消費者物価指数｣
                    </HelpButton>
                  </div>
                  <InputSelect
                    className="block w-full"
                    options={inflationRateOptions}
                    defaultValue={inflationRate}
                    onChange={(event) => {
                      const { value } = event.currentTarget;
                      setInflationRate(value);
                    }}
                  />
                </div>
              </ToggleDetail>
            </div>
          </section>
        </div>
        <FixedButton
          disabled={deepEqual(defaultLivingExpenses, livingExpenses)}
          contentId="livingCosts"
          onClick={() => {
            dispatch(setDispatchLivingExpenses(livingExpenses));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeBasicsLivingCosts;
