import type { AxiosResponse } from 'axios';

const ItemMapping: { [key: string]: string } = {
  // 基本情報
  // TODO:アカウントの場合はもっと掘らないといけない
  account: 'ユーザアカウント',
  loginFlg: 'ログインフラグ',
  jisTid: 'JIS&T ID',
  userRegistrationStatus: 'ユーザ登録ステータス',
  initialSettingStatus: '初期設定ステータス',
  nickname: 'ニックネーム',
  email: 'メールアドレス',
  requestForEmailNewsletter: 'メール配信希望',
  annualIncomeFaceValue: '年収(額面)',
  lifeStyle: 'ライフスタイル',
  birthDay: '生年月日(RAW)',
  // 家族構成: "
  hasSpouse: '配偶者有無',
  birthMonth: '生年月',
  willHaveSpouse: '結婚の予定',
  ageOfMarriagePlanHouseholder: '結婚予定年齢(本人)',
  ageOfMarriagePlanSpouse: '結婚予定年齢(配偶者)',
  hasChildren: '子供有無',
  willHaveChildren: '将来の子供の予定',
  expectedBirthYear: '予定生まれ年',
  // 収入: "
  depositAmount: '預金額',
  employmentStatusOfHouseholder: '職業(本人)',
  employmentStatusOfSpouse: '職業(配偶者)',
  annualIncome: '年収(本人)',
  annualIncomeOfSpouse: '年収(配偶者)',
  industry: '業種(本人)',
  industryOfSpouse: '業種(配偶者)',
  numberOfEmployees: '従業員数',
  lengthOfServiceOfHouseholder: '勤続年数(本人)',
  lengthOfServiceOfSpouse: '勤続年数(配偶者)',
  incomeGrowthRate: '年収の増加率',
  occupation: '職系',
  willHaveChildcareLeave: '育休',
  postpartumWorkStyle: '育休後の就業スタイル(本人)',
  postpartumWorkStyleOfSpouse: '育休後の就業スタイル(配偶者)',
  // 支出: "
  monthlyLivingExpenses: '生活費(月額)',
  monthlyTermInsurance: '保険料(月額)',
  carMaintenanceExpensesMonthly: '自動車購入費(月額)',
  endAgeOfCarMaintenance: '自動車購入費(年齢)',
  annualTaxes: '税金(年額)',
  annualTravelExpenses: '旅行(年額)',
  annualOtherExpenses: 'その他(年額)',
  inflationrate: '物価上昇率',
  savingExpensesRate: '生活費削減率',
  educationPolicy: '教育費イメージ',
  publicPrivate: '区分',
  entranceFee: '入学金',
  annualTuition: '授業料',
  annualLessonFee: '習い事',
  presentHouse: '居住形態',
  monthlyRent: '家賃',
  monthlyHousingExpense: '居住費',
  maintenanceFee: '管理費',
  hasMortgageLoan: '住宅ローン有無',
  loanStartYear: '借入年月',
  loanAmount: '借入額',
  loanPeriod: '借入期間',
  interestCalculationMethod: '金利計算方式',
  loanRate: '金利',
  periodOfFixedRate: '固定期間',
  repaymentMethod: '返却方法',
  presentLoanAmount: '現在残高',
  baseDateOfPresentAmount: '現在残高基準日',
  periodPfFixedRate: '固定期間',
  willBuyHouse: '住宅購入予定',
  ageOfPlanToBuyHouseHouseholder: '購入年齢(本人)',
  ageOfPlanToBuyHouseSpouse: '購入年齢(配偶者)',
  amountOfPlanToBuyHouse: '購入金額',
  willHaveMortgageLoan: '住宅ローン予定',
  whoWillHaveMortgageLoan: 'ローンを組む人',
  downPayment: '頭金',
  loanAmountHouseholder: '借入額(本人)',
  loanAmountSpouse: '借入額(配偶者)',
  willHavePrepaymentOfLoan: '繰り上げ返済計画',
  minimumDepositAmount: '最低預金確保額',
  hasConsiderationOfDeduction: '住宅ローン控除考慮',
  targetLoan: '対象ローン',
  // 退職金・年金: "
  retirementAllowance: '想定退職金',
  startAgeOfPension: '受給開始年齢',
  monthlyPensionAmount: '想定受給額',
  drawTypeOfPension: '受け取り方法',
  yearsOfPensionDrawing: '受け取り年数',
  retirementAge: '退職年齢',
  lifeAfterRetirement: '老後の生活イメージ',
  willBeReEmployed: '老後の再雇用',
  startAgeOfReEmployment: '期間(開始年齢)',
  endAgeOfReEmployment: '期間(終了年齢)',
  // 資産形成:
  hasbBeJoined: '加入状況',
  joinMonth: '加入年月',
  monthlyContributionAmount: '積立額',
  monthlyContributionAmountOfEmployee: '積立額(本人負担)',
  presentValue: '資産評価額',
  acquisitionValue: '取得金額',
  riskValue: 'リスク',
  expectedReturn: '予想資産残高',
  volatility: '標準偏差',
  hasBeJoined: '加入状況',
  startMonth: '加入年月(開始)',
  endMonth: '加入年月(終了)',
  totalReturn: '運用利率(トータルリターン)',
};

// エラー詳細文言
const ErrorDetailMapping: { [key: string]: string } = {
  'value does not match regex': '入力された値の形式が合っていません。',
  'null value not allowed': '入力値がありません。',
  'must be of integer type': '入力値が正しくありません。',
  'unknown field': '存在しない項目が指定されました。',
  'not exists JisTid': '存在しないJIS&T IDです。',
  'max value is': '最大値を越えています。',
  'min value is': '最小値を下回っています。',
};

// APIからレスポンスされたエラーの型
type ResponseErrorMessages = {
  [key: string]: string[]|ResponseErrorMessages;
};
type ResponseError = {
  code: number;
  apiErrorCode: 'validation_failed'|'data_not_found'|'method_not_allowd'|'precondition_failed'|'login_expired_error'|'server_error';
  message: string;
  details?: {
    message:string|ResponseErrorMessages,
    item:string
  }[];
};

type Message = {
  value: string,
  hits: boolean,
}

// 英名や物理名を程よい感じに日本語名や論理名にコンバートする
// 変換できない項目はそのまま返す
const convertItemName = (item:string) => {
  let itemName = ItemMapping[item] ?? undefined;
  if (itemName === undefined) {
    // 項目名が一致していなかったら、最初の文字だけマッチするか検索
    Object.keys(ItemMapping).forEach((key) => {
      if (item.startsWith(key) && itemName === undefined) {
        itemName = ItemMapping[key];
      }
    });
  }
  if (itemName !== undefined) {
    return { itemName, hits: true };
  }
  return { itemName: item, hits: false };
};

// エラー詳細文言を日本語名にコンバートする
// 変換できない項目はそのまま返す
const convertErrorDetail = (messages:string[]) => messages.map((message) => {
  let errorMessage:string|undefined;
  Object.keys(ErrorDetailMapping).forEach((key) => {
    if (message.startsWith(key) && errorMessage === undefined) {
      errorMessage = ErrorDetailMapping[key];
    }
  });
  return errorMessage ?? message;
});

const getErrorMessagesRoop = (messageObj:ResponseErrorMessages) => {
  let messages:Message[] = [];
  if (messageObj !== null) {
    Object.keys(messageObj).forEach((key) => {
      const obj = messageObj[key];
      const values = obj as string[];
      if (typeof values[0] === 'string') {
        const converted = convertItemName(key);
        const detailMessages = convertErrorDetail(values);
        messages.push({
          value: `項目「${converted.itemName}」：${JSON.stringify(detailMessages).replace(/"/g, '')}`,
          hits: converted.hits,
        });
      } else {
        // hitするまで辿り続ける
        const messages2 = getErrorMessagesRoop(obj as ResponseErrorMessages);
        messages = [...messages, ...messages2];
      }
    });
  }
  return messages;
};

// エラーメッセージを取得
const getErrorMessages = (error:ResponseError) => {
  let messages:Message[] = [];
  switch (error.apiErrorCode) {
    case 'validation_failed':
      error.details?.forEach((detail) => {
        if (typeof detail.message === 'string') {
          const converted = convertItemName(detail.item);
          const detailMessages = convertErrorDetail([detail.message]);
          messages.push({
            value: `項目「${converted.itemName}」：${JSON.stringify(detailMessages).replace(/"/g, '')}`,
            hits: true,
          });
        } else {
          const messages2 = getErrorMessagesRoop(detail.message);
          messages = [...messages, ...messages2];
        }
      });
      break;
    case 'login_expired_error':
      if (error.details !== undefined && error.details.length > 0) {
        const { message } = error.details[0];
        if (typeof message === 'string') {
          messages.push({
            value: 'ログイン有効期限が切れました。再度ログインしてください。',
            hits: true,
          });
        }
      }
      break;
    case 'server_error':
      if (error.details !== undefined && error.details.length > 0) {
        const { message } = error.details[0];
        if (typeof message === 'string' && message.includes('not exists')) {
          messages.push({
            value: '必要なデータが存在しません',
            hits: true,
          });
        }
      }
      break;
    default:
  }

  // ItemMapping

  const hitsMessages = messages.filter((m) => m.hits).map((m) => m.value);
  if (hitsMessages.length > 0) {
    return hitsMessages;
  }
  if (messages.length > 0) {
    throw new Error(JSON.stringify(messages.filter((m) => m.hits).map((m) => m.value)));
  }
  throw new Error(JSON.stringify(error));
};

// JSONデータからエラーかどうか判断する
const checkApiError = (response:AxiosResponse) => {
  const errorData = response.data as {errorMessage?:string};
  if (errorData.errorMessage) {
    const errorJson = JSON.parse(errorData.errorMessage);
    const error: ResponseError = Object.prototype.hasOwnProperty.call(errorJson, 'error') ? errorJson.error : errorJson;
    const errorMessages = getErrorMessages(error);
    if (errorMessages !== null) {
      return errorMessages;
    }
    throw new Error(JSON.stringify(error));
  }
  return false;
};
export default checkApiError;
