import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// データ
import { useAppSelector } from '../../app/hooks';
import {
  selectRecommendedDc, selectRecommendedIdeco, selectRecommendedNisa, selectRecommendedPweb,
} from '../../reducers/userReducer';

// images
import img01 from './assets/img_recommend01.png';
import img02 from './assets/img_recommend02.png';
import img03 from './assets/img_recommend03.png';
import img04 from './assets/img_recommend04.png';

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme`fontSize.sm`};
  & > figure {
    flex-grow: 0;
    flex-shrink: 0;
  }
  & > div {
    padding-left: 20px;
    & > a {
      margin-top: 0.5rem;
    }
  }
`;

const Recommends: React.FC = () => {
  const isDc = useAppSelector(selectRecommendedDc);
  const isIdeco = useAppSelector(selectRecommendedIdeco);
  const isNisa = useAppSelector(selectRecommendedNisa);
  const isPweb = useAppSelector(selectRecommendedPweb);
  return (
    <div className="space-y-5">
      {isDc && (
        <div className="card">
          <Row>
            <figure>
              <img src={img01} alt="" width={80} height={80} />
            </figure>
            <div>
              <p>確定拠出年金 (DC) の運用シミュレーションしてみよう</p>
              <a href="https://fund.www.mizuhobank.co.jp/webasp/mizuho-bk/dcp/" className="arrow-link" target="_blank" rel="noreferrer">SMART FOLIO&lt;DC&gt;へ</a>
            </div>
          </Row>
        </div>
      )}
      {isIdeco && (
        <div className="card">
          <Row>
            <figure>
              <img src={img02} alt="" width={80} height={80} />
            </figure>
            <div>
              <p>個人型確定拠出年金 (iDeCo)で節税しながら老後に備えよう</p>
              <a href="https://www.mizuhobank.co.jp/retail/products/ideco/index.html" className="arrow-link" target="_blank" rel="noreferrer">みずほのiDeCoへ</a>
            </div>
          </Row>
        </div>
      )}
      {isNisa && (
        <div className="card">
          <Row>
            <figure>
              <img src={img03} alt="" width={80} height={80} />
            </figure>
            <div>
              <p>NISA/つみたてNISAを活用して、資産運用をはじめよう！</p>
              <a href="https://www.mizuhobank.co.jp/retail/products/nisa/index.html" className="arrow-link" target="_blank" rel="noreferrer">みずほのNISAへ</a>
            </div>
          </Row>
        </div>
      )}
      {isPweb && (
        <div className="card">
          <Row>
            <figure>
              <img src={img04} alt="" width={80} height={80} />
            </figure>
            <div>
              <p>
                特典も！お問い合わせは
                <br />
                「0120-324-181」まで！
              </p>
              <a href="https://pweb.mizuhobank.co.jp/imb/IBGate/login" className="arrow-link" target="_blank" rel="noreferrer">
                みずほプロムナード
                <br />
                ウェブへ
              </a>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};
export default Recommends;
