import * as React from 'react';
import moment from 'moment';

// データ
import { useAppSelector } from '../../app/hooks';
import { getAssetStartDateNisa } from '../../reducers/inputReducer';
import { selectAssetsNisa } from '../../reducers/cashflowReducer';
import { selectRiskNisa } from '../../reducers/riskReducer';

// 入力データの型
import type { AssetFormationDataItem } from '../../reducers/inputReducer';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputYearMonth from '../../features/input/InputYearMonth';
import InputReserveAmount from '../../features/input/InputReserveAmount';
import InputAssetValuation from '../../features/input/InputAssetValuation';
import AssetGraph from '../../features/recharts/AssetGraph';
import RiskGraph from '../../features/recharts/RiskGraph';
import HelpButton from '../../features/button/HelpButton';
import { isLessThanToday } from '../../services/validation';

type FieldsetProps = {
  prefix?: string;
  data: AssetFormationDataItem;
  onChange?: (_:AssetFormationDataItem) => void;
};
const Fieldset: React.FC<FieldsetProps> = ({ prefix, data, onChange }) => {
  // 加入
  const [hasBeJoined, setHasBeJoined] = React.useState(data.hasBeJoined ?? 'JOINED');

  // 加入年月
  const [joinMonth, setJoinMonth] = React.useState(data.joinMonth);

  // 積立額
  const [
    monthlyContributionAmount, setMonthlyContributionAmount,
  ] = React.useState(data.monthlyContributionAmount);

  // 資産評価額
  const [presentValue, setPresentValue] = React.useState(data.presentValue);

  // 運用状況（元本積立額/予想資産残高）のグラフデータ
  const assetGraphData = useAppSelector(selectAssetsNisa);

  // 年利回り
  const [expectedReturn, setExpectedReturn] = React.useState(data.expectedReturn);

  // リスクデータ
  const riskDatas = useAppSelector(selectRiskNisa);
  const [riskValue, setRiskValue] = React.useState(data.riskValue);

  // 加入状況を変更する処理
  const onChangeHasBeJoined = ((value: string) => {
    if (value === 'TOBE_JOINED') {
      // 今から加入の場合は現在にする
      const now = moment();
      const date = moment(joinMonth);
      date.set('years', now.year());
      date.set('months', now.month());
      date.set('date', 1);
      setJoinMonth(date.format('YYYY-MM-DD'));
    }
  });

  // 値に変化があったらイベントを呼び出す
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        ...data,
        hasBeJoined,
        joinMonth,
        monthlyContributionAmount,
        presentValue,
        riskValue,
        expectedReturn,
      });
    }
  }, [
    hasBeJoined,
    joinMonth,
    monthlyContributionAmount,
    presentValue,
    riskValue,
    expectedReturn,
  ]);

  return (
    <>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入</span>
          <HelpButton>
            ※つみたてNISAは、2023年末で新規買付は停止になりました。加入シミュレーションの際はご注意ください。
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}hasBeJoined`}
          options={[
            { value: 'JOINED', label: '加入済み' },
            { value: 'NOT_JOINED', label: '非加入' },
            { value: 'TOBE_JOINED', label: '今から加入' },
          ]}
          defaultValue={hasBeJoined}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setHasBeJoined(value);
            onChangeHasBeJoined(value);
          }}
        />
        {assetGraphData !== undefined && assetGraphData.length > 0 && (
          <div className="mt-8">
            <AssetGraph
              datas={assetGraphData}
              expectedReturn={expectedReturn}
              help={(
                <>
                  加入年齢から20年間積み立てた場合の年齢を表示しています。
                </>
              )}
            />
          </div>
        )}
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入年月</span>
          <HelpButton>
            ｢今から加入｣をご選択された方で加入シミュレーションを実施される場合、｢今月｣をご入力ください (＝来月以降の日付はご入力いただけません)。
          </HelpButton>
        </div>
        <InputYearMonth
          name={`${prefix}joinMonth`}
          value={joinMonth}
          dayValue={1}
          startYear={getAssetStartDateNisa().year()}
          endYear={moment().year()}
          disabled={hasBeJoined !== 'JOINED'}
          onChange={(date) => setJoinMonth(date)}
          validate={isLessThanToday(parseInt(moment().format('YYYYMMDD'), 10))}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>積立額</span>
          <HelpButton>
            ｢積立額｣を月単位でご入力ください。制度上の上限金額は年間40万円(約33,000円/月)です。
          </HelpButton>
        </div>
        <InputReserveAmount
          name={`${prefix}monthlyContributionAmount`}
          defaultValue={monthlyContributionAmount}
          disabled={hasBeJoined === 'NOT_JOINED'}
          onChange={(value) => setMonthlyContributionAmount(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>資産評価額</span>
          <HelpButton>
            ｢加入済み｣の方は現時点での資産評価額を入力してください。
          </HelpButton>
        </div>
        <InputAssetValuation
          name={`${prefix}presentValue`}
          defaultValue={presentValue}
          disabled={hasBeJoined !== 'JOINED'}
          onChange={(value) => setPresentValue(value)}
        />
      </div>
      <div className="fieldset-body">
        <RiskGraph
          datas={riskDatas}
          value={riskValue}
          onChange={((value, expectedReturnValue) => {
            setRiskValue(value);
            setExpectedReturn(expectedReturnValue?.expectedReturn ?? null);
          })}
        />
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  onChange: undefined,
};
export default Fieldset;
