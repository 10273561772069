import * as React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'twin.macro';

// ユーザ情報
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectAccount,
  setGuest,
  setUserConfig,
} from '../../reducers/userReducer';

// ユーザ情報更新
import { createAccountAsync } from '../../reducers/authReducer';

// config
import { MINIMUM_ANNUAL_INCOME, MAXIMUM_ANNUAL_INCOME } from '../../config/validation';

// services
import validation from '../../services/validation';

// images
import imgHero from './assets/hero.png';

// components
import InputInteger from '../../features/input/InputInteger';
import InputNickname from '../../features/input/InputNickname';
import InputYearMonth from '../../features/input/InputYearMonth';
import InputEmail from '../../features/input/InputEmail';
import InputCheckbox from '../../features/input/InputCheckbox';
import Alert, { Error } from '../../features/error/Alert';

import { selectMessages } from '../../reducers/errorReducer';

// components
const FormLabel = styled.p`
  display: block;
  font-size: ${theme`fontSize.base`};
  font-weight: 700;
  color: ${theme`colors.gray-500`};
  margin-bottom: 1em;
`;

type RegistrationProps = {
  guest?: boolean;
}
const Registration: React.FC<RegistrationProps> = (props) => {
  const { guest } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // エラーメッセージを取得
  const errorMes = useAppSelector(selectMessages);
  // 検証エラーメッセージ
  const [errors, setErrors] = React.useState<Error[]>([]);

  React.useEffect(() => {
    const errs: Error[] = [];
    errorMes?.map((message, i) => {
      errs.push({
        key: i.toString(),
        message,
      });
      return errs;
    });
    setErrors(errs);
  }, [errorMes]);

  // デフォルト値を指定するためにユーザ情報
  const defaulAccount = useAppSelector(selectAccount);

  // ニックネーム
  const [nickname, setNickname] = React.useState(defaulAccount.nickname);

  // メールアドレス
  const [email, setEmail] = React.useState(defaulAccount.email);

  // みずほ銀行からの情報提供メールを受け取る
  const [
    requestForEmailNewsLetter, setRequestForEmailNewsLetter,
  ] = React.useState(defaulAccount.requestForEmailNewsLetter);

  // 年収
  const [annualIncome, setAnnualIncome] = React.useState(defaulAccount.annualIncome);

  // 生年月のセレクト項目のために本日の日付を取得
  const today = moment();

  // 一時的に生年月の値を保持しておく
  const defaultBirthDate = (moment(new Date(today.year() - 35, today.month(), 1))).format('YYYY-MM-DD');
  const [
    birthdateTmp, setBirthdateTemp,
  ] = React.useState(defaulAccount.birthDay ?? defaultBirthDate);

  return (
    <div className="container pt-[55px] pb-[50px]">
      <div className="text-center text-primary font-bold">
        <h1 className="text-2xl">ユーザ登録</h1>
        <p className="text-2xl mt-7">
          みずほの人生100年
          <br />
          デザイナーへようこそ！
        </p>
        <img src={imgHero} alt="" width="295" height="160" className="mx-auto mt-5" />
        <p className="text-lg mt-7">
          シミュレーションの準備として、
          <br />
          ユーザ登録をお願いいたします。
        </p>
      </div>
      <div className="px-sm mt-11">
        <Alert errors={errors} className="mb-4">
          アカウント登録エラー
        </Alert>
        <div className="space-y-8">
          <div>
            <FormLabel>ニックネーム</FormLabel>
            <InputNickname defaultValue={nickname} onChange={(value) => setNickname(value)} />
          </div>
          <div>
            <FormLabel>年収 (額面)</FormLabel>
            <InputInteger
              className="w-[150px]"
              unit="万円"
              division={4}
              required
              defaultValue={annualIncome}
              min={MINIMUM_ANNUAL_INCOME}
              max={MAXIMUM_ANNUAL_INCOME}
              onChange={(value) => setAnnualIncome(value)}
            />
            <p className="text-xs color-gray-500 mt-2">年収を入力することで簡単にライフプランがシミュレーションできます。</p>
          </div>
          {guest
            ? (
              <div className="block mt-8">
                <FormLabel>生年月</FormLabel>
                <InputYearMonth
                  defaultValue={birthdateTmp}
                  startYear={today.year() - 59}
                  endYear={today.year() - 18}
                  onChange={(date) => setBirthdateTemp(date ?? defaultBirthDate)}
                />
              </div>
            )
            : (
              <>
                <div>
                  <FormLabel>メールアドレス</FormLabel>
                  <InputEmail defaultValue={email} onChange={(value) => setEmail(value)} />
                </div>
                <InputCheckbox
                  className="mt-4"
                  defaultChecked={requestForEmailNewsLetter}
                  onChange={(checked) => setRequestForEmailNewsLetter(checked)}
                >
                  みずほ銀行からの情報提供メールを受け取る
                </InputCheckbox>
              </>
            )}
        </div>
        <button
          type="submit"
          className="btn-submit mt-12"
          onClick={async () => {
            // バリデーションのエラー結果
            const errorMessages:Error[] = [];

            // ニックネームのバリデーション
            const nicknameError = validation(nickname, { name: 'ニックネーム', required: true });
            if (nicknameError !== null) {
              errorMessages.push({ key: 'name', message: nicknameError });
            }

            // 年収 (額面)のバリデーション
            const annualIncomeValue = annualIncome !== null ? annualIncome / 10000 : null;
            const annualIncomeError = validation(annualIncomeValue?.toString(), {
              name: '年収 (額面)', required: true, integer: true, range: { min: MINIMUM_ANNUAL_INCOME, max: MAXIMUM_ANNUAL_INCOME },
            });
            if (annualIncomeError !== null) {
              errorMessages.push({ key: 'annualIncome', message: annualIncomeError });
            }

            if (!guest) {
              // メールアドレスのバリデーション
              const emailError = validation(email, { name: 'メールアドレス', required: true, email: true });
              if (emailError !== null) {
                errorMessages.push({ key: 'email', message: emailError });
              }
            }

            if (errorMessages.length === 0) {
              // バリデーションが成功したら値を保存
              if (!guest) {
                const isSuccesed = await dispatch(createAccountAsync({
                  nickname,
                  email,
                  requestForEmailNewsLetter,
                  annualIncome,
                }));
                if (!isSuccesed.payload) {
                  return;
                }
              } else {
                dispatch(setUserConfig({
                  nickname,
                  birthdate: birthdateTmp,
                  annualIncome,
                }));
              }

              // ゲストかどうかの情報を保存
              dispatch(setGuest(guest ?? false));

              // 画面遷移
              navigate('/home');
            }
            setErrors(errorMessages);
          }}
        >
          登録する
        </button>
      </div>
    </div>
  );
};
Registration.defaultProps = {
  guest: false,
};
export default Registration;
