import * as React from 'react';
import styled from 'styled-components';
import { Dialog, Transition } from '@headlessui/react';

// components
const CloseIcon = styled.div`
  position: relative;
  width: 1.4375rem;
  height: 1.4375rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0.625rem;
    display: block;
    width: 1.5625rem;
    height: 0.125rem;
    background-color: white;
    border-radius: 9999px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

type ModalProps = {
  show: boolean;
  onClose: () => void;
}
const Modal: React.FC<ModalProps> = ({ show, onClose, children }) => (
  <Transition show={show}>
    <Dialog onClose={onClose} className="relative z-50">
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-90"
        leave="ease-in duration-200"
        leaveFrom="opacity-90"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500" aria-hidden="true" />
      </Transition.Child>
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 flex items-center justify-center p-md">
          <button type="button" className="absolute top-8 right-8" onClick={onClose}>
            <CloseIcon />
          </button>
          <Dialog.Panel className="w-full max-w-sm rounded bg-white">
            {children}
          </Dialog.Panel>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
);
export default Modal;
