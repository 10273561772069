import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import headerReducer from '../reducers/headerReducer';
import authReducer from '../reducers/authReducer';
import userReducer from '../reducers/userReducer';
import inputReducer from '../reducers/inputReducer';
import cashflowReducer from '../reducers/cashflowReducer';
import estimatedReducer from '../reducers/estimatedReducer';
import riskReducer from '../reducers/riskReducer';
import stepReducer from '../reducers/stepReducer';
import errorReducer from '../reducers/errorReducer';
import validateReducer from '../reducers/validateReducer';

export const store = configureStore({
  reducer: {
    header: headerReducer,
    auth: authReducer,
    user: userReducer,
    input: inputReducer,
    cashflow: cashflowReducer,
    estimated: estimatedReducer,
    risk: riskReducer,
    step: stepReducer,
    error: errorReducer,
    validate: validateReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
