import * as React from 'react';
import deepEqual from 'deep-equal';

// 入力データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAuthStatus } from '../../reducers/authReducer';
import { selectRetirementAge, setRetirementAge as setDispachRetirementAge } from '../../reducers/userReducer';
import {
  selectHasSpouseOrWill,
  selectIncome,
  selectAfterRetirement,
  setAfterRetirement as setDispatchAfterRetirement,
} from '../../reducers/inputReducer';
import { selectIsAdviceOpened } from '../../reducers/stepReducer';

// 推定値
import { selectEstimated } from '../../reducers/estimatedReducer';

// images
import imgHero from './assets/hero.svg';

// components
import PensionGraphSection from '../../features/section/PensionGraphSection';
import PensionTableSection from '../../features/section/PensionTableSection';
import Append from '../../features/button/Append';
import Fieldset from './Fieldset';
import FieldsetSpouse from './FieldsetSpouse';
import FixedButton from '../../features/button/FixedButton';
import ServerError from '../../features/error/ServerError';

const HomeRetirePension: React.FC = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const defaultRetirementAge = useAppSelector(selectRetirementAge);
  const defaultAfterRetirement = useAppSelector(selectAfterRetirement);
  const estimated = useAppSelector(selectEstimated);

  // 処方箋モード前は○○歳以降の給与表示が非表示になる
  const isAdviced = useAppSelector(selectIsAdviceOpened);

  // 配偶者、結婚予定の配偶者有無
  const hasSpouse = useAppSelector(selectHasSpouseOrWill);

  // 入力データ
  const [afterRetirement, setAfterRetirement] = React.useState(defaultAfterRetirement);

  // 本人の退職年齢
  const [retirementAge, setRetirementAge] = React.useState(defaultRetirementAge);

  // 本人の退職金・年金データ
  const [householder, setHouseholder] = React.useState(defaultAfterRetirement.householder);

  // 配偶者の退職金・年金データ
  const [spouse, setSpouse] = React.useState(defaultAfterRetirement.spouse);

  // 正社員かどうかの判定をするために、収入データを取得
  const income = useAppSelector(selectIncome);

  // 配偶者情報の表示
  const [showSpouse, setShowSpouse] = React.useState(false);

  // データ更新
  React.useEffect(() => {
    setAfterRetirement((data) => {
      return {
        ...data,
        householder,
        spouse,
      };
    });
  }, [
    householder,
    spouse,
  ]);

  // APIの更新が完了したら入力データを更新
  React.useEffect(() => {
    if (authStatus === 'idle') {
      setAfterRetirement(defaultAfterRetirement);
    }
  }, [authStatus]);

  return (
    <div className="container">
      <section className="card">
        <h2>退職金・年金</h2>
        <figure>
          <img src={imgHero} alt="" width="295" height="140" />
        </figure>
        <p className="text-xl font-bold">老後の貯蓄額のバランスを見て退職金・年金の受け取り方を検討しましょう。</p>
        <p className="text-sm mt-2 mb-6">
          会社の退職金・年金の制度をしっかりと理解して準備は進めておくことも重要です。
          各退職金・年金の税制控除や老後の収入、臨時支出のイメージ、生活スタイルや預金額を考慮しながら受取方法を選択していきましょう。
        </p>
        <ServerError />
        <div className="fieldset relative">
          <PensionGraphSection />
          <PensionTableSection isAdviced={isAdviced} />
          <Fieldset
            defaultRetirementAge={retirementAge}
            datas={householder}
            estimated={estimated.householder}
            isNotFullEmployee={income.householder.employmentStatus !== 'FULLTIME_EMPLOYEE'}
            onChange={(data, age) => {
              setHouseholder(data);
              setRetirementAge(age);
            }}
          />
          <div className="py-8 border-primary border-t">
            <Append
              label={showSpouse ? '詳細を閉じる（配偶者）' : '配偶者にも退職金・年金がある場合'}
              onClick={() => setShowSpouse(!showSpouse)}
              minus={showSpouse}
              disabled={!hasSpouse}
            />
          </div>
          {showSpouse && (
            <div className="border-primary border-t">
              <FieldsetSpouse
                prefix="spouse"
                datas={spouse}
                estimated={estimated.spouse}
                isNotFullEmployee={income.spouse.employmentStatus !== 'FULLTIME_EMPLOYEE'}
                onChange={
                  (data) => setSpouse(data)
                }
              />
            </div>
          )}
        </div>
        <FixedButton
          disabled={
            deepEqual(defaultRetirementAge, retirementAge)
            && deepEqual(defaultAfterRetirement, afterRetirement)
          }
          contentId="pension"
          onClick={() => {
            dispatch(setDispachRetirementAge(retirementAge));
            dispatch(setDispatchAfterRetirement(afterRetirement));
          }}
        >
          保存してグラフに反映
        </FixedButton>
      </section>
    </div>
  );
};
export default HomeRetirePension;
