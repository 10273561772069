import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// データ
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectIsGuest } from '../../reducers/userReducer';

// ユーザ情報更新・削除
import { updateAccountAsync } from '../../reducers/authReducer';

// components
import { Error } from '../../features/error/Alert';
import { selectMessages } from '../../reducers/errorReducer';

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // エラーメッセージを取得
  const errorMes = useAppSelector(selectMessages);
  // ゲストかどうか
  const guest = useAppSelector(selectIsGuest);
  const Dialogs = {
    LOGIN_EXPIRED: 'ログイン有効期限が切れました。再度ログインしてください。',
  };

  React.useEffect(() => {
    const errs: Error[] = [];
    errorMes?.map((message, i) => {
      errs.push({
        key: i.toString(),
        message,
      });
      return errs;
    });
    if (errorMes?.includes(Dialogs.LOGIN_EXPIRED)) {
      // eslint-disable-next-line no-alert
      alert(Dialogs.LOGIN_EXPIRED);
      navigate('/top', { replace: true });
    }
  }, [errorMes]);

  return (
    <div className="container py-[50px]">
      <div className="px-sm">
        <div className="space-y-8 mt-10">
          <div className="px-xs">
            <button
              type="submit"
              className="btn-submit"
              onClick={async () => {
                if (!guest) {
                  // バリデーションが成功したら値を保存
                  await dispatch(updateAccountAsync({
                    loginFlg: false,
                  }));
                }
                // eslint-disable-next-line no-alert
                alert('ログアウトしました');
                navigate('/top');
              }}
            >
              ログアウト
            </button>
          </div>
          <div className="px-sm">
            <Link to="/home" className="btn-back btn-back--lg">シミュレーション結果に戻る</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logout;
