import * as React from 'react';

// 退職金・年金の分割受取イメージ (月額(税引前))
import { useAppSelector } from '../../app/hooks';
import { selectPension } from '../../reducers/cashflowReducer';

// components
import PensionGraph from '../recharts/PensionGraph';

/**
 * 退職金・年金の分割受取イメージ (月額(税引前))
 * @returns
 */
const PensionGraphSection: React.FC = () => {
  const chartData = useAppSelector(selectPension);
  return (
    <section>
      <h3 className="fieldset-title">
        退職金・年金の分割受取
        <br />
        イメージ (月額(税引前))
      </h3>
      <div className="fieldset-body">
        <PensionGraph datas={chartData} />
      </div>
    </section>
  );
};
export default PensionGraphSection;
