import * as React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts';
import { theme } from 'twin.macro';

// types
import { Risk } from '../../reducers/riskReducer';

// components
import HelpButton from '../button/HelpButton';
import RiskSlider from './RiskSlider';

export type RiskGraphChart = {
  key: number;
  name: string;
  value: number;
  color: string;
  legend: string;
}
export type RiskGraphCharts = {
  riskTolerance: number;
  expectedReturn: number;
  sigma: number;
  datas: RiskGraphChart[];
}

/**
 * リスクグラフ
 */
type RiskGraphProps = {
  datas: Risk[]|undefined;
  value?: number|null;
  onChange?: (_:number, __:Risk|undefined) => void;
};
const RiskGraph: React.FC<RiskGraphProps> = ({ datas, value, onChange }) => {
  // カラー設定されたグラフ
  const [chartDatas, setChartDatas] = React.useState<RiskGraphCharts[]>();

  // ローリスク/ハイリスクスライダーの値
  const [min, setMin] = React.useState<number>();
  const [max, setMax] = React.useState<number>();
  const [riskValue, setRiskValue] = React.useState(value ?? 0);

  // 現在選択中のグラフ
  const [chartData, setChartData] = React.useState<RiskGraphCharts>();

  // グラフデータを最適化する
  React.useEffect(() => {
    const newChartDatas = datas?.map((risk) => {
      return {
        riskTolerance: risk.riskTolerance,
        expectedReturn: risk.expectedReturn,
        sigma: risk.sigma,
        datas: risk.datas.map((data, i) => {
          return {
            key: i,
            name: data.name,
            value: Math.round(data.value * 100),
            color: data.color,
            legend: data.legend,
          };
        }),
      };
    });
    setChartDatas(newChartDatas);

    // スライダーの最小値と最大値を取得
    const riskTolerances = newChartDatas?.map((risk) => risk.riskTolerance);
    if (riskTolerances !== undefined) {
      setMin(Math.min(...riskTolerances));
      setMax(Math.max(...riskTolerances));
    }
  }, [datas]);

  // グラフデータ取得
  const getChartData = (val: number) => chartDatas?.find((risk) => risk.riskTolerance === val);

  // スライダーが変更されたときに、リスクデータを更新
  React.useEffect(() => {
    setChartData(getChartData(riskValue));
  }, [chartDatas, riskValue]);

  return (
    <>
      <div className="form-label">
        <span>リスク</span>
        <HelpButton>
          資産運用に伴い発生する損失をどの程度受け入れられるかの度合いをいいます。リスクに応じて想定年利回りが変更されます。
        </HelpButton>
      </div>
      <div className="relative w-full pt-[210px]">
        <div className="absolute inset-0">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData?.datas}
                nameKey="name"
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius="100%"
                innerRadius="70%"
                isAnimationActive={false}
              >
                {chartData !== undefined
                && chartData.datas.map((chart) => <Cell key={chart.key} fill={chart.color} />)}
                <Label position="center" style={{ fill: theme('colors.primary') }} dy={10 - 32} className="text-xl">年利回り</Label>
                <Label position="center" style={{ textAnchor: 'end', fill: theme('colors.primary') }} dx={20} dy={10} className="font-alpha text-3xl">
                  {(() => Math.round((chartData?.expectedReturn ?? 0) * 10000) / 100)()}
                </Label>
                <Label position="center" style={{ textAnchor: 'start', fill: theme('colors.primary') }} dx={20 + 6} dy={10 + 3} className="font-alpha text-xl">%</Label>
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex flex-wrap mt-4 -mx-3">
        {chartData?.datas.map((data) => (
          <div className="flex justify-between items-center min-w-[50%] my-0.5 px-3" key={data.key}>
            <div>
              <span className={`inline-block w-3 h-3 mr-1.5 ${data.legend}`} />
              <span className="text-xs text-gray-500">{data.name}</span>
            </div>
            <div>
              <span className="font-alpha text-xl">{data.value}</span>
              <span className="font-alpha text-base">%</span>
            </div>
          </div>
        ))}
      </div>
      <div className="mx-2">
        <RiskSlider
          value={riskValue}
          valueLabelFormat={() => null}
          min={min}
          max={max}
          sx={{ mt: 2 }}
          onChange={(event: Event, newValue: number | number[]) => {
            if (typeof newValue === 'number') {
              setRiskValue(newValue);

              // 更新情報を渡す
              if (onChange !== undefined) {
                onChange(newValue, getChartData(newValue));
              }
            }
          }}
          valueLabelDisplay="on"
        />
        <div className="flex justify-between text-sm text-gray-500 leading-none">
          <p>ローリスク</p>
          <p>ハイリスク</p>
        </div>
      </div>
    </>
  );
};
RiskGraph.defaultProps = {
  value: undefined,
  onChange: undefined,
};
export default RiskGraph;
