import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';

const Icon = styled.span`
  color: ${theme`colors.white`};
  font-family: ${theme`fontFamily.alpha`};
  font-size: ${theme`fontSize.lg`};
  width: ${theme`width.8`};
  height: ${theme`height.8`};
  border-radius: ${theme`borderRadius.full`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Q: React.FC = ({ children }) => (
  <div className="space-y-4">
    <Icon className="bg-[#6262A0]">Q</Icon>
    <div className="text-primary text-base font-bold">
      {children}
    </div>
  </div>
);

export const A: React.FC = ({ children }) => (
  <div className="space-y-4">
    <Icon className="bg-[#FD5880]">A</Icon>
    <div className="text-primary text-sm">
      {children}
    </div>
  </div>
);

const FAQ: React.FC = ({ children }) => (
  <div className="py-[50px] border-gray-300 border-t first:border-0 space-y-4">
    {children}
  </div>
);
export default FAQ;
