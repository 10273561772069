import * as React from 'react';
import moment from 'moment';

// 加入年月の範囲を指定するための生年月
import { useAppSelector } from '../../app/hooks';
import { selectBirthdate } from '../../reducers/userReducer';

// 入力データの型
import type { AssetFormationOtherItem } from '../../reducers/inputReducer';

// components
import InputRadioGroup from '../../features/input/InputRadioGroup';
import InputYearMonth from '../../features/input/InputYearMonth';
import InputReserveAmount from '../../features/input/InputReserveAmount';
import InputAssetValuation from '../../features/input/InputAssetValuation';
import InputInteger from '../../features/input/InputInteger';
import HelpButton from '../../features/button/HelpButton';
import { isLessThanToday } from '../../services/validation';

type FieldsetProps = {
  prefix?: string;
  data: AssetFormationOtherItem;
  onChange?: (_:AssetFormationOtherItem) => void;
};
const Fieldset: React.FC<FieldsetProps> = ({ prefix, data, onChange }) => {
  const [defaultData, setDefaultData] = React.useState<AssetFormationOtherItem>();

  // 加入
  const [hasBeJoined, setHasBeJoined] = React.useState(data.hasBeJoined ?? 'NOT_JOINED');
  const [defaultHasBeJoined, setDefaultHasBeJoined] = React.useState<string>();

  // 加入年月
  const [fromDate, setFromDate] = React.useState(data.startMonth);
  const [toDate, setToDate] = React.useState(data.endMonth);

  // 加入年月の範囲を指定するために、生年月を取得
  const birthdate = useAppSelector(selectBirthdate);
  const [fromYear, setFromYear] = React.useState<number>();
  const [toYear, setToYear] = React.useState<number>();

  // 積立額
  const [
    monthlyContributionAmount, setMonthlyContributionAmount,
  ] = React.useState(data.monthlyContributionAmount);

  // 資産評価額
  const [presentValue, setPresentValue] = React.useState(data.presentValue);

  // 運用利率(トータルリターン)
  const [totalReturn, setTotalReturn] = React.useState(data.totalReturn);

  // デフォルト値取得
  React.useEffect(() => {
    if (defaultData === undefined) {
      setDefaultData(data);
    }
  }, [defaultData]);

  // 加入年月の範囲を更新
  React.useEffect(() => {
    if (hasBeJoined === 'JOINED') {
      // 加入済みの場合は、年の選択エリアを誕生日以降にする
      setFromYear(moment(birthdate).add(18, 'years').set('date', 1).year());
      setToYear(moment().set('date', 1).year());
    } else if (hasBeJoined === 'NOT_JOINED') {
      // 非加入の場合は、年の選択エリアを今年度以降にする
      setFromYear(moment().set('date', 1).year());
      setToYear(moment().set('date', 1).year());
    } else if (hasBeJoined === 'TOBE_JOINED') {
      // 今から加入の場合は、年の選択エリアを5年後以降にする
      setFromYear(moment().set('date', 1).year());
      setToYear(moment().add(5, 'years').set('date', 1).year());
    }

    if (defaultHasBeJoined !== undefined) {
      // 2回目以降、加入状況を切り替えたら加入年月の値を更新
      if (hasBeJoined === 'JOINED') {
        // 加入済みの場合は、開始と終了を再計算
        const newFromDate = moment(birthdate).add(18, 'years').set('date', 1);
        setFromDate(newFromDate.format('YYYY-MM-DD'));
        const newToDate = moment();
        newToDate.set('month', moment(birthdate).get('month'));
        newToDate.set('date', 1);
        setToDate(newToDate.format('YYYY-MM-DD'));
      } else if (hasBeJoined === 'NOT_JOINED') {
        // 未加入の場合は、開始と終了を現在にする
        const now = moment();
        const newFromDate = moment(fromDate);
        newFromDate.set('year', now.year());
        newFromDate.set('month', now.month());
        newFromDate.set('date', 1);
        setFromDate(newFromDate.format('YYYY-MM-DD'));
        const newToDate = moment(toDate);
        newToDate.set('year', now.year());
        newToDate.set('month', now.month());
        newToDate.set('date', 1);
        setToDate(newToDate.format('YYYY-MM-DD'));
      } else if (hasBeJoined === 'TOBE_JOINED') {
        // 今から加入の場合は、開始を現在にして、終了を5年後にする
        const now = moment();
        const newFromDate = moment(fromDate);
        newFromDate.set('year', now.year());
        newFromDate.set('month', now.month());
        newFromDate.set('date', 1);
        setFromDate(newFromDate.format('YYYY-MM-DD'));
        const newToDate = moment(toDate);
        newToDate.set('year', now.year());
        newToDate.add(5, 'years');
        newToDate.set('month', now.month());
        newToDate.set('date', 1);
        setToDate(newToDate.format('YYYY-MM-DD'));
      }
    } else {
      setDefaultHasBeJoined(hasBeJoined);
    }
  }, [hasBeJoined]);

  // 値に変化があったらイベントを呼び出す
  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        hasBeJoined,
        startMonth: fromDate,
        endMonth: toDate,
        monthlyContributionAmount,
        presentValue: (() => {
          if (hasBeJoined === 'TOBE_JOINED') {
            // 今から加入の場合は取得金額と現在残高をゼロにする
            return 0;
          }
          return presentValue;
        })(),
        totalReturn,
        acquisitionValue: (() => {
          if (hasBeJoined === 'TOBE_JOINED') {
            // 今から加入の場合は取得金額と現在残高をゼロにする
            return 0;
          }
          return presentValue;
        })(),
      });
    }
  }, [
    hasBeJoined,
    fromDate,
    toDate,
    monthlyContributionAmount,
    presentValue,
    totalReturn,
  ]);

  return (
    <>
      <div className="fieldset-body" key={prefix}>
        <div className="form-label">
          <span>加入</span>
          <HelpButton>
            その他運用商品をご保有の方は｢加入済み｣をご選択ください。
            その他運用商品を保有されない方で今後保有をご検討される方は｢今から加入｣をご選択ください。
            それ以外の方は｢非加入｣をご選択ください。
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}hasBeJoined`}
          options={[
            { value: 'JOINED', label: '加入済み' },
            { value: 'NOT_JOINED', label: '非加入' },
            { value: 'TOBE_JOINED', label: '今から加入' },
          ]}
          defaultValue={hasBeJoined}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setHasBeJoined(value);
          }}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>加入年月</span>
          <HelpButton>
            ■開始年月：
            <br />
            対象運用商品の保有開始日をご入力ください。
            <br />
            ■終了年月：
            <br />
            対象運用商品の保有終了日をご入力ください。
          </HelpButton>
        </div>
        {fromYear !== undefined && toYear !== undefined
          && (
            <div className="space-y-3">
              <InputYearMonth
                name={`${prefix}fromDate`}
                value={fromDate}
                startYear={fromYear}
                endYear={fromYear + 99}
                disabled={hasBeJoined !== 'JOINED'}
                onChange={(date) => setFromDate(date)}
                validate={isLessThanToday(parseInt(moment().format('YYYYMMDD'), 10))}
                showDuring
              />
              <InputYearMonth
                name={`${prefix}toDate`}
                value={toDate}
                startYear={toYear}
                endYear={toYear + 99}
                disabled={hasBeJoined === 'NOT_JOINED'}
                onChange={(date) => setToDate(date)}
              />
            </div>
          )}
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>積立額 (任意)</span>
          <HelpButton>
            対象運用商品が積立性の商品である場合は｢積立額｣を月単位でご入力ください。
          </HelpButton>
        </div>
        <InputReserveAmount
          name={`${prefix}monthlyContributionAmount`}
          defaultValue={monthlyContributionAmount}
          disabled={hasBeJoined === 'NOT_JOINED'}
          onChange={(value) => setMonthlyContributionAmount(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>資産評価額</span>
          <HelpButton>
            ｢加入済み｣の方は現時点での資産評価額を入力してください。
          </HelpButton>
        </div>
        <InputAssetValuation
          name={`${prefix}presentValue`}
          defaultValue={presentValue}
          disabled={hasBeJoined !== 'JOINED'}
          onChange={(value) => setPresentValue(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>運用利率(トータルリターン)</span>
          <HelpButton>
            ■トータルリターンとは、投資商品への投資から得られる総合収益率を指します。
            <br />
            ■0～1000の範囲内の整数でご入力ください。
            <br />
            (例)元本積立額100万円が、加入期間後に120万円となることを想定する場合は120(%)と入力してください。
            逆に、元本積立額100万円が、加入期間後に90万円となることを想定する場合は90(%)と入力してください
          </HelpButton>
        </div>
        <InputInteger
          name={`${prefix}totalReturn`}
          defaultValue={totalReturn}
          disabled={hasBeJoined === 'NOT_JOINED'}
          required
          unit="%"
          min={0}
          max={1000}
          className="w-[80px]"
          onChange={(value) => setTotalReturn(value)}
        />
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  onChange: undefined,
};
export default Fieldset;
