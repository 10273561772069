import React from 'react';
import { theme } from 'twin.macro';
import { TickProps } from '../props';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../../services/costValue';

type YAxisTickProps = {
  dx?: number;
} & TickProps;
const YAxisTick: React.FC<YAxisTickProps> = ({
  x,
  y,
  height,
  textAnchor,
  orientation,
  payload,
  dx = -6,
}) => {
  const [rect, setRect] = React.useState<DOMRect>();
  const isRight = orientation === 'right';
  const fontFamily = theme('fontFamily.alpha');
  const fontSize = '10px';
  const paddingX = 6;
  const paddingY = 1;
  const value = (payload !== undefined ? payload.value : undefined);
  const text = React.useCallback(
    (node) => {
      if (node !== null && x !== undefined && y !== undefined) {
        const textRect: DOMRect = node.getBoundingClientRect();
        textRect.width += paddingX * 2;
        textRect.x = isRight ? x - textRect.width - dx : x + dx;
        textRect.y = y - textRect.height / 2 - paddingY;
        setRect(textRect);
      }
    },
    [x, y, isRight, dx],
  );
  return (
    <g>
      {rect !== undefined && (
        <foreignObject x={rect.x} y={rect.y} width={rect.width} height={height}>
          <div
            style={{
              fontFamily,
              fontSize,
              color: theme('colors.white'),
              textAlign: 'center',
              backgroundColor: 'rgba(0, 0, 0, .3)',
              padding: `${paddingY}px 0`,
              borderRadius: '5px',
            }}
          >
            {value !== undefined && costValue(Number(value), 15)}
          </div>
        </foreignObject>
      )}
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        style={{
          fontFamily,
          fontSize,
          visibility: 'hidden',
        }}
        ref={text}
      >
        {value !== undefined && costValue(Number(value), 15)}
      </text>
    </g>
  );
};
YAxisTick.defaultProps = {
  dx: undefined,
};

export default YAxisTick;
