import * as React from 'react';
import type { ValidationError } from '../../services/validation';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

// components
import FailedMessage from '../input/FailedMessage';
import HelpButton from '../button/HelpButton';

/**
 * 入力フォーム付きの表の行コンポーネント
 * @param props
 * @returns
 */
type TableInputRowProps = {
  className?: string;
  label?: string;
  value?: number|null;
  errors?: ValidationError[];
  help?: React.ReactElement;

  // 空のときの値
  empty?: string;

  // 単位の幅
  unitWidth?: string;

  // 単位の文字
  unit?: string;

  // 単位
  division?: number;

  // 小数点の桁数
  digits?: number;

  // 三桁カンマ区切り
  fixed?: boolean;

  // 最大桁数
  maxDigits?: number;
};
const TableInputRow: React.FC<TableInputRowProps> = ({
  className, label, children, value, errors, help,
  empty = '-',
  unitWidth = 'w-15', unit,
  division, digits,
  fixed = false,
  maxDigits = 6,
}) => {
  // 単位
  const divisionNum = (division !== undefined && division !== 0) ? 10 ** division : 1;
  const divisionDigits = (digits !== undefined) ? 10 ** digits : 1;

  // 値
  const output = (value !== undefined && value !== null)
    ? Math.round((value / divisionNum) * divisionDigits) / divisionDigits
    : null;

  return (
    <div>
      <div className={`flex justify-between items-center min-h-[34px] ${className}`}>
        <div className="flex-shrink-0 flex items-center">
          <span className="text-sm">{label}</span>
          {help !== undefined
          && (
            <HelpButton className="text-xl mt-1 ml-2">
              {help}
            </HelpButton>
          )}
        </div>
        {children
          ? (
            <div className="flex-auto flex flex-col items-end text-sm px-2">
              { children }
            </div>
          )
          : (
            <div className="flex-auto flex flex-col items-end text-alpha text-2xl px-2 mb-2 break-all">
              {(() => {
                if (output !== undefined && output !== null) {
                  if (fixed) {
                    return costValue(output, maxDigits);
                  }
                  return output;
                }
                return empty;
              })()}
            </div>
          )}
        {unit !== undefined && <p className={`${unitWidth} -mr-3 flex text-sm`}>{unit}</p>}
      </div>
      <FailedMessage errors={errors} />
    </div>
  );
};
TableInputRow.defaultProps = {
  className: '',
  label: '',
  value: undefined,
  errors: undefined,
  help: undefined,
  empty: undefined,
  unit: undefined,
  unitWidth: undefined,
  division: undefined,
  digits: undefined,
  fixed: false,
  maxDigits: undefined,
};

export default TableInputRow;
