import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'twin.macro';
import FAQ, { Q, A } from './FAQ';

const List = styled.ul`
  li {
    position: relative;
    padding-left: ${theme`padding.4`};
    margin-top: ${theme`margin.8`};
    &::before {
      content: '・';
      position: absolute;
      left: 0;
      top: 0;
    };
    &::first-child {
      margin-top: ${theme`margin.0`};
    }
  }
`;

const HelpPersonalInfo: React.FC = () => (
  <FAQ>
    <Q>
      <p>メールアドレス・年収の入力はなぜ必要なのでしょうか？</p>
    </Q>
    <A>
      <List>
        <li>
          メールアドレス：今後、みずほ銀行から本サービス・生活設計・資産運用全般に係る情報提供をさせていただくことを考えております。
          (「配信希望」にチェックをしていただいた方のみ)。
        </li>
        <li>年収：本サービス上においてご入力いただいた年収を基に100歳までの収支・貯蓄額を推計するためです。</li>
      </List>
    </A>
  </FAQ>
);
export default HelpPersonalInfo;
