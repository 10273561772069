import * as React from 'react';
import type { ValidationError } from '../../services/validation';

// 教育費用の値
import { useAppSelector } from '../../app/hooks';
import { selectEducationExpenses, getEducationExpense } from '../../reducers/userReducer';

// 教育費のカスタマイズ時のこどもの学年要素の型
import type { Grade, EducationExpensesMatrix } from '../../reducers/userReducer';
import type { EducationExpensesChildrenSchool, EducationPolicy } from '../../reducers/inputReducer';

// components
import InputInteger from '../../features/input/InputInteger';
import InputSelect, { InputSelectOption } from '../../features/input/InputSelect';
import TableGroup from '../../features/table/TableGroup';
import TableInputRow from '../../features/table/TableInputRow';

type SchoolProps = {
  label: string,
  prefix: string;
  help?: React.ReactNode;
  disabled?: boolean,

  // 教育費データ
  datas: EducationExpensesChildrenSchool,

  options: InputSelectOption[],

  // 小学校・中学校・高校・大学
  grade: Grade;

  // 教育費の出費イメージ
  educationPolicy: EducationPolicy|null,

  // 入学費を含むかどうか
  isEntranceFee: boolean,

  // 在学中の期間
  period: number,

  onChange?: (_:EducationExpensesChildrenSchool, __:number) => void,
}
const School: React.FC<SchoolProps> = ({
  label, prefix, help, disabled,
  datas, options, grade, educationPolicy, isEntranceFee, period,
  onChange,
}) => {
  // 教育費用の値
  const educationExpenses = useAppSelector(selectEducationExpenses);
  const [educationMatrix, setEducationMatrix] = React.useState<EducationExpensesMatrix>();

  // デフォルトの出費パターン
  const [defaulEducationPolicy, setDefaultEducationPolicy] = React.useState<string|null>();

  // カスタマイズ用 区分
  const [publicPrivate, setPublicPrivate] = React.useState(datas.publicPrivate);

  // カスタマイズ用 習い事・仕送り
  const [annualLessonFee, setAnnualLessonFee] = React.useState(datas.annualLessonFee);
  const [defaultAnnualLessonFee, setDefaultAnnualLessonFee] = React.useState<number|null>();
  const [annualLessonFeeErros, setAnnualLessonFeeErrors] = React.useState<ValidationError[]>([]);

  // 合計費用
  const getTotal = (() => {
    let newTotal = 0;
    if (isEntranceFee) {
      newTotal += ((Math.floor(educationMatrix?.entranceFee ?? 0) / 10000) * 10000);
    }
    newTotal += ((Math.floor(educationMatrix?.tuition ?? 0) / 10000) * 10000) * period;
    if (educationPolicy === 'CUSTOMIZATION') {
      newTotal += ((Math.floor(annualLessonFee ?? 0) / 10000) * 10000) * period;
    } else {
      newTotal += ((Math.floor(educationMatrix?.lessonFee ?? 0) / 10000) * 10000) * period;
    }
    return newTotal;
  });

  React.useEffect(() => {
    // デフォルトの出費パターン取得
    if (defaulEducationPolicy === undefined) {
      setDefaultEducationPolicy(educationPolicy);
    }
    // デフォルトの習い事・仕送り取得
    if (defaultAnnualLessonFee === undefined) {
      setDefaultAnnualLessonFee(datas.annualLessonFee);
    }
  }, []);

  React.useEffect(() => {
    // 出費イメージが切り替わったとき、教育費用の値を更新
    const newEducationMatrix = getEducationExpense(
      educationExpenses,
      grade,
      educationPolicy,
      publicPrivate,
    );
    setEducationMatrix(newEducationMatrix);
  }, [
    educationExpenses,
    grade,
    educationPolicy,
    publicPrivate,
  ]);

  React.useEffect(() => {
    if (disabled) {
      return;
    }
    if (educationPolicy !== 'CUSTOMIZATION') {
      // 教育費の出資イメージを「カスタマイズ」に切り替えたとき
      // 以前の情報を引き継がせるために、 区分と習い事・仕送りをそれぞれ更新
      setPublicPrivate(educationMatrix?.publicPrivate ?? null);
      if (defaulEducationPolicy !== educationPolicy) {
        setAnnualLessonFee(educationMatrix?.lessonFee ?? null);
      } else {
        setAnnualLessonFee(defaultAnnualLessonFee ?? null);
      }
    }
  }, [
    educationMatrix,
    educationPolicy,
    defaulEducationPolicy,
    publicPrivate,
  ]);

  React.useEffect(() => {
    // 更新されたらイベントを送る
    if (onChange !== undefined && !disabled) {
      onChange(
        {
          ...datas,
          publicPrivate,
          annualLessonFee,
        },
        getTotal(),
      );
    }
  }, [
    publicPrivate,
    annualLessonFee,
    educationMatrix,
    educationPolicy,
  ]);

  return (
    <>
      <TableGroup label={label} help={help}>
        <div className="space-y-2">
          {!disabled ? (
            <>
              <TableInputRow label="区分" unit={educationPolicy === 'CUSTOMIZATION' ? undefined : ''}>
                {educationPolicy === 'CUSTOMIZATION' ? (
                  <InputSelect
                    className="block w-[140px]"
                    options={options}
                    defaultValue={publicPrivate ?? 'PUBLIC'}
                    onChange={(event) => {
                      const { value } = event.currentTarget;
                      setPublicPrivate(value);
                    }}
                  />
                ) : (
                  <span>
                    {
                      options
                        .find((option) => option.value === educationMatrix?.publicPrivate)?.label
                    }
                  </span>
                )}
              </TableInputRow>
              {isEntranceFee
                ? <TableInputRow label="入学費" value={educationMatrix?.entranceFee !== 0 ? educationMatrix?.entranceFee : null} unit="万円/年" division={4} fixed />
                : <TableInputRow label="入学費" unit="" />}
              <TableInputRow label="授業料" value={educationMatrix?.tuition !== 0 ? educationMatrix?.tuition : null} unit="万円/年" division={4} fixed />
              {educationPolicy === 'CUSTOMIZATION' || educationMatrix?.lessonFee
                ? (
                  <TableInputRow label="習い事・仕送り" value={educationMatrix?.lessonFee !== 0 ? educationMatrix?.lessonFee : null} unit="万円/年" division={4} fixed errors={annualLessonFeeErros}>
                    {educationPolicy === 'CUSTOMIZATION' && (
                      <InputInteger
                        name={`${prefix}annualLessonFee`}
                        className="w-[100px]"
                        defaultValue={annualLessonFee}
                        maxlength={4}
                        division={4}
                        onChange={(value) => setAnnualLessonFee(value)}
                        onError={(errors) => setAnnualLessonFeeErrors(errors)}
                      />
                    )}
                  </TableInputRow>
                )
                : <TableInputRow label="習い事・仕送り" unit="" />}
            </>
          ) : (
            <>
              <TableInputRow label="区分" unit="">
                教育期間が終わっています
              </TableInputRow>
              <TableInputRow label="入学費" unit="" />
              <TableInputRow label="授業料" unit="" />
              <TableInputRow label="習い事・仕送り" unit="" />
            </>
          ) }
        </div>
      </TableGroup>
      {!disabled ? (
        <TableInputRow label="費用合計" value={getTotal()} unit="万円" division={4} fixed className="mt-2" />
      ) : (
        <TableInputRow label="費用合計" unit="" className="mt-2" />
      ) }
    </>
  );
};
School.defaultProps = {
  help: undefined,
  disabled: false,
  onChange: undefined,
};
export default School;
