import { failed } from '../config/messages';
import { HousingExpenses, PlanToBuyHouse } from '../reducers/inputReducer';

export type Rule = {
  name?: string,
  required?: boolean,
  integer?: boolean,
  decimal?: {
    size?: number
    digits?: number
  },
  range?: {
    min: number
    max: number
  },
  more?: number,
  less?: number,
  email?: boolean,
}

// 検証エラー用のオブジェクト
export type ValidationError = {
  key: string,
  message: string,
}

const validation = (value: string|null|undefined, rule: Rule) => {
  if (value === undefined || value === null) {
    // 必須項目チェック
    if (rule.required !== undefined && rule.name !== undefined) {
      return failed.required(rule.name);
    }
    return null;
  }

  // 必須項目チェック
  if (rule.required !== undefined && rule.name !== undefined) {
    if (value === '') {
      return failed.required(rule.name);
    }
  }

  // 整数チェック
  if (rule.integer !== undefined) {
    if (!/^[0-9]*$/.test(value)) {
      return failed.integer(rule.name);
    }
    // 先頭０チェック
    if (!/^([1-9][0-9]*|0)$/.test(value)) {
      return failed.zeroFirst(value);
    }
  }

  // 実数チェック（符号なし0省略なし）
  if (rule.decimal !== undefined) {
    const size = rule.decimal.size ? `{0,${rule.decimal.size - 1}}` : '+';
    const digits = rule.decimal.digits ? `{1,${rule.decimal.digits}}` : '+';
    const reg = new RegExp(`^([1-9][0-9]${size}|0)(?:\\.[0-9]${digits})?$`);
    if (!reg.test(value)) {
      return failed.decimal(value);
    }
  }

  // 指定範囲チェック
  if (rule.range !== undefined) {
    const n = parseInt(value, 10);
    if (!(rule.range.min <= n && rule.range.max >= n)) {
      return failed.range(rule.range.min, rule.range.max, rule.name);
    }
  }

  // ○○以上かどうかのチェック
  if (rule.more !== undefined) {
    const n = parseInt(value, 10);
    if (!(rule.more <= n)) {
      return failed.more(rule.more);
    }
  }

  // ○○以下かどうかのチェック
  if (rule.less !== undefined) {
    const n = parseInt(value, 10);
    if (!(rule.less >= n)) {
      return failed.less(rule.less);
    }
  }

  // メールアドレスチェック
  if (rule.email !== undefined) {
    if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
      return failed.email(value);
    }
  }
  return null;
};

export const isLessThanToday = (
  today: number,
  label = '加入年月',
): ((_: string) => string | null) => {
  const valiFunc = (s: string): string | null => (today < parseInt(s.replace(/-/g, ''), 10)
    ? failed.isLessThanToday(label) : null);
  return valiFunc;
};

export const isPeriodOfFixedRateCheck = (
  house?: HousingExpenses | null,
  planToBuyHouse?: PlanToBuyHouse | null,
  subTab?: 'present' | 'plan',
): (() => string | null) => {
  const valiFunc = (): string | null => {
    if (house !== null && house !== undefined && subTab === 'present') {
      if (house.loanOfHouseholder.hasMortgageLoan
        && house.loanOfHouseholder.interestCalculationMethod === 'FIXED') {
        const loanPeriod = house.loanOfHouseholder.loanPeriod
          ? house.loanOfHouseholder.loanPeriod : null;
        const periodOfFixedRate = house.loanOfHouseholder.periodOfFixedRate
          ? house.loanOfHouseholder.periodOfFixedRate : null;
        if (loanPeriod === null || periodOfFixedRate === null) {
          return null;
        }
        return loanPeriod < periodOfFixedRate ? failed.isPeriodOfFixedRateCheck() : null;
      }
    } else if (planToBuyHouse !== null && planToBuyHouse !== undefined && subTab === 'plan') {
      if (planToBuyHouse.willBuyHouse
        && planToBuyHouse.interestCalculationMethod === 'FIXED') {
        const loanPeriod = planToBuyHouse.loanPeriod
          ? planToBuyHouse.loanPeriod : null;
        const periodOfFixedRate = planToBuyHouse.periodOfFixedRate
          ? planToBuyHouse.periodOfFixedRate : null;
        if (loanPeriod === null || periodOfFixedRate === null) {
          return null;
        }
        return loanPeriod < periodOfFixedRate ? failed.isPeriodOfFixedRateCheck() : null;
      }
    }
    return null;
  };
  return valiFunc;
};

export default validation;
