import * as React from 'react';
import moment from 'moment';

// 現在の住宅が持ち家のときの型
import type { HousingExpensesLoanData } from '../../reducers/inputReducer';

// config
import { LOAN_START_AGE_ADDITION } from '../../config/validation';

// components
import InputInteger from '../../features/input/InputInteger';
import InputDecimal from '../../features/input/InputDecimal';
import InputRadioGroup, { InputRadioOption } from '../../features/input/InputRadioGroup';
import InputYearMonth from '../../features/input/InputYearMonth';
import HelpButton from '../../features/button/HelpButton';
import ToggleDetail from '../../features/block/ToggleDetail';
import { isLessThanToday } from '../../services/validation';

// 金利計算方式のオプション
const interestCalcMethodOptions: InputRadioOption[] = [
  { value: 'FLOATING', label: '変動金利' },
  { value: 'FIXED', label: '固定金利' },
  { value: 'ALL_PERIOD_FIXED', label: '全期間固定金利' },
];

// 返済方法のオプション
const repaymentMethodOptions: InputRadioOption[] = [
  { value: 'EQUAL_INTEREST', label: '元利均等' },
  { value: 'EQUAL_PRINCIPAL', label: '元金均等' },
];

type FieldsetProps = {
  prefix?: string;
  datas: HousingExpensesLoanData,
  birthdate?: string|null,
  onChange?: (_:HousingExpensesLoanData) => void,
}
const Fieldset: React.FC<FieldsetProps> = ({
  prefix, datas, birthdate, onChange,
}) => {
  // 住宅ローン
  const [hasMortgageLoan, setHasMortgageLoan] = React.useState(datas.hasMortgageLoan);

  // 借入年月
  const [loanStartYear, setLoanStartYear] = React.useState(datas.loanStartYear);
  const startDate = (birthdate !== undefined)
    ? moment(birthdate).year() + LOAN_START_AGE_ADDITION : undefined;
  const endDate = (startDate !== undefined) ? startDate + 99 : undefined;

  // 借入額
  const [loanAmount, setLoanAmount] = React.useState(datas.loanAmount);

  // 借入期間
  const [loanPeriod, setLoanPeriod] = React.useState(datas.loanPeriod);

  // 金利計算方式
  const [
    interestCalculationMethod, setInterestCalculationMethod,
  ] = React.useState(datas.interestCalculationMethod);

  // 金利
  const [loanRate, setLoanRate] = React.useState(datas.loanRate);

  // 固定期間
  const [periodOfFixedRate, setPeriodOfFixedRate] = React.useState(datas.periodOfFixedRate);

  // 返済方法
  const [repaymentMethod, setRepaymentMethod] = React.useState(datas.repaymentMethod);

  // 現在残高 (残債)
  const [presentLoanAmount, setPresentLoanAmount] = React.useState(datas.presentLoanAmount);
  const [isChangedPresentLoanAmount, setIsChangedPresentLoanAmount] = React.useState(false);

  React.useEffect(() => {
    if (onChange !== undefined) {
      onChange({
        ...datas,
        hasMortgageLoan,
        loanStartYear,
        loanAmount,
        loanPeriod,
        interestCalculationMethod,
        loanRate,
        periodOfFixedRate,
        repaymentMethod,
        presentLoanAmount,
        baseDateOfPresentAmount: (() => {
          // ユーザが画面で、現在残高を変更した場合、ログイン日付にとっての前月一日(ついたち)をセット
          // 現在残高が不変の場合、API側から返却した日付(現在残高基準日)を再度そのままセット
          if (isChangedPresentLoanAmount) {
            return moment().add(-1, 'months').startOf('months').format('YYYY-MM-DD');
          }
          return datas.baseDateOfPresentAmount;
        })(),
      });
    }
  }, [
    hasMortgageLoan,
    loanStartYear,
    loanAmount,
    loanPeriod,
    interestCalculationMethod,
    loanRate,
    periodOfFixedRate,
    repaymentMethod,
    presentLoanAmount,
  ]);

  return (
    <>
      <div className="fieldset-body">
        <div className="form-label">
          <span>住宅ローン</span>
          <HelpButton>
            ｢将来の住宅ローン｣を入力している場合は、現在の住宅ローンは入力できません。
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}hasMortgageLoan`}
          options={[
            { value: 0, label: 'なし' },
            { value: 1, label: 'あり' },
          ]}
          defaultValue={hasMortgageLoan ? 1 : 0}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setHasMortgageLoan(parseInt(value, 10) === 1);
          }}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>借入年月</span>
        </div>
        <InputYearMonth
          name={`${prefix}loanStartYear`}
          defaultValue={loanStartYear}
          startYear={startDate}
          endYear={endDate}
          disabled={!hasMortgageLoan}
          onChange={(date) => setLoanStartYear(date)}
          validate={isLessThanToday(parseInt(moment().format('YYYYMMDD'), 10), '借入年月')}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>借入額</span>
          <HelpButton>
            借入時の借入金額を入力してください。
          </HelpButton>
        </div>
        <InputInteger
          name={`${prefix}loanAmount`}
          className="w-[120px]"
          unit="万円"
          defaultValue={loanAmount}
          division={4}
          disabled={!hasMortgageLoan}
          onChange={(value) => setLoanAmount(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>借入期間</span>
          <HelpButton>
            借入時の借入期間を入力してください。
          </HelpButton>
        </div>
        <InputInteger
          name={`${prefix}loanPeriod`}
          className="w-[120px]"
          unit="年"
          defaultValue={loanPeriod}
          maxlength={2}
          disabled={!hasMortgageLoan}
          onChange={(value) => setLoanPeriod(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>金利計算方式</span>
          <HelpButton>
            ■変動金利：金利が変動する金利タイプ
            <br />
            ■固定金利：一定期間は固定の金利、その期間がすぎると変動する金利タイプ
            <br />
            ■全期間固定金利：全期間、金利が固定する金利タイプ (フラット35など)
          </HelpButton>
        </div>
        <InputRadioGroup
          name={`${prefix}interestCalculationMethod`}
          options={interestCalcMethodOptions}
          defaultValue={interestCalculationMethod}
          disabled={!hasMortgageLoan}
          onChange={(event) => {
            const { value } = event.currentTarget;
            setInterestCalculationMethod(value);
          }}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>金利</span>
          <HelpButton>
            借入時の金利を入力してください。
          </HelpButton>
        </div>
        <InputDecimal
          name={`${prefix}loanRate`}
          className="w-[150px]"
          unit="％"
          defaultValue={loanRate}
          size={3}
          digits={5}
          disabled={!hasMortgageLoan}
          onChange={(value) => setLoanRate(value)}
        />
      </div>
      <div className="fieldset-body">
        <div className="form-label">
          <span>固定期間</span>
        </div>
        <InputInteger
          name={`${prefix}periodOfFixedRate`}
          className="w-[120px]"
          unit="年"
          defaultValue={periodOfFixedRate}
          maxlength={2}
          disabled={!hasMortgageLoan || (interestCalculationMethod !== 'FIXED')}
          onChange={(value) => setPeriodOfFixedRate(value)}
        />
      </div>
      <div className="fieldset-body">
        <ToggleDetail>
          <div className="fieldset-body">
            <div className="form-label">
              <span>返済方法</span>
              <HelpButton>
                ■元利均等返済：毎月のご返済額(元金と利息の合計)が一定となる返済方法です
                <br />
                ■元金均等返済：毎月のご返済元金が一定となる返済方法です。毎月のご返済額はこれに利息を加えたものとなります
              </HelpButton>
            </div>
            <InputRadioGroup
              name={`${prefix}repaymentMethod`}
              options={repaymentMethodOptions}
              defaultValue={repaymentMethod}
              disabled={!hasMortgageLoan}
              onChange={(event) => {
                const { value } = event.currentTarget;
                setRepaymentMethod(value);
              }}
            />
          </div>
          <div className="fieldset-body">
            <div className="form-label">
              <span>現在残高 (残債)</span>
              <HelpButton>
                該当ローンの繰り上げ返済を行った事がある場合は、現在残高をご入力ください。
              </HelpButton>
            </div>
            <InputInteger
              name={`${prefix}presentLoanAmount`}
              className="w-[120px]"
              unit="万円"
              defaultValue={presentLoanAmount}
              division={4}
              disabled={!hasMortgageLoan}
              onChange={(value) => {
                setPresentLoanAmount(value);
                if (value !== presentLoanAmount) {
                  // 値に変化があれば、現在残高変更フラグをONにする
                  setIsChangedPresentLoanAmount(true);
                }
              }}
            />
          </div>
        </ToggleDetail>
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  prefix: '',
  birthdate: undefined,
  onChange: undefined,
};
export default Fieldset;
