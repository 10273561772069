import React from 'react';
import type { LabelProps } from 'recharts';

type ViewBox = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};
type XAxisIconLabelProps = {
  dy?: number;
} & LabelProps;
const XAxisIconLabel: React.FC<XAxisIconLabelProps> = ({ viewBox, dy = 10 }) => {
  const viewbox = viewBox as ViewBox;
  const width = 33;
  const height = 39;
  const x = (viewbox.x || 0) + (viewbox.width || 0) / 2 - width / 2;
  const y = (viewbox.y || 0) + dy;
  return (
    <foreignObject x={x} y={y} width={width} height={height}>
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="39" viewBox="0 0 33 39">
        <g transform="translate(-88 -533.5)">
          <path d="M0,16.5a16.6,16.6,0,0,1,32.991-2.574L39,16.913l-6.179,3.072A16.607,16.607,0,0,1,0,16.5Z" transform="translate(88 572.5) rotate(-90)" fill="red" />
          <path d="M-3.78-12.222H-5.608l-.259-7.684h2.346Zm-.914,1.2a1.233,1.233,0,0,1,.895.327,1.114,1.114,0,0,1,.339.838,1.1,1.1,0,0,1-.339.83,1.233,1.233,0,0,1-.895.327,1.223,1.223,0,0,1-.887-.327,1.1,1.1,0,0,1-.339-.83,1.117,1.117,0,0,1,.339-.834A1.215,1.215,0,0,1-4.694-11.019Z" transform="translate(109.409 571.656)" fill="#fff" />
        </g>
      </svg>
    </foreignObject>
  );
};
XAxisIconLabel.defaultProps = {
  dy: undefined,
};

export default XAxisIconLabel;
