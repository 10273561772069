import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import { getHasDc, getHasIdeco } from './userReducer';
import { selectHasChildren } from './inputReducer';
import { selectDeadline } from './cashflowReducer';

// 進捗メッセージ
export const CONFIG_DATAS = [
  {
    contentId: 'family',
    to: '/home/basics/family',
    message: 'これは簡易結果です。より正確な結果を得るために、まずは「基本情報」の家族構成から入力を進めましょう！',
    advice: true,
  },
  {
    contentId: 'incomings',
    to: '/home/basics/incomings',
    message: '次に、あなたの「預金・収入」を確認、入力してみましょう！',
    advice: true,
  },
  {
    contentId: 'livingCosts',
    to: '/home/basics/livingCosts',
    message: '次に、あなたの「生活費」を確認、入力してみましょう！',
    advice: true,
  },
  {
    contentId: 'education',
    to: '/home/education',
    message: '次に、こどもの「教育費」を確認、入力してみましょう！',
  },
  {
    contentId: 'present',
    to: '/home/house/present',
    message: '次に、あなたの「住宅費用」を入力してみましょう！',
    advice: true,
  },
  {
    contentId: 'pension',
    to: '/home/retire/pension',
    message: '次に、あなたの「退職金・年金」を確認、入力してみましょう！',
    advice: true,
  },
  {
    contentId: 'retirement',
    to: '/home/retire/retirement',
    message: '次に、あなたの「老後の生活」を確認してみましょう！',
    advice: true,
  },
  {
    contentId: 'depositFinAssets',
    to: '/home/asset/depositFinAssets',
    message: '次に、あなたの「貯蓄・金融資産」を確認、入力してみましょう！ (資産形成・運用をしている場合)',
    advice: true,
  },
  {
    contentId: 'dc',
    to: '/home/asset/dc',
    message: '次に、「企業年金 (DC)」の状況を確認・シミュレーションしてみましょう！',
    advice: true,
  },
  {
    contentId: 'ideco',
    to: '/home/asset/ideco',
    message: '次に、「個人年金 (iDeCo)」の運用状況を確認・シミュレーションしてみましょう！',
    advice: true,
  },
  {
    contentId: 'advice',
    to: '/home/advice',
    message: '全入力項目の確認が完了し、「改善」項目を入力できるようになりました！「アドバイス」を見てみましょう！',
  },
  {
    contentId: 'prepayment',
    to: '/home/house/prepayment',
    message: '「住宅ローン繰り上げ返済」をすると、上手に資産形成することができます！',
  },
  {
    contentId: 'dc',
    to: '/home/asset/dc',
    message: '「企業年金 (DC)」で上手に資産運用すると、節税しながら貯蓄を増やすことができます！',
  },
  {
    contentId: 'ideco',
    to: '/home/asset/ideco',
    message: '「個人年金 (iDeCo)」で上手に資産運用すると、節税しながら貯蓄を増やすことができます！',
  },
  {
    contentId: 'nisa',
    to: '/home/asset/nisa',
    message: '「つみたてNISA(～2023年)」で資産運用すると、節税しながら貯蓄を増やすことができます！',
  },
  {
    contentId: 'reEmployment',
    to: '/home/retire/reEmployment',
    message: '「老後の再就職」により、収入の面で老後の暮らしに備えることができます！',
  },
  {
    contentId: 'livingCostsReduce',
    to: '/home/basics/livingCostsReduce',
    message: '「生活費の削減」により、支出の面で老後の暮らしに備えることができます！',
  },
  {
    contentId: 'advice',
    to: '/home/advice',
    message: '目標の100歳まであと一歩！もう一度「アドバイス」に戻って「改善」項目の入力をしてみましょう！',
  },
  {
    contentId: 'advice',
    to: '/home/advice',
    message: '目標の100歳を達成しています！「アドバイス」のライフプラン診断・商品を確認してみましょう！',
  },
];

// 進捗の重要度を指定
const PROGRESS_ORDER = [
  'UNOPENED',
  'OPENED',
  'SCROLLED',
  'SAVED',
  'ADVICE_OPENED',
  'ADVICE_SCROLLED',
  'ADVICE_SAVED',
];

// aがbより小さいとき（イコール含む）
const isLessProgress = (a: string|null|undefined, b: string) => {
  if (a === null || a === undefined) {
    return true;
  }
  const an = PROGRESS_ORDER.findIndex((value) => value === a);
  const bn = PROGRESS_ORDER.findIndex((value) => value === b);
  return an <= bn;
};

// aがbより大きいとき（イコール含む）
export const isGreaterProgress = (a: string|null|undefined, b: string) => {
  if (a === null || a === undefined) {
    return false;
  }
  const an = PROGRESS_ORDER.findIndex((value) => value === a);
  const bn = PROGRESS_ORDER.findIndex((value) => value === b);
  return an >= bn;
};

export type NextStep = {
  contentId?: string|null,
  progress?: string|null,
  jisTid?: string|null,
};

// 全入力項目の確認が完了しているかどうか
const getIsAdviceOpened = (nextSteps: NextStep[], companyFlg: number|null|undefined) => {
  const stepAdvice = nextSteps.find((st) => st.contentId === 'advice');
  if (isGreaterProgress(stepAdvice?.progress, 'ADVICE_OPENED')) {
    return true;
  }

  let configDatas = CONFIG_DATAS.filter((data) => data.advice);
  const hasDc = getHasDc(companyFlg);
  if (!hasDc) {
    // 企業年金（DC）が無効の場合、DCを除外
    configDatas = configDatas.filter((data) => data.contentId !== 'dc');
  }
  const hasIdeco = getHasIdeco(companyFlg);
  if (!hasIdeco) {
    // 個人年金（iDeco）が無効の場合、iDeCoを除外
    configDatas = configDatas.filter((data) => data.contentId !== 'ideco');
  }

  return configDatas.every((data) => {
    const step = nextSteps.find((st) => st.contentId === data.contentId);
    return isGreaterProgress(step?.progress, 'OPENED');
  });
};

export type State = {
  list: NextStep[],

  // 企業フラグ
  companyFlg?: number|null,
}

const initialState: State = {
  list: [],
};

export const stepSlice = createSlice({
  name: 'step',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNextStep: (state, action: PayloadAction<NextStep[]>) => {
      state.list = action.payload;
    },

    // 画面遷移時にアクティブページを記録する
    pushNextStep: (state, action: PayloadAction<string>) => {
      const { list, companyFlg } = current(state);
      const steps = list.slice();
      const contentId = action.payload;
      const stepIndex = steps.findIndex((step) => step.contentId === contentId);
      if (stepIndex === -1) {
        // 最初に表示されたときにデータを追加
        steps.push({
          contentId,
          progress: 'OPENED',
        });
      } else if (isLessProgress(steps[stepIndex]?.progress, 'UNOPENED')) {
        // 最初に表示されたときにデータを更新
        steps[stepIndex] = {
          contentId,
          progress: 'OPENED',
        };
      } else {
        // 全入力項目の確認が完了したらアドバイスフラグを更新する
        const isAdvice = getIsAdviceOpened(steps, companyFlg);
        if (isAdvice) {
          const stepIndex2 = steps.findIndex((step) => step.contentId === contentId);
          if (stepIndex2 !== -1) {
            const { progress } = steps[stepIndex2];
            if (progress === 'OPENED') {
              steps[stepIndex2] = {
                contentId,
                progress: 'ADVICE_OPENED',
              };
            } else if (progress === 'SCROLLED') {
              steps[stepIndex2] = {
                contentId,
                progress: 'ADVICE_SCROLLED',
              };
            } else if (progress === 'SAVED') {
              steps[stepIndex2] = {
                contentId,
                progress: 'ADVICE_SAVED',
              };
            }
          }
        }
      }

      // 情報を更新
      state.list = steps;
    },

    // 画面遷移時にアクティブページを記録する
    updateNextStep: (state, action: PayloadAction<{
      contentId: string,
      progress: number,
    }>) => {
      const steps = current(state.list).slice();
      const { contentId, progress } = action.payload;
      const progressValue = PROGRESS_ORDER[progress];
      const stepIndex = steps.findIndex((step) => step.contentId === contentId);
      if (stepIndex === -1) {
        steps.push({
          contentId,
          progress: progressValue,
        });
      } else {
        steps[stepIndex] = {
          contentId,
          progress: progressValue,
        };
      }

      // 情報を更新
      state.list = steps;
    },

    setCompanyFlg: (state, action: PayloadAction<number|null|undefined>) => {
      state.companyFlg = action.payload;
    },
  },
});

export const {
  setNextStep, pushNextStep, updateNextStep,
  setCompanyFlg,
} = stepSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNextStep = (state: RootState) => state.step.list;

// 全入力項目の確認が完了し、「改善」項目を入力できるようなっているかどうか
export const selectIsAdviceOpened = (state: RootState) => getIsAdviceOpened(
  state.step.list,
  state.step.companyFlg,
);

// 目標メッセージ情報
export const selectStepData = (state: RootState) => {
  const steps = state.step.list;

  const stepFamily = steps.find((st) => st.contentId === 'family');
  if (isLessProgress(stepFamily?.progress, 'UNOPENED')) {
    // これは簡易結果です。より正確な結果を得るために、まずは「基本情報」の家族構成から入力を進めましょう！
    return CONFIG_DATAS[0];
  }

  const stepIncomings = steps.find((st) => st.contentId === 'incomings');
  if (isLessProgress(stepIncomings?.progress, 'UNOPENED')) {
    // 次に、あなたの「預金・収入」を確認、入力してみましょう！
    return CONFIG_DATAS[1];
  }

  const stepLivingCosts = steps.find((st) => st.contentId === 'livingCosts');
  if (isLessProgress(stepLivingCosts?.progress, 'UNOPENED')) {
    // 次に、あなたの「生活費」を確認、入力してみましょう！
    return CONFIG_DATAS[2];
  }

  // 子供がいるか、または予定があるか
  const hasChildren = selectHasChildren(state);
  if (hasChildren) {
    const stepEducation = steps.find((st) => st.contentId === 'education');
    if (isLessProgress(stepEducation?.progress, 'UNOPENED')) {
      // 次に、こどもの「教育費」を確認、入力してみましょう！
      return CONFIG_DATAS[3];
    }
  }

  const stepPresent = steps.find((st) => st.contentId === 'present');
  if (isLessProgress(stepPresent?.progress, 'UNOPENED')) {
    // 次に、あなたの「住宅費用」を入力してみましょう！
    return CONFIG_DATAS[4];
  }

  const stepPension = steps.find((st) => st.contentId === 'pension');
  if (isLessProgress(stepPension?.progress, 'UNOPENED')) {
    // 次に、あなたの「退職金・年金」を確認、入力してみましょう！
    return CONFIG_DATAS[5];
  }

  const stepRetirement = steps.find((st) => st.contentId === 'retirement');
  if (isLessProgress(stepRetirement?.progress, 'UNOPENED')) {
    // 次に、あなたの「老後の生活」を確認してみましょう！
    return CONFIG_DATAS[6];
  }

  const stepDepositFinAssets = steps.find((st) => st.contentId === 'depositFinAssets');
  if (isLessProgress(stepDepositFinAssets?.progress, 'UNOPENED')) {
    // 次に、あなたの「貯蓄・金融資産」を確認、入力してみましょう！ (資産形成・運用をしている場合)
    return CONFIG_DATAS[7];
  }

  const hasDc = getHasDc(state.step.companyFlg);
  if (hasDc) {
    const stepDc = steps.find((st) => st.contentId === 'dc');
    if (isLessProgress(stepDc?.progress, 'UNOPENED')) {
      // 次に、「企業年金 (DC)」の状況を確認・シミュレーションしてみましょう！
      return CONFIG_DATAS[8];
    }
  }

  const hasIdeco = getHasIdeco(state.step.companyFlg);
  if (hasIdeco) {
    const stepIdeco = steps.find((st) => st.contentId === 'ideco');
    if (isLessProgress(stepIdeco?.progress, 'UNOPENED')) {
      // 次に、「個人年金 (iDeCo)」の運用状況を確認・シミュレーションしてみましょう！
      return CONFIG_DATAS[9];
    }
  }

  const isAdviceOpened = (() => {
    const stepAdvice = state.step.list.find((st) => st.contentId === 'advice');
    return isGreaterProgress(stepAdvice?.progress, 'ADVICE_OPENED');
  })();
  if (!isAdviceOpened) {
    // 全入力項目の確認が完了し、「改善」項目を入力できるようになりました！「アドバイス」を見てみましょう！
    return CONFIG_DATAS[10];
  }

  // ローンがあるか、または予定があるか
  const { housingExpenses } = state.input.expenses;
  const hasMortgageLoan = (housingExpenses.loanOfHouseholder?.hasMortgageLoan ?? false)
   || (housingExpenses.loanOfSpouse?.hasMortgageLoan ?? false)
   || (housingExpenses.planToBuyHouse.willHaveMortgageLoan ?? false);
  if (hasMortgageLoan) {
    const stepPrepayment = steps.find((st) => st.contentId === 'prepayment');
    if (isLessProgress(stepPrepayment?.progress, 'UNOPENED')) {
      // 「住宅ローン繰り上げ返済」をすると、上手に資産形成することができます！
      return CONFIG_DATAS[11];
    }
  }

  if (hasDc) {
    const stepDc = steps.find((st) => st.contentId === 'dc');
    if (isLessProgress(stepDc?.progress, 'OPENED') && isAdviceOpened) {
      // 「企業年金 (DC)」で上手に資産運用すると、節税しながら貯蓄を増やすことができます！
      return CONFIG_DATAS[12];
    }
  }

  if (hasIdeco) {
    const stepIdeco = steps.find((st) => st.contentId === 'ideco');
    if (isLessProgress(stepIdeco?.progress, 'OPENED') && isAdviceOpened) {
      // 「個人年金 (iDeCo)」で上手に資産運用すると、節税しながら貯蓄を増やすことができます！
      return CONFIG_DATAS[13];
    }
  }

  const stepNisa = steps.find((st) => st.contentId === 'nisa');
  if (isLessProgress(stepNisa?.progress, 'UNOPENED') && isAdviceOpened) {
    // 「つみたてNISA」で資産運用すると、節税しながら貯蓄を増やすことができます！
    return CONFIG_DATAS[14];
  }

  const stepReEmployment = steps.find((st) => st.contentId === 'reEmployment');
  if (isLessProgress(stepReEmployment?.progress, 'UNOPENED') && isAdviceOpened) {
    // 「老後の再就職」により、収入の面で老後の暮らしに備えることができます！
    return CONFIG_DATAS[15];
  }

  const stepLivingCostsReduce = steps.find((st) => st.contentId === 'livingCostsReduce');
  if (isLessProgress(stepLivingCostsReduce?.progress, 'UNOPENED') && isAdviceOpened) {
    // 「生活費の削減」により、支出の面で老後の暮らしに備えることができます！
    return CONFIG_DATAS[16];
  }

  const deadLine = selectDeadline(state);
  if (isAdviceOpened && deadLine !== undefined) {
    // 目標の100歳まであと一歩！もう一度「アドバイス」に戻って「改善」項目の入力をしてみましょう！
    return CONFIG_DATAS[17];
  }
  // 目標の100歳を達成しています！「アドバイス」のライフプラン診断・商品を確認してみましょう！
  return CONFIG_DATAS[18];
};

export default stepSlice.reducer;
