import * as React from 'react';
import ToggleButton from '../button/ToggleButton';

type ToggleDetailProps = {
  className?: string;
};

/**
 * 詳細開閉コンポーネント
 * @param props
 * @returns
 */
const ToggleDetail: React.FC<ToggleDetailProps> = ({ className, children }) => {
  const [show, setShow] = React.useState(false);
  return (
    <div className={`bg-gray-100 rounded-sm px-5 ${className}`}>
      <div className="fieldset-body py-6">
        <div className="w-full flex items-center justify-between">
          <span className="text-base font-bold">{show ? '詳細を閉じる' : 'もっと詳しく入力する'}</span>
          <ToggleButton open={show} onClick={() => setShow(!show)} />
        </div>
      </div>
      {show && children}
    </div>
  );
};
ToggleDetail.defaultProps = {
  className: '',
};
export default ToggleDetail;
