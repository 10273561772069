import React from 'react';
import type { LabelProps } from 'recharts';

// components
import Amount from '../../block/Amount';

// 老後の生活イメージ (退職時～100歳までの平均) 専用ラベル
const RetirementGraphLabel: React.FC<LabelProps> = ({ y, value }) => {
  const posy = y as number;
  return (
    <g>
      <foreignObject x={90} y={posy + 10} width={200} height={60}>
        <div className="flex items-center">
          <div className="mr-2 pt-1 flex-shrink-0">
            <span className="text-sm text-primary">月額</span>
          </div>
          <Amount value={value as number} maxDigits={7} />
        </div>
      </foreignObject>
    </g>
  );
};
export default RetirementGraphLabel;
