import * as React from 'react';

// 価格の値を取得（限界桁数は+をつける）
import costValue from '../../services/costValue';

/**
 * 表の行コンポーネント
 * @param props
 * @returns
 */
type TableRowProps = {
  className?: string;
  label?: string;
  value?: number|null;
  empty?: string;

  // 小数点の桁数
  digits?: number;

  // 最大桁数
  maxDigits?: number;
};
const TableRow: React.FC<TableRowProps> = ({
  className, label, value, empty = '-', digits, maxDigits = 5,
}) => {
  // 単位
  const divisionNum = 10 ** 4;
  const divisionDigits = (digits !== undefined) ? 10 ** digits : 1;

  // 値
  const output = (value !== undefined && value !== null)
    ? Math.round((value / divisionNum) * divisionDigits) / divisionDigits
    : null;

  const unit = output !== null ? '万円' : '';

  return (
    <div>
      <div className={`flex justify-between items-center ${className}`}>
        <div className="flex-shrink-0 flex items-center">
          <span className="text-sm text-gray-500">{label}</span>
        </div>
        <div className="flex-auto flex flex-col items-end text-alpha text-2xl leading-none px-2 mb-2 break-all">
          {output !== null ? costValue(output, maxDigits) : empty}
        </div>
        {unit !== undefined && <p className="w-10 -mr-3 flex text-sm">{unit}</p>}
      </div>
    </div>
  );
};
TableRow.defaultProps = {
  className: '',
  label: '',
  value: undefined,
  empty: undefined,
  digits: undefined,
  maxDigits: undefined,
};
export default TableRow;
