import * as React from 'react';

// ヘッダー情報
import { useAppSelector } from '../../app/hooks';
import { selectHeaderHeight } from '../../reducers/headerReducer';

// 検証エラー用のオブジェクト
export type Error = {
  key: string,
  message: string,
}

type AlertProps = {
  className?: string;
  errors: Error[];
};

/**
 * アラートコンポーネント
 * @param props
 * @returns
 */
const Alert: React.FC<AlertProps> = ({ className, errors, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // スクロール位置のオフセットに合わせるために、ヘッダーの高さを取得
  const height = useAppSelector(selectHeaderHeight);

  // スクロール位置のマージン
  const margin = 10;

  // 入力エラーが発生したらメッセージまでスクロール
  React.useEffect(() => {
    if (errors.length > 0) {
      const $el = ref.current;
      if ($el !== null) {
        window.scrollTo(0, $el.offsetTop - height - margin);
      }
    }
  }, [errors]);

  return (
    <div ref={ref}>
      {
        errors.length > 0 && (
          <div className={`${className}`}>
            <h3 className="text-sm bg-rose text-white px-4 py-2">{children}</h3>
            <ul className="text-sm bg-caution-contain text-caution p-4">
              {
                errors.map((error) => <li key={error.key}>{error.message}</li>)
              }
            </ul>
          </div>
        )
      }
    </div>
  );
};
Alert.defaultProps = {
  className: '',
};

export default Alert;
