import * as React from 'react';

// 型
import type { EducationExpensesChildren, EducationPolicy } from '../../reducers/inputReducer';

// components
import type { InputSelectOption } from '../../features/input/InputSelect';
import SumTable from '../../features/table/SumTable';
import School from './School';

// 学校データ
export type SchoolStatus = 'NONE' | 'PRE_SCHOOL' | 'PRIMARY_SCHOOL' | 'JUNIOR_HIGH_SCHOOL' | 'HIGH_SCHOOL' | 'UNIVERSITY';

// 小学校・中学校・高校の区分選択
export const publicPrivateTypeOptions: InputSelectOption[] = [
  { value: 'PRIVATE', label: '私立' },
  { value: 'PUBLIC', label: '公立' },
];

// 大学の区分選択
export const universityTypeOptions: InputSelectOption[] = [
  { value: 'PRIVATE_HUMANITIES', label: '私立文系' },
  { value: 'PRIVATE_SCIENCE', label: '私立理系' },
  { value: 'PUBLIC', label: '国立' },
];

type FieldsetProps = {
  prefix: string;
  status: SchoolStatus,
  educationPolicy: EducationPolicy|null,
  datas: EducationExpensesChildren,

  // 満年齢
  age: number,

  onChange?: (_:EducationExpensesChildren) => void,
  onTotal?: (_:number) => void,
}
const Fieldset: React.FC<FieldsetProps> = ({
  prefix, status, educationPolicy, datas, age, onChange, onTotal,
}) => {
  // 小学校情報
  const [primarySchool, setPrimarySchool] = React.useState(datas.primarySchool);
  const [primarySchoolTotal, setPrimarySchoolTotal] = React.useState(0);

  // 中学校情報
  const [juniorHighSchool, setJuniorHighSchool] = React.useState(datas.juniorHighSchool);
  const [juniorHighSchoolTotal, setJuniorHighSchoolTotal] = React.useState(0);

  // 高校情報
  const [highSchool, setHighSchool] = React.useState(datas.highSchool);
  const [highSchoolTotal, setHighSchoolTotal] = React.useState(0);

  // 大学情報
  const [university, setUniversity] = React.useState(datas.university);
  const [universityTotal, setUniversityTotal] = React.useState(0);

  // 合計
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    // 更新
    if (onChange !== undefined) {
      onChange({
        primarySchool,
        juniorHighSchool,
        highSchool,
        university,
      });
    }
  }, [
    primarySchool,
    juniorHighSchool,
    highSchool,
    university,
  ]);

  React.useEffect(() => {
    // 合計値計算
    const newTotal = primarySchoolTotal
      + juniorHighSchoolTotal
      + highSchoolTotal
      + universityTotal;
    setTotal(newTotal);

    // 更新
    if (onTotal !== undefined) {
      onTotal(newTotal);
    }
  }, [
    primarySchoolTotal,
    juniorHighSchoolTotal,
    highSchoolTotal,
    universityTotal,
  ]);

  // 小学校から開始するかどうか
  const isStartPrimarySchool = (s: string) => (s !== 'NONE')
      && (s !== 'PRIMARY_SCHOOL')
      && (s !== 'JUNIOR_HIGH_SCHOOL')
      && (s !== 'HIGH_SCHOOL')
      && (s !== 'UNIVERSITY');

  // 中学校から開始するかどうか
  const isStartJuniorHighSchool = (s: string) => (s !== 'NONE')
      && (s !== 'JUNIOR_HIGH_SCHOOL')
      && (s !== 'HIGH_SCHOOL')
      && (s !== 'UNIVERSITY');

  // 高校から開始するかどうか
  const isStartHighSchool = (s: string) => (s !== 'NONE')
    && (s !== 'HIGH_SCHOOL')
    && (s !== 'UNIVERSITY');

  // 大学生から開始するかどうか
  const isStartUniversity = (s: string) => (s !== 'NONE')
    && (s !== 'UNIVERSITY');

  return (
    <>
      <div className="fieldset-body">
        <School
          label="小学校"
          prefix={`${prefix}PRIMARY_SCHOOL`}
          datas={primarySchool}
          options={publicPrivateTypeOptions}
          disabled={!isStartJuniorHighSchool(status)}
          grade="PRIMARY_SCHOOL"
          educationPolicy={educationPolicy}
          isEntranceFee={isStartPrimarySchool(status)}
          period={Math.min(13 - age, 6)}
          onChange={(data, t) => {
            setPrimarySchool(data);
            setPrimarySchoolTotal(t);
          }}
        />
      </div>
      <div className="fieldset-body">
        <School
          label="中学校"
          prefix={`${prefix}JUNIOR_HIGH_SCHOOL`}
          datas={juniorHighSchool}
          options={publicPrivateTypeOptions}
          disabled={!isStartHighSchool(status)}
          grade="JUNIOR_HIGH_SCHOOL"
          educationPolicy={educationPolicy}
          isEntranceFee={isStartJuniorHighSchool(status)}
          period={Math.min(16 - age, 3)}
          onChange={(data, t) => {
            setJuniorHighSchool(data);
            setJuniorHighSchoolTotal(t);
          }}
        />
      </div>
      <div className="fieldset-body">
        <School
          label="高校"
          prefix={`${prefix}HIGH_SCHOOL`}
          datas={highSchool}
          options={publicPrivateTypeOptions}
          disabled={!isStartUniversity(status)}
          grade="HIGH_SCHOOL"
          educationPolicy={educationPolicy}
          isEntranceFee={isStartHighSchool(status)}
          period={Math.min(19 - age, 3)}
          onChange={(data, t) => {
            setHighSchool(data);
            setHighSchoolTotal(t);
          }}
        />
      </div>
      <div className="fieldset-body">
        <School
          label="大学"
          prefix={`${prefix}UNIVERSITY`}
          help={(
            <>
              カスタマイズ入力する方へ：
              <br />
              大学は｢4年制｣を前提に計算しています。
              ｢6年制｣の大学にお通い中、もしくは通われることを想定する場合は、1年あたりに所要する実金額の1.5倍の金額を入力してください。
            </>
          )}
          datas={university}
          options={universityTypeOptions}
          disabled={!(status !== 'NONE')}
          grade="UNIVERSITY"
          educationPolicy={educationPolicy}
          isEntranceFee={isStartUniversity(status)}
          period={Math.min(23 - age, 4)}
          onChange={(data, t) => {
            setUniversity(data);
            setUniversityTotal(t);
          }}
        />
      </div>
      <div className="fieldset-body">
        {(status !== 'NONE')
          ? (
            <SumTable
              label="合計"
              unit="万円"
              division={4}
              value={total}
            />
          )
          : (
            <SumTable
              label="合計"
              unit=""
            />
          )}
      </div>
    </>
  );
};
Fieldset.defaultProps = {
  onChange: undefined,
  onTotal: undefined,
};
export default Fieldset;
